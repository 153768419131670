section.blog {
  position: relative;
  padding-bottom: 12.5vh;
  z-index: 10;
  background-color: $main-white;
  img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 500px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.blog-single {
  position: relative;
  padding: 12.5vh 0;
  &::before {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 660px !important;
    background-color: #e0f2f9;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }
}

.blog-single,
.module {
  h1 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1.4em;
  }
  a {
    color: $light-blue;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 700;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 1.2em 0;
  }
  p {
    font-size: 17px;
    margin: 1em 0;
  }
  li {
    font-size: 17px;
    margin-bottom: 10px;
  }
}

.blog-single-sidebar {
  border-top: 5px solid #f5bd43;
  h2 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  img {
    display: block;
    width: 60px !important;
    height: 40px !important;
    object-fit: cover;
  }
  h3 {
    margin: 0;
    padding-left: 1rem;
    font-size: 1rem;
    color: $main-blue;
    font-weight: 500;
  }
  a {
    display: block;
    margin-bottom: 1.5rem;
    &:hover {
      text-decoration: none;
      h3 {
        color: $light-blue;
      }
    }
  }
}

.blog-content-share {
  align-items: center;
  a {
    margin-right: 1rem;
    margin-top: 10px;
  }
  img {
    width: 125px !important;
  }
}

.blog-share-text {
  width: 100%;
  margin-bottom: 0 !important;
}

.cta-banner {
  h2 {
    font-weight: bold;
  }
  a {
    background-color: $main-yellow;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    font-size: 17px;
    &:hover {
      text-decoration: none;
      color: $main-blue;
      background: #f5bd43;
    }
  }
}

@media screen and (min-width: 768px) {
  .blog-single-sidebar {
    h2 {
      padding: 1.5rem 0;
    }
  }

  .blog-share-text {
    width: auto;
    margin: 0 !important;
  }

  .blog-content-share {
    a {
      margin-left: 1rem;
      margin-top: 0;
    }
  }
}
