.general-logout-title {
  font-size: 1.45em;
}

.pricing-page {
  background: #fff;
  h1 {
    font-size: 1.45em;
  }
  h1,
  h2 {
    font-weight: 700;
  }
  .close-button {
    position: fixed;
    top: 25px;
    right: 50px;
    z-index: 100;
    width: 25px;
    cursor: pointer;
  }
}

.pricing-page-heading {
  background-color: #e0f2f9;
  position: relative;
  .container {
    position: relative;
    z-index: 1;
  }
}

.pricing-card {
  height: 100%;
  background: #fff;
  h2 {
    font-size: 1.1em;
    font-weight: 400;
    position: relative;
    color: #0094cc;
    padding-top: 1.65em !important;
  }
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.payment-button {
  font-size: 1em;
  border: 1px solid #0094cc;
  border-radius: 5px;
  color: #0094cc;
  min-width: calc(100% - 1em);
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
}

.mogul-message {
  position: absolute;
  top: -21px;
  margin: auto;
  left: 0;
  right: 0;
  width: 65%;
  z-index: 10;
  .message-text {
    background: #fff;
    border: 1px solid rgba($main-grey, 0.1);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: #0094cc;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.1px;
  }
  .date-text {
    color: rgba(0, 18, 43, 0.4);
    font-style: italic;
    margin-bottom: 0.4em;
    font-size: 1em;
    display: none;
  }
}

.free-box {
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 5px;
  .message-text {
    max-width: 200px;
    margin: 0 auto;
  }
  p {
    margin-bottom: 0;
  }
  .plan-title {
    font-size: 1.15em;
    font-weight: 700;
  }
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #00122b;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
  }
  .button-downgrade {
    color: rgba(0, 18, 43, 0.4);
    font-size: 1em;
  }
  .grey-dot {
    background: rgba(0, 18, 43, 0.4);
  }
}

.highlighted-channel {
  position: absolute;

  h2 {
    color: #fff;
  }
  .pricing-card {
    background: #0094cc;
    color: #fff;
  }
  .payment-button {
    color: #0094cc;
    background: #fff;
    &:hover {
      background-color: rgba(#fff, 0.9);
    }
  }
  .pricing-description {
    background: rgba(0, 28, 53, 0.25);
  }
  .view-features {
    color: #fff;
  }
  .pricing-card--billed {
    color: rgba(#fff, 0.6);
  }
  .payment-button-block {
    border: 1px solid #fff;
    color: #fff;
    background: $hover-blue;
    &:hover {
      background: $hover-blue;
    }
  }
  .pricing-padlock {
    background: url('#{$cdn-url}padlock-lightblue.svg') center / contain no-repeat;
  }
  .pricing-features-container {
    li {
      background: url('#{$cdn-url}tick-white.svg') left 8px / 15px auto no-repeat;
    }
  }
}

.active-period {
  color: $hover-blue !important;
}

.pricing-description {
  background: rgba(212, 212, 212, 0.3);
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.9em;
  p {
    margin-bottom: 0;
  }
}

.pricing-card--title {
  border-radius: 5px 5px 0px 0px;
  position: relative;
  min-height: 180px;
}

.pricing-feature-more {
  text-indent: -10000px;
  display: inline-block;
  height: 13px;
  width: 13px;
  background: url('#{$cdn-url}information-icon.svg') center/contain no-repeat;
  overflow: hidden;
  margin-left: 6px;
  vertical-align: middle;
  margin-top: -3px;
  cursor: pointer;
}

.mogul-title {
  &::before {
    background-color: #f2a805;
  }
}

.pricing-card--price {
  font-size: 2.3em;
  line-height: 1;
  .from-text {
    font-size: 0.5em;
  }
  .month-text {
    font-weight: 400;
    font-size: 0.5em;
  }
}

.pricing-card--billed {
  color: rgba(0, 18, 43, 0.4);
  margin-top: 1.5em;
  font-size: 0.9em;
  font-style: italic;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}
.pricing-feature-tooltip {
  text-align: left;
  border-radius: 5px !important;
  padding: 13px 21px;
  line-height: 1.45em;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.price-container {
  p {
    margin-bottom: 0;
  }
}

.slider {
  cursor: pointer;
  background-color: #ebecec;
  box-shadow:
    0 0 0 5px #f7f7f7,
    0 0 10px 0 rgba(0, 0, 0, 0.4);
  transition: 0.75s;
  border-radius: 44px;
  width: 68px;
  height: 24px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    transition: 0.75s;
    box-shadow:
      0 0 3px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
}
.slider-annually {
  &:before {
    left: 42px;
  }
}
.slider-monthly {
  &:before {
    left: 3px;
  }
}
.toggle-container {
  p {
    margin-bottom: 0;
    font-size: 0.85rem;
    font-weight: 700;
    color: rgba(0, 18, 43, 0.4);
    span {
      color: #0094cc;
      font-size: 0.95em;
      font-style: italic;
      font-weight: 400;
    }
  }
}

.pricing-features {
  background-color: #f9f9f9;
  th {
    font-size: 1.2em;
  }
  tr {
    td,
    th {
      border: none;
      padding: 1em 0;
      vertical-align: middle;
      width: 150px !important;
      &:not(:first-child) {
        text-align: center;
      }
      &:first-child {
        width: 210px !important;
      }
      &:nth-child(2) {
        background: rgba(212, 212, 212, 0.2);
        border-left: 1px solid rgba(212, 212, 212, 0.4);
      }
      &:nth-child(4) {
        background: rgba(212, 212, 212, 0.2);
        border-right: 1px solid rgba(212, 212, 212, 0.4);
        border-left: 1px solid rgba(212, 212, 212, 0.4);
      }
    }
  }
  .container {
    max-width: 1000px;
  }
}

.pricing-without-free {
  tr {
    td {
      &:first-child {
        width: 340px !important;
      }
    }
  }
}

.vat-message {
  background-color: #f9f9f9;
  p {
    font-size: 1.2rem;
  }
  .bottom-terms {
    border-top: none;
    a {
      font-size: 0.9em;
    }
  }
}

.current-plan-button {
  //background:
  background: #f0f3fc !important;
  box-shadow: none !important;
  color: #807d7d !important;
  cursor: auto !important;
  text-transform: none !important;
}

//Solution found here https://stackoverflow.com/questions/7455502/webkit-backface-visibility-not-working/9276526#9276526
.side-a,
.side-b {
  backface-visibility: hidden;
  opacity: 0.99;
}

.side-b {
  position: absolute;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);
  height: 100%;
  transform: rotateY(-180deg);
}

.price-box {
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  .side-a,
  .side-b {
    height: 100%;
  }
}

.subscription-details {
  h3 {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 0;
  }
  .policy-list {
    li {
      padding-left: 1.65em;
      margin-bottom: 1em;
      background: url('#{$cdn-url}tick.png') left center / 15px auto no-repeat;
    }
  }
  .block-list {
    color: rgba(0, 18, 43, 0.5);
    background: url('#{$cdn-url}padlock.svg') left center / 14px auto no-repeat !important;
  }
}

.view-features {
  font-size: 0.95em;
  cursor: pointer;
  padding-bottom: 1.75em;
  color: rgba(0, 18, 43, 0.6);
  margin: 0;
}

.pricing-mobile-title {
  flex: 1 1;
  background: #f5bd43;
  text-align: center;
  padding: 1rem 0;
  cursor: pointer;
  p {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.2em;
  }
}

.pricing-mobile-mogul {
  background: #f2a805;
}

.pricing-mobile-title-active {
  p {
    text-decoration: underline;
    font-weight: 900 !important;
  }
}

.pricing-mobile-price {
  background: #f4efed;
  text-align: center;
  p {
    margin-bottom: 0;
    font-weight: 700 !important;
    font-size: 1.5em;
    line-height: 1.3;
  }
  .pricing-note {
    font-size: 0.95em;
    font-weight: 400 !important;
    font-style: italic;
  }
}

.features-mobile {
  background: #f4efed;
  li {
    margin-bottom: 1rem;
  }
}

.mobile-buttons-container {
  text-align: center;
  padding: 1.2rem 0;
  p {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 0;
  }
  .general-button {
    @extend .btn-list-update;
    @extend .save-changes-button;
    text-transform: uppercase;
  }
}

.mogul-container {
  .general-button {
    margin-top: 1.2em;
  }
}

.pricing-tooltip {
  cursor: pointer;
  font-weight: 900;
  font-style: normal;
  color: #001c35;
  background-color: #f4efed;
  border-radius: 50%;
  font-size: 0.9em;
  padding: 3px 8.5px;
  text-align: center;
  &:hover {
    + .keyword-description {
      display: block;
    }
  }
  &::before {
    font-size: 1.08rem;
    vertical-align: middle;
  }
}

.pricing-tooltip-description {
  max-width: 400px;
}
.disclaimer-tooltip {
  max-width: 360px;
}
.table-item-cell-tooltip {
  max-height: 34em;
  overflow: hidden;
  white-space: break-spaces;
}
.sender-tooltip {
  max-width: 180px;
}

.pricing-loading-text {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.pointer {
  cursor: pointer !important;
}

.upgrade-button-block {
  position: relative;
  color: rgba(0, 18, 43, 0.5) !important;
  padding-right: 26px !important;
  background: #f0f3fc url('#{$cdn-url}padlock.svg') calc(100% - 13px) center/12px auto no-repeat !important;
  &:hover {
    .upgrade-message {
      display: block !important;
    }
  }
}

.upgrade-message-pricing {
  text-transform: none;
  font-size: 0.75em;
  width: 214px;
  top: -31px !important;
  left: -28px;
  font-weight: 400;
  z-index: 10;
}

.pricing-padlock {
  text-indent: -10000px;
  background: url('#{$cdn-url}padlock.svg') center/contain no-repeat;
  width: 13px;
  height: 14px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}

.payment-button-block {
  border: 1px solid rgba(0, 18, 43, 0.4);
  color: rgba(0, 18, 43, 0.4);
  position: relative;
  &:hover {
    .upgrade-message {
      display: block !important;
    }
  }
}

.pricing-contact-button {
  color: rgba(0, 18, 43, 0.6);
}

.pricing-table {
  z-index: 1;
  position: relative;
  .table {
    table-layout: fixed;
  }
  .payment-button {
    font-size: 0.8em;
    min-width: 120px;
    padding: 0.45em 0.75em !important;
    margin-bottom: 0.45em;
  }
  .beta-feature {
    display: none;
  }
}

.highlighted-payment-button {
  .payment-button {
    border: 1px solid $hover-blue;
    background-color: $hover-blue;
    color: #fff;
  }
  .pricing-padlock {
    background: url('#{$cdn-url}padlock-white.svg') center / contain no-repeat;
  }
}

.circle-tick {
  width: 26px;
}

.pricing-matrix-title {
  h4 {
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 0 !important;
    font-weight: 700;
  }
  td {
    border-bottom: 1px solid rgba(212, 212, 212, 0.4) !important;
    padding-bottom: 0.5em !important;
  }
}

.pricing-users-title {
  td {
    border-bottom: none !important;
    padding-bottom: 2em !important;
  }
}

.pricing-plan-title {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-bottom: 1px solid rgba(212, 212, 212, 0.6);
  }
}

.pricing-toggle {
  height: 23px !important;
  &:focus {
    box-shadow: none !important;
  }
  &::after {
    top: 1px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
  }
}

.toggle-skeleton {
  background-color: #f3eeee !important;
  &::after {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  }
}

.toggle-annually {
  &::after {
    left: 18px !important;
  }
}

.pricing-features-list {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 65%;
    border-top: 1px solid rgba(212, 212, 212, 0.6);
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
  }
  li {
    margin-bottom: 0.75em;
  }
}

.Monitoring-feature-2 {
  td {
    &:nth-child(4) {
      padding-left: 1.14em;
    }
  }
}

.pricing-description {
  p {
    text-align: center !important;
  }
}

.pricing-features-container {
  max-width: 250px;
  margin: 0 auto;
  li {
    background: url('#{$cdn-url}tick-blue.svg') left 8px / 15px auto no-repeat;
    padding-left: 25px;
  }
}

.enterprise-card {
  .contact-us-price {
    font-weight: 400;
    font-size: 1.9em;
  }
}

.coupon-banner {
  background: #00122b;
  color: #fff;
  display: table;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 3em;
}

.pricing-toggle-tabs {
  border: 1px solid #d4d4d4;
  background: white;
  border-radius: 100px;
  display: inline-flex;
  margin: 0 auto;
}

.toggle-pricing-button {
  padding: 13px 25px;
  border-radius: 100px;
  position: relative;
  z-index: 1;
}

.toggle-pricing-button.active {
  color: $main-white;
  background-color: $light-blue;
}

.pricing-toggle-indicator {
  border-radius: 100px;
  position: absolute;
  background-color: $light-blue;
  top: 0;
  left: 0;
  height: 50px;
  width: 245px;
  transition: 0.2s ease-in-out;
  z-index: 0;
}

.pricing-unauthenticated-banner-text {
  max-width: 758px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 20px !important;
}

.pricing-toggle-mobile {
  .dropdown-item-element {
    &::after {
      top: 9px;
    }
  }
}

.pricing-options-mobile {
  width: calc(100% + 40px);
  left: -20px;
  padding: 1.2rem 0rem 0.5rem 0rem;
  button {
    width: 100%;
  }
}

.pricing-feature-item {
  .pricing-feature-more {
    margin-top: 1px;
  }
}

.select-contact-pricing {
  .frequency-dropdown {
    border-radius: 5px;
  }
  .dropdown-item-element {
    &::after {
      top: 13px;
      right: 9px;
    }
  }
}

.select-contact-value {
  text-align: center;
  min-width: 80px;
}

.billing-page-main-container {
  width: calc(100% + 2em);
  margin-left: -1em;
}

.agency-small-check {
  margin-top: 3px;
}

@media screen and (min-width: 375px) {
  .slider {
    width: 100px;
    height: 24px;
    &:before {
      height: 24px;
      width: 24px;
    }
  }
  .slider-annually {
    &:before {
      left: 71px;
    }
  }
}

@media screen and (min-width: 500px) {
  .toggle-container {
    p {
      font-size: 1rem;
    }
  }
  .slider {
    width: 120px;
    height: 44px;
    &:before {
      height: 44px;
      width: 44px;
    }
  }
  .slider-annually {
    &:before {
      left: 72px;
    }
  }
}

@media screen and (min-width: 768px) {
  .agency-small-check {
    margin-top: 1px;
  }
}

@media screen and (min-width: 992px) {
  .pricing-unauthenticated-banner-text {
    width: unset;
  }

  .billing-page-main-container {
    width: 100%;
    margin-left: 0;
  }

  .billing-page-calculation {
    border-left: 1px solid rgba($main-grey, 0.5);
  }

  .highlighted-payment-button {
    .payment-button {
      transition: 0.2s ease-in;
      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: rgba($hover-blue, 0.8);
      }
    }
  }

  .agencies-padding-pricing {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pricing-table {
    .beta-feature {
      display: inline-block;
    }
  }

  .view-features {
    &:hover {
      text-decoration: underline;
    }
  }

  .general-logout-title {
    font-size: 2.25em;
  }

  .pricing-page {
    h1 {
      font-size: 2.25em;
    }
  }

  .pricing-features-container {
    max-width: none;
  }

  .pricing-page-heading {
    background-color: #fff;
    &::before {
      background-color: #f9f9f9;
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
      z-index: 0;
    }
  }

  .pricing-features {
    position: relative;
    padding-bottom: 150px !important;
    background-color: #fff;
    &::after {
      content: '';
      background-color: #f9f9f9;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      clip-path: polygon(100% 85%, 0% 100%, 100% 100%);
      z-index: 0;
    }
  }

  .toggle-pricing-button.active {
    background-color: transparent;
  }
}
