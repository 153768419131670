.calendar-page-container {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  min-height: 100%;
  .empty-container {
    border: none;
  }
}

.calendar-list-title {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  padding: 1em 1.3em;
}
.calendar-item {
  position: relative;
  .item-share-feedback-container {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  }
}

.calendar-item.item-highlighted {
  padding: 0px 15px;
  background-color: #fdeecd;
  border-radius: 3px;
  border: 1px solid #d4d4d4;
  h4 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .policymogul-keyword-match {
    background-color: #f9f9f9 !important;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 0px 3px 1px 3px;
  }
  .upcoming-event-body-container {
    padding: 0px !important;
  }
}

.calendar-item.no-highlight {
  .calendar-save-button-container {
    margin-right: 1em;
  }
}

.events-color-badge {
  padding: 5px 15px;
  background-color: #fdeecd;
  border-radius: 3px;
  border: 1px solid #d4d4d4;
}

.calendar-category {
  font-size: 0.75em;
  text-transform: uppercase;
  color: #736598;
  border-bottom: 1px solid #736598;
  font-weight: bold;
  padding-bottom: 0.65em;
}

.calendar-category-container {
  &:not(:first-child) {
    margin-top: 1em !important;
  }
}

.calendar-category-lords {
  color: rgba(180, 10, 55, 0.8);
  border-bottom: 1px solid rgba(180, 10, 55, 0.8);
}

.calendar-category-commons {
  color: rgba(0, 101, 72, 0.8);
  border-bottom: 1px solid rgba(0, 101, 72, 0.8);
}

.upcoming-event {
  h4 {
    font-weight: 400;
    line-height: 1.4;
  }
}

.adjustment-upcoming-event-width {
  width: calc(100% - 50px);
}

.upcoming-event-open {
  h4 {
    color: #0094cc;
  }
}

.upcoming-event-expandable {
  h4 {
    &:hover {
      color: #0094cc;
    }
  }
}

.calendar-more-button {
  font-weight: 700;
  font-size: 0.85em;
  position: relative;
  padding-right: 18px;
  .icon-arrow-down {
    color: #9c9c9c;
    margin-left: 8px;
    font-size: 6px;
    display: inline-block;
    transform: translateY(-2px);
    transition: 0.2s ease-in;
  }
}

.calendar-less-button {
  .icon-arrow-down {
    transform: translateY(-2px) rotate(180deg);
  }
}

.current-date-tooltip {
  margin-top: -5px !important;
}

.calendar-current-date {
  font-size: 18px;
  margin-left: -5px;
  span {
    display: inline-block;
  }
  a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-indent: -1000px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: -3px;
    &:after {
      position: absolute;
      content: '';
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 9px solid $main-yellow;
      top: 7px;
      left: 8px;
    }
  }
}

.calendar-picker-controls-list {
  min-width: 60px;
}

.calendar-current-date-next {
  &:after {
    left: 12px !important;
    border-right: none !important;
    border-left: 9px solid $main-yellow;
  }
}

.today-link {
  color: #00122b;
  text-decoration: underline;
}

.today-button {
  border-radius: 5px !important;
  &:hover {
    text-decoration: none;
  }
}

.calendar-select-date {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  color: rgba(0, 18, 43, 0.75);
  background: none !important;
  padding-left: 18px !important;
  .icon-calendar {
    margin-right: 8px;
    display: inline-block;
    transform: translateY(1.5px);
  }
}

.calendar-picker {
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  .date-input-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    .input-wrapper {
      display: flex;
      input {
        border: 1px solid rgba(#848484, 0.3);
        border-radius: 5px;
        padding: 0.3em;
        outline: none;
        height: auto;
        width: 100%;
        transition: 0.2s ease-in;
        background: #f9f9f9;
        &:disabled {
          background: none;
          color: #00122b;
        }
        &:focus {
          border: 1px solid #00122b;
          box-shadow: none;
        }
        &:last-child {
          width: 80px;
        }
      }
    }
    .single-date-input {
      input {
        flex-grow: 1;
        &:last-child {
          width: 100%;
        }
      }
    }
  }
  .DayPicker-Day--selected {
    border-radius: 50% !important;
    background: none !important;
    border: 1px solid #00122b !important;
    &:hover {
      background: none !important;
    }
  }
  .DayPicker-Day {
    &:hover {
      border-width: 2px !important;
    }
  }
  .DayPicker-Day--today {
    border: none !important;
    &:hover {
      background: none !important;
    }
  }
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today {
    border: 1px solid #00122b !important;
    border-radius: 50% !important;
  }
}

.calendar-picker-date {
  width: fit-content;
  border: 1px solid rgba(132, 132, 132, 0.5);
  margin: 0 auto;
  border-radius: 3px;
  min-width: 184px;
}

.calendar-select-date-selected {
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #d4d4d4;
  border-radius: 5px 5px 0px 0px;
  z-index: 100;
}

.search-calendar-item {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
}

.search-calendar-date {
  padding-bottom: 0.5em;
  margin-bottom: 1.6em;
  font-size: 1.188rem;
}

.search-event-container {
  h3 {
    margin-top: 0.5em;
  }
}

.calendar-main-title {
  .main-title {
    min-width: 0;
  }
}

.calendar-current-date {
  span {
    min-width: 225px;
  }
}

.date-link-padding {
  width: 100%;
}

.mobile-calendar-picker {
  .reference-calendar-picker {
    border: none;
    margin-top: 0;
    min-width: 258px;
    max-width: 258px;
  }
}

.reference-calendar-picker {
  top: 0;
  box-shadow: none !important;
}

.calendar-save-button-container {
  max-height: 30px;
}

.calendar-matching-lists {
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 12px 15px;
  .explanation-tag {
    background-color: #fff;
    font-size: 0.875rem;
    background-position: 10px 10px;
    cursor: auto;
    &:hover {
      background-color: #fff;
    }
  }
}

.calendar-tag-list {
  .rendering-items-container {
    margin-top: 0px;
    margin-bottom: 12px;
  }
  .explanation-tag {
    background-color: #fff;
    cursor: auto;
    &:hover {
      background-color: #fff;
    }
  }
}

@media screen and (min-width: 480px) {
  .calendar-list-title {
    padding: 1em 2.1em;
  }
}

@media screen and (min-width: 992px) {
  .calendar-current-date {
    margin-left: 0;
    a {
      transition: 0.2s ease-in;
      &:hover {
        background-color: rgba($light-blue, 0.06);
      }
    }
  }

  .reference-calendar-picker {
    margin: 0px 0px 11px 0px;
  }
}
