.outside-filters {
  padding-top: 8px;
  .toggle-title {
    font-size: 1rem;
  }
  .dropdown-item-element.open {
    &::after {
      top: 9px;
    }
  }
  .filter-toggle {
    height: 20px;
    width: 38px;
    max-width: 38px;
    min-width: 38px;
  }
  .toggle-item {
    width: 16px;
    height: 16px;
    left: 3px;
    background: #fff;
  }
  .filter-toogle-active {
    .toggle-item {
      transform: translateX(16px);
    }
  }
  .suboptions-list {
    margin-top: -4px;
    padding-bottom: 18px !important;
    padding-left: 2em;
    .radio-button {
      label {
        padding-left: 28px;
        font-size: 15px;
        &::before {
          width: 19px;
          height: 19px;
        }
        &::after {
          width: 9px;
          height: 9px;
        }
      }
    }
  }
  .sort-mode-radio-stakeholders label {
    padding: 2px 0 0 34px !important;
  }
  .label-description {
    font-size: 14px;
  }
}

.outside-filters-spacing-container {
  padding: 20px;
}

.outside-filters-box {
  padding-bottom: 80px;
}

.outside-filters-around-container {
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgba($main-grey, 0.5);
  border-bottom: none;
}

.popup-outside-filter-container {
  &:not(:first-child) {
    padding-top: 16px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba($main-grey, 0.5);
    padding-bottom: 16px;
  }
  .main-input {
    margin-bottom: 10px;
  }
  .dropdown-item-element {
    -webkit-appearance: none !important;
    outline: none !important;
  }
}

.filter-item-dropdown {
  padding: 10px 12px 10px 16px;
}

.dropdown-filter-options {
  padding-top: 8px;
  max-height: 500px;
  overflow-y: auto;
}

.common-outside-filter {
  .filter-item-container {
    .checkbox-item {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .filter-item-dropdown {
    padding: 10px 12px 10px 16px;
    & .checkbox-item {
      margin: 0;
    }
  }
  .checkbox-item {
    display: flex;
    input {
      display: none;
    }
    label {
      display: block;
      padding-left: 34px;
      height: auto;
      line-height: 1.2;
      margin-top: -2px;
    }
  }
  .custom-filter-house {
    label {
      margin-top: -1px;
    }
  }
}

.outside-collapsing-options {
  .radio-button,
  .checkbox-item {
    color: rgba($main-blue, 0.8);
  }
}

.relevant-extract-option {
  color: rgba($main-blue, 0.8);
}

.outside-filters-title {
  min-height: 24px;
  margin-bottom: 0.5em;
}

.filters-content-scrollbar,
.top-content-scrollbar {
  .ScrollbarsCustom-TrackY {
    top: 0px !important;
  }
}

.outside-filters-container {
  @extend .outside-filters-container-width;
  display: none;
}
.outside-filters-stakeholder {
  margin-top: 7px;
}

.simple-input-box {
  padding: 4px 10px;
  .main-search-icon {
    margin-right: 8px;
  }
}

.stakeholder-mapping-skeleton {
  margin-top: 51px;
}

.skeleton-adjustment {
  padding-top: 1px;
}

.label-country-sort {
  background-position: 31px 2px;
  background-size: 28px 17px;
  padding-left: 70px !important;
  background-repeat: no-repeat;
}

.group-choose-topic-popup,
.group-filter-item {
  .label-country-sort {
    background-position: 14px 1px;
    padding-left: 55px !important;
    margin-bottom: 0px !important;
    padding-bottom: 8px;
  }
}

.nations-sort-subtext {
  background-position: 0px 2px;
  background-size: 28px 17px;
  padding-left: 38px !important;
  background-repeat: no-repeat;
  margin-top: 10px;
  display: inline-block;
}
.nations-sort-subtext.westminster {
  @extend .uk-flag;
}
.nations-sort-subtext.senedd {
  @extend .label-wales;
}
.nations-sort-subtext.holyrood {
  @extend .label-scotland;
}

@media screen and (min-width: 992px) {
  .outside-filters-container-width {
    &:not(.outside-filters-container-width:has(.total-result-more-1000)) {
      width: 250px;
    }
  }

  .outside-filters-container {
    display: block;
  }
  .outside-filters {
    .suboptions-list {
      input[type='radio'] {
        &:hover + label:before {
          box-shadow: 0px 0px 0px 7px rgba(0, 28, 53, 0.06);
          background-color: rgba(0, 28, 53, 0.06);
        }
      }
    }
  }
}
