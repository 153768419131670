.team-table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  color: #00122b;
  td {
    width: 335px;
    border-bottom: 1px solid #eaeaea !important;
  }
  .table-title {
    text-align: left;
    font-weight: 400;
    font-size: 1em;
    border-bottom: 1px solid #848484 !important;
    &:first-child {
      padding-left: 2em !important;
    }
  }
}

.team-item {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  .dashbboard-dots {
    width: 28px;
  }
}

.team-action-selector {
  width: 38px;
  height: 38px;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  background: url('#{$cdn-url}dashboard-dots.svg') center / auto 5px no-repeat;
  border-radius: 50%;
  transform: rotate(90deg);
  cursor: pointer;
}

.team-actions {
  background: #fff;
  border-radius: 5px;
  position: absolute;
  z-index: 99;
  top: 47px;
  right: 6px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.team-action-item {
  width: 100%;
  padding: 5px 14px;
}

.team-content {
  p {
    margin-bottom: 0;
  }
  td {
    padding: 1.5em 1em;
    &:first-child {
      padding-left: 2em;
    }
  }
  button {
    display: block;
    text-align: left;
  }
  select {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background: rgba(249, 249, 249, 0.83);
    color: #00122b;
    &:focus {
      border: 1px solid rgba(249, 249, 249, 0.83);
      box-shadow: none;
    }
    &:disabled {
      border: 1px solid rgba(249, 249, 249, 0.83);
      color: rgba(0, 18, 43, 0.5);
      background: rgba(249, 249, 249, 0.83) !important;
    }
  }
  .custom-select {
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background: rgba(249, 249, 249, 0.83) url('#{$cdn-url}accordion_bottom_arrow.svg') no-repeat right 0.75rem
      center/10px 10px;
  }
}

.roles-article {
  color: #00122b;
  text-decoration: underline;
  &:hover {
    color: #00122b;
  }
}
.resend-btn {
  color: #848484;
}

.pending-invitation {
  color: #b40a37;
  font-style: italic;
}

.team-form {
  input {
    &:focus {
      border: 1px solid rgba(0, 18, 43, 0.8);
      box-shadow: none;
    }
  }
  select {
    &:focus {
      border: 1px solid rgba(0, 18, 43, 0.8);
      box-shadow: none;
    }
  }
}

.team-box {
  height: calc(100% - 90px);
}

.change-email-button {
  &:hover {
    text-decoration: none;
  }
}

.team-upgrade-message {
  background-color: #001c35;
  color: #fff;
  a {
    color: #f2a805;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
}

.button-block {
  padding-right: 35px !important;
  background: rgba(0, 18, 43, 0.28) url('#{$cdn-url}padlock-white.svg') calc(100% - 13px) center/12px auto no-repeat !important;
}

.team-list-email {
  color: rgba(#212529, 0.7);
}

.team-select {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  min-height: 38px;
  padding-top: 0.4em !important;
}

.team-role-dropdown {
  top: 35px !important;
}
.team-titles {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 200;
  border-bottom: none !important;
}

.disabled-role-select {
  background-color: #fff !important;
  border: none !important;
  cursor: auto !important;
  padding: 0 !important;
  &::after {
    border: none !important;
  }
}

.role-select-container {
  max-width: 200px;
}
.select-hidden-items-first-item {
  border-bottom: 1px solid rgba(212, 212, 212, 0.6);
}

.team-action-selector-selected {
  background-color: rgba(0, 148, 204, 0.08);
}

.team-popup-subtitle {
  color: rgba(#212529, 0.7);
  a {
    display: inline-block;
    margin-left: 4px;
    color: $hover-blue;
    &:hover {
      color: $hover-blue;
    }
  }
}

.team-popup-invitation {
  &:not(:last-child) {
    margin-bottom: 1em;
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  }
  padding-bottom: 1.5em;
}

@media screen and (min-width: 768px) {
  .team-popup-invitation {
    &:not(:last-child) {
      border-bottom: none;
    }
    padding-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .team-upgrade-message {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .team-titles {
    display: flex;
    border-bottom: 1px solid #848484 !important;
  }
  .team-actions {
    width: 95%;
  }
  .team-action-selector {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }
  }
  .team-action-item {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(244, 239, 237, 0.6);
    }
  }
}
