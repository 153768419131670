.no-lists-box {
  height: calc(100% - 80px);
  @extend .box;
}
.keywords-box {
  height: 100%;
}

.remaining-words {
  background: rgba(#f5bd43, 0.6);
  border-radius: 8px;
  font-weight: 700;
  color: #00122b;
}

.upgrade-text {
  color: #0094cc;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #0094cc;
  }
}

.no-lists-text {
  p {
    color: #807d7d;
    font-size: 1.08rem;
  }
  button {
    @extend .box-button;
  }
}

.box-button {
  background: #0094cc;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  &:hover {
    color: #fff;
  }
}

.keywords-lists-box {
  height: calc(100% - 80px);
  @extend .box;
  input {
    width: 100%;
    border: none;
    border-bottom: 0.5px solid rgba($soft-grey, 0.4);
    height: 30px;
    margin-top: 0.35rem;
    color: #00122b;
    &::placeholder {
      color: rgba($soft-grey, 0.6);
    }
    &:disabled {
      background-color: #fff;
    }
    &:focus {
      outline: none;
    }
  }
  h3 {
    color: #00122b;
    font-size: 1.08rem;
    font-weight: 700;
  }
}

.keywords-lists-content {
  height: 100%;
}

.keywords-lists-buttons {
  background: rgba($soft-grey, 0.12);
  border-radius: 0px 0px 8px 8px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.btn-list-update {
  @extend .box-button;
  background: #0094cc;
  font-weight: 700;
  min-width: 148px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.btn-list-email {
  @extend .btn-list-update;
  background: #f2a805;
}

.add-teammates-btn {
  text-transform: uppercase;
}

.keywords-lists-titles {
  height: calc(100% - 30px);
  .scroll-container {
    height: 100%;
  }
  h3 {
    color: #00122b;
    font-size: 1.12rem;
    font-weight: 700;
    display: inline-block;
  }
  .info-tooltip {
    cursor: pointer;
    &:hover {
      + .keyword-description {
        display: block;
      }
    }
  }
}

.keyword-description {
  //color: rgba($soft-grey, 0.6);
  line-height: 1.3rem;
  position: absolute;
  @extend .box;
  top: 30px;
  display: none;
  z-index: 10;
}

.list-title {
  color: #00122b;
  font-weight: 700;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  max-width: 250px;
}

.list-keywords {
  color: #848484;
}

.team-member-list {
  color: #848484;
  font-weight: 700;
  margin: 0 !important;
}

.add-keyword-list {
  @extend .box;
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 700;
  img {
    width: 15px;
    vertical-align: middle;
  }
  &:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
}

.list-dot {
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 0.5px solid #00122b;
  border-radius: 50%;
  cursor: pointer;
}
.active-dot {
  position: relative;
  &::before {
    content: '';
    width: 9px;
    height: 9px;
    background: #00122b;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
  }
}

.delete-list-button {
  width: 100px;
  padding: 0.3rem 0.5rem !important;
}

.delete-message {
  h2 {
    padding: 0 !important;
  }
}

.not-now-button {
  background: transparent !important;
  color: #807d7d !important;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 0;
}

.add-more-button {
  margin: 0.75rem 0 0 0;
  color: #0094cc;
  border-bottom: 0.5px solid rgba(132, 132, 132, 0.4);
  display: block;
  cursor: pointer;
  text-decoration: none;
  .plus {
    font-size: 1.04rem;
  }
  img {
    width: 30px;
    margin-top: -4px;
  }
  .mogul-image {
    width: 45px;
  }
  &:hover {
    text-decoration: none;
    color: #0094cc;
  }
}

.add-more-lists {
  border-bottom: none;
}

.save-changes-button {
  background-color: #0094cc;
}

.keywords-lists-content {
  overflow: hidden;
  position: relative;
}

.keywords-lists-titles {
  transform: translateX(0);
  transition: 0.2s ease-in;
}

.keywords-lists-box {
  position: absolute;
  top: 10px;
  left: 101%;
  width: 100%;
  transition: 0.2s ease-in;
}

.create-email-popup {
  align-items: start;
  padding-top: 10vh;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 1.15rem;
  }
}

.keywords-titles {
  li {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .list-dot {
    display: none;
  }
  p {
    margin-bottom: 0;
  }
}
.active-title {
  background: #fff !important;
}

.keywords-items-container {
  height: calc(100% - 160px);
  @extend .scroll-container;
}

.add-keywords-text {
  //padding: 5px 0px 0px 5px;
}

@media screen and (min-width: 992px) {
  .keywords-titles {
    li {
      background: transparent;
    }
    .list-dot {
      display: block;
    }
  }
  .keywords-lists-box {
    position: relative;
    left: 0;
  }
  .add-keyword-list {
    background: transparent;
  }
}
