.engagement-statistics-container {
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
}

.influence-statistics-btn {
  font-size: 18px !important;
}
.influence-statistics-title {
  padding-left: 2em !important;
}
.engagement-statistics-header-container {
  @extend .crm-email-header-container;
  margin-top: 0 !important;
}

@media screen and (min-width: 992px) {
  .engagement-statistics-influence-section {
    margin-top: 8px;
  }
}

@media screen and (max-width: 640px) {
  .influence-statistics-title {
    text-align: center !important;
  }
}
