.report-main-list-item {
  .icon-document {
    margin-top: 1px;
  }
  .emoji-mart-emoji {
    margin-top: -10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.report-top-nav-selector {
  margin-top: -4px;
  .icon-document {
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
  }
}

.report-list-icon {
  width: 28px;
  margin-right: 8px;
}
