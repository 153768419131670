$progress-bar-radius: 3px;
.progress-menu {
  a {
    color: $main-blue;
    &:hover {
      text-decoration: none;
    }
  }
}

.step-text {
  padding-left: 20px;
}

.progress-menu-item {
  flex-grow: 1;
  &:first-child {
    .step-text {
      padding-left: 0px;
    }
    .step-progress {
      border-radius: $progress-bar-radius 0 0 $progress-bar-radius;
    }
    .step-progress-complete {
      position: relative;
    }
  }
  &:last-child {
    .step-progress {
      border-radius: 0 $progress-bar-radius $progress-bar-radius 0;
    }
    .step-progress-complete {
      &::before {
        border-radius: 0;
      }
    }
  }
}

.progress-menu-item_active {
  font-weight: 700;
}

.progress-menu-item_complete,
.progress-menu-item_active {
  .step-progress {
    &::before {
      width: 100%;
    }
  }
}

.progress-menu-item_complete {
  &:first-child {
    .step-progress {
      &::before {
        border-radius: $progress-bar-radius 0 0 $progress-bar-radius;
      }
    }
  }
}

.progress-menu-item_active {
  .step-progress {
    &::before {
      border-top-right-radius: $progress-bar-radius;
      border-bottom-right-radius: $progress-bar-radius;
    }
  }
}

.step-progress {
  background: rgba($main-blue, 0.05);
  height: 6px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0px;
    transition: 0.2s ease-in;
    background-color: #00122b;
  }
}

.progress-menu-item_disabled {
  a {
    color: rgba($main-blue, 0.5);
  }
}
