.settings-header {
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .team-header-title {
    margin-left: 0;
  }
}
.team-header-title-container {
  width: 100%;
}

.settings-navigation {
  box-shadow: none;
  border-radius: 0;
  transition: all 0s ease 0s;
  left: -100%;
  .main-navigation-logo {
    border-bottom: none;
  }
  .bottom-terms {
    border-top: none;
  }
}

.section-sidebar-subtitle {
  margin-top: 1.2rem;
  text-transform: uppercase;
}

.setting-item {
  background-position: 37px 12.5px;
  background-size: 17px auto;
  background-repeat: no-repeat;
}

.admin-sidebar-item {
  padding-left: 38px !important;
  background-position: 10px 12.5px !important;
}

.setting-item-my-teams-content {
  background-position: 38px 12px;
}

.setting-item-account {
  background-position: 37px 12px;
}

.setting-item-topics,
.setting-item-clients {
  background-size: 15px auto;
  background-position: 38px 11px;
}

.setting-item-email-alerts {
  background-size: auto 13px;
  background-position: 37px 14px;
}

.setting-item-website-feeds {
  background-position: 37px 15px;
}

$icon-classes: (
  'topics': '202110291',
  'clients': '20220418',
  'email-alerts': '20211125',
  'website-feeds': '202110291',
  'team': ' ',
  'account': ' ',
  'billing': '20230507',
  'my-teams-content': ' ',
  'get-help': ' ',
  'logout': ' ',
  'saved-items': '20220308',
  'edit': '20220423',
  'search': '20220519',
);

@each $icon, $icon-class in $icon-classes {
  $base-date: '20211028';
  @if ($icon-class != ' ') {
    $base-date: $icon-class;
  }
  $url: '#{$cdn-url}#{$base-date}-#{$icon}-icon.svg';
  .setting-item-#{$icon} {
    background-image: url($url);
  }
  .active.setting-item-#{$icon} {
    background-image: url('#{$cdn-url}#{$base-date}-#{$icon}-icon-blue.svg');
  }
}

.setting-item-email-domains {
  background-image: url('#{$cdn-url}at-sign-dark.svg');
  background-position: 39px 11.5px;
}
.active.setting-item-email-domains {
  background-image: url('#{$cdn-url}at-sign-blue.svg');
}
.setting-item-sender-email-addresses {
  background-image: url('#{$cdn-url}send-icon-dark.svg');
  background-position: 39px 10.5px;
}
.active.setting-item-sender-email-addresses {
  background-image: url('#{$cdn-url}send-icon-blue.svg');
  background-position: 39px 10.5px;
}

.icon-container-at-sign {
  font-size: 19px;
  padding-top: 2px;
}

.icon-container-code {
  font-size: 12px;
  padding-top: 6px;
}

.icon-container-billing,
.icon-container-team,
.icon-container-logout {
  font-size: 15px;
  padding-top: 5px;
}

.icon-container-suitcase {
  font-size: 16px;
  padding-top: 4px;
}

.icon-container-email {
  font-size: 14px;
  padding-top: 6px;
}
.icon-container-hubspot {
  font-size: 19px;
  padding-top: 1px;
}

.admin-sidebar-item {
  .icon-external-link {
    font-size: 15px;
    padding-top: 5px;
    margin-left: 8px;
  }
}

.icon-container-topics,
.icon-container-saved-items {
  padding-top: 3px;
  font-size: 17px;
  padding-left: 2px;
  margin-right: -2px;
}

.icon-container-edit-pencil {
  padding-top: 3px;
  font-size: 15px;
  padding-left: 2px;
  margin-right: -2px;
}

.icon-container-question-circle {
  font-size: 18px;
  padding-top: 4px;
}
.icon-container-send {
  font-size: 19px;
  padding-top: 2px;
}

.icon-container-alert-medium {
  font-size: 18px;
  padding-top: 3px;
  padding-left: 1px;
}

.icon-container-associated-content {
  padding-top: 5px;
}

.icon-container-clients {
  padding-top: 4px;
  font-size: 17px;
}

@media screen and (min-width: 992px) {
  .settings-navigation {
    width: 265px;
    left: 0;
  }

  .admin-sidebar-item {
    background-position: 37px 12.5px !important;
  }
  .admin-sidebar-item.setting-item-topics {
    background-position: 38px 11px !important;
  }

  .settings-header {
    .team-header-title {
      margin-left: 135px;
    }
  }

  .team-header-title-container {
    width: auto;
  }
}
