.related-content-input {
  padding-top: 10px;
}

.policy-match-lighter {
  background-color: transparent;
  font-weight: 700;
  color: rgba(0, 18, 43, 0.75) !important;
}

.influence-related-content {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  .policymogul-keyword-match {
    @extend .policy-match-lighter;
  }
}

.related-item-title {
  padding: 19px 19px 19px 25px;
}

.related-item-title_selected {
  .add-item-button {
    background: url('#{$cdn-url}icon_yellow_list.svg') center/contain;
  }
}

.influence-related-view {
  border-left: 1px solid rgba($main-grey, 0.6);
  .view-link {
    height: 100%;
    display: flex;
    align-items: center;
    color: $light-blue;
    &:hover {
      color: $light-blue;
      text-decoration: none;
    }
  }
}

.related-content-preview-link {
  display: block;
  color: $main-blue;
  padding: 22px 31px;
  margin-bottom: 16px;
  .policymogul-keyword-match {
    @extend .policy-match-lighter;
  }
  &:hover {
    text-decoration: none;
    color: $main-blue;
  }
}

.noresults-related-content {
  margin-top: 9vw;
  max-width: 420px;
}

.confirm-results-section {
  padding-top: 1em;
  padding-bottom: 1.3em;
  border-top: 1px solid rgba($main-grey, 0.5);
  background-color: $background-grey;
}

.search-modal-related-results {
  height: calc(100% - 273px);
  overflow: auto;
}

.related-content-snippet {
  @extend .hidden-lines;
  -webkit-line-clamp: 3;
  word-break: break-word;
}

.preview-related-content-container {
  border-left: 1px solid rgba($main-grey, 0.5) !important;
  .related-content {
    border-left: 0;
  }
}

.content-preview-container {
  border-right: 0 !important;
}

.influence-related-date {
  font-size: 13px !important;
  .dot {
    margin: 0 5px;
    margin-top: -3px;
    width: 3px;
    height: 3px;
  }
}

.skip-for-now-section {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .influence-related-view,
  .related-item-title {
    &:hover {
      background-color: rgba($light-blue, 0.04);
    }
  }
}
