//https://codepen.io/hansmaad/pen/qaGrQL
$md-checkbox-checked-color: currentColor;
$md-checkbox-border-color: currentColor;

$md-checkbox-margin: 1em 0;
$md-checkbox-size: 1.15em;
$md-checkbox-padding: 0.235em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.125em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 0.75em;

.checkbox-item {
  position: relative;
  margin: $md-checkbox-margin;
  text-align: left;
  &::before {
    content: '';
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 0px 8px rgba(0, 28, 53, 0.06);
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    opacity: 0;
  }

  &.md-checkbox-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline;
    line-height: $md-checkbox-size;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
    line-height: 1;
    &:not(:empty) {
      padding-left: $md-checkbox-label-padding;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid $md-checkbox-border-color;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      //transition: background .1s;
    }

    &:after {
      // checkmark
    }
  }

  input[type='checkbox'] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
      + label:before {
        background: $md-checkbox-checked-color;
        border: none;
      }
      + label:after {
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate($md-checkbox-padding, calc($md-checkbox-size / 2) - calc($md-checkmark-size / 2.6))
          rotate(-45deg);
        width: $md-checkmark-size;
        height: calc($md-checkmark-size / 2);

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
        border-radius: 0px;
      }
    }
  }
}

.checkbox-item-disabled {
  label {
    color: rgba($main-blue, 0.45);
    &:before {
      border: $md-checkbox-border-width solid rgba($main-blue, 0.45);
    }
  }
  input[type='checkbox'] {
    &:checked {
      + label:before {
        background: rgba($main-blue, 0.45);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .checkbox-item {
    &:hover {
      label {
        &::before {
          background-color: rgba(0, 28, 53, 0.06);
        }
      }
      &::before {
        opacity: 1;
      }
    }
  }
}
