.assistant-widget-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.related-content {
  .related-content-main-content,
  .parliamentary-widget,
  .related-content-create-report-from-update,
  .box-political-mentions-widget,
  .ai-assistant-widget {
    transition: 0.3s ease-in-out;
    opacity: 1;
    transform: translateX(0);
  }
}

.related-content-open-assistant {
  overflow: hidden;
  .related-content-main-content,
  .parliamentary-widget,
  .related-content-create-report-from-update,
  .box-political-mentions-widget {
    opacity: 0;
  }
}

.user-message {
  max-width: 90%;
}

.chat-bubble {
  background-color: rgba($light-blue, 0.08);
  padding: 12px 14px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

//https://codepen.io/bilalck/pen/jdGGNW
.chat-typing {
  align-items: center;
  display: flex;
  .chat-dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: rgba($light-blue, 0.7);
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-right: 3px;
    vertical-align: middle;
    display: inline-block;
    &:nth-child(1) {
      animation-delay: 200ms;
    }
    &:nth-child(2) {
      animation-delay: 300ms;
    }
    &:nth-child(3) {
      animation-delay: 400ms;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: rgba($light-blue, 0.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: rgba($light-blue, 0.4);
  }
  44% {
    transform: translateY(0px);
    background-color: rgba($light-blue, 0.2);
  }
}

.text-area-send-message-btn {
  margin-left: -36px;
}
