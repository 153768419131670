/* .stakeholde-item-image{
  width: 60px!important;
  min-width: 60px!important;
  height: 60px!important;
}
 */
.stakeholder-mainbar-information {
  color: #fff;
}

.bar-commons {
  background: #006e46;
}

.bar-lords {
  background: #b40a37;
}

.profile-image-bar {
  color: #fff;
  border-bottom: 4px solid #c0c0c0;
}

.bar-special-adviser {
  background: #f4efed;
  border-bottom-width: 3px;
  color: #00122b;
}

.profile-main-image {
  z-index: 1;
  left: 24px;
  border: 3px solid #c0c0c0;
  border-radius: 50%;
  object-fit: cover;
  width: 90px;
  height: 90px;
  min-width: 90px;
}

.profile-image-content {
  position: relative;
  z-index: 1;
  padding-bottom: 1em;
}

.stakeholder-dropdown-list {
  .dropdown-keywords-activeitem {
    padding-top: 4px;
  }
  .main-search-icon {
    margin-top: -1px;
  }
  .search-main-text-value {
    width: auto;
    flex-grow: 1;
  }
}

.stakeholder-section-button {
  padding: 0.75em 4em;
  border-right: 1px solid rgba($main-grey, 0.5);
  color: #212529;
  opacity: 0.7;
  transition: all 0s ease 0s;
  font-weight: 700;
}

.stakeholder-section-button.active {
  background-color: $hovered-blue;
  opacity: 1;
  color: $light-blue;
  .stake-main-title {
    color: $light-blue;
    &::before {
      border-color: #0094cc;
    }
  }
}

.mobile-stakeholders-container {
  .activity-title-element {
    border-left: none !important;
  }
  .biography-secondary-container,
  .stakeholder-activity-content-container {
    border: none !important;
  }
}

.stakeholder-activity-content-container {
  .sentiment-dot {
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) {
  .stakeholder-profile-image {
    width: 95px;
    height: 95px;
    max-width: 95px;
    max-height: 95px;
  }
  .stakeholder-list-profile-image {
    width: 95px !important;
    height: 95px !important;
  }
}

@media screen and (min-width: 992px) {
  .profile-image-bar {
    border-radius: 5px 5px 0px 0px;
    &::before {
      border-radius: 5px 5px 0px 0px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .profile-main-image {
    width: 110px;
    height: 110px;
    min-width: 110px;
  }

  .profile-image-bar {
    &::before {
      height: 76%;
    }
  }

  .profile-image-content {
    position: relative;
    z-index: 1;
  }
}
