.border-bottom {
  border-bottom: 1px solid rgba($main-grey, 0.5);
}

.border-top {
  border-top: 1px solid rgba($main-grey, 0.5);
}

.border-general {
  border-width: 1px;
  border-style: solid;
}

@for $i from 1 through 10 {
  .border-general-#{$i} {
    border-width: #{$i}px;
  }
}

.border-grey {
  border-color: $main-grey !important;
}

.border-main-blue {
  border-color: $main-blue;
}

.border-light-blue {
  border-color: $light-blue;
}

.border-left {
  border-left: 1px solid rgba($main-grey, 0.5);
}

.border-right {
  border-left: 1px solid rgba($main-grey, 0.5);
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-main-blue-60 {
  border-color: rgba($main-blue, 0.6);
}

.border-main-blue-50 {
  border-color: rgba($main-blue, 0.5);
}

.border-red-50 {
  border-color: rgba($main-red, 0.2);
}

.border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
}

.border-dashed {
  border-style: dashed !important;
}

.rounded-left-lg {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.border-white {
  border-color: $main-white;
}

.border-none {
  border: none;
}

.border-red {
  border-color: $main-red;
}

.rounded-pill-left {
  border-top-left-radius: 50em;
  border-bottom-left-radius: 50em;
}
.rounded-pill-right {
  border-top-right-radius: 50em;
  border-bottom-right-radius: 50em;
}
