.monitor-items-bar {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 0.75em 0;
}

.contact-crm-subnav {
  padding: 14px 0 14px 0 !important;
  background-color: #fff;
}

.research-bar {
  padding-bottom: 0;
  .main-content-wrapper {
    min-height: 48px;
  }
  .main-content-wrapper {
    overflow-x: auto;
    padding-bottom: 0.75em;
  }
  .dropdown-edit-keyword-list {
    visibility: hidden;
  }
  &:hover {
    .dropdown-edit-keyword-list {
      visibility: visible;
    }
  }
}

.monitor-items-container {
  flex-wrap: nowrap;
}

.section-unseen-item-active {
  position: relative;
  &::after {
    content: '';
    width: 6px;
    height: 6px;
    background: $main-yellow;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: -8px;
  }
}

.dropdown-section {
  position: absolute;
  z-index: 2000;
  left: 0;
  width: 218px;
  top: 31px;
  p {
    cursor: pointer;
    margin: 0;
  }
}

.monitor-dropdown-item {
  &::after {
    top: 10px !important;
  }
}

.nowrap-item {
  white-space: nowrap;
}

.inbox-items-bar {
  .sort-button {
    min-height: 38px;
    height: 38px;
    padding-top: 0.45em;
    padding-bottom: 0.45em;
  }
  .more-filters-button {
    background-size: auto 15px;
    width: 38px;
    background-position: 10px center;
  }
  .button-export-results {
    background-position: 10px 10px;
    .filter-name {
      max-width: none;
    }
  }
  .mark-arrow {
    width: 38px;
    height: 38px;
    min-height: 38px;
  }
  .more-filters-button-active {
    min-height: 0;
    height: auto;
    width: auto;
    padding-top: 0.3em;
  }
  .mark-button {
    margin-left: 16px !important;
  }
}

.code-button {
  height: 38px;
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.generate-code-button {
  line-height: 1.3;
}

.influence-assistant-button {
  padding: 5px 1.5em !important;
  font-size: 15px;
}

.influence-ai-assistant-container {
  overflow: auto;
  .writing-creation-buttons {
    padding-top: 0px !important;
    .action-button {
      @extend .influence-assistant-button;
    }
  }
}

.alerts-button {
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .inbox-items-bar {
    .more-filters-button {
      width: auto;
      background-position: 14px;
    }
  }
}

@media screen and (min-width: 992px) {
  .embed-button-container {
    margin: 0 10px 0 12px;
  }
  .code-button {
    padding-left: 15px;
    padding-right: 17px;
    .icon-code-thin {
      font-size: 12px;
    }
  }

  .alerts-button {
    padding-left: 15px;
    padding-right: 15px;
  }

  .monitor-dropdown-item {
    font-size: 1em !important;
  }
  .monitor-items-bar {
    .dropdown-keywords-lists {
      flex-grow: 1;
      max-width: 400px;
    }

    .main-content-wrapper {
      transition: 0.2s ease-in;
    }

    .sidebar-item {
      transition: 0.2s ease-in;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.showing-results-text {
  color: $main-blue;
  span {
    opacity: 0.6;
  }
}

@media screen and (min-width: 1200px) {
  .monitor-dropdown-item {
    font-size: 1.125em !important;
  }
  .inbox-items-bar {
    .mark-text,
    .mark-arrow {
      height: 38px;
      padding-top: 0.45em;
      padding-bottom: 0.45em;
      min-height: 38px;
      max-height: 38px;
    }
    .mark-arrow {
      &::after {
        top: 16px;
      }
    }
  }
  .monitor-items-bar {
    .dropdown-keywords-lists {
      min-width: 400px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .monitor-items-bar {
    .dropdown-keywords-lists {
      min-width: 423px;
      max-width: 423px;
    }

    .dropdown-keywords-new-agency {
      min-width: 455px;
      max-width: 455px;
    }
  }
}
