//Legal Pages
.legal_pages {
  overflow: hidden;
  position: relative;
  padding: 12.5vh 0 25vh 0;
  z-index: 10;

  &::before {
    content: '';
    background: $salmon;
    height: 265px;
    width: 150%;
    position: absolute;
    top: -120px;
    left: -15%;
    transform: rotate(-4deg);
    z-index: -1;
  }

  &::after {
    content: '';
    background: $main-yellow;
    height: 245px;
    width: 150%;
    position: absolute;
    bottom: -120px;
    left: -15%;
    transform: rotate(-5deg);
    z-index: -1;
  }

  h1 {
    margin: 0;
    padding-bottom: 2rem;
  }

  //Content
  &__content {
    width: 100%;

    @media screen and (min-width: 1023px) {
      width: 70%;
    }

    p {
      font-size: 1rem;
    }

    ul {
      li {
        padding-bottom: 0.75rem;
      }
    }

    a {
      color: $light-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.page-template-page-legal {
  background: $salmon;
  .main-header {
    background: $salmon;
    a,
    .static-page-link {
      color: $main-blue;
    }
  }
}
