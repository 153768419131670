.progress-bar {
  background-color: rgba(0, 18, 43, 0.12);
  border-radius: 3px;
  position: relative;
  margin: 15px 0;
  height: 40px;
  width: 100%;
}

.progress-bar-done {
  background: #00122b;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
