.vertical-progress-item {
  margin-bottom: 20px;
  position: relative;
  [class^='icon-'] {
    display: inline-block;
    width: 32px;
  }
  a {
    color: rgba($main-blue, 0.6);
    &:hover {
      text-decoration: none;
      color: rgba($main-blue, 0.6);
    }
  }
}
.vertical-progress-item_active,
.vertical-progress-item_complete {
  a {
    color: $light-blue;
    &:hover {
      color: $light-blue;
    }
  }
}

.vertical-progress-item_disabled {
  a {
    cursor: auto;
  }
}

.item-preview {
  background-size: 13px 13px;
  background-position: 1px 5px;
}
.item-visibility {
  background-size: 16px 15px;
}

.item-client {
  .icon-suitcase {
    font-size: 15px;
  }
}

.item-description {
  .icon-description {
    font-size: 13px;
  }
}

.vertical-progress-item.vertical-progress-item_active {
  &::before {
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid $light-blue;
    position: absolute;
    left: -18px;
    top: 7px;
    width: 9px;
    height: 9px;
  }
}
