#parliamentarian-teams-platform-block {
  ul {
    margin: 1em 0px;
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 800px) {
  #parliamentarian-teams-platform-block {
    .container {
      max-width: 800px !important;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1023px) {
  #parliamentarian-teams-header-block {
    .image_text__image {
      width: 60%;
      img {
        width: 100%;
        max-height: none;
      }
    }
    .image_text__text {
      padding-left: 5em;
      width: 40%;
    }
  }
}
