.reference-topic-picker {
  font-size: 0.925em;
  .dropdown-keywords-lists {
    background: #fff;
  }
  .main-search-icon {
    min-width: 16px;
    max-width: 16px;
    min-height: 15px;
    max-height: 15px;
    margin-right: 9px;
    margin-top: 1px;
  }

  .main-search-icon-list {
    height: 20px !important;
    width: 18px !important;
    max-width: 18px !important;
    max-height: 20px !important;
    margin-top: -1px;
  }
  .dropdown-keywordlist {
    background-position: 15px 14px;
    p {
      &:nth-child(1) {
        width: 100%;
      }
    }
  }
  .dropdown-keywords-activeitem {
    padding: 5px 8px 5px 14px;
  }
  .close-icon-container {
    padding-right: 50px !important;
    margin-top: 1px;
    margin-left: 6px;
  }
  .close-icon {
    width: 12px;
    height: 12px;
  }
  .dropdown-keywords-lists {
    min-height: 0;
    max-height: none;
  }
  .search-dropdown {
    height: 32px;
    width: 33px;
    &::after {
      top: 15px;
      right: 13px;
    }
  }
  .open-dropdown {
    top: 34px;
  }
  .search-main-text-value {
    flex-grow: 1;
  }
}

.reference-topic-picker.active {
  .search-main-text-value {
    color: $light-blue;
  }
  .main-search-icon-list {
    background-image: url('#{$cdn-url}keywordlist-icon-blue.svg');
  }
  .dropdown-keywords-lists {
    background: #f7fcfe;
  }
  .main-icon-topic-picker.icon-topics-thin {
    color: $light-blue;
    font-size: 17px;
    margin-top: -2px;
  }
}
