.reports-editor-container {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: repeat(6, minmax(40px, 1fr));
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-auto-flow: row dense;
}

.reports-editor-item {
  grid-area: span 2 / span 2;
}

.reports-editor-item-placeholder {
  &:nth-child(1) {
    grid-area: 1 / 1 / 7 / 5;
  }
}

.reports-editor-item-placeholder-empty {
  display: flex;
  user-select: none;
  div {
    padding-top: 60px;
    margin: 0 auto;
  }
}

.reports-board-main-container {
  [class*='awsui_empty'] {
    padding: 0 !important;
  }
  /*
    AE: LEFT HERE IN CASE WE NEED TO MODIFY THE SIZE BUT FOR NOW IT CREATES SOME CONFLICTS
    [class*="awsui_columns"]{
      grid-auto-rows: 84px !important;
    }
 */
  [class^='awsui_container-override'] {
    border: 1px solid rgba($main-grey, 0.5) !important;
    border-radius: 0.25rem !important;
    &::after {
      box-shadow: none !important; //0px 0px 1px 1px #e9ebeddb, 0px 1px 8px 2px rgba(0, 7, 22, 0.05) !important;
    }
  }
  .item-actions-button {
    margin-right: -8px;
    background-image: none !important;
    padding-top: 3px;
  }
  [class*='awsui_resizer'] {
    right: 10px !important;
    bottom: 6px !important;
  }
}

.reports-preview-main-content {
  [class*='awsui_variant-default'] {
    border: 1px solid rgba($main-grey, 0.5) !important;
    border-radius: 0.25rem !important;
    &::after {
      box-shadow: none !important;
    }
  }

  [class*='awsui_root'],
  [class*='awsui_content-wrapper'] {
    height: 100%;
  }

  .reports-logo {
    max-height: 70px;
  }

  [class*='awsui_screen-reader-navigation'] {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}

.reports-board-main-container > div {
  height: 100%;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}

.reports-board-main-container > div > div {
  min-height: 100%;
}

.empty-state-grid-item {
  grid-column: 2 / span 2;
  grid-row: 2/4;
  align-self: center;
}

.reports-logo {
  max-width: 120px;
  max-height: 84px;
}

.reports-preview-main-content,
.reports-preview-side-nav {
  transition: all 0.2s;
}

.reports-preview-side-nav {
  opacity: 0;
}

.reports-preview-main-content {
  transform: translateX(-140px);
}

.reports-preview-background {
  transition: all 0.2s ease-in;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba($main-blue, 0.8);
}

.reports-preview-commentary-component {
  min-height: 223px;
  font-size: 16px;
  overflow: hidden;
  a {
    color: $main-blue;
    cursor: text;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 0;
    line-height: 1.4;
    color: #676a75;
  }
}

.report-logo-edit-preview {
  opacity: 0;
  transition: 0.2s ease-in;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($main-blue, 0.8);
  top: 0;
  left: 0;
}

.report-logo-edit {
  width: 35px;
  height: 35px;
}

.report-change-logo-container {
  max-width: 150px;
}

.report-logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .report-logo-edit-preview {
      opacity: 1;
    }
  }
}

.reports-white-container {
  min-height: 100%;
  height: auto;
}

.emoji-selector {
  width: 35px;
  max-width: 35px;
  max-height: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in;
  margin-top: 4px;
  &:hover {
    background-color: rgba($main-grey, 0.25);
  }
}

.macro-icon-selector {
  .emoji-selector {
    margin-top: 0px;
  }
}

.reports-preview-specific-update-container {
  .report-component-name,
  .local-commentary {
    padding-left: 4px;
  }
  .user-content-page-main {
    padding-left: 16px;
  }
}

.report-subtext-input {
  color: rgba($main-blue, 0.7);
  &:focus {
    color: rgba($main-blue, 0.7);
  }
}
