/*==================================
LOGGED OUT NAVIGATION MOBILE
===================================*/
.navigation-container-mobile-logged-out {
  .static-page-link {
    font-size: 20px;
    margin: 20px 0;
    display: block;
    color: $secondary-blue !important;
    opacity: 1;
    transform: translate(0, 2rem);
    transition: 0.2s ease;
    &:hover {
      text-decoration: none;
    }

    &.active-page {
      color: $main-yellow !important;
    }
  }
  .monitor-title {
    margin: 20px 0 !important;
  }
  .influence-title {
    padding-top: 0;
  }
  .monitor-title,
  .influence-title {
    opacity: 0;
    transform: translate(0, 2rem);
    margin-top: 0 !important;
    font-size: 20px;
    font-weight: 400;
    text-transform: initial;
    color: $main-blue;
    transition: 0.2s ease;
  }

  .monitor-title,
  .influence-title {
    position: relative;
    display: inline-block;
    padding-right: 40px;
    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $main-blue;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  .sidenav-title-open {
    &::after {
      border-top: none;
      border-bottom: 6px solid #00122b;
    }
  }

  .sidenav-list-open {
    padding-bottom: 20px;
  }

  .login-buttons {
    border-bottom: 1px solid rgba($main-grey, 0.5);
    padding: 30px 0px 20px 20px;
  }

  .pricing-link {
    transition-delay: 0.2s;
  }

  .monitor-title {
    transition-delay: 0.4s;
  }

  .influence-title {
    transition-delay: 0.6s;
  }

  .blog-link {
    transition-delay: 0.8s;
  }

  .btn-login {
    font-size: 17px !important;
  }

  .sidebar-item {
    font-size: 15px;
    color: $header-blue !important;
  }

  .sidebar-item.active {
    color: $light-blue !important;
  }

  .news-a .item-icon {
    margin-top: 2px;
  }

  .legislation-a .item-icon {
    margin-top: 1px;
  }

  .social-a .item-icon {
    margin-top: 1px;
  }
}

.show-menu {
  .navigation-container-mobile-logged-out {
    .static-page-link,
    .monitor-title,
    .influence-title {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

.hamburger {
  z-index: 20001;
  position: fixed;
  top: 20px;
  left: 17px;
  transition: transform 0.2s ease-in;
}

.hamburger-box {
  height: 20px;
}

.hamburger-logged-in {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $main-blue;
  }
}

.sidenav-item-open {
  transition: all 0s ease 0s !important;
}

.dropdown-item-element-main-title {
  color: $light-blue !important;
  &::after {
    top: 7px;
  }
}

.main-title-items {
  top: 32px !important;
  width: 270px !important;
  left: 0;
}
