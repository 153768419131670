.slack-integration {
  column-gap: 90px;
  row-gap: 30px;
  margin-top: 100px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 30px;
  .slack-integration-header {
    .slack-integration-btn {
      margin-top: 30px;
      border-radius: 5px;
      border: 1px solid #d4d4d4;
      width: fit-content;
      background-color: #ffffff;
      background-image: url('#{$cdn-url}slack_icon.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 11px 9px;
      padding: 7px 12px 7px 40px;
      &:hover {
        background-color: rgba(#0094cc, 0.04);
        transition: all 200ms;
      }
    }
  }
  .slack-integration-subtitle,
  .slack-integration-pretitle {
    color: #00122b;
    line-height: 1.5rem;
    margin: 0;
  }
  .slack-integration-pretitle {
    font-weight: 600;
    font-size: 1.1rem;
  }
  .slack-integration-subtitle {
    font-weight: 500;
    font-size: 1rem;
  }

  .slack-integration-animation {
    height: 270px;
    border-radius: 15px;
  }
  .policy-logo {
    height: 70px;
    width: 280px;
  }
}
.slack-integration-description {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-top: 50px;
  width: 80%;
  max-width: 1300px;
  a {
    color: #0094cc;
    &:hover {
      color: #0094cc;
      opacity: 0.8;
    }
  }

  .slack-integration-description-section {
    display: flex;
    column-gap: 50px;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 32px;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: unset !important;
      min-width: unset !important;
      & > img {
        max-width: 400px;
        border: 1px solid rgba(#d4d4d4, 0.5);
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 867px) {
  .slack-integration {
    margin-top: 0px;
    .slack-integration-header {
      align-items: end;
    }
    .slack-integration-animation {
      height: 200px;
      border-radius: 15px;
    }
  }
  .slack-integration-description {
    width: 100%;
    border-radius: 0;
    margin-top: 30px;
  }
}
