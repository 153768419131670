.box-political-mentions-widget {
  & > h3 {
    margin-top: -9px;
  }
  .tailor-summaries-dropdown-component {
    max-width: calc(100% - 105px);
    .hour-select {
      padding-bottom: 4px !important;
    }
    .frequency-dropdown.general-dropdown {
      left: -129px;
      width: 311px;
    }
    &.agency {
      max-width: calc(100% - 125px);
      .frequency-dropdown.general-dropdown {
        left: -152px;
        width: 311px;
      }
    }
  }
  .summary-content-dropdown-component-container {
    max-width: calc(100% - 34px);
  }
  .summary-content-dropdown-component-container .tailor-summaries-dropdown-component .hidden-lines.hidden-one-line {
    margin-right: 10px;
  }
  .summary-content-dropdown-component-container .tailor-summaries-dropdown-component {
    .hidden-lines.hidden-one-line {
      font-weight: normal;
    }
    &.agency {
      .hidden-lines.hidden-one-line {
        font-weight: bold;
      }
    }
  }
}
