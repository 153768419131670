.activity-title-container {
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -1px;
    background: #f9f9f9;
    border-radius: 50%;
    z-index: 1;
  }
  &::after {
    right: -1px;
  }
  &::before {
    left: -1px;
  }
}

.activity-title-element {
  background: white;
  position: relative;
  z-index: 2;
  border: 1px solid rgba($main-grey, 0.5);
  border-top: none;
  border-radius: 5px 5px 0px 0px;
}

.activity-title-scrolled {
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.08),
    0 1px 0 0 rgba(29, 28, 29, 0.13);
  border-bottom: none;
}

.no-activity-message {
  padding-top: 24vh;
}

.profile-share-buttons {
  .item-actions-save-content {
    background-color: #fff;
  }
}

.logged-out-activity-message {
  border-bottom: 2px solid $light-blue;
  border-radius: 5px 5px 0 0;
}

@keyframes moveArrow {
  0% {
    background-position: calc(100% - 25px) 14px;
  }
  50% {
    background-position: calc(100% - 22px) 14px;
  }
  100% {
    background-position: calc(100% - 25px) 14px;
  }
}

.create-stakeholder-map {
  background: #0094cc url('#{$cdn-url}20220826-sign-up-left-arrow.svg') calc(100% - 22px) 14px / 20px auto no-repeat;
  padding-right: 62px;
  padding-left: 28px;
  animation: moveArrow 0.8s ease-in-out infinite backwards;
}

.sentiment-filter-bar {
  min-height: 57px;
}

.sentiment-close-button {
  text-align: center;
  width: 15px;
  height: 15px;
  margin-top: -8px;
  display: inline-block;
  .icon-close {
    display: inline-block;
    vertical-align: middle;
    margin-top: 3px;
  }
}

.sentiment-bar-filter-button {
  min-height: 35px;
  max-height: 35px;
  max-width: 35px;
  padding-left: 9px;
  padding-right: 9px;
  .icon-filters {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1.5px;
    margin-left: 0.5px;
  }
}

.sentiment-bar-filter-button-active {
  max-width: none;
  .sentiment-bar-text {
    padding-bottom: 1px;
    .sentiment-dot {
      margin-top: 0px;
    }
  }
}

@media screen and (min-width: 576px) {
  .sentiment-bar-filter-button-active {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 992px) {
  .activity-title-element {
    border-top: 1px solid rgba($main-grey, 0.5);
  }

  .profile-share-buttons {
    .item-actions-save-content {
      &:hover {
        background-color: $hovered-blue !important;
      }
    }
  }

  .sentiment-close-button {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    margin-top: 0px;
    margin-right: -10px;
  }
}
