.research-button {
  padding: 5px 15px;
  min-width: 143px;
  height: 36px;
  width: 100%;
  text-align: left;
  border: 1px solid rgba(132, 132, 132, 0.25);
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.925em;
  &:hover {
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.18);
  }

  &:nth-of-type(3) {
    margin-bottom: 0;
  }
}

.empty-research-button {
  padding: 5px 15px;
}

.add-influence-button {
  background-color: rgba($main-grey, 0.2) !important;
  color: $light-blue !important;
  border: 1px solid $main-grey !important;
}

.research-clear-button {
  padding: 5px 15px;
}

.research-category-number {
  color: $light-blue;
}

.research-top-actions {
  .general-button {
    padding-top: 16px;
    padding-bottom: 16px;
    color: rgba($main-blue, 0.8);
  }

  .dropdown-item-element {
    padding-left: 18px;
    padding-right: 34px;
    &::after {
      right: 14px;
      top: 25px;
    }
  }

  .simple-link-button {
    color: $light-blue;
    transition: 0.2s ease-in;
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }

  .topic-action-button {
    padding-right: 18px;
  }

  .clear-keywords {
    padding-left: 28px;
    color: #e46666 !important;
    &:hover {
      color: #e46666 !important;
    }
  }
}

.topic-action-button {
  background: url('#{$cdn-url}202110291-topics-icon-blue.svg') 1.35em 20px / 14px auto no-repeat;
  color: $light-blue !important;
  padding-left: 48px;
}

.research-topic-boxes {
  .keyword-rule-title-container {
    min-height: 30px;
  }

  .keyword-rule-title {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .keywords-category-words {
    border: 1px solid rgba($main-blue, 0.3);
    border-radius: 5px;
    padding: 16px;
    min-height: 120px;
  }
  .pricing-feature-more {
    display: none;
  }
  .item-actions-button-horizontal {
    opacity: 0.7;
  }
}

.topic-name-icon {
  display: block;
  text-align: left;
  background: url('#{$cdn-url}keywordlist-icon.svg') 0 12px / auto 20px no-repeat;
  span {
    width: 100%;
  }
}

.topic-name-icon.selected {
  background-image: url('#{$cdn-url}keywordlist-icon-blue.svg');
  color: $light-blue;
  background-color: rgba($light-blue, 0.08);
}

.research-keyword-lists {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 400px;
  .topic-name-icon {
    padding: 10px 24px 10px 38px;
    background-position: 12px 12px;
    width: 100%;
  }
}

.research-keyword-lists-master-topic {
  position: relative;
  width: 100%;
  .ScrollbarsCustom-Content {
    padding-left: 0 !important;
  }
}

.research-item-actions {
  right: 0 !important;
}

.clear-keywords-button {
  background: url('#{$cdn-url}20220226-cancel-button.svg') 13px 11px / 14px auto no-repeat;
}

.copy-keywords-button {
  background: url('#{$cdn-url}20220226-copy-button.svg') 13px 10px / 14px auto no-repeat;
}

.section-title-research-popup {
  padding: 36px 0;
}

/*AE: LEFT HERE IN CASE WE NEED TO COME BACK TO THIS VERSION
 .research-scrollbar{
  .ScrollbarsCustom-Scroller{
    scroll-behavior: smooth;
  }
} */

.smooth-scrollbar {
  .ScrollbarsCustom-Scroller {
    scroll-behavior: smooth;
  }
}

.research-items-container-popup {
  transition: 0.2s ease-out;
}

.topic-description-field {
  color: rgba(0, 18, 43, 0.4);
  font-size: 0.85em;
  line-height: 1.3;
}

@media screen and (min-width: 526px) {
  .research-top-actions {
    .dropdown-item-element {
      padding-left: 28px;
      padding-right: 48px;
      &::after {
        right: 28px;
      }
    }
    .topic-action-button {
      padding-right: 35px;
    }
  }
}

@media screen and (min-width: 768px) {
  .research-top-actions {
    .dropdown-item-element {
      border-left: 1px solid rgba($main-grey, 0.5);
      border-right: 1px solid rgba($main-grey, 0.5);
    }
  }
}

@media screen and (min-width: 992px) {
  .topic-action-button {
    background-position: 38px 20px;
    padding-left: 62px;
  }

  .research-keyword-lists {
    .topic-name-icon {
      transition: 0.2s ease-in;
      &:hover {
        background-color: rgba($main-grey, 0.25);
      }
    }

    .topic-name-icon.selected {
      &:hover {
        background-color: rgba($light-blue, 0.08);
      }
    }
  }

  .research-topic-boxes {
    .pricing-feature-more {
      display: inline-block;
    }
    .padding-topic-component {
      padding: 0 45px 0 40px;
    }
  }
}
