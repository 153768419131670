.email-component-container {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .open-dropdown-arrow {
    position: absolute;
    top: -7px;
    right: 2px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    z-index: 99;
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }

    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #00122b;
      position: absolute;
      top: 18px;
      right: 15px;
    }
  }

  .email-component-input {
    border: none;
    outline: none;
    padding: 0;
    min-width: 15px;
    background-color: transparent;
    color: #00122b;
  }
  .email-field-to-input-span {
    position: absolute;
    opacity: 0;
    right: 100%;
  }
  .email-component-input-subject {
    width: 100%;
    color: #00122b !important;
    appearance: none;
    &:disabled {
      color: #00122b !important;
      opacity: 1 !important;
    }
    &::placeholder {
      color: rgba(0, 18, 43, 0.6);
    }
  }

  .email-component-input-container {
    display: flex;
    align-items: center;
  }

  .email-component-section,
  .send-preview-modal {
    padding: 10.5px 20px 10.5px 22px;
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    & > div {
      display: flex;
      gap: 14px;
      align-items: center;
      .labelText {
        color: rgba(0, 18, 43, 0.7);
        cursor: default;
      }
    }
  }
  .email-subject-section {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .email-component-to-section {
    min-height: 39.6px;
    align-items: flex-start !important;
    & > .labelText {
      transform: translateY(5px);
    }
  }
}
.blue-link-underline-select {
  @extend .blue-link-underline;
  display: inline-block;
  &:hover:after {
    bottom: 2px;
  }
  margin: 0;
}
.trial-banner-email {
  position: absolute;
  top: -27px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  @media screen and (max-width: 500px) {
    font-size: 13.5px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.email-component-container-sent {
  height: 100% !important;
}
.main-content-sent-email {
  width: 100%;
}

.sent-tabs-qntty {
  display: none;
}

.email-tabs-container {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  .email-tab-btn {
    width: 135px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(132, 132, 132, 0.25);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #00122b;
    cursor: pointer;
    transition: all 200ms;
    user-select: none;
    &:hover {
      background-color: rgba(#0094cc, 0.04);
    }
  }
  .email-tab-btn-active {
    background: #0094cc;
    border: none;
    color: #ffffff;
    &:hover {
      background-color: rgba(#0094cc, 0.8);
    }
  }
}
.email-tabs-container-performance {
  @extend .email-tabs-container;
}

.email-tabs-container-recipients {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 23px;
  align-items: center;
}

.recipient-search-bar {
  border: 1px solid rgba(132, 132, 132, 0.25);
  border-radius: 5px;
  height: 38px;
  flex-grow: 1;
  max-width: 425px;
  min-width: 250px;
  background-color: #fff;
  .simple-searchbar-open-influence {
    padding: 4px 15px !important;
  }
}

.empty-recipients-message {
  text-align: center;
  color: rgba(0, 28, 53, 0.75);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.email-component-wrapper {
  margin-top: -29px;
  padding-top: 1.65em;
}

.email-component-body {
  display: flex;
  column-gap: 20px;
  .email-component-side-section {
    width: 341px;
    background: #ffffff;
    border: 1px solid rgba(212, 212, 212, 0.6);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 18px 30px 0 26px;
    row-gap: 32px;
  }
}
.text-700 {
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .email-tabs-container {
    padding-left: 280px;
  }
  .email-tabs-container-performance {
    padding-left: 0;
  }
  .email-component-body-sent {
    padding: 0 280px;
  }
  .sent-tabs-qntty {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #00122b;
    margin: 0 !important;
    position: absolute;
    left: 38px;
    display: block;
  }
  .email-component-wrapper {
    margin-top: -25px;
  }
  .main-content-sent-email {
    padding-top: 1.65em;
  }
}

@media screen and (max-width: 768px) {
  .email-component-wrapper {
    margin-top: 8px;
  }
  .email-component-container {
    height: calc(100% - 33px);
  }
  .main-content-sent-email {
    margin-top: -29px;
  }
}

@media screen and (max-width: 480px) {
  .email-tabs-container .email-tab-btn {
    width: 106px;
    font-size: 15px;
  }
  .email-component-container .email-component-section > div {
    gap: 5px;
  }
  .email-component-container .email-component-section {
    padding: 10.5px 10px 10.5px 12px;
  }
  .main-content-sent-email {
    margin-top: -51px;
  }
}

@media screen and (max-width: 375px) {
  .email-component-custom-select .open-dropdown {
    left: -84px;
  }
}

@media screen and (max-width: 480px) {
  .email-component-custom-select .open-dropdown {
    width: 260px !important;
  }
}
