.end-of-trial-container {
  background: #001c34;
  color: #fff;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 30vh;
    background-color: #00112b;
    bottom: 0;
    clip-path: polygon(100% 57%, 0% 100%, 100% 100%);
    left: 0;
  }
}

.trial-image-container {
  border-radius: 50%;
  background: #fff;
  width: 300px;
  height: 300px;
  img {
    padding-top: 46px;
    max-width: 207px;
  }
}

.logout-button {
  background: #d1d4d7;
  color: rgba(#00112b, 0.6);
  border-radius: 5px;
}

.owner-trial-text {
  font-size: 26px;
}

.choose-plan-button {
  @extend .logout-button;
  background: $light-blue;
  transition: 0.2s ease-in;
  color: #fff;
  &:hover {
    background: rgba($light-blue, 0.85);
  }
}

.trial-end-text {
  font-size: 18px;
}

.team-trial-text {
  max-width: 520px;
}

.check-plan-container {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  font-weight: 400;
}

.trial-link {
  color: #f2a805;
  text-decoration: none;
  &:hover {
    color: #f2a805;
    text-decoration: underline;
  }
}

.check-plan-text {
  max-width: 500px;
}

.check-plan-lists {
  color: #00122b;
  height: auto !important;
  width: 100%;
}

.button-select-categories {
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
  padding-right: 35px !important;
  width: 100%;
  text-align: left !important;
  position: relative;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid $main-white;
    position: absolute;
    top: 14px;
    right: 12px;
  }
}

.close-check-plan-lists {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid $main-blue;
  position: absolute;
  top: 14px;
  right: 12px;
}

.list-disabled {
  color: rgba(0, 18, 43, 0.3);
}

.lists-button-check-plan {
  background: #f2a805;
  border-radius: 5px;
  color: #fff;
}
