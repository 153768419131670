.influence-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  margin-top: 20px;
  margin-bottom: -2px;
  position: relative;
  .section-title-helper-text {
    display: flex;
    justify-content: flex-end;
    column-gap: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: rgba(0, 18, 43, 0.8);
    margin-bottom: 0;
  }
  .section-title-left-side {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }
  .lobbying-title-helper-text {
    margin-right: -8px;
  }
}
.influence-section-picker {
  top: 35px !important;
  left: 0px !important;
}
.influence-section-picker-stakeholders {
  left: 81px !important;
}

@media screen and (max-width: 992px) {
  .influence-section-title {
    margin-left: 0;
  }
  .influence-section-title-stakeholders {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 480px) {
  .stakeholder-mapping-helper-text {
    padding-left: 62px;
  }
}

.title-helper-text-animated {
  transform: translateY(30px);
  animation-name: title-animation;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.6, 0.9, 0.58, 0.77);
  position: relative;
  color: transparent;
  z-index: 0;
}

@keyframes title-animation {
  100% {
    transform: translateY(0);
  }
}
