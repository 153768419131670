.update-custom-contact-container {
  overflow: hidden;
  .update-custom-contact-body {
    padding-bottom: 75px !important;
  }
  .account-list {
    li {
      padding: 1rem 0 0.3rem 0;
    }
  }
}
.update-custom-contact-container,
.add-contact-modal-container,
.send-preview-modal {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
  }
}
.update-custom-contact-names-container {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  & li {
    flex-grow: 1;
    min-width: 200px;
  }
}

.custom-contact-item {
  .react-tagsinput {
    background-color: transparent !important;
    overflow: hidden;
    padding: 0.3em 0.75em 0em !important;
  }
  .react-tagsinput-input {
    padding: 0 !important;
    margin-bottom: 6px;
  }
}
.tags-no-pseudo-elem {
  .react-tagsinput-tag {
    margin-right: 10px !important;
    word-break: break-all;
    &:after {
      display: none !important;
    }
  }
}

.update-custom-contact-header {
  margin-top: 20px;
}
.update-custom-contact-salutation {
  position: relative;
  width: fit-content;
}

.custom-contact-mobile-container {
  .box-top-rounded {
    border-radius: 0;
  }
  .ScrollbarsCustom-Content {
    padding-right: calc(2.5rem - 34px) !important;
  }
}

.custom-contact-section-title-wrapper {
  background-color: #00122b;
  h3.my-lg-0.main-title.mb-0,
  .my-lg-0.main-title.mb-0 > span.font-weight-bold.dropdown-item-element.pointer.dropdown-item-element-main-title {
    color: #fff !important;
  }
  .influence-section-title {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 992px) {
  .custom-contact-activities-box {
    flex: 0 0 44%;
    max-width: 44%;
  }
}
