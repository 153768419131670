.share-user-content-container {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 40%;
    height: 35%;
    right: 0;
    bottom: 0;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    background-color: $medium-blue;
    z-index: 100;
  }
  .icon-circle-check {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }
}

@media screen and (min-width: 992px) {
  .copy-link-button {
    transition: 0.2s ease-in;
    &:hover {
      background: $hovered-blue;
    }
  }
}
