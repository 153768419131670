.dropdown-keywords-lists {
  background: $main-white;
  color: #00122b;
  position: relative;
  z-index: 101;
  border: 1px solid rgba(132, 132, 132, 0.25);
  border-radius: 20px;
  min-height: 38px;
  max-height: 38px;
  user-select: none;
  &:hover {
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.18);
  }
}
.dropdown-keywords-lists-stakeholders {
  background-color: #fff !important;
  margin-right: 0 !important;
}

.dropdown-keywords-lists-influence {
  @extend .dropdown-keywords-lists;
}
.search-bar-email-performance {
  max-width: 400px;
  .dropdown-keywords-activeitem {
    padding: 4px 8px 6px 14px;
  }
}
.search-bar-engagement-statistics {
  max-width: 400px;
}
@media screen and (max-width: 992px) {
  .dropdown-keywords-lists-influence {
    flex-grow: 1;
  }
  .search-bar-engagement-statistics {
    max-width: none;
  }
}
.select-topic-create-group {
  .dropdown-keywords-activeitem {
    .main-icon-topic-picker {
      margin-top: 1px;
    }
  }
}

.dropdown-keyword-lists-empty {
  background-color: #fff;
  .main-icon-topic-picker {
    margin-top: 2px;
  }
  .search-main-input {
    padding-top: 1px;
  }
}

.dropdown-keywords-lists-open {
  border-color: transparent;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28) !important;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  //min-height: 42px;
  &:hover {
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28) !important;
  }
  .dropdown-keywords-activeitem {
    background: #fff;
    border-radius: 20px;
    z-index: 1;
  }
  .open-dropdown {
    z-index: 1;
  }
}
.dropdown-filter-activeItem {
  p {
    width: 89.3% !important;
  }
}
.dropdown-keywords-activeitem-sort-button {
  padding: 5px 17px !important;
}
.dropdown-keywords-activeitem {
  position: relative;
  padding: 5px 8px 5px 14px;
  p {
    width: 89.3%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6;
  }
}
.close-icon {
  width: 28px;
  height: 28px;
  display: block;
  background: url('#{$cdn-url}close-icon.svg') center 52%/12px auto no-repeat;
  border-radius: 50%;
  transition: 0.2s ease-in;
}

.open-dropdown {
  position: absolute;
  top: 37px;
  width: calc(100% + 2px);
  left: -1px;
  background: #ffffff;
  box-shadow:
    0 1px 3px rgba(60, 63, 66, 0.32),
    0 4px 10px rgba(60, 63, 66, 0.15);
  user-select: none;
  //box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
  //box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 20px 20px;
  //padding-top: 5px;
  .open-dropdown-container {
    //border-top: 1px solid rgba(212,212,212,.3);
  }
}

.open-dropdown-sort-stakeholders {
  z-index: 350;
  border-radius: 20px;
  top: 48px;
  padding-top: 0.5em;
  left: unset;
  right: 0 !important;
  width: 300px;
}

.search-main-input {
  width: calc(100% - 65px);
  background: transparent;
  border: none;
  outline: none;
  color: #212529;
  padding-top: 0px;
  padding-bottom: 0px;
  /*&:disabled{
    color: #212529;
    opacity: 1;
    -webkit-text-fill-color: #212529;
  }*/
  &::placeholder {
    color: rgba(0, 18, 43, 0.6) !important;
  }
  &:focus {
    border: none;
  }
  &:disabled {
    pointer-events: none;
  }
}

.search-main-text-value {
  width: calc(100% - 60px) !important;
  &.placeholder {
    width: unset !important;
    color: rgba(#00122b, 0.6) !important;
  }
  color: #212529;
  margin-bottom: 0;
  span {
    width: 100%;
  }
  .filter-sort-dropdown-header {
    padding-right: 20px;
    cursor: pointer !important;
    color: #00122b;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    transition: all 200ms;
    .filter-sort-dropdown-header-active {
      opacity: 0.6;
      @media screen and (max-width: 490px) {
        display: none;
      }
    }
    .filter-sort-dropdown-header-active-stakeholders {
      @extend .filter-sort-dropdown-header-active;
      color: #0094cc;
      opacity: 1;
    }
  }
}

.search-main-text-value-disabled {
  color: rgba($main-blue, 0.6);
}

.dropdown-keywords-influence {
  background: $main-white !important;
}

.dropdown-keywordlist {
  padding: 0.9em 1em;
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 0.25em;
  }
  .dropdown-name {
    color: #00122b;
  }
  .dropdown-description {
    color: rgba(0, 18, 43, 0.4);
    font-size: 0.85em;
  }
  p {
    margin-bottom: 0;
  }
  [class^='icon-topics'] {
    font-size: 19px;
    margin-right: 12px;
    margin-top: 3px;
  }
  .icon-topics-master {
    font-size: 21px;
  }
  .icon-briefing-material {
    font-size: 18px;
    margin-right: 12px;
    margin-top: 5px;
  }
  .icon-email {
    font-size: 13px;
    margin-right: 12px;
    margin-top: 7px;
  }
}

.dropdown-keywordlist-description {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.dropdown-engagementstatistics {
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
}

.dropdown-emailperformance {
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
}

.dropdown-keywordlist-master {
  .dropdown-keywordlist {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}

.default-view-title {
  padding: 1.5em 1em 0.5em 17px;
  letter-spacing: 0.3px;
}

.topic-selected-mark {
  display: block;
  position: absolute;
  top: 35%;
  right: 24px;
  width: 14px;
  height: 14px;
}

.keywordlist-common-view.dropdown-keywordlist-highlighted {
  .topic-selected-mark {
    display: none;
  }
}

.dropdown-keywordlist-highlighted {
  .dropdown-keywordlist {
    background-color: rgba(0, 148, 204, 0.04);
    .dropdown-list-actions {
      display: flex;
    }
  }
}

.dropdown-keywordlist-unseen {
  .dropdown-keywordlist {
    background-image: url('#{$cdn-url}keywordlist-icon-unseen.svg');
    background-position: 15px 14px;
  }
}

.dropdown-list-actions {
  position: absolute;
  top: 25%;
  right: 15px;
  display: none;
  button {
    width: 30px;
    height: 30px;
    justify-content: center;
    background: $main-white;
    color: $light-blue;
    border-radius: 50%;
    &:hover {
      background: $light-blue;
      color: $main-white;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.dropdown-edit-keyword-list-active {
  background: url('#{$cdn-url}keyword-list-editicon-blue.svg') center/contain no-repeat;
}

.dropdown-edit-keyword-list {
  font-size: 14px;
}

.dropdown-edit-keyword-list.active {
  @extend .dropdown-edit-keyword-list-active;
}

.default-view-button {
  margin-right: 10px;
  font-size: 15px;
  .icon-default {
    margin-top: -1px;
  }
}

.search-dropdown-open {
  right: 1px;
  top: 1px;
  &::after {
    top: 15px;
    right: 14px;
  }
  &:hover {
    background: none;
  }
  &::after {
    border-bottom: 6px solid #00122b;
    border-top: 0px solid #00122b;
  }
}

.search-dropdown-open-stakeholders {
  @extend .search-dropdown-open;
  &::after {
    border-bottom: 6px solid #0094cc;
  }
}

.dropdown-searchbar {
  border-bottom: 1px solid rgba(212, 212, 212, 0.3);
  margin: 0;
  padding: 0.55em 0 0.55em 2.45em;
  border-radius: 0px 0px 3px 3px;
  color: rgba(0, 18, 43, 0.3);
  background: url('#{$cdn-url}icon_header_search_grey.svg') 0.895em 0.84em/17px auto no-repeat;
  cursor: text;
}

.padlock-image {
  width: 12px !important;
  display: inline-block;
  margin-left: 10px;
  margin-top: -3px;
}

.add-keyword-list-button {
  color: #0094cc !important;
  font-weight: 400 !important;
  padding: 0.85em 0.75em 0.9em 1em;
  border-top: 1px solid rgba(#d4d4d4, 0.5);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  text-align: left;
  img {
    @extend .padlock-image;
    margin-top: -2px;
  }
  .icon-square-plus {
    margin-right: 8px;
    vertical-align: middle;
    margin-top: -4px;
    display: inline-block;
  }
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
    .available-text {
      display: inline-block;
    }
  }
  .available-text {
    color: rgba(0, 18, 43, 0.6);
    font-style: italic;
    font-size: 0.9em;
    margin-left: 0.75em;
    display: none;
  }
}

.manage-topics-client-button {
  padding: 0.75em 0.75em 0.75em 1em;
}

.add-nav-more-lists {
  padding-top: 0.4em;
  padding-bottom: 0.6em;
}

.btn-set-keywords {
  text-transform: initial !important;
  color: #fff;
  margin-top: 1px;
  display: none;
  background-color: $light-blue !important;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.search-bar-lists {
  max-height: 290px;
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-top: 4px transparent solid;
    border-right: 5px transparent solid;
    background-clip: padding-box;
    border-radius: 0px;
  }
}

.main-search-icon {
  //AE: NECCESSARY TO DO AND ADJUSTMENT OF THE HEIGHT IN THE SEARCHBAR
  min-width: 18px;
  max-width: 18px;
  min-height: 17px;
  max-height: 17px;
  background: url('#{$cdn-url}icon_header_search_grey.svg') center/contain no-repeat;
  margin-right: 11px;
  margin-top: 1px;
}

.main-icon-topic-picker {
  font-size: 19px;
  margin-right: 11px;
}

.main-icon-topic-picker.icon-topics-thin {
  margin-left: 2px;
  margin-right: 9px;
}

.main-icon-topic-picker.icon-topics-master {
  font-size: 20px;
}

.main-icon-topic-picker.icon-search {
  font-size: 17px;
  color: rgba($main-blue, 0.5);
}

.main-search-icon-list {
  background-image: url('#{$cdn-url}keywordlist-icon.svg');
  background-position: 2px 0.6px;
  height: 22px !important;
  width: 20px !important;
  max-width: 19px !important;
  max-height: 22px !important;
  margin-top: -1px;
}

.main-search-icon-master-topic {
  background-image: url('#{$cdn-url}keywordlist-digest-topics.svg');
  width: 20px !important;
  height: 21px !important;
  max-width: 20px !important;
  max-height: 21px !important;
}

.main-search-icon-engagement-statistics {
  background-image: url('#{$cdn-url}20211028-my-teams-content-icon.svg');
  width: 17px !important;
  height: 17px !important;
  max-width: 17px !important;
  max-height: 17px !important;
}

.dropdown-kewyord-lists-logged-out {
  background: #fff;
  width: 100%;
  &:hover {
    box-shadow: none;
  }
  .open-dropdown {
    top: 39px;
  }
  .dropdown-keywords-activeitem {
    padding: 4px 8px 6px 14px;
  }
}

.dropdown-kewyord-lists-logged-out.dropdown-keywords-lists-open {
  margin-top: 1px;
}

.create-topic-more-feature {
  height: 25px !important;
  width: 25px !important;
  margin-left: 0 !important;
  background: url('#{$cdn-url}information-icon.svg') center/13px auto no-repeat !important;
}

.dropdown-keywords-lists-disabled {
  background-color: rgba($main-grey, 0.45);
  .search-main-text-value {
    color: rgba($main-blue, 0.85);
  }
  .main-search-icon {
    opacity: 0.6;
  }
}
.navigation-sent-emails-description {
  font-size: 0.85rem !important;
  color: rgba(0, 18, 43, 0.4) !important;
  letter-spacing: 0 !important;
  width: fit-content !important;
  white-space: nowrap;
}
.navigation-sent-emails-description-container {
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.dropdown-keywords-new-agency {
  background-color: $main-white;
}

.main-client-picker-lists {
  .search-bar-lists {
    max-height: 240px;
  }
}

@media screen and (min-width: 992px) {
  .close-icon {
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }
  }

  .dropdown-keywordlist {
    p {
      &:nth-child(1) {
        width: calc(100% - 30px);
      }
    }
  }

  .description-field {
    max-width: calc(100% - 60px);
  }

  .header-scrolled {
    .dropdown-kewyord-lists-logged-out {
      &:hover {
        box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
      }
    }
  }
  .dropdown-kewyord-lists-logged-out {
    width: 220px;
  }
  .dropdown-kewyord-lists-home {
    width: 100% !important;
    .dropdown-keywords-activeitem {
      padding: 5px 8px 6px 14px;
    }
  }
  .btn-set-keywords {
    display: inline-block;
  }
  .dropdown-keywords-lists {
    z-index: 99;
  }
  .dropdown-keywords-lists-open {
    z-index: 1003;
  }
}

@media screen and (min-width: 1100px) {
  .dropdown-kewyord-lists-logged-out {
    width: 298px;
  }
  .dropdown-keywordlist-with-actions {
    p {
      &:nth-child(1) {
        width: calc(100% - 90px);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .search-bar-email-performance {
    flex-grow: 1;
  }
}
@media screen and (min-width: 320px) {
  .search-bar-email-performance {
    width: 100%;
  }
  .search-bar-engagement-statistics {
    min-width: 200px;
  }
}
@media screen and (min-width: 425px) {
  .search-bar-email-performance {
    min-width: 350px;
  }
}
