.analytics-embed-container {
  .spinner {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .analytics-widget-spacing {
    padding-left: 27px;
  }
  .sentiment-snapshot-container,
  .inner-mentions-container,
  .inner-sentiment-changes-container,
  .region-inner-container {
    padding-right: 27px !important;
    padding-left: 27px !important;
  }
  .region-inner-container {
    .map-chart {
      height: 580px !important;
    }
  }
  .embed-analytics-widget-title {
    border-bottom: 1px solid $main-grey;
    padding-bottom: 2.5em !important;
  }
}

.analytics-embed-back-button {
  margin-bottom: -20px;
  padding-left: 41px !important;
}

.embed-analytics-graph-item {
  .map-chart {
    position: relative !important;
    top: 0 !important;
  }
}

.dropdown-item-element-analytics-popup {
  &::after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid currentColor;
    right: -2px;
    top: 10px;
  }
}

.dropdown-item-element-analytics-popup.open {
  &::after {
    top: 12px;
    border-bottom: 9px solid currentColor;
  }
}

.embed-analytics-graph-item,
.analytics-popup {
  #legend-container-custom {
    margin-left: 3px;
  }
}

.bg-region-popup {
  background-color: #f4fbfd;
  .preloader-min-height {
    background-color: #f4fbfd !important;
  }
}

.analytics-popup-interest-by-region {
  .map-chart {
    top: 0px !important;
  }
}

.analytics-popup-sentiment-snapshot {
  .pie-chart-graph-container {
    height: 170px !important;
  }
  .pie-chart-container {
    max-width: 250px;
    min-width: 250px;
  }
  .sentiment-snapshot-container {
    padding: 1em !important;
  }
  .show-all-parties-button {
    width: 170px;
    height: 170px;
  }
}

.embed-analytics-topic-picker {
  .dropdown-keywordlist {
    &:hover {
      background-color: #f8f8f7;
    }
  }
}

.custom-graph-content {
  .sentiment-snapshot-container {
    padding-top: 1em !important;
  }
  .map-chart {
    .chart-map-frontbenchers-checkbox {
      right: 14px;
      .checkbox-input,
      .email-performance-info-tooltip {
        margin-top: 2px;
      }
    }
  }
}

.open-dropdown-analytics-embed {
  border-radius: 0 0 5px 5px !important;
}

.analytics-search-team-embed {
  .search-dropdown {
    border-left: none;
    &:hover {
      background-color: $main-white !important;
    }
    &::after {
      right: 11px !important;
    }
  }
}

.preview-analytics-embed {
  min-height: 280px;
  .preview-analytics-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    text-align: center;
    opacity: 0;
  }
  &:hover {
    .preview-analytics-image {
      opacity: 0;
    }
    .preview-analytics-text {
      opacity: 1;
    }
  }
}

.preview-analytics-single {
  min-height: 500px;
}

.preview-image-interest-by-region {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.analytics-preview-title {
  .embed-analytics-widget-title {
    padding-left: 27px !important;
    padding-bottom: 2em !important;
    border-bottom: 1px solid $main-grey;
  }
  .main-title {
    font-size: 20px;
  }
  .main-decription {
    font-size: 18px;
  }
  .widget-title-container {
    padding-left: 0px !important;
  }
}

.embed-analytics-widget-title {
  .main-title {
    font-size: 1.188em !important;
    margin-bottom: 10px !important;
  }
}

.widget-analytics-title {
  .embed-analytics-widget-title {
    padding-left: 27px !important;
    padding-bottom: 2em !important;
    border-bottom: 1px solid $main-grey;
  }
}

.analytics-widget-content-container {
  padding: 0px 13px;
}
