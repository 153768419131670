.mark-contributions-message {
  .checkbox-item {
    font-size: 0.9em;
    label {
      display: inline-block;
      margin-top: -9px;
      vertical-align: middle;
      &::before {
        border: 1px solid $secondary-blue;
      }
    }
  }
  .action-button {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
  }
}

.mark-contributions_question {
  span {
    font-weight: 700;
    font-variant: small-caps;
    font-size: 0.95em;
  }
}
