@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Work+Sans:wght@300&display=swap');

.page-competitors {
  p {
    line-height: 1.7;
    font-size: 1.1em;
  }
  img {
    max-width: 100%;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  h2 {
    font-weight: bold;
  }
}

.pages-hero-title {
  padding-bottom: 12.5vh;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.competitor-small-subtitle {
  color: rgba(0, 18, 43, 0.4);
  font-weight: 700;
  font-size: 14px !important;
  letter-spacing: 0.2px;
}

.competitor-first-banner {
  em {
    font-style: normal;
    color: #f2a805;
  }
  h2 {
    font-size: 1.65em;
    max-width: 500px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
  }
  ul {
    list-style: none;
    padding-left: 0px;
    padding-top: 20px;
    margin-bottom: 0px;
  }
  li {
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 20px;
    padding-left: 55px;
    position: relative;
    &::before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0;
      top: 4px;
      background: url('https://cdn.policymogul.com/images/20221003-white-tickbox.svg');
    }
  }
}

.competitor-blockquotes {
  blockquote {
    position: relative;
    display: block;
    font-style: italic;
    padding-left: 50px;
    p {
      color: #fff;
      padding-top: 15px;
      padding-left: 0px;
      padding-right: 30px;
    }
    &::before {
      content: '“';
      top: 0px;
      left: 0px;
    }
  }
  p {
    padding-left: 50px;
    color: rgba(242, 168, 5, 1);
  }
}

.competitor-blockquotes blockquote::before,
.quotation-end span {
  font-family: 'Gilda Display', serif;
  font-style: normal;
  font-size: 96px;
  position: absolute;
  color: rgba(242, 168, 5, 1);
  width: 30px;
  height: 30px;
  line-height: 1;
}

.quotation-end {
  position: relative;
  span {
    top: 0px;
    right: -50px;
    color: rgba(242, 168, 5, 1);
  }
}

.competitor-blockquote-image {
  position: relative;
  img {
    position: relative;
    z-index: 1;
    max-width: 65%;
    display: block;
    margin: 0 auto;
  }
  &:before {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% + 20px);
    left: 15px;
    border-radius: 50px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
  }
}

.competitor-transparent-block {
  p {
    max-width: 948px;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 1.5em;
  }
  a {
    color: #0094cc;
  }
}

.competitor-second-text {
  h2 {
    margin-bottom: 20px;
  }
}

.competitors-alternatives {
  table {
    background-color: rgba(0, 18, 43, 0.04);
    outline: 1px solid rgba(212, 212, 212, 1);
    border-radius: 5px;
    max-width: 1000px;
    margin: 0 auto;
    ul {
      padding-left: 20px;
      margin-bottom: 0px;
      line-height: 1.5;
    }
    li {
      line-height: 1.7;
    }
    td {
      padding: 10px 20px;
    }
    tr:not(:first-child) td {
      border-top: 1px solid #d4d4d4;
    }
    tr:not(:last-child) td {
      border-bottom: 1px solid #d4d4d4;
    }
    tr td:not(:first-child) {
      border-left: 1px solid #d4d4d4;
    }
    tr td:not(:last-child) {
      border-right: 1px solid #d4d4d4;
    }
    tr td:last-child {
      min-width: 420px;
    }
  }
  p {
    margin-top: 24px;
    font-size: 0.92em;
    text-align: center;
  }
}

.competitor-banner-description {
  font-size: 18px;
  font-weight: 700;
}

.competitor-start-trial-button,
.competitor-trial-main-button {
  transition: 0.2s ease-in;
}

.competitor-start-trial-button:hover {
  background-color: #f2a805 !important;
  border: 1px solid #f2a805 !important;
  color: #00122b;
}

.competitors-logos,
.home-logos {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  opacity: 1;
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 25%,
    rgb(0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: hidden;
}

@keyframes animationLogos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1890px);
  }
}

@keyframes animationHomeLogos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1360px);
  }
}

.home-logo-element {
  margin: 0 40px;
  display: inline-block;
}
.logo-track {
  display: flex;
  align-items: center;
  width: max-content;
  animation: scrollLogos 30s linear infinite;
}

@keyframes scrollLogos {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.competitors-logos {
  ul {
    width: 100%;
    height: 90px;
    list-style-type: none;
    flex-grow: 1;
    display: flex;
    place-items: center;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    position: relative;
    flex-direction: row;
    will-change: transform;
    animation: animationLogos 40s linear infinite;
  }
}

.competitor-logotype {
  max-height: 65px;
  width: auto;
  margin: 0 60px;
}

.competitor-logotype-01,
.competitor-logotype-05 {
  max-height: 50px;
}

.competitor-logotype-07,
.competitor-logotype-03 {
  max-height: 90px;
}

.competitors-logos ul,
.competitors-logos li {
  flex-wrap: nowrap;
}

.competitors-logos li {
  min-width: max-content;
}

.competitor-image {
  max-height: 65px;
  width: 250px;
  text-align: center;
  object-fit: contain;
  img {
    max-width: 240px;
    max-height: 65px;
  }
}

.competitor-image-07,
.competitor-image-03 {
  max-height: 90px;
}

.competitor-image-01 {
  img {
    max-width: 210px;
  }
}

.competitor-image-05 {
  img {
    max-width: 200px;
  }
}

.competitor-image-07,
.competitor-image-03 {
  img {
    max-height: 90px !important;
  }
}

@media screen and (min-width: 778px) {
  .page-competitors {
    .pages-general-hero {
      h1 {
        font-size: 3.25em;
      }
    }
  }
}
