.email-box {
  @extend .box;
  min-width: 840px;
  height: calc(100% - 100px);
  min-height: 380px;
}

.email-alerts-subtitle {
  font-size: 1.09em;
}

.hour-item-selected {
  border-radius: 5px;
  background: rgba(#d4d4d4, 0.2);
}

.hour-select {
  text-decoration: underline;
  cursor: pointer;
  span {
    max-width: 98%;
  }
}

.table-title {
  @extend .email-alerts-subtitle;
  padding: 0.6rem 1rem !important;
  text-align: center;
  border-bottom: 1px solid #00122b !important;
}

.create-alert-button {
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
}
