.module__text-single,
.module__text-image {
  padding: 7vh 0;
  h2 {
    margin: 0;
  }
}

.module__text-single {
  &.white {
    background-color: $main-white;
  }
  &.blue {
    max-width: 700px;
    margin: 0 auto;
    h1 {
      margin-bottom: 10px;
    }
    p {
      font-size: 1.2em;
    }
  }
}

.image_text__image {
  img {
    display: block;
    width: auto;
    margin: 0 auto;
    max-height: 400px;
  }
}

.module__content-panel--testimonial {
  z-index: 10;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    background: $salmon;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
}
.testimonial {
  padding: 10vh 0 15vh 0;
  max-width: 800px;
  margin: 0 auto;
  h3 {
    font-size: 1.875rem;
    font-weight: 700;
    text-align: initial;
    line-height: initial;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    &:before,
    &::after {
      content: '';
      display: block;
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 10;
    }
    &:before {
      background-image: url('#{$cdn-url}quote_open.png');
      width: 40px;
      height: 30px;
      top: -45px;
      left: 0;
    }
    &::after {
      background-image: url('#{$cdn-url}quote_close.png');
      background-size: 100%;
      width: 40px;
      height: 30px;
      bottom: -65px;
      right: 0;
    }
  }
}

.image_text {
  align-items: center;
}

.image_text__text {
  h1 {
    margin-bottom: 0.5em;
  }
  a.button {
    display: inline-block;
    border-radius: 5px;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }
  a.button__two {
    color: #fff;
    background: #0094cc;
  }
}

@media screen and (min-width: 992px) {
  .image_text__image {
    width: 50%;
  }
  .image_text__text {
    width: 50%;
  }
}

@media screen and (min-width: 1023px) {
  .testimonial {
    h3 {
      &::before {
        width: 65px;
        height: 50px;
        top: 0;
        left: -85px;
      }
      &::after {
        width: 65px;
        height: 50px;
        bottom: 0px;
        right: 0px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .image_text {
    &.container {
      max-width: 1200px !important;
      padding: 0 !important;
    }
  }
}
