@import './account.scss';
@import './key-words.scss';
@import './email-alerts.scss';
@import './pricing-page.scss';
@import './team.scss';
@import './settings-sidebar.scss';
@import './react-tags-input.scss';
@import './interests-alerts.scss';
@import './popupcheckout.scss';
@import './topics.scss';
@import './web-feeds.scss';
@import './email-alerts-page.scss';
@import './countries-popup.scss';
@import './email-domains.scss';
@import './referral-modal.scss';
@import './clients.scss';
@import './embeddables.scss';

.setting-item {
  margin-bottom: 0;
  font-size: 1rem !important;
  color: $main-blue;
}

.settings-navbar {
  .main-title {
    &::before {
      background-color: #fff;
    }
  }
}

.setting-scrollbar {
  .ScrollbarsCustom-TrackY {
    top: 0px !important;
  }
}

.main-content-settings {
  background: #fff;
  margin-top: 3px;
}

@media screen and (min-width: 992px) {
  .main-content-settings {
    background: none;
    margin-top: 0px;
  }
  .setting-scrollbar {
    margin-left: -0.4em !important;
    width: calc(100% + 2.4rem) !important;
  }
}
