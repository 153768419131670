.simple-scrollbar {
  .ScrollbarsCustom-TrackY {
    top: 0px !important;
    width: 8px !important;
    height: 100% !important;
    background-color: transparent !important;
  }
  .ScrollbarsCustom-Wrapper {
    inset: 0px 0px 0px 0px !important;
  }
  .ScrollbarsCustom-ThumbY {
    background-color: rgba(#99a4ae, 0.7) !important;
    right: 0px !important;
  }
  .dragging {
    background-color: #99a4ae !important;
  }
  .ScrollbarsCustom-Content {
    padding-right: 0em !important;
    padding-bottom: 0em !important;
  }
}
.election-content-scrollbar {
  @media screen and (min-width: 1180px) {
    .ScrollbarsCustom-Wrapper {
      width: 100vw !important;
    }
    .ScrollbarsCustom-Content {
      width: 774px !important;
      min-width: 774px !important;
    }
  }
}

.engagement-statistics-scrollbar {
  @extend .simple-scrollbar;
  .ScrollbarsCustom-TrackY {
    right: -14px !important;
  }
  .ScrollbarsCustom-Wrapper {
    border-radius: 5px;
  }
}

.related-content-scroll {
  width: calc(100% + 28px) !important;
  .ScrollbarsCustom-Content {
    padding-right: 20px !important;
    padding-bottom: 12px !important;
  }
}

.related-content-open-assistant {
  .related-content-scroll {
    .ScrollbarsCustom-Content {
      padding-bottom: 0px !important;
    }
  }
}

.smooth-scrolling {
  .ScrollbarsCustom-Content {
    scroll-behavior: smooth;
  }
}

.scrollbar-total-height {
  .ScrollbarsCustom-Content {
    height: 100%;
  }
}

@media screen and (min-width: 992px) {
  .simple-scrollbar {
    &:hover {
      .ScrollbarsCustom-TrackY {
        opacity: 1;
      }
    }
    .ScrollbarsCustom-ThumbY {
      &:hover {
        background-color: #99a4ae !important;
      }
    }
    .ScrollbarsCustom-TrackY {
      opacity: 0;
    }
  }

  .indented-scrollbar-small {
    .ScrollbarsCustom-TrackY {
      right: 5px !important;
    }
  }

  .indented-scrollbar {
    .ScrollbarsCustom-TrackY {
      right: 16px !important;
    }
  }

  .top-spaced-scrollbar {
    .ScrollbarsCustom-TrackY {
      padding-top: 16px !important;
    }
  }

  .top-spaced-scrollbar-medium {
    .ScrollbarsCustom-TrackY {
      padding-top: 10px !important;
    }
  }
}
