.client-picker {
  margin-left: 1em;
  margin-right: 1em;
  .search-main-text-value {
    width: calc(100% - 25px) !important;
  }
}

.client-picker-logo {
  width: 24px !important;
  min-width: 24px !important;
  height: auto !important;
  margin-right: 0.65em;
}

.client-picker-logo-main {
  display: block;
  border-radius: 50%;
}

.manage-clients-button {
  transition: 0.2s ease-in;
  border-radius: 0px 0px 20px 20px;

  &:hover {
    background-color: $hovered-blue;
    color: $light-blue;
    text-decoration: none !important;
  }
  .icon-edit-pencil-thick {
    display: inline-block;
    width: 24px;
    margin-left: 6px;
    margin-top: -3px;
    margin-right: 4px;
    vertical-align: middle;
  }

  .icon-square-plus {
    margin-right: 13px;
    margin-left: 2px;
    vertical-align: middle;
    margin-top: -4px;
    display: inline-block;
  }
}

.client-picker-name {
  .topic-selected-mark {
    font-size: 13px;
  }
}

.client-picker {
  min-width: 220px !important;
  max-width: 220px !important;
}

.client-picker-complete {
  min-width: 100% !important;
  max-width: 100% !important;
}

.navigation-client-picker {
  border-bottom: 1px solid $main-grey;
  margin-bottom: 5px;
  .client-picker {
    border: none;
    background-color: $main-white !important;
    box-shadow: none !important;
    .search-dropdown {
      border: none !important;
      &:hover {
        background-color: $main-white !important;
      }
      &::after {
        top: 16px;
      }
    }
  }
}

.client-loading-logo-container {
  margin: -140px auto 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  img {
    width: 50%;
  }
}

.client-loading-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  border: 5px solid rgba(0, 18, 43, 0.2);
  background: transparent;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

.client-picker-logo-hovered {
  position: absolute;
  font-size: 34px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -5px;
  left: -5px;
  color: rgba(0, 18, 43, 0.3);
  opacity: 0;
  transition: transform 0.4s ease-in-out;
  transform-origin: 17px 17px;
  transform: rotate(-180deg);
}

.client-picker-highlighted {
  .client-picker-logo-hovered {
    transform: rotate(0deg);
    opacity: 1;
  }
}

.client-loading-image {
  opacity: 0;
  transition: 1s ease-in;
  &.appearing {
    opacity: 1;
  }
  &.dissapearing {
    opacity: 0;
  }
}

.client-animation-container {
  border-radius: 50%;
  transition: all 0.2s ease-out;
  &.animated {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    //animation: scale2 2.4s infinite;
  }
}

.main-client-loading-container {
  position: absolute;
  width: 100%;
}

.client-loading-container-fade-out {
  transition: all 0.6s ease-out;
  //transform: translateX(100%);
}

$dot-width: 2px;
$speed: 1.5s;

.client-loading-text {
  width: calc(100% + 40px);
  span {
    animation: blink $speed infinite;
    animation-fill-mode: both;
    display: inline-block;
    height: $dot-width;
    width: $dot-width;
    background: currentColor;
    border-radius: 50%;
    margin-left: 2px;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: $dot-width * 1;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: $dot-width * 1;
    }
  }
}

@keyframes scale2 {
  0% {
    transform: scale(1);
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.2);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media screen and (min-width: 1170px) {
  .client-picker {
    min-width: 270px !important;
    max-width: 270px !important;
  }

  .client-picker-complete {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
