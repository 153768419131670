.parliamentarian-name {
  .react-autosuggest__container {
    width: 100%;
    input {
      &::placeholder {
        color: rgba($main-blue, 0.35);
      }
    }
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
    left: 0;
    box-shadow: none;
    border-color: $main-black;
  }
  .react-autosuggest__suggestion {
    padding-left: 0;
    padding-right: 0;
    p {
      color: rgba($main-blue, 0.75);
    }
    .search-item {
      border-radius: 0px;
      padding: 0.75rem 1rem;
    }
  }
  .react-autosuggest__suggestion--highlighted {
    .search-item {
      background-color: rgba($light-blue, 0.04);
    }
    &::after {
      background-color: transparent;
    }
  }
}

.create-parliamentarian-container {
  label {
    font-size: 15px;
  }
  .terms-conditions-label {
    color: rgba($main-blue, 0.75);
  }
  h2 {
    font-weight: 700;
    font-size: 1.375rem;
  }
}

.general-error-message {
  background: url('#{$cdn-url}warning-icon.svg') 0px 6px / 23px auto no-repeat;
  padding-left: 35px !important;
  .message {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    min-width: 100%;
    z-index: 100;
    position: relative;
    top: 0;
    left: 0;
    color: rgba($main-blue, 0.7);
  }
}

.form-item-password {
  position: relative;
  input {
    padding-right: 32px;
  }
}

.show-password-button {
  position: absolute;
  right: 12px;
  top: 41px;
  width: 20px;
  height: 19px;
  background: url('#{$cdn-url}show-password1.png') center/contain no-repeat;
  z-index: 2;
}

.show-password-shown {
  background: url('#{$cdn-url}hide-password1.png') center/contain no-repeat;
}
