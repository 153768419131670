$dynamic-color: #34c79d;
$static-color: #a0a2a9;

.group-type {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -2.9px;
  display: inline-block;
}
.group-type-dynamic {
  background-color: $dynamic-color;
}
.group-type-static {
  background-color: $static-color;
}

.subnav-group-type {
  .group-type {
    width: 12px;
    height: 12px;
    margin-right: 1.1em;
    box-shadow: 0px 0px 1px 1px #0000001a;
  }
}

.subnav-group-type-dynamic {
  background-color: rgba($dynamic-color, 0.06);
  .group-type {
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    animation: anim-vibrate 3s infinite;

    @keyframes anim-vibrate {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($dynamic-color, 0.65);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 0.75rem rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }
}

.subnav-group-type-static {
  background-color: rgba($static-color, 0.06);
}

.groups-membership {
  .membership-main-container {
    margin-bottom: 0px !important;
  }
  .react-custom-select-contacts__menu {
    margin-top: 5px;
  }
}

.group-tags,
.groups-membership {
  .react-custom-select-contacts__menu {
    width: calc(100% + 2px) !important;
    left: -1px !important;
    z-index: 10000000;
  }
}

.groups-columns-component {
  min-height: 88px;
}

.group-tags {
  .react-custom-select-contacts__menu {
    margin-top: 0px;
    top: 0px !important;
  }
  .tags-filter-container {
    margin-bottom: 0 !important;
  }
}
.shadow-dropdown {
  .hour-select-opendropdown {
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.12) !important;
  }
}

.shadow-open-dropdown {
  box-shadow:
    0 1px 3px rgba(60, 63, 66, 0.12),
    0 4px 10px rgba(60, 63, 66, 0.05);
}

.shadow-dropdown-highlight {
  border: 1px solid $light-blue !important;
  box-shadow:
    1px 1px 3px rgba(0, 148, 204, 0.12),
    0 4px 10px rgba(0, 148, 204, 0.05);
  &.hour-select-opendropdown {
    border: 1px solid rgba($main-grey, 0.6) !important;
  }
}

.groups-add-filter-button {
  &:hover {
    background-color: rgba($main-grey, 0.2);
  }
}

.groups-add-filter-group-button {
  &:hover {
    background-color: rgba($light-blue, 0.08);
  }
}

.delete-group-button {
  &:hover {
    background: rgba(228, 7, 65, 0.07);
  }
}

.groups-sidebar {
  .group-separator {
    &:last-of-type {
      display: none;
    }
  }
  .group-single-filter-item {
    &:last-of-type {
      border-bottom: none !important;
    }
  }
  .group-single-container {
    padding-bottom: 0 !important;
  }
  .filter-group-title {
    font-size: 15px;
    padding-left: 5px;
    padding-top: 1px;
  }
}

.filter-item-and-text {
  position: relative;
  margin: 8px 0;
  &:before {
    content: '';
    width: 100%;
    background-color: rgba($main-grey, 0.7);
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    z-index: 0;
  }
  span {
    position: relative;
    z-index: 2;
  }
}

.group-sidebar {
  .filter-item-single-text {
    font-size: 15px;
  }
  //AE: Changes for or
  .group-filter-item {
    margin-bottom: 0 !important;
  }
  .group-separator {
    text-align: left !important;
    width: calc(100% + 2em);
    margin-left: -1em;
  }
  .or-separator {
    background-color: #f7fcfe;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    margin-left: 4em;
    color: rgba(0, 18, 43, 0.7);
    font-size: 12px;
    line-height: 1.25;
  }
}

.save-filter-button {
  width: 113px;
}

.group-select-container {
  .react-custom-select-contacts__control {
    height: 35px !important;
    min-height: 35px !important;
  }
  .main-search-icon {
    top: 9px;
  }
  .react-custom-select-contacts__placeholder {
    margin-top: -4px;
  }
}

.group-view-edit-button {
  width: 38px;
  height: 38px;
  min-width: 38px;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.group-view-title {
  min-width: 105px;
  cursor: pointer;
  .main-title {
    line-height: 1.2;
  }
  &:hover {
    .group-view-edit-button {
      opacity: 1;
    }
  }
}

$number-of-elements: 6;
$delay-step: 0.2s;

.empty-message-contacts-element {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  @for $i from 1 through $number-of-elements {
    &:nth-of-type(#{$i}) {
      animation-delay: $i * $delay-step;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.groups-filters-dropdown {
  max-height: 195px !important;
  .keyword-lists-website-feeds {
    .login-navbar {
      padding-bottom: 0 !important;
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  .ScrollbarsCustom-Content {
    padding: 0.35em 0em 0em 0em !important;
  }
  .settings-button {
    padding-right: 1em !important;
    padding-left: 1em !important;
  }
}

.create-group-actions-bar {
  padding-top: 12px;
  padding-bottom: 12px;
}

.subnav-buttons-spacing {
  padding-top: 0.45em;
  padding-bottom: 0.45em;
}

.empty-groups-text-red {
  color: #b91943;
}
.empty-groups-text-green {
  color: #12a46f;
}

.empty-groups-message-container {
  position: relative;
  padding: 1em;
  border: 1px solid $main-blue;
  background-color: $hovered-blue;
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  font-size: 1.25em;
  min-height: 190px;
}

.inspired-message {
  top: -16px;
  left: 8px;
  font-size: 12px;
}

.blinking-cursor {
  display: inline-block;
  background-color: #333;
  width: 0px;
  animation: blink 0.8s step-end infinite;
}

.group-alerts-select {
  .hour-select {
    color: rgba($main-blue, 0.8);
    border-radius: 20px;
    border-color: $main-grey;
    min-height: 36px;
    background-color: $main-white;
    &::after {
      right: 18px;
    }
    &:hover {
      background-color: $hovered-blue;
    }
  }
  .icon-alert-medium,
  .icon-alert-off {
    transform: translateY(1px);
    display: inline-block;
  }
}
.group-alerts-select-dropdown {
  min-width: 415px;
  .keyword-lists-website-feeds .login-navbar {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 0;
    & > button {
      padding: 0.7em 1em;
      &:last-of-type {
        border-top: 1px solid rgba(212, 212, 212, 0.5);
      }
      &:last-of-type:not(.text-main-blue) {
        color: $danger-text;
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@media screen and (min-width: 640px) {
  .empty-groups-message-container {
    padding: 1.25em 2.25em;
    font-size: 2.5em;
    min-height: 290px;
  }
  .inspired-message {
    left: 32px;
    font-size: 16px;
  }
}

.group-filters-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}
.preselected-group-filter-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(#fff, 0.8);
  top: 0;
}
.group-type-select-dropdown {
  &[data-disabled='true'] {
    .position-relative > button {
      @extend .disabled-hour-select;
      pointer-events: none;
      &::after {
        display: none;
      }
    }
  }
}
.group-filters-grid-container {
  padding-right: 0.5rem;
  .reports-default-popup {
    padding-right: 1rem;
    grid-auto-rows: 1fr;
  }
  .ScrollbarsCustom-Content {
    height: calc(100% + 25px) !important;
    padding-bottom: 10px !important;
  }
  .groups-preselected-filters-scrollbar {
    max-height: 310px;
    .general-button {
      .border.rounded.text-center.hovered-button {
        padding: 1.3rem 1rem;
      }
      &:disabled {
        & .hovered-button {
          background-color: rgba(0, 18, 43, 0.04) !important;
          color: rgba(0, 18, 43, 0.5);
        }
      }
      .icon-padlock {
        position: absolute;
        right: 14px;
        top: 12px;
      }
    }
  }
  @media (min-width: 992px) {
    padding-right: 1rem;
    .reports-default-popup {
      padding-right: 1.5rem;
      grid-template-rows: repeat(2, minmax(130px, 1fr));
    }
  }
}
.select-topic-create-group {
  .scroll-container.hover-container.search-bar-lists {
    max-height: 225px;
  }
}
@media (min-width: 480px) {
  .group-filters-grid-container {
    .groups-preselected-filters-scrollbar {
      max-height: 490px;
    }
  }
}
@media (min-width: 992px) {
  .group-view-title {
    min-width: none;
  }
}
