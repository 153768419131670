$editor-color: #fafafa;
.rdw-editor-toolbar {
  background: $editor-color;
  border: 1px solid rgba($main-grey, 0.5);
  margin-bottom: 0;
}

.rdw-option-wrapper {
  background: $editor-color;
  border: none;
  &:hover {
    box-shadow: none;
  }
}

.rdw-editor-main {
  padding: 10px 15px 20px 15px;
  border: 1px solid rgba($main-grey, 0.5);
  border-top: none;
  min-height: 120px;
  border-radius: 0px 0px 3px 3px;
  margin-bottom: 2em;
}

.public-DraftStyleDefault-block {
  margin: 0.3em;
}

.rdw-link-modal {
  font-family: $font-family-sans-serif;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  button {
    &:hover {
      box-shadow: none;
    }
  }
}

.rdw-link-modal-btn {
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
  &:nth-of-type(1) {
    &:not(:disabled) {
      background-color: $light-blue;
      color: $main-white;
    }
  }
}

.rdw-link-modal-buttonsection {
  &:first-child {
    &:not(:disabled) {
      background-color: $light-blue;
      color: $main-white;
    }
  }
}

.rdw-link-modal-input {
  height: 28px;
}
