.arrow-left-white {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: url('#{$cdn-url}20211222-left-arrow-white.svg') center 9px / 15px auto no-repeat;
  margin-right: 8px;
  margin-left: -8px;
}

.arrow-left-blue {
  @extend .arrow-left-white;
  margin-top: -1px;
  background: url('#{$cdn-url}20220201-left-arrow-blue.svg') center 9px / 15px auto no-repeat;
}

@media screen and (min-width: 992px) {
  .arrow-left-white {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba($main-white, 0.1);
    }
  }
  .arrow-left-blue {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba($light-blue, 0.04);
    }
  }
}
