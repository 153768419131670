.content-item {
  padding: 0 0em 0 0.5em !important;
  position: relative;
}

.content-item-link {
  position: relative;
  display: block;
  border: none;
  outline: none;
  &:not(.influence-list-item) {
    &:focus {
      border: none;
    }
  }
  &:focus {
    outline: none;
  }
  .button-thumbs-down {
    margin-right: 4px;
  }
}

.content-item-title {
  color: #022344;
  font-size: 0.95rem;
  font-weight: 600;
  @extend .hidden-lines;
  -webkit-line-clamp: 3;
}

.stakeholder-list-item {
  padding-left: 0em !important;
}
