//https://codepen.io/hansmaad/pen/qaGrQL
$md-radio-checked-color: currentColor;
$md-radio-border-color: currentColor;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 8px;
*,
*:before,
*:after {
  box-sizing: border-box;
}
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 18, 43, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.radio-button {
  input[type='radio'] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
      //animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0 0 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    line-height: 1.1;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.1s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
      background: transparent;
    }

    &:after {
      top: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
      left: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

@media screen and (min-width: 992px) {
  .radio-button {
    input[type='radio'] {
      &:hover + label:before {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 28, 53, 0.06);
        background-color: rgba(0, 28, 53, 0.06);
      }
    }
  }
}
