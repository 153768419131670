.assitant-text {
  border-left: 3px solid $main-blue;
  padding-left: 20px;
  white-space: pre-wrap;
  p {
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.writing-phrases {
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid $main-grey;
    padding-bottom: 5px;
    border-radius: 0px !important;
    &:focus {
      border-bottom: 1px solid $light-blue;
    }
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: rgba($main-blue, 0.35);
    }
  }
}

.writing-textarea {
  width: 100%;
  outline: none;
  border: 1px solid $main-grey;
  padding: 15px;
  border-radius: 5px;
  resize: none;
  &:focus {
    border: 1px solid $light-blue;
  }
  &::placeholder {
    color: rgba($main-blue, 0.35);
  }
}

.writing-list {
  list-style: disc;
  padding-left: 20px;
  li {
    margin-bottom: 12px;
  }
}

.disclaimer-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.writing-back-link {
  transition: 0.2s ease-in;
  .icon-long-arrow-left {
    margin-top: 4px;
    display: inline-block;
  }
  &:hover {
    color: $light-blue;
  }
}

.writing-my-content {
  .content-item-link {
    &:first-child {
      margin-top: 0 !important;
      li {
        margin-top: 0 !important;
      }
    }
  }
}

.writing-creation-buttons {
  margin-top: -8px;
  .creation-button {
    margin-top: 8px;
  }
}

.create-lobbying-material-button {
  .dropdown-item-element {
    &::after {
      top: 10px;
    }
  }
  .dropdown-item-element.open {
    &::after {
      top: 9px;
    }
  }
}

.add-to-policymogul-button {
  background: $hovered-blue;
  border: 1px solid $main-grey;
  margin-top: -22px;
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
  }
  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid $main-grey;
    right: 67px;
    top: -10px;
    width: 10px;
    height: 10px;
  }
  &::after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid $hovered-blue;
    right: 68px;
    top: -8px;
    width: 9px;
    height: 9px;
  }
}

.my-content-buttons {
  button,
  a {
    font-size: 0.9em;
  }
}

.team-credits-popup {
  width: 330px;
  right: 0;
  top: 38px;
  .action-button {
    padding-top: 5px;
    padding-bottom: 6px;
  }
}

.content-summary-credits {
  top: 44px;
}

@media screen and (min-width: 992px) {
  .writing-assistant-bar {
    top: 0;
    position: sticky;
    z-index: 200;
  }

  .my-content-buttons {
    button,
    a {
      font-size: 1em;
    }
  }
}
