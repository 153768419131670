.map-chart {
  .leaflet-tooltip {
    width: 180px;
    display: block;
    white-space: pre-wrap;
  }
  .leaflet-tile-pane {
    filter: brightness(1.15);
  }
  .leaflet-container {
    background-color: #f4fbfd;
  }
  .leaflet-bottom {
    z-index: 999;
  }
  .info {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    div {
      display: flex;
      column-gap: 15px;
      align-items: center;
      .label {
        display: block;
        border-radius: 1px;
        border: 1px solid rgba(0, 18, 43, 0.3);
      }
      .blue {
        background-color: rgba(0, 148, 204, 0.8);
      }
      .white {
        background-color: #e6f2f5;
      }
      .bubble-chart-label {
        background-color: rgba(0, 148, 204, 0.5);
        border: none;
      }
      .max-round {
        @extend .bubble-chart-label;
        height: 18px !important;
        width: 18px !important;
        min-width: 18px;
        min-height: 18px;
        border-radius: 50% !important;
      }
      .legend {
        text-align: center;
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        color: rgb(0, 18, 43);
      }
    }
  }
  .chart-map-frontbenchers-checkbox {
    position: absolute;
    bottom: 13px;
    right: 33px;
    z-index: 1000;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    padding-left: 15px;

    &:hover {
      label {
        color: #00122b;
      }
    }
    &:hover .inner-label {
      &::before {
        content: '';
        width: 34px;
        height: 34px;
        background-color: rgba(0, 28, 53, 0.06);
        position: absolute;
        top: -8.5px;
        left: -8.5px;
        border-radius: 50%;
        z-index: 1;
        transform: translateZ(-1px);
      }
    }
    .inner-label {
      &::before {
        content: '';
        width: 34px;
        height: 34px;
        background-color: transparent;
        position: absolute;
        top: -8.5px;
        left: -8.5px;
        border-radius: 50%;
        transition: 200ms;
        z-index: 1;
      }
    }
    .checkbox-input {
      position: relative;
      margin-right: 10px;
      display: flex;
      align-items: center;
      z-index: 1000;
      & > input {
        visibility: hidden;
        position: absolute;
      }
      & > input:checked + label::after {
        content: '';
        position: absolute;
        left: 0.5px;
        -webkit-transform: translate(0.235em, 0.31346em) rotate(-45deg);
        -ms-transform: translate(0.235em, 0.31346em) rotate(-45deg);
        transform: translate(0.235em, 0.31346em) rotate(-45deg);
        width: 11px;
        height: 5px;
        border: 2px solid #00122b;
        border-top-style: none;
        border-right-style: none;
        box-sizing: border-box;
      }

      & > label {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 1px solid rgba(0, 18, 43, 0.6);
        border-radius: 3px;
        position: relative;
        margin: 0 auto;
        cursor: pointer;
        transform-style: preserve-3d;
      }
    }
    .checkbox-input > input:checked + label {
      background-color: white;
    }

    & > label {
      transform: translateY(1px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin-bottom: 0;
      font-size: 14.8px;
      font-family: 'Lato', sans-serif;
      color: rgb(102, 102, 102);
      transition: 200ms;
      cursor: pointer;
    }
    .info-icon-container {
      transform: translate(-5px, 3px);
    }
  }
}
.interest-chart-data-container {
  margin-top: 345px;
}
.external-interest-by-region-tooltip {
  background: rgb(244, 239, 237);
  border-radius: 5px;
  color: rgb(0, 18, 43);
  border: 1px solid rgba(0, 18, 43, 0.3);
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 15px);
  transition: all 0.2s ease;
  min-width: 220px;
  font-size: 12px;
  padding: 12px;
  font-family: 'Lato', sans-serif;
  z-index: 10000000;
  .chart-tooltip-title {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .chart-tooltip-description-p {
    margin-bottom: 3px;
    display: flex;
    column-gap: 4px;
    line-height: 16px;
  }
  .chart-tooltip-description-no-contributions {
    opacity: 0.7;
    margin-bottom: 3px;
  }
}
.interest-by-region-chart-title {
  position: relative;
  z-index: 1000;
}
.interest-by-region-chart-title-container {
  margin: 40px 0;
}
@media screen and (max-width: 580px) {
  .map-chart-background {
    background: linear-gradient(180deg, rgba(244, 251, 253, 1) 20%, rgba(255, 255, 255, 0) 100%);
  }
  .chart-map-frontbenchers-checkbox {
    bottom: -50px !important;
    left: 1px;
  }
  .interest-chart-data-container {
    margin-top: 415px !important;
  }
  .interest-by-region-chart-title-container {
    transform: translateY(-20px);
    position: relative;
    z-index: 1000;
  }
}

.DownloadChart-map {
  display: block !important;
  h4 {
    text-align: center;
    margin-bottom: 14px;
  }
  .imgContainer-map {
    display: block;
    position: relative;
    padding: 0 !important;
    padding-top: 50px !important;
    background-color: #f4fbfd !important;
    margin-bottom: 14px;

    & img {
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
    }
  }
  .imgContainer-no-title-map {
    padding-top: 0px !important;
    & img {
      border-radius: 11px;
    }
  }
  .sectionTitle-map {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
  }
  .sectionImg-map {
    filter: brightness(1.15);
  }
  .logoContainer-map {
    display: block !important;
    text-align: end;
    p {
      display: inline;
      margin-right: 5px;
    }
  }
}
.dashboard-analytics-interest-by-region {
  a {
    height: 378px !important;
  }
}

.popup-analytis-download-map {
  .sectionTitle-map {
    position: relative;
    left: 0px;
    transform: translateX(0%);
    top: 0px;
  }
}

@media screen and (max-width: 992px) {
  .dashboard-analytics-interest-by-region {
    .map-chart {
      left: 1px !important;
      top: 1px !important;
      width: calc(100% - 2px) !important;
    }
  }
  .leaflet-control {
    margin-left: 16px !important;
  }
}
.leaflet-region-style-heat {
  transition: fill-opacity 100ms;
  &:hover {
    fill-opacity: 1;
  }
}
.leaflet-region-style-buble {
  transition: fill-opacity 100ms;
  &:hover {
    fill-opacity: 0.9;
  }
}
