.email-performance-container {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  row-gap: 27px;
  padding-bottom: 20px;
  overflow-x: hidden;
  .email-performance-recipients-qntty {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #00122b;
    margin-bottom: 0;
    a {
      color: #0094cc;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .email-performance-horizontal-container {
    display: flex;
    column-gap: 38px;
    row-gap: 27px;
    flex-wrap: wrap;
  }

  .email-performance-rate-header {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #00122b;
  }

  .email-performance-rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    color: rgba(0, 18, 43, 0.7) !important;
  }
  .email-performance-rate-total {
    padding-top: 13px;
    padding-bottom: 44px;
    border-top: 1px solid rgba(212, 212, 212, 0.5);
    position: relative;
  }
  .email-performance-rate-unique {
    padding-bottom: 11px;
  }
  .email-performance-delivery {
    border-top: 1px solid rgba(212, 212, 212, 0.5);
    padding-top: 12px;
  }
  .delivery-container {
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-top: 17px;
  }
  .email-performance-device {
    position: absolute !important;
    width: 100%;
    bottom: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #00122b;
    padding-top: 17px;
    padding-left: 38px;
    padding-right: 36px;
    position: relative;
    left: 0;
    .email-performance-device-percentage {
      color: rgba(#00122b, 0.8);
      margin-left: 5px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #d4d4d4;
      top: 0;
      left: 0;
    }
  }
  .top-clicked-links-table {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 15px;
    .top-clicked-links-row {
      column-gap: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .top-clicked-links-table-header {
      font-size: 12px;
      line-height: 30px;
      color: rgba(0, 18, 43, 0.7);
      border-bottom: 1px solid #d4d4d4;
      padding-bottom: 5px;
    }
  }
  .email-performance-clicks {
    font-size: 14px;
    line-height: 30px;
    color: rgba(0, 18, 43, 0.7);
  }
}
.email-performance-info-tooltip {
  cursor: pointer;
  width: 17px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sender-dropdown-info-tooltip {
  margin-left: 8px;
  margin-top: 2px;
}
.email-performance-section-container {
  background: #ffffff;
  border: 1px solid rgba(132, 132, 132, 0.25);
  border-radius: 5px;
  padding: 26px 36px 19px 38px;
  min-width: 284px;
  flex-grow: 1;
  flex-basis: 284px;
}
.email-performance-section-container-top {
  @extend .email-performance-section-container;
  min-height: 245px;
  width: calc(50% - 27px);
}
.email-performance-link {
  margin-left: 11px;
  color: #0094cc;
  transition: all 200ms;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #0094cc;
    text-decoration: underline;
  }
  // :after {
  //   content: "";
  //   display: block;
  //   width: 100%;
  //   position: absolute;
  //   bottom: 1px;
  //   right: 0;
  //   height: 1.5px;
  //   background-color: #0094cc;
  // }
}
.email-performance-section-delivery {
  padding-top: 36px !important;
  .delivery-items-container {
    padding: 63px 140px 74px 99px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    .delivery-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      line-height: 30px;
      color: #00122b;
      & > span {
        white-space: nowrap;
      }
      .delivery-item-link {
        font-size: 20px;
        margin-top: 17px;
        margin-left: 0;
      }
    }
  }
}
.engaged-links-header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 18px;
}
.engaged-links-tabs {
  display: flex;
  justify-content: flex-end;
  .engaged-links-tabs-button {
    border: 1px solid #d4d4d4;
    border-left: none;
    background-color: rgba(0, 18, 43, 0.05);
    width: 90px;
    height: 29px;
    font-size: 12px;
    &:nth-of-type(1) {
      border-radius: 3px 0 0 3px;
      border-left: 1px solid #d4d4d4;
    }
    &:nth-of-type(2) {
      border-radius: 0 3px 3px 0;
    }
  }
  .engaged-links-tabs-button-active {
    background-color: rgba(0, 18, 43, 0.1);
  }
}
.engaged-links-empty-state {
  margin-top: 51px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 18, 43, 0.45);
}
.top-clicked-links-empty-state {
  @extend .engaged-links-empty-state;
  margin-top: 36px;
}
.engaged-links-table {
  @extend .top-clicked-links-table;
  margin-top: 35px !important;
  padding-right: 14px;
}
.engaged-links-row {
  @extend .top-clicked-links-row;
  .email-performance-link {
    margin-left: 0 !important;
  }
}

.recipient-sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 29px 0 39px;
  font-size: 16px;
  line-height: 38px;
  color: #022344;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 200ms;
  &:hover {
    background-color: #f6f6f6;
  }
}

.recipient-sidebar-item-active {
  color: #0094cc;
  background: rgba(0, 148, 204, 0.06) !important;
}

.recipient-sidebar-item-qntty {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.5);
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 10px;
  line-height: 9px;
}

.email-recipients-title {
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 18, 43, 0.7);
}

.email-recipients-body {
  margin-top: 0px;
  padding-top: 17px;
  border: none;
  .email-recipients-table-cell {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .email-recipients-table-row {
    display: flex;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
    padding: 0 30px;
    & > div:nth-of-type(1) {
      width: 60%;
    }
    & > div:nth-of-type(2) {
      width: 40%;
    }
  }
  .email-recipients-expanded-content {
    padding: 0 30px;
  }
  .email-recipients-regular-row {
    border-top: 1px solid rgba(212, 212, 212, 0.5);
    & > div {
      display: flex;
      align-items: center;
    }
    font-size: 16px;
    line-height: 18px;
    transition: all 200ms;
    &:hover {
      background: rgba(0, 148, 204, 0.06) !important;
    }
    .email-recipients-regular-row-first-cell {
      column-gap: 10px;
      color: #0094cc;
      .recipients-row-arrow {
        width: 12px;
        height: 12px;
        border: 6px solid transparent;
        border-left: 9px solid #0094cc;
        margin-right: 8px;
        transition: all 200ms;
      }
    }
    .email-recipients-regular-row-second-cell {
      color: #00122b;
      overflow: hidden;
      .recipients-link {
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
          color: #0094cc;
          text-decoration: underline;
        }
      }
    }
  }
  .email-recipients-table-arrows {
    margin-left: 20px;
    margin-top: 3px;
  }
  .email-recipients-table-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #00122b;
    & > div {
      transition: all 200ms;
      justify-content: flex-start;
    }
    & > div:hover {
      opacity: 0.85;
    }
  }
  .empty-recipients-message {
    .empty-message-no-results {
      color: #00122b;
      font-size: 22px;
    }
  }
}
.side-left-element-recipients {
  @extend .side-left-element;
  margin-top: 9px;
  width: 233px;
  margin-right: 47px;
}

.side-left-element-recipients-performance {
  @extend .side-left-element-recipients;
}

.recipients-performance-wrapper {
  background: #ffffff;
  border: 1px solid rgba(132, 132, 132, 0.25);
  border-radius: 5px;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .email-performance-wrapper {
    padding-left: 38px;
    padding-right: 48px;
  }
}
.blue-link-underline-recipient {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  height: 19px;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &:hover + .external-link-recipient {
    visibility: visible;
  }
}
.external-link-recipient {
  visibility: hidden;
  margin-left: -5px;
  margin-right: 5px;
  display: inline-block;
  font-size: 13px;
  color: rgba(0, 18, 43, 0.5);
}

.additional-recipient-info-loading-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  & i {
    font-size: 20px;
  }
}

.additional-recipient-info-row {
  @extend .email-recipients-table-row;
  border-top: 1px solid rgba(212, 212, 212, 0.5);
  color: rgba(0, 18, 43, 0.7);
  padding: 0 !important;
  cursor: default !important;
  & > div {
    display: flex;
    align-items: center;
  }
  font-size: 16px;
  line-height: 18px;
  .additional-recipient-info-row-first-cell {
    padding-left: 32px;
    padding-right: 15px;
    display: block;
    .email-performance-link {
      margin-left: 0;
      display: block;
    }
  }
  .additional-recipient-info-row-second-cell {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &:first-child {
    border-top: none;
  }
}

@media screen and (max-width: 768px) {
  .recipients-container {
    padding: 0px 0px 19px 0px;
  }
  .additional-recipient-info-row {
    .additional-recipient-info-row-first-cell {
      padding-left: 0;
    }
  }
  .email-recipients-body {
    .email-recipients-table-row {
      padding: 0 15px;
    }
  }
}
