.saved-items-popup {
  label {
    font-weight: 700;
  }
}

.item-share-access {
  background-repeat: no-repeat;
  padding-left: 38px !important;
}

.private-to-me {
  background-image: url('#{$cdn-url}20211229-private-icon-blue.svg');
  background-position: 14px 13px;
  background-size: 12px auto;
}

.shared-with-my-team {
  background-image: url('#{$cdn-url}20211229-shared-icon-blue.svg');
  background-size: 16px auto;
  background-position: 12px 14px;
}

.add-folder-button-active {
  background: url(https://cdn.policymogul.com/images/20211229-plus-icon-light-blue.svg) 3px 6px/12px auto no-repeat;
  padding-left: 30px !important;
  margin-top: -2px;
  display: block;
}

.saved-to-label {
  background-repeat: no-repeat;
  background-position: 100% 5px;
  padding-right: 24px;
}
