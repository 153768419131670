.empty-container {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  color: rgba(0, 28, 53, 0.75);
}

.empty-image {
  max-width: 110px;
}

.empty-message-no-results {
  font-size: 1.25em;
  margin: 0.5em auto;
  max-width: 350px;
}

.empty-button {
  padding: 0.5em 1.4em;
  font-size: 1em;
}

.empty-main-container {
  padding-top: 2px;
}

.empty-inbox-message {
  .item-blocked {
    background-image: url('#{$cdn-url}padlock-lightblue.svg');
    padding-right: 25px;
  }
}

.inbox-empty-title {
  font-size: 1.3em;
}

.emptymessage-flex-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 100%;
}

.topic-name-icon-empty-inbox {
  padding-left: 38px !important;
  background-position: 12px 8px !important;
}

.inbox-empty-message {
  padding-top: 80px;
}

.new-agency-empty-message {
  max-width: 650px;
}

@media screen and (min-width: 576px) {
  .empty-image {
    max-width: 190px;
  }
}

@media screen and (min-width: 992px) {
  .emptymessage-flex-container {
    padding-top: 40px;
  }

  .empty-main-container {
    padding-top: 8px;
  }

  .empty-container {
    .empty-message-information {
      margin-top: -1em;
    }
    .inbox-empty-title {
      font-size: 1.5em;
      color: $main-blue;
    }
  }
  .empty-inbox-message {
    margin-top: -70px;
  }
  .new-agency-empty-message {
    margin-top: -90px;
  }
}
