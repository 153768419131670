.report-component-name {
  padding-top: 1px;
  display: inline-block;
  font-size: 18px;
}

.reports-actions-main-container {
  z-index: 100000;
}

.reports-actions-box {
  width: 190px;
  right: 4px;
  button,
  a {
    font-size: 15px;
    padding: 7px 0px 7px 18px !important;
    &:hover {
      background-color: rgba($main-blue, 0.04) !important;
      text-decoration: none !important;
      color: $main-blue !important;
    }
  }
  .icon-edit-bold-container {
    margin-left: -1px;
    margin-right: 1px;
    padding-top: 3px;
    padding-right: 4px;
  }
  .icon-commentary-filled-container {
    margin-left: -2px;
    margin-right: 1px;
    padding-left: 1px;
    padding-top: 2px;
    padding-right: 4px;
  }
  .icon-delete-container {
    display: inline-block;
    padding-top: 2px;
    padding-right: 4px;
  }
  .delete-widget-button {
    color: rgba(140, 49, 73, 0.9) !important;
    &:hover {
      color: rgba(140, 49, 73, 0.9) !important;
    }
  }
  a {
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
  }
}

.report-edit-content {
  max-height: 68vh;
}

.reports-graph-settings {
  .frequency-dropdown {
    top: 34px;
    box-shadow:
      0 1px 3px 0 rgba(60, 64, 67, 0.2),
      0 4px 8px 3px rgba(60, 64, 67, 0.1);
    border-radius: 8px;
    z-index: 1001;
    padding: 5px 0;
  }
  .graph-date-button-container {
    .filter-group {
      border-radius: 5px;
      border: 1px solid $main-grey;
      font-size: 1em;
      .filter-item-button {
        width: 100%;
        display: block;
        border-radius: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        .dropdown-item-element {
          text-align: left;
          display: block;
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  .hour-select {
    border-color: $main-grey;
  }

  .hour-item {
    padding: 0.35em 1em;
  }

  .filter-item-button {
    &:hover {
      background-color: $main-white !important;
    }
  }
}

.reports-topick-picker {
  .dropdown-keywords-lists {
    background-color: #fff;
    box-shadow: none !important;
    border-radius: 5px !important;
    border-color: $main-grey !important;
  }

  .search-dropdown {
    background-color: transparent !important;
    border: none !important;
  }
}

.reports-latest-news-actions-box {
  width: 180px;
  right: -17px;
  z-index: 10000 !important;
  button {
    &:last-of-type {
      color: rgba(140, 49, 73, 0.9) !important;
    }
  }
  .icon-edit-pencil-thick {
    margin-left: -1px;
    margin-right: 9px;
  }
  .icon-external-bold {
    margin-right: 9px;
  }
  .icon-close-bold {
    margin-left: 1px;
    margin-right: 8px;
  }
}

.report-stakeholder-item {
  img {
    width: 70px;
    min-width: 70px;
    min-height: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
  .reports-actions-box {
    @extend .reports-latest-news-actions-box;
  }
  .item-actions-button {
    background-image: none !important;
    right: -23px;
    position: absolute;
    padding-top: 2px;
    top: -4px;
  }
}

.most-vocal-item {
  img {
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
  }
}

.reports-stakeholders-widget {
  @extend .reports-latest-news-actions-box;
  width: 195px;
  .icon-close {
    margin-top: -1px;
    margin-left: 1px;
    margin-right: 8px;
  }
  .icon-close-bold {
    margin-top: -2px;
  }
}

.report-stakeholder-item-empty {
  img {
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    height: 50px;
  }
}

.latest-new-item {
  .item-actions-button {
    top: 13px;
    right: -8px;
  }
  .content-image {
    max-width: 90px;
    min-width: 90px;
    height: 70px;
  }
}

.reports-contribution-text {
  width: calc(100% - 60px);
}

.reports-add-update {
  .search-related-content-container {
    padding-top: 5px !important;
  }
  .search-modal-related-results {
    flex-grow: 1;
    height: auto !important;
  }
  .related-content-loading-suggestions {
    overflow: hidden;
    padding-top: 16px !important;
  }
}

.reports-commentary-container {
  border: 1px solid rgba($main-grey, 0.5);
  .tox-editor-container {
    border: none !important;
    margin-bottom: 0 !important;
  }
  .tox-tinymce {
    //height: auto !important;
    //min-height: 100%;
  }
  .tox-toolbar__group:last-of-type {
    display: none;
  }
  .tox-editor-container {
    min-height: 111px !important;
  }
}

.reports-commentary-container,
.report-local-widget-commentary,
.macros-commentary {
  .tox-toolbar__group:nth-child(5) {
    width: auto !important;
    div {
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      box-shadow: none !important;
    }
    .tox-tbtn:first-child {
      padding: 0px 27px 0px 10px !important;
      position: relative;
      width: auto !important;
      &::after {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #00122b;
        position: absolute;
        top: 18px;
        right: 12px;
      }
    }
    .tox-icon {
      width: 14px;
      min-width: 14px;
      height: 14px;
      min-height: 14px;
      border-radius: 50%;
      background-color: #676a75;
      svg {
        display: none;
      }
    }
  }
}

.report-local-widget-commentary {
  .tox-toolbar__group:nth-child(5) {
    .tox-icon {
      background-color: #7e8c8d;
    }
  }
}

.macros-commentary {
  .tox.tox-tinymce {
    height: 300px !important;
  }
  .tox-editor-container {
    margin-bottom: 0 !important;
  }
}

.tox-swatches-menu {
  .tox-swatches__row:last-child {
    display: none;
  }
}

.reports-commentary-with-macros {
  .tox-toolbar__group:last-of-type {
    .tox-icon {
      display: none;
    }
    .tox-tbtn__select-label {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}

.reports-commentary-with-macros,
.report-local-widget-commentary {
  .tox-toolbar__group:last-of-type {
    display: inline-block;
    .tox-tbtn {
      padding-left: 15px;
      padding-right: 15px;
      width: auto !important;
      position: relative;
    }
    .tox-tbtn__select-label {
      display: inline-block !important;
      cursor: pointer !important;
    }
  }
}

.report-local-widget-commentary {
  .tox-editor-container {
    min-height: 0 !important;
    margin-bottom: 0 !important;
  }
  .tox-toolbar__group:nth-child(6),
  .tox-toolbar__group:last-child {
    display: inline-block;
    .tox-icon {
      display: none !important;
    }
    .tox-tbtn__select-label {
      margin: 0 !important;
    }
  }

  .tox-toolbar__group:last-of-type {
    margin-right: 0;
    margin-left: auto;
    border-left: 1px solid rgba($main-grey, 0.5) !important;
    border-right: none !important;
    .tox-tbtn__select-label {
      font-family: 'Lato' !important;
      color: rgba(140, 49, 73, 0.9) !important;
    }
  }
}

.report-commentary-saved {
  color: #0094cc !important;
  display: flex;
  align-items: center;
  padding: 0px 12px !important;
  font-size: 14px !important;
  .icon-tick {
    font-size: 12px;
    margin-right: 4px;
    margin-top: 1px;
  }
}

.reports-analytics-item {
  .leaflet-pane {
    z-index: 0 !important;
  }
  .leaflet-interactive {
    cursor: auto !important;
  }
  .leaflet-control {
    margin-left: 1.45em !important;
  }
  .pie-chart-graph-container {
    .specific-graph-container {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  .leaflet-region-style-heat,
  .leaflet-pane > svg path.leaflet-interactive {
    pointer-events: none !important;
  }
  .leaflet-control {
    .legend {
      transform: translateY(-1px) !important;
    }
  }
}

.report-region-preview-map {
  position: absolute;
  top: -2px;
  left: -1px;
}

.report-title {
  color: #022344;
}

.report-stakeholder-item-content {
  padding-left: 20px;
  padding-right: 16px;
  line-height: 1.5;
  [class^='icon-'] {
    display: inline-block;
  }
  .icon-parliamentary-record {
    margin-top: 6px;
  }
  .icon-star {
    margin-top: 5px;
  }
  .icon-group {
    margin-top: 8px;
    margin-left: -2px;
    margin-right: 7px !important;
  }
}

.reports-specific-update-container {
  width: calc(100% + 20px);
  margin-left: -20px;
  height: 100%;
  overflow: hidden;
  line-height: 1.4;
  .share-container {
    display: none !important;
  }
  .main-title,
  .news-title,
  .update-title,
  .legislation-content-title,
  .consultation-content-title,
  .library-material-documents-to-download,
  .key-update-attachments {
    display: none;
  }
  .specific-content-container {
    padding-top: 0px !important;
    a {
      color: rgba($main-blue, 0.85) !important;
      text-decoration: none !important;
      cursor: text !important;
      pointer-events: none !important;
    }
  }
  .news-content-in {
    h3 {
      font-size: 16.4px;
    }
  }
  .keyupdate-contente-date {
    padding-top: 0px !important;
  }
  .parliamentary-title {
    padding-left: 32px !important;
  }

  .update-legislation {
    padding-top: 0px !important;
  }
  .consultation-content-key-dates {
    margin-top: 0px !important;
  }

  .consultation-content-subtitle {
    font-size: 15px;
    padding: 0px;
    background-color: #fff;
  }

  .consultation-contente-dates {
    padding: 0 !important;
  }

  .keyupdate-content-title,
  .parliamentary-title,
  .library-material-title {
    .heading-date {
      font-size: 14px;
    }
  }
  .legislation-source {
    display: none;
  }
  .parliamentary-share-buttons {
    display: none !important;
  }
  .debate-item-contributiondebateitem-highlighted {
    background-color: #fff;
  }
}

.reports-specific-update-container-preview {
  width: calc(100% + 40px);
  padding-right: 20px;
  overflow-y: auto;
}

.reports-specific-update-skeleton {
  .skeleton-update-container {
    .related-content-column {
      display: none !important;
    }
    .general-content-column {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.reports-empty-widget-container {
  visibility: hidden;
  [class*='awsui_root_'] {
    visibility: hidden !important;
  }
  .report-component-name {
    visibility: hidden;
  }
}

.reports-empty-widget-container-preview {
  display: none !important;
}

.report-empty-state-paragraph {
  max-width: 360px;
}

.report-empty-bottom {
  border: 1px dashed #001c3599;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 19px;
    width: 20px;
    height: 20px;
    background: url('#{$cdn-url}20240115-hand-icon.png') no-repeat center / contain;
  }
}

.report-empty-or-word {
  margin: 21px 0px;
}

.commentary-custom-color-picker {
  border-radius: 6px;
  box-shadow:
    0 0 2px 0 rgba(34, 47, 62, 0.2),
    0 4px 8px 0 rgba(34, 47, 62, 0.15);
  width: 150px;
  z-index: 100000;
  button {
    width: 30px;
    height: 30px;
    &:nth-child(1) {
      border-top-left-radius: 6px;
    }
    &:nth-child(5) {
      border-top-right-radius: 6px;
    }
    &:nth-child(16) {
      border-bottom-left-radius: 6px;
    }
    &:nth-child(20) {
      border-bottom-right-radius: 6px;
    }
  }
}

.report-create-macro-name {
  img {
    max-height: 26px;
    cursor: pointer;
  }

  .EmojiPickerReact {
    position: absolute !important;
    left: 0px;
    z-index: 10000;
  }

  .EmojiPickerReact * {
    font-family: 'Lato' !important;
  }
}

.report-stakeholder-content {
  font-size: 14.4px;
  line-height: 1.5;
}
