.general-complementary-title {
  font-weight: 700;
  font-size: 1em;
}

.attachment-box {
  border: 1px solid rgba($main-blue, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba($main-blue, 0.85);
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
    color: rgba($main-blue, 0.85);
    text-decoration: none;
  }
  .icon-arrow-download {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }
}

.attachment-file-name {
  min-width: 85%;
}
