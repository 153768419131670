.blog-case-studies {
  padding-top: 50px;
  .container {
    position: relative;
    z-index: 1;
  }
  &::before {
    background-color: #fff !important;
  }
  .post-image {
    max-width: 240px;
    height: auto;
  }
  h1 {
    margin-top: 16px;
  }
  .blog_single__content--share {
    padding-top: 10px;
    a {
      img {
        max-width: 100%;
      }
    }
  }
  .home-blockquote-container {
    display: flex;
    margin: 35px 0;
    .flex-blockquote-container {
      align-items: center;
      img {
        max-width: 100%;
        margin-bottom: -3px;
      }
    }
    blockquote {
      margin: 0;
    }
  }
  .blockquote-container {
    margin-top: 30px;
    margin-bottom: 30px;
    &::before {
      left: 0px;
    }
    &::after {
      bottom: -10px;
    }
  }
  .photograph-container {
    display: none;
    position: relative;
    z-index: 10;
    img {
      max-height: 280px;
    }
  }
}

.blog-title-container {
  padding-top: 2.5rem;
  background: #e0f2f9;
}

.blog-items-content {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: #e0f2f9;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    top: 0;
    height: 200px;
    width: 100%;
    left: 0;
  }
}

.blog-title {
  padding-bottom: 60px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.case-studies-content-item {
  width: 100%;
  .blog_thumbnail {
    overflow: hidden;
    height: 240px;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    &:hover {
      img {
        transform: scale(1.1) rotate(3deg);
      }
    }
    img {
      transition: ease-in-out 0.35s;
      object-fit: unset;
      max-width: 65%;
      margin: auto;
      height: auto;
    }
  }
  a {
    text-decoration: none;
    color: $main-blue;
    &:hover {
      h3 {
        color: $light-blue !important;
      }
    }
  }
}

.blog-content-item {
  .blog_thumbnail {
    img {
      max-width: none;
      object-fit: cover;
      width: 100%;
      height: 240px;
    }
  }
}

.blog-main-content {
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .case-studies-content-item {
    width: calc(100% / 3 - 2.5rem);
  }
  .blog-case-studies {
    .home-blockquote-container {
      blockquote {
        p {
          font-size: 0.8em;
          line-height: 1.4;
        }
      }
    }
    .blockquote-container {
      blockquote {
        font-size: 1.45em;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .blog-case-studies {
    h1 {
      max-width: 80%;
    }
    .home-blockquote-container {
      margin-top: 80px;
      padding-left: 50px;
      margin-bottom: 45px;
    }
    .photograph-container {
      width: 27%;
      display: block;
      margin-left: 5%;
    }
    .blockquote-container {
      padding-left: 70px;
      width: 75%;
    }
  }
}

@media screen and (min-width: 1023px) {
  .blog__archive--title {
    h1 {
      font-size: 2.25rem;
    }
  }
}
