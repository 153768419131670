.main-settings-content-container {
  z-index: 301;
  position: relative;
}

.countries-button {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 34px;
  padding: 0px 25px 0px 27px;
  margin-right: 16px;
  background: url('#{$cdn-url}westminster-icon.png') 4px 2px / 28px 28px no-repeat;
  cursor: pointer;
  &::after {
    top: 13px;
    right: 6px;
  }
}

.label-country {
  background-position: 0.75em 2px;
  background-size: 28px 17px;
  padding-left: 51px !important;
  background-repeat: no-repeat;
}

.label-all-countries {
  background-size: auto 18px;
  background-image: url('#{$cdn-url}uk-flag.png');
  padding-left: 35px !important;
  background-position: 0px 4px;
}

.uk-flag {
  background-image: url('#{$cdn-url}uk-flag.png');
}

.label-westminster-uk {
  background-image: url('#{$cdn-url}westminster-flag.png');
  background-size: 30px 20px;
  padding-bottom: 4px;
}

.label-scotland {
  background-image: url('#{$cdn-url}scottish-icon.png');
}
.label-wales {
  background-image: url('#{$cdn-url}senedd-icon.png');
}
.label-northernireland {
  background-image: url('#{$cdn-url}northern-ireland.png');
}

.topic-label-countries {
  .label-all-countries {
    padding-left: 40px !important;
  }
  .topic-alert-edit {
    display: none;
    color: rgba(0, 18, 43, 0.6);
  }
}

@media screen and (min-width: 1200px) {
  .topic-label-countries {
    &:hover {
      .topic-alert-edit {
        display: block;
      }
    }
  }
}
