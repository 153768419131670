$white: #fff;
$whisper-white: #e5e5e5;
$smoke-white: #f5f5f5;
$orchid-white: #f0ead8;

$light-blue: #f4f4f4;
$medium-blue: #001c35;
$dark-blue: #00122b;
$hover-blue: #0094cc;
$border-bottom-blue: #e7e6e6;
$button-light-blue: #f6f6f6;
$echo-blue: #9fa8da;
$twilight-blue: #f6f9ef;
$denim-blue: #1264a3;

$sweet-pink: #ef9a9a;
$soft-peach: #f4e9eb;

$caper: #b5c788;

$dark-mustard: #f2a805;
$background-mustard: #d2be83;

$border-gainsboro: #dedede;
$gainsboro: #dfdfdf;

$medium-gray: #a3a3a3;
$dark-gray: #8c8c8c;
$border-gray: #eaeaea;
$gray: #ccc;
$arrow-border-gray: #747474;
$text-gray: #707070;
$btn-border-gray: #d6d6d6;
$charcoal-gray: #424242;
$nepal-gray: #97a8b7;

$black: #000;

$buccaneer: #6a303b;

$mercury: #e8e8e8;
$soft-grey: rgb(132, 132, 132);
$settings-grey: rgba(0, 18, 43, 0.3);
$soft-blue: #6293bb;
$description-grey: #848484;

// new variables

$main-grey: #d4d4d4;
$background-grey: #f9f9f9;
$main-white: #fff;
$main-black: #000;
$main-blue: #00122b;
$light-blue: #0094cc;
$main-yellow: #f2a805;
$main-red: #e40741;
$secondary-blue: #001c35;
$default-font-size: 16;
$button-border-radius: 5px;
$hover-blue-color: #f5fbfd;
$input-border-gray: #ced4da;
$salmon: #f4efed;
$hovered-blue: #f7fcfe;
$reports-blue: #eff5f7;
$danger-background: #9b171f;
$danger-text: #b91943;
$cdn-url: 'https://cdn.policymogul.com/images/';
$title-sizes: (
  'h1': 28,
  'h2-m': 25,
  'h2': 24,
  'h3': 20,
  'h4-m': 19,
  'h4': 18,
  'h5-m': 17,
  'h5': 16,
  'h6': 14,
);

$paragraph-sizes: (
  'p1': 16,
  'p2': 15,
  'p3': 14,
  'p4': 13,
  'p5': 12,
  'p6': 11,
  'p7': 10,
  'general': 16.4,
);

$header-blue: #022344;
$font-family-sans-serif:
  'Lato',
  sans-serif,
  // Emoji fonts
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol' !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2.5,
    ),
  ),
  $spacers
);

$datepicker__selected-color: #f2a805 !default;
$grid-columns: 16;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 526px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // Extra large screen / wide desktop
  xxl: 1440px
);

$hamburger-layer-width: 28px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $main-blue !default;
