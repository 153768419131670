.add-contact-modal-container {
  .account-list {
    li {
      padding: 1rem 0 0.5rem 0;
    }
  }
  .confirm-add-contact-modal {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #f4efed;
    z-index: 999;
  }
  .add-contact-modal-body {
    height: 500px;
    margin-bottom: 81px;
  }
  @media screen and (max-width: 560px) {
    .add-contact-modal-body {
      height: 660px;
    }
  }
}
