.remove-contacts-wrapper {
  max-height: calc(80vh - 250px);
  overflow: auto;
  .remove-contacts-title {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #00122b;
  }
  .remove-contacts-list {
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
    font-size: 18px;
    line-height: 24px;
    color: #00122b;
    & li {
      margin-bottom: 9px;
      &:before {
        content: '•';
        font-size: 130%;
        padding-right: 15px;
      }
    }
  }
}
.remove-contacts-helper-text {
  padding-top: 0.55em;
  padding-bottom: 0.65em;
  background: #f9f9f9;
  font-weight: 400;
  color: #8c3149;
  border-top: 1px solid rgba(212, 212, 212, 0.5);
  margin-bottom: 0;
}
.remove-contacts-helper-text-icon {
  background-image: url('#{$cdn-url}information-icon-red.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-position: center;
  margin-bottom: -1px;
}

@media screen and (min-width: 992px) {
  .remove-contacts-helper-text {
    font-size: 17px;
  }
}
