.scrolled-title {
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.08),
    0 1px 0 0 rgba(29, 28, 29, 0.13);
}

.content-item-scrolled-title {
  @extend .scrolled-title;
  display: none;
  margin-left: -1.5em;
  border-radius: 5px 5px 0 0;
  z-index: 100;
  padding: 0.5em 1em;
  border-bottom: none;
  .main-title {
    font-size: 17px;
    margin-bottom: 0;
    width: calc(100% - 20px);
  }
  .share-container {
    height: 30px;
  }
  .item-actions-box {
    .share-container {
      height: auto;
    }
  }
}

.scrolled-title-container {
  align-items: center;
}

@keyframes mmfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.item-scrolled-title-visible {
  display: block;
  .scrolled-title-container {
    animation: mmfadeIn 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
}

.parliamentary-scrolled-title {
  width: 100%;
  margin-left: 0em;
}

@media screen and (min-width: 420px) {
  .content-item-scrolled-title {
    .main-title {
      width: calc(100% - 110px);
    }
  }
}

@media screen and (min-width: 768px) {
  .content-item-scrolled-title {
    padding: 0.5em 1em;
  }
}

@media screen and (min-width: 992px) {
  .content-item-scrolled-title {
    padding: 0.5em 1.5em;
  }
}
