.reports-top-nav {
  button {
    min-height: 38px;
  }
  .emoji-selector {
    margin-left: -10px;
  }
}

.report-design-button {
  background: url('#{$cdn-url}20240121-reports-design-icon.png') 18px 9px / 18px auto no-repeat;
  padding-left: 45px !important;
}

.report-design-options {
  width: 496px;
  .checkbox-item label:not(:empty) {
    padding-left: 1em;
  }
}

.report-color-picker {
  left: -6px;
  top: 35px;
}

.report-more-options-popup {
  width: 330px;
}

.report-popup-specific-option {
  padding: 0.5em 1.5em;
  transition: 0.2s ease-in;
  &:hover {
    background: rgba($main-grey, 0.25);
  }
}

.report-popup-specific-option-change-design {
  padding: 0.6em 1.5em;
}

.report-popup-specific-option-delete {
  &:hover {
    background: rgba(#9b171f, 0.05);
  }
}

.report-extra-index-modal {
  z-index: 10000000;
}

.report-calendar-modal {
  .react-modal-content {
    background: transparent;
    .modal-close-button {
      z-index: 10000;
      top: -38px;
    }
  }
}

div[title='#FFFFFF'] {
  border: 1px solid $main-grey;
}

[id^='rc-editable-input'] {
  width: 109px !important;
  height: 30px !important;
}
