.react-calendar {
  color: #001c35;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border: 1px solid #eaeaea;
  width: 290px;
  margin: 0 auto;
}

.highlight-calendar {
  background-color: #e8f0f6;
  border-radius: 5px;
  text-align: center;
  max-width: 290px;
  font-size: 0.9em;
}

.events_present {
  font-weight: 900;
}

.react-calendar__navigation {
  border-bottom: 1px solid #eaeaea;
}

.react-calendar__tile {
  padding: 0.6em 0.5em;
  max-width: 50px !important;
  max-height: 50px !important;
  border-radius: 50%;
}

.react-calendar__tile--now {
  background-color: transparent;
  border: 1px solid #eaeaea !important;
  &:enabled {
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
}

.react-calendar__tile--active {
  background-color: #f7941d;
  &:enabled {
    &:hover {
      background-color: #f7941d;
    }
    &:focus {
      background-color: #f7941d;
    }
  }
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1.2em;
  abbr[title] {
    text-decoration: none;
  }
}

.react-calendar__navigation button[disabled] {
  background: #fff;
  color: #00122b;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #fff;
}

.react-calendar__tile {
  position: relative;
  overflow: auto !important;
}

.event-icon {
  background: #fff;
  min-width: 42px;
  height: 42px;
  border: 1px solid #ededed;
  border-radius: 50%;
}

.single_event {
  cursor: pointer;
  color: #022344;
}

.event-expand {
  font-size: 0.9em;
  @extend .section-title;
  .collapsed {
    background-color: #fff !important;
  }
  .single_event {
    border-radius: 10px;
    background-color: #e8f0f6;
  }
  .tailored-events-highlighted {
    background-color: #e8f0f6 !important;
  }
}

.event-category {
  color: #848484;
}

.event-date {
  font-size: 0.75rem;
  font-weight: 600;
}

.calendar-container {
  height: auto !important;
}

.collapsible {
  background-image: url('#{$cdn-url}accordion-top-arrow.svg');
  background-repeat: no-repeat;
  background-position: 98% 25%;
}
.collapsible.collapsed {
  background-image: url('#{$cdn-url}accordion_bottom_arrow.svg');
  background-repeat: no-repeat;
  background-position: 98% 25%;
}

@media screen and (min-width: 992px) {
  .calendar-container {
    height: 0;
  }
}
