.influence-general-container {
  max-width: 1440px;
}

.influence-creation_section {
  padding: 28px 16px;
  .paragraph-p2 {
    margin-top: -5px;
    margin-left: 1px;
  }
}

.influence-creation_header {
  padding-top: 21px;
  padding-bottom: 21px;
  background-color: $main-white;
  z-index: 100;
  border-bottom: 1px solid rgba($main-grey, 0.6);
  border-top: 1px solid rgba($main-grey, 0.6);
}

.influence-creation_title {
  color: $main-yellow;
}

.influence-creation_content {
  border: none;
}

.influence-menu-items {
  width: 230px;
}

.influence-creation_visibility {
  padding-bottom: 240px !important;
}

.menu-items-influence {
  .menu-embed-container {
    padding-left: 20px !important;
    padding-right: 18px !important;
  }
  .embed-menu-swiper {
    padding: 0 4px 0 5px !important;
  }
}

.create-content-buttons {
  font-size: 14px;
  .general-button-spacing {
    padding: 4px 15px;
  }
}

.influence-creation_edit_group {
  position: relative;
  transition: 0.2s ease-in;
  .general-edit-button {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}

.create-select-options {
  column-gap: 1em;
}

.create-no-selected-button {
  background-color: rgba($main-grey, 0.6);
  color: $main-blue;
}

.create-material-input {
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

.client-authorization {
  label {
    line-height: 1.3 !important;
  }
}

.create-material-options-container {
  .dropdown-keywords-lists {
    text-align: left;
  }
}

.agency-dropdown {
  .dropdown-keywords-lists {
    border-radius: 5px;
  }
  .search-dropdown {
    border-radius: 0px 5px 5px 0px;
  }
  .dropdown-keywords-lists-open {
    border-radius: 5px 5px 0px 0px;
  }
}

.create-material-agency-dropdown {
  border-radius: 0px 0px 5px 5px;
  .add-keyword-list-button {
    border-radius: 0px 0px 5px 5px;
  }
}
.influence-content-container {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .create-content-buttons {
    font-size: 16px;
    .general-button-spacing {
      padding: 6px 24px;
    }
  }

  .influence-creation_header {
    border: 1px solid rgba($main-grey, 0.6);
    border-radius: 5px 5px 0 0;
  }

  .influence-creation_content {
    border: 1px solid rgba($main-grey, 0.6);
    border-top: none;
  }

  .influence-creation_section {
    padding: 28px 50px;
  }
  .influence-content-container {
    width: calc(100% - 230px);
  }
  .create-no-selected-button {
    &:hover {
      background-color: rgba($main-grey, 0.7);
      color: $main-blue;
    }
  }

  .unpublished-text {
    position: absolute;
    bottom: 50px;
    margin: auto;
    left: 0;
    right: 0;
  }

  .create-material-options-container {
    min-width: 675px;
  }

  .subtitle-1-agency {
    max-width: 620px;
  }

  .create-material-agency {
    min-width: 480px;
    width: 480px;
  }
}
