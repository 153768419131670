@import 'styles/variables.scss';

.DownloadChart {
  padding: 20px 33px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 100%;
  &__header {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
  }
  &__sectionTitle {
    margin-bottom: 15px !important;
    user-select: none;
    margin: 0;
    font-weight: 400;
    color: #00122b;
    z-index: 1;
  }
  &__imgContainer {
    background-color: white;
    border-radius: 13px;
    padding: 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    & img {
      width: 100%;
    }
  }
  &__logoContainer {
    display: flex;
    gap: 5px;
    align-self: flex-end;
    margin: 5px 0;
    & svg {
      height: 16px;
    }
  }
  &__helperText {
    align-self: flex-end;
    font-size: map-get($paragraph-sizes, p6) + px;
    margin: 0;
    margin-top: -2px;
    user-select: none;
    white-space: nowrap;
    & > span {
      font-weight: 800;
      font-size: map-get($paragraph-sizes, p2) + px;
      margin-left: 5px;
      white-space: nowrap;
    }
  }
  &__buttonGroup {
    display: flex;
    gap: 20px;
    border-top: 0.5px solid #ececec;
    padding: 10px 32.66px;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    z-index: 10000;
    &_button {
      border-radius: 20px;
      border: none;
      transition: all 200ms;
      padding: 6.75px 22.5px;
      background-color: white;
      color: rgba(0, 18, 43, 0.75);
      border: 1px solid #d4d4d4;
      font-size: map-get($paragraph-sizes, p2) + px;
      &:hover {
        background-color: rgba(0, 148, 204, 0.04);
      }
      &_download {
        border-radius: 20px;
        border: inherit;
        transition: inherit;
        padding: 6.75px 22.5px;
        background-color: #0094cc;
        color: white;
        font-size: map-get($paragraph-sizes, p2) + px;
        &:hover {
          background-color: rgba(0, 148, 204, 0.8);
        }
      }
    }
  }
}

.backgroundOptionsPTag {
  font-size: map-get($paragraph-sizes, p3) + px;
  color: #375167;
  margin-bottom: 5px;
  user-select: none;
}
.checkShowTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  & p {
    font-size: map-get($paragraph-sizes, p3) + px;
    color: #375167;
    margin-bottom: 5px;
    user-select: none;
  }
  &__label {
    font-size: map-get($paragraph-sizes, p3) + px;
    margin: 0;
    position: relative;
    cursor: pointer;
    user-select: none;
    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      border: 1px solid #c3cad0;
      top: -19px;
      left: -13px;
      position: absolute;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }
  &__input {
    visibility: hidden;
  }
  & input:checked + label {
    &::before {
      content: '';
      width: 30px;
      height: 30px;
      display: block;
      background-image: url('#{$cdn-url}tick-dark.svg');
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
    }
  }
}

.headerInputGroup {
  display: flex;
  gap: 4px;
  flex-direction: column;
  font-size: map-get($paragraph-sizes, p3) + px;
  color: #375167;
  user-select: none;
  &__label {
    width: 12rem;
    padding: 0.5rem 1.3333333rem 0 0;
    margin: 0;
  }
  &__input {
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 308px;
    padding: 6px 14px;
    border: 1px solid #dbe3eb;
    border-radius: 5px;
    outline: none;
    color: #375167;
    &::placeholder {
      color: #8fa6b4;
    }
    &:focus {
      border-color: #8fa6b4;
    }
  }
}
.modalChartHeader {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #375167;
  letter-spacing: -0.01em;
  line-height: 26.5px;
}
.modalChartContainer {
  padding: 18.66px 32.66px 32.66px;
}

.headerTitleGroup {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 35px;
  margin-bottom: 30px;
}

.checkBoxesGroup {
  width: calc(50%);
  margin-bottom: 7px;
  color: #375167;
}

.chartKeysContainer {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  margin-left: 24px;
  & p {
    font-size: map-get($paragraph-sizes, p3) + px;
  }
}

.backgroundOptionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.skeletonImgContainer {
  width: 100%;
  border-radius: 13px;
  & > span > span {
    height: 357px;
  }
}
