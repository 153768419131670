@import './NeedVerification.scss';
@import './Endoftrial.scss';
@import './CreateProfile.scss';
@import './RegistrationVerification.scss';
@import './CreateATopicPage.scss';

.spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 1;
  transition: 0.5s ease-out;
  img {
    width: 64px;
    height: 64px;
  }
}

.login-buttons {
  button {
    white-space: nowrap;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .btn-login,
  .btn-register {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.btn-register {
  background: $light-blue;
  border-radius: 20px;
  transition: 0.2s ease-in;
  &:hover {
    background: $main-yellow;
  }
}

.btn-login-home {
  transition: 0.2s ease-in;
  &:hover {
    color: $main-white;
    border: 1px solid $light-blue;
    background: $light-blue;
  }
}

.btn-login {
  transition: all;
  color: $dark-blue !important;
  &:focus {
    box-shadow: none;
  }
}

.profile-button {
  font-weight: 700 !important;
  img {
    vertical-align: middle;
    width: 13px;
  }
}

.login-navbar {
  position: absolute;
  top: 51px;
  right: 0;
  width: 100%;
  min-width: 220px;
  background: #ffffff;
  box-shadow:
    0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  border-radius: 8px;
  z-index: 1001;
  a {
    color: #00122b;
    display: block;
  }
  .settings-button {
    padding: 0.35em 1em;
    &:focus {
      box-shadow: none;
    }
  }
  a,
  button {
    &:hover {
      background-color: rgba(244, 239, 237, 0.6);
      text-decoration: none;
    }
  }
  hr {
    border-top: 1px solid #d4d4d4;
  }
  .text-light-blue {
    color: $light-blue;
  }
}

.adjustment-of-position {
  z-index: 1000000;
}

.loginbar-profile-button {
  padding-left: 1em !important;
  img {
    width: 20px;
    height: 20px;
    margin-right: 13px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
    margin-left: -1px;
  }
}

.login-arrow {
  display: none;
  width: 13px;
  margin-left: 6px;
}

.btn-logout {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 400 !important;
  color: #00122b !important;
  font-size: 1em !important;
}

.btn-refer {
  display: block;
  width: 100%;
  text-align: left;
  .icon-present {
    transform: translate(1px, -3px);
    font-size: 18px;
  }
}

.btn-refer-subscribed {
  color: #0094cc !important;
}

.profile-active {
  background: #fff;
  color: #00122b !important;
}

.profile-picture {
  height: 28px !important;
  width: 28px !important;
  border-radius: 50%;
}

.btn-register {
  span {
    display: none;
  }
}

.nav-user-profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid rgba($description-grey, 0.25);
  border-radius: 20px;
  padding: 1px;
  background-color: $main-white;
  .hamburger-box {
    display: none;
    width: 24px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $main-blue;
    width: 14px;
    height: 2px;
  }
  .hamburger-inner::before {
    top: -4px;
  }
  .hamburger-inner::after {
    bottom: -4px;
  }
  .profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
  }
}

@media screen and (min-width: 992px) {
  .nav-user-profile {
    padding: 2px 4px 2px 12px;
    &:hover {
      @extend .profile-hover-state;
    }
    .hamburger-box {
      display: block;
    }
    .profile-image {
      width: 29px;
      height: 29px;
    }
  }

  .profile-hover-state {
    border: 1px solid transparent;
    background-color: $hovered-blue;
  }

  .nav-user-profile.active {
    @extend .profile-hover-state;
  }

  .nav-user-profile {
    transition: 0.2s ease-in;
  }
  .profile-picture {
    height: 37px !important;
    width: 37px !important;
  }
  .login-arrow {
    display: inline-block;
  }
  .btn-login {
    color: #fff !important;
    min-width: 0;
  }
}

@media screen and (min-width: 1280px) {
  .btn-register {
    span {
      display: inline-block;
    }
  }
}
