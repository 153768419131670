.simple-searchbar {
  display: none;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  padding-left: 9px;
  background: $main-white;
  position: relative;
  border: 1px solid transparent;
  .open-dropdown {
    width: 100%;
    top: 40px;
    left: 0px;
    z-index: 1000;
  }
  &:hover {
    background: $hovered-blue;
    text-decoration: none;
  }
  input {
    &:disabled {
      cursor: pointer;
    }
  }
  .main-search-icon {
    margin-top: 2px;
    margin-left: -1px;
  }
  .icon-search-medium {
    margin-top: -1px;
    margin-left: -1px;
    color: $main-blue;
  }
}

.simple-searchbar-influence {
  height: auto !important;
  .search-main-input {
    margin-top: -1px !important;
  }
}

.home-searchbar-container {
  .simple-searchbar-open {
    padding: 9px 15px 11px 15px;
  }
}

.simple-searchbar-open {
  width: auto;
  height: 38px;
  border-radius: 20px;
  padding: 5px 15px;
  flex-grow: 1;
  &:hover {
    background: $main-white;
  }
  .search-main-input {
    margin-top: -2px;
  }
}

.simple-searchbar-open-influence {
  padding: 4px 15px !important;
}

.simple-searchbar-open.dropdown-keywords-lists-open {
  border-color: transparent;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28) !important;
  border-radius: 20px 20px 0 0;
}

.top-search-input-container {
  padding: 3px 10px 3px 10px;
}

.top-nav-search-box {
  top: 38px;
}

.simple-searchbar-home {
  max-width: 520px !important;
  margin-right: auto !important;
  margin-left: auto;
  .search-main-input {
    margin-top: 1px;
  }
}

.simple-searchbar-active {
  background-color: $main-yellow;
  .main-search-icon {
    background-image: url('#{$cdn-url}20220629-icon-search-white.svg');
  }
  .icon-search-medium {
    color: $main-white;
  }
  &:hover {
    background-color: $main-yellow;
  }
}

@media screen and (min-width: 992px) {
  .simple-searchbar {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .simple-searchbar-open {
    max-width: 350px;
  }
}
