$box-radius: 5px;
.simple-box {
  background-color: $main-white;
  border: 1px solid rgba($main-grey, 0.6);
}
.box-top-rounded {
  @extend .simple-box;
  border-radius: $box-radius $box-radius 0 0;
}
.box-rounded {
  @extend .simple-box;
  border-radius: $box-radius;
}

.box-rounded-dark {
  @extend .simple-box;
  border-color: $main-blue;
}

.box-rounded-dashed {
  @extend .simple-box;
  border-style: dashed;
}

.box-shadow {
  @extend .simple-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.box-shadow-hover {
  transition: 0.2s ease-in;
  &:hover {
    @extend .box-shadow;
  }
}

.box-outstanding {
  @extend .simple-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.box-outstanding-reports {
  @extend .simple-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.box-shadow-rounded {
  @extend .box-shadow;
  @extend .box-rounded;
}

.dark-box {
  @extend .simple-box;
  border: 1px solid $main-blue;
}

.box-select {
  @extend .simple-box;
  position: relative;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    top: 16px;
    right: 17px;
    width: 18px;
    height: 18px;
    border: 1px solid $main-grey;
  }
}

.box-selected {
  @extend .simple-box;
  border: 1px solid $main-yellow;
  &:before {
    border: 1px solid $main-yellow;
  }
  &::after {
    width: 10px;
    height: 10px;
    background: $main-yellow;
    top: 20px;
    right: 21px;
  }
}

.attachment-box {
  border: 1px solid rgba($main-blue, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &:hover {
    background-color: rgba($light-blue, 0.04);
  }
}

.attachment-file-name {
  padding: 8px 0px 7px 14px;
}

.box-grey {
  background: $background-grey;
  .checkbox-item {
    label {
      &::before {
        background: $background-grey;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .box-list-item {
    transition: 0.2s ease-in;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    }
  }
}
