.all-open-consultations {
  color: rgba(0, 28, 53, 0.8);
  h4 {
    font-size: 0.95rem;
    font-weight: 700;
  }
}

.all-open-space-between {
  margin-top: 1.85em;
}

.open-consultation-heading {
  font-weight: 700;
  font-size: 1.125rem !important;
  color: #001c35;
  padding-left: 6px;
}

.open-consultations-item-link {
  &:hover {
    text-decoration: none;
  }
}

.open-consultations-item {
  position: relative;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .heading {
    position: relative;
    .arrow-down {
      position: absolute;
      right: 5%;
      top: 31%;
      transform: rotate(180deg);
      padding: 4px;
      width: 25px;
    }
    position: relative;
    background-color: rgba(0, 148, 204, 0.1);
    cursor: pointer;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid rgba(212, 212, 212, 0.6);
    border-bottom: none;
    transition: transform 0.2s ease-in;
    h4 {
      color: #001c35;
      &:hover {
        color: #001c35;
      }
    }
  }
  .collapsed {
    background-color: #fff !important;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 1px solid rgba(212, 212, 212, 0.6);
    transition: all 0.2s ease-in;
    transition-property: background-color, box-shadow;
    &::after {
      background-color: transparent;
    }
    .arrow-down {
      transform: rotate(0deg) !important;
    }
    p {
      display: block;
    }
  }
}

.heading-all-open-consultations {
  padding-left: 1.875em !important;
}

.open-consultation-link {
  color: #001c35;
  font-size: 1em;
  font-weight: 400;
  background: url('#{$cdn-url}icon-consultation-v1.svg') left center / 16px auto no-repeat;
  display: block;
  padding-left: 27px;
  &:hover {
    color: #0094cc;
    text-decoration: none;
  }
}

.open-consultation-closing-time {
  padding-left: 27px;
}

.open-consultation-datetitle {
  font-size: 0.925em;
  span {
    font-weight: 400;
  }
}

.open-consultation-content {
  position: relative;
  font-size: 1.025rem;
  font-weight: 600;
}

.inside-consultation-container {
  display: block;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  }
}

.open-consultations-items {
  background: #fff;
  box-shadow: 1px 1px 0.2px rgba(0, 0, 0, 0.03);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-bottom: 1.3rem;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-top: none;
  transition: height 0.35s ease;
}

.closing-time {
  color: #848484;
  font-weight: 400;
  text-align: left;
}

.below-seven {
  color: #b40a37 !important;
}

.consultation-results {
  font-weight: 700;
  font-size: 1.08rem;
  color: #022344;
}

.no-consultations-results {
  color: #848484;
}

.individual-consultation-item {
  //background: url('#{$cdn-url}icon-consultation-v1.svg') left 4px / 20px auto no-repeat;
  padding-left: 0.6em;
  .source-name {
    font-size: 0.9em !important;
  }
}

.consultation-content-subtitle {
  background: rgba(212, 212, 212, 0.2);
  border-radius: 3px;
  padding: 0.5em 1em;
  h3 {
    font-size: 1em;
    font-weight: 700;
  }
  .closing-time {
    background: url('#{$cdn-url}clock-icon-blue.svg') calc(100% - 2px) 3px/ 14px auto no-repeat;
    padding-right: 26px !important;
  }
}

.consultation-content {
  .btn-has-img {
    position: relative;
    padding-left: 38px;
    max-width: 450px;
    img {
      position: absolute;
      left: 10px;
      top: 27%;
      width: 20px;
      height: 20px;
    }
    background: #001c35;
    color: #fff;
    font-size: 0.95em;
    text-align: left;
    &:focus {
      box-shadow: none;
    }
  }
  .visually-hidden {
    display: none;
  }
  .gem-c-govspeak {
    .attachment {
      margin-top: 1em;
      display: flex;
    }
    .attachment-details {
      padding-left: 1rem;
      h2 {
        font-size: 1.15em;
      }
      .metadata {
        font-size: 0.85em;
      }
      a {
        color: #022344;
      }
    }
  }
}

.closing-time-container {
  padding-left: 1.75em;
}

.consultation-sticky {
  background: #f9f9f9;
  z-index: 3;
  margin-top: 0 !important;
}

.consultation-individual-content-width {
  width: calc(100% - 45px);
}
