.crm-table {
  border-collapse: collapse;
  position: absolute;
  top: -5px;
  table-layout: fixed;
  display: grid;
  background-color: #fff;
  & thead,
  & tbody,
  & tr {
    display: contents;
  }
  & tr {
    &:nth-child(2) {
      & td {
        border-top: none;
      }
    }
    & td {
      overflow: hidden;
      background-color: #fff;
    }
  }

  & thead {
    & tr {
      & th {
        &:hover {
          background-color: #fafafa !important;
        }
        & .crm-table-header {
          display: flex;
          align-items: center;
          height: 51px;
          justify-content: space-between;
          cursor: pointer;
          gap: 15px;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #00122b;
        }
        position: sticky;
        top: -1px;
        background-color: #fff;
        z-index: 20;
        border-right: 1px solid rgba(212, 212, 212, 0.5);
        height: 50px;
        padding: 0 20px;
        transition: all 200ms;
        white-space: nowrap;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .checkbox-crm-table {
    text-align: center;
    width: 48px !important;
    padding: 0 !important;
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    height: calc(100% + 2px);
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #fff !important;
    height: 49px;
    & > input {
      visibility: hidden;
      position: absolute;
    }
    & > input:checked + label {
      background: url('#{$cdn-url}stakeholders-selectBar-line.svg');
      background-position: 2.5px 7px;
      background-size: 9.5px;
      background-repeat: no-repeat;
    }
    & > label {
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      background: #ffffff;
      border: 1px solid #000;
      border-radius: 3px;
      position: relative;
      margin: 0 auto;
      cursor: pointer;
      &:hover::before {
        content: '';
        width: 34px;
        height: 34px;
        background: rgba(0, 28, 53, 0.06);
        position: absolute;
        top: -10px;
        left: -10px;
        border-radius: 50%;
      }
    }
  }
  .checkbox-crm-table-select-all {
    & > input:checked + label {
      background: url('#{$cdn-url}stakeholders-tick.svg');
      background-position: 3px 3px;
      background-size: 10.5px;
      background-repeat: no-repeat;
    }
  }
}
.crm-table-contacts {
  grid-template-columns:
    minmax(49px, min-content)
    minmax(365px, min-content)
    minmax(225px, min-content)
    minmax(160px, min-content)
    minmax(300px, min-content)
    minmax(300px, min-content)
    minmax(200px, min-content)
    minmax(200px, min-content);
  & thead {
    & tr {
      & th {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          display: block;
          height: 1px;
          background-color: rgba(212, 212, 212, 0.5);
          box-sizing: border-box;
          left: 0;
        }
      }
      @media screen and (min-width: 769px) {
        & th:first-child {
          position: sticky;
          left: 0;
          z-index: 99;
          padding: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  & tr {
    @media screen and (min-width: 769px) {
      & td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
.crm-table-emails {
  grid-template-columns:
    minmax(365px, min-content)
    minmax(160px, min-content)
    minmax(220px, min-content)
    minmax(220px, min-content)
    minmax(140px, min-content)
    minmax(140px, min-content)
    minmax(140px, min-content)
    minmax(140px, min-content);
  .text-center {
    display: flex;
    justify-content: center !important;
  }
  & thead {
    & tr {
      & th {
        border-bottom: 1px solid rgba(212, 212, 212, 0.5);
        &:first-child {
          & > div {
            padding-left: unset;
          }
        }
      }
    }
  }
}

.crm-table-groups {
  grid-template-columns:
    minmax(49px, min-content)
    minmax(365px, min-content)
    minmax(225px, min-content)
    minmax(160px, min-content)
    minmax(160px, min-content)
    minmax(160px, min-content)
    minmax(200px, min-content);
}

.crm-table-no-contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 83px 20px 0px 20px;
  text-align: center;
  .crm-table-no-contacts-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #00122b;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .crm-table-no-contacts-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: rgba(0, 18, 43, 0.45);
    max-width: 490px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .crm-table-no-contacts-image {
    background: url('#{$cdn-url}no-contacts-image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% + 40px);
    margin-top: 25px;
  }
}

.crm-email-quantity {
  color: rgba(0, 18, 43, 0.7);
  font-weight: 400;
  font-size: 16.5px;
  line-height: 30px;
  margin: 0 !important;
}
.crm-email-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 1.5px;
}

.crm-email-table-container {
  height: calc(100% - 44px);
}
.crm-table-arrows-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: baseline;
  & span {
    display: block;
    height: 7px;
    width: 8px;
    background: url('#{$cdn-url}crm-list-table-arrow-dark.svg');
    background-position: center;
    background-size: 9px;
    background-repeat: no-repeat;
  }
  & span:first-child {
    transform: rotate(180deg);
  }
}
.crm-contacts-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0 4px 0px;
  .crm-contacts-table-helper-text {
    margin-bottom: 0;
    color: rgba(0, 18, 43, 0.7);
    font-weight: 400;
    font-size: 16.5px;
  }
}

.email-performance-emty-screen-img-container {
  padding: 10px 20px 0px 20px;
  background-color: rgb(242, 168, 5);
  .pswp-gallery {
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }
  .lg-react-element,
  .swiper-container,
  .swiper-wrapper {
    height: 100%;
  }
}

.email-performance-image {
  width: 100%;
}

.email-swiper-pagination {
  text-align: center;
  margin-bottom: 10px;
  .swiper-pagination-bullet {
    margin-left: 8px;
  }
  .swiper-pagination-bullet-active {
    background-color: $main-blue;
  }
}

@media screen and (min-width: 768px) {
  .email-performance-emty-screen-img-container {
    padding: 16px 65px 0px 65px;
  }
  .email-swiper-pagination {
    margin-bottom: 1em;
  }
}
