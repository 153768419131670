.influence-team-content-header {
  position: relative;
  .teams-content-results {
    margin-bottom: 0;
    color: rgba(0, 18, 43, 0.7);
    font-weight: 400;
    font-size: 16.5px;
  }
  .influence-team-content-btn-container {
    position: absolute !important;
    top: -83px;
    right: 24px;
    width: 100%;
    justify-content: flex-end;
  }

  .influence-team-content-title {
    color: rgba(0, 18, 43, 0.7) !important;
    font-weight: 400 !important;
    font-size: 16.5px !important;
    line-height: 30px !important;
    margin: 0 !important;
    margin-top: 18px !important;
  }
}

.influence-team-content-fake-btn {
  min-width: 380px;
  opacity: 0;
}

@media screen and (max-width: 992px) {
  .influence-team-content-header {
    .influence-team-content-btn-container {
      top: -119px;
    }
    .type-of-content-influence-btn {
      margin-right: -7px;
    }
  }
}
@media screen and (min-width: 992px) {
  .type-of-content-influence-btn {
    margin-right: 7px;
  }
}
