.party-filters-more-button {
  margin-top: -7px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.925em;
  color: rgb(102, 102, 102);
  .dropdown-item-element {
    &::after {
      top: 10px;
    }
  }
}

.trends-party-box {
  width: 220px !important;
  min-width: 220px !important;
  max-height: 250px;
  overflow: hidden;
  .legend-container-select-box {
    margin-bottom: 15px !important;
  }
}

.loading-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 100;
}

.contributions-tooltip {
  margin-top: 10px !important;
  max-width: 300px;
  background: $main-white !important;
  color: $main-blue !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba($main-grey, 0.6) !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  opacity: 1 !important;
  z-index: 1000;
}

.contributions-tooltip-sentiment-changes {
  max-width: 400px;
}

.contributions-tooltip-statistics {
  margin-top: 10px !important;
  z-index: 10000 !important;
  max-width: 350px;
}

.sentiment-dot-tooltip {
  padding: 0px !important;
  margin-top: 0px !important;
}

.sentiment-max-tooltip {
  z-index: 10000 !important;
}

.select-all-button {
  padding: 8px 16px;
  font-size: 0.925em;
  &:hover {
    background: rgba($light-blue, 0.04) !important;
    border-radius: 5px;
    color: $light-blue;
  }
}

.legend-item.crossbench,
.legend-item.dup,
.legend-item.independt,
.legend-item.bishops,
.legend-item.alba-party,
.legend-item.non-aligned-lords,
.legend-item.alliance {
  &::after {
    border-color: #fff !important;
  }
}

.empty-small-pie-chart {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 1px solid $main-grey;
}

.show-all-parties-button {
  @extend .empty-small-pie-chart;
  display: block;
  margin: 0 auto;
  color: rgba($main-blue, 0.6);
  .dropdown-item-element {
    &::after {
      top: 10px;
    }
  }
}

.pie-chart-container {
  min-width: 315px;
}

.external-mentions-tooltip {
  z-index: 100;
}

.top-stakeholders-waiting {
  .settings-box {
    box-shadow: none;
  }
}

@media screen and (min-width: 992px) {
  .show-all-parties-button {
    transition: 0.2s ease-in;
    &:hover {
      background: $hovered-blue;
    }
  }
}
