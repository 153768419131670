.campaigns-topic-picker {
  .search-main-text-value {
    width: calc(100% - 95px) !important;
  }
  .dropdown-keywords-lists {
    background-color: $main-white;
  }
}

.campaigns-topic-picker,
.analytics-topic-picker {
  .dropdown-keywords-activeitem {
    padding: 0px 8px 0px 14px;
  }
  .dropdown-keywords-lists {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dropdown-keywords-activeitem {
    position: static;
  }
  .main-icon-topic-picker {
    margin-top: -1px;
  }
}

.analytics-topic-picker {
  width: 0;
  flex-grow: 1;
}

@media screen and (min-width: 992px) {
  .campaigns-text {
    font-size: 17px;
  }
}
