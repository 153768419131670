/*base styles*/
#check-email {
  display: none;
}

.noty_layout {
  font-size: 18px !important;
  z-index: 100000000000 !important;
}

.noty_theme__metroui.noty_bar {
  border-radius: 5px;
}

.noty_theme__metroui.noty_bar .noty_body {
  font-size: 17px !important;
  padding: 1em 1.25em !important;
}

.main-information-form input:focus,
.main-information-form button:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.main-information-form button {
  display: block;
  width: 100%;
  color: #fff;
  padding: 7px 0;
}

.main-information-form button:hover {
  color: #fff;
}

.join-for-free-button {
  &:disabled {
    background-color: #0094cc !important;
    &:hover {
      background-color: #0094cc !important;
    }
  }
}

/*page styles*/

.for-parlamentarians-content {
  background-color: #f9f9f9;
  position: relative;
  h1 {
    font-size: 2.25em;
    max-width: 500px;
    margin: 0 auto;
  }
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  p {
    font-size: 1.1em;
  }
}

.parliamentarians-main-banner {
  min-height: 530px;
  .main-information-container {
    p {
      max-width: 600px;
    }
  }
}

.parliamentarians-information {
  padding: 1em 0 3em 0;
  max-width: 900px;
  margin: 0 auto;
  .text-information {
    max-width: 385px;
    margin: 0 auto;
  }
  img {
    min-width: 252px;
    max-width: 250px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.parliamentarians-information-image {
  min-height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parliamentarian-information-form {
  .ui-state-default {
    font-size: 1em;
    font-family: 'Lato';
    font-weight: 400;
    color: rgba(0, 18, 43, 0.8);
    text-align: left;
    border-radius: 5px;
    background: #fcfbfa;
    border: 1px solid #ced4da;
    min-width: 100%;
    padding-top: 3px;
    min-height: 44px;
    font-size: 16px !important;
  }
  input {
    width: 100%;
    border-radius: 5px;
    height: 44px;
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
  .action-button {
    height: 44px;
    display: block;
    border-radius: 5px;
    &:focus {
      box-shadow: none;
    }
  }
}

.parliamentarian-information-form .ui-state-default[aria-expanded='true'] {
  border-radius: 5px 0px 0px 0px;
}

.main-input-container {
  width: 100%;
}

.bottom-email-form {
  max-width: 750px;
}

.parliamentarian-bottom-banner h2 {
  color: #fff;
}

.ui-selectmenu-menu {
  .ui-widget-content {
    padding: 0;
    border: 1px solid #ced4da;
    box-shadow: none;
    border-top: none;
    font-size: 16px !important;
    font-family: 'Lato';
  }
  .ui-menu-item {
    box-shadow: none !important;
    border: none;
    padding: 9px 1px 9px 39px !important;
    margin: 0 !important;
  }
  .ui-state-focus {
    border: none !important;
    background: rgba(196, 196, 196, 0.25) url('https://cdn.policymogul.com/images/tick-symbol.svg') 11px 13px/15px auto
      no-repeat !important;
    padding: 9px 1px 9px 39px !important;
    margin: 0 !important;
  }
}

.ui-selectmenu-button span.ui-icon {
  background: none !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #00122b;
  width: 10px;
  height: 10px;
  right: 13px !important;
  top: 26px !important;
}

.ui-selectmenu-button[aria-expanded='true'] span.ui-icon {
  border-top: none;
  border-bottom: 6px solid #00122b;
  top: 23px !important;
}

.button-loading-spinner {
  width: 15px;
  animation: fa-spin 2s linear infinite;
}

.spinner-preload {
  position: fixed;
  top: -25px;
  left: -25px;
  z-index: -100;
  opacity: 0;
}

.check-email-address {
  font-weight: 700;
}

.home-blockquote-container {
  background: #f2a805;
  &:before {
    background: #f5bd43;
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    right: 0%;
    width: 60%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  }
}

.blockquote-container {
  color: #fff;
  padding-top: 35px;
  blockquote {
    font-size: 1.25em;
    font-weight: 700;
  }
  &::before {
    background: url('#{$cdn-url}quote_open_white.png') center / contain no-repeat;
    width: 40px;
    height: 30px;
    left: 16px;
    top: 0px;
    content: '';
    position: absolute;
  }
  &::after {
    content: '';
    background: url('#{$cdn-url}quote_close_white.png') center / contain no-repeat;
    width: 40px;
    height: 30px;
    position: absolute;
    bottom: -30px;
    right: 0;
    z-index: 10;
  }
}

@media screen and (min-width: 768px) {
  .blockquote-container {
    padding-top: 0px;
    padding-left: 50px;
    blockquote {
      font-size: 1.85em;
    }
    &::before {
      width: 65px;
      height: 50px;
      top: 0;
      left: -25px;
    }
    &::after {
      width: 65px;
      height: 50px;
      right: 20px;
      bottom: -60px;
    }
  }
}

@media screen and (min-width: 992px) {
  .ui-selectmenu-menu .ui-widget-content {
    width: 260px !important;
  }

  .parliamentarian-information-form input {
    width: 320px;
    border-radius: 0;
    border-left: none;
  }

  .main-input-container {
    width: auto;
  }

  .parliamentarian-information-form .ui-state-default {
    border-radius: 5px 0px 0px 5px;
    min-width: 260px;
  }
  .parliamentarians-main-banner .main-information-container {
    margin-top: -3em;
  }
  .parliamentarian-information-form .action-button {
    display: block;
    border-radius: 0px 5px 5px 0px;
  }
}

@media screen and (min-width: 1280px) {
  .photograph-container {
    margin-top: -60px;
  }
}
