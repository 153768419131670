@media screen and (min-width: 992px) {
  .influence-search-bar {
    flex-grow: 1;
    margin-right: 16px;
    min-width: 400px;
    max-width: 400px;
  }
}

@media screen and (min-width: 1366px) {
  .influence-search-bar {
    min-width: 423px;
    max-width: 423px;
  }
}
