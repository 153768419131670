@import 'styles/variables.scss';

.election-2024 {
  background-color: #00122b;
  background-repeat: no-repeat;

  video {
    position: absolute;
    z-index: 1;
    width: 110%;
    margin-top: -14px;
  }
  h1.election-header {
    font-size: 2.125rem;
    line-height: 2.5rem;
    color: #00122b;
  }
  h3 {
    margin: 0;
  }
  .dropdown-item-element-main-title {
    &::after {
      top: 16px;
    }
  }
  .election-sidebar {
    width: 303px;
    &.collapsed {
      width: 110px;
    }
    h5 {
      padding-left: 15px;
    }
    li {
      width: 100%;
      &:not(&:last-child) {
        margin-bottom: 3px;
      }
    }
    .election-link {
      padding: 7px 14px;
      color: rgba(0, 18, 43, 0.8);
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 5px;
      transition: all 150ms;
      .election-link-icon {
        color: rgba(0, 18, 43, 0.8);
        margin-right: 25px;
        &.icon-team {
          margin-right: 22px;
          margin-left: -2px;
        }
        &.icon-document {
          margin-right: 27px;
          margin-left: 2px;
        }
        &.icon-briefing-material {
          margin-right: 24px;
          margin-left: 1px;
        }
        &.icon-statistics {
          margin-right: 20px;
        }
        &.icon-key-update {
          margin-right: 21px;
          margin-left: -3px;
        }
        &.icon-search-medium {
          margin-right: 25px;
        }
      }
      & > svg {
        margin-right: 21px;
        margin-left: -3px;
        display: block;
        path,
        line {
          stroke: currentColor;
        }
      }
      &:hover {
        background-color: rgba(#0094cc, 0.04);
        text-decoration: none;
      }
      &-active {
        @extend .election-link;
        background-color: rgba(#0094cc, 1);
        color: #fff;
        .election-link-icon {
          color: #fff;
        }
        &:hover {
          background-color: rgba(#0094cc, 0.9);
        }
        & > svg {
          path,
          line {
            stroke-opacity: 1;
          }
        }
      }
    }
    &-mobile {
      left: 50%;
      transform: translateX(-50%);
      right: auto;
      top: 21px;
    }
  }

  .election-main-container {
    max-width: 1150px;
    margin: 0 auto;
  }

  .election-content-box {
    flex-grow: 1;
    .election-content-box-header {
      min-height: 86px;
      border-bottom: 1px solid #d4d4d4;
    }
    .time-table-container {
      padding-left: 45px !important;
      padding-top: 30px;
      .time-table-item {
        padding-left: 44px;
        padding-bottom: 27px;
        position: relative;

        p {
          color: rgba(0, 18, 43, 0.7);
          margin: 0;
        }
        &::before {
          content: '';
          width: 18.5px;
          height: 18.5px;
          border-radius: 50%;
          background-color: #00122b;
          position: absolute;
          left: -10px;
          top: 4px;
        }
        &::after {
          content: '';
          width: 3px;
          height: 100%;
          background-color: #00122b;
          position: absolute;
          left: -2px;
          top: 4px;
        }
      }
      .time-table-item-past {
        &::before {
          content: '';
          background-color: #f2a805;
        }
        &::after {
          content: '';
          background-color: #f2a805;
        }
      }
      .time-table-item-active {
        &::after {
          content: '';
          background: linear-gradient(180deg, rgba(242, 168, 5, 1) 29px, rgba(0, 18, 43, 1) 29px);
        }
      }
      .time-table-item-active-svg {
        position: absolute;
        left: -20px;
        top: -6.5px;
        & circle:last-of-type {
          animation-name: outerCircle;
          animation-duration: 1.3s;
          animation-iteration-count: infinite;
        }
      }
      .time-table-item-last {
        &::after {
          background-color: transparent !important;
          background: transparent !important;
        }
      }
    }
    .search-manifesto-container {
      background-color: rgba(#0094cc, 0.04);
      border-bottom: 1px solid #d4d4d4;
    }
    .manifestos-container {
      .manifestos-item {
        display: flex;
        column-gap: 20px;
        align-items: center;
        padding-bottom: 27px;
        .manifesto-image {
          width: 40px;
          &.manifesto-labour {
            width: 60px;
            margin-left: -14px;
            margin-right: -5px;
          }
        }
        p {
          color: rgba(0, 18, 43, 0.7);
          margin: 0;
        }
      }
    }
    .manifestos-searchbar {
      height: 50px;
      border-radius: 25px;
      border: 1px solid #d4d4d4;
      background-color: #fff;
      display: flex;
      align-items: center;

      .simple-searchbar {
        display: flex !important;
        border-radius: 25px;
        height: 100%;
        max-width: none;
        padding: 18px 15px 17px 16px;
      }
    }
    .manifestos-parties-select {
      width: 140px;
      background-color: #00122b;
      color: #fff;
      border-radius: 17px;
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 18, 43, 0.85);
      }
      h5 {
        margin: 0;
      }

      .arrow-triangle {
        width: 10px;
        border: 6px solid transparent;
        border-top: 7px solid #fff;
        position: absolute;
        right: 15px;
        top: 16px;
      }
      .arrow-triangle-opened {
        @extend .arrow-triangle;
        transform: rotate(180deg);
        top: 8px;
      }
    }
    .manifestos-select-list {
      background-color: #fff;
      position: absolute;
      top: 40px;
      right: 0;
      width: 200px;
      .checkbox-item {
        display: flex;
        align-items: center;
        label {
          margin-top: -4px;
        }
      }
    }
    .candidates-header {
      display: flex;
      column-gap: 30px;
      align-items: center;
      justify-content: space-between;
      .candidates-searchbar {
        height: 37px;
        border-radius: 5px;
        border: 1px solid #d4d4d4;
        background-color: #fff;
        display: flex;
        align-items: center;
        width: 395px;
        .simple-searchbar {
          display: flex !important;
          border-radius: 5px;
          height: 100%;
          max-width: none;
        }
        @media screen and (max-width: 640px) {
          width: 140px;
        }
      }
    }
    .more-filters-button-active {
      padding: 0.35em 1em !important;
      height: 37px !important;
    }
    .lobbying-election-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: rgba(#0094cc, 0.04);
      height: 100%;
      h1 {
        max-width: 460px;
        text-align: center;
      }
      p {
        color: rgba(0, 28, 53, 0.6);
        max-width: 465px;
        text-align: center;
      }
    }
    .election-search-page {
      background-color: rgba(#0094cc, 0.04);
      height: 100%;
      .simple-searchbar {
        border: 1px solid rgba(212, 212, 212, 1);
        padding: 16px 15px 17px 16px;
        height: 50px;
        border-radius: 25px;
        .main-search-icon {
          margin-top: 1px;
        }
      }
      .sentiment-page-searchbar {
        padding-top: 0;
        .simple-searchbar {
          max-width: none !important;
        }
      }
      .home-searchbar-container {
        width: 460px;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }
    .election-keyupdates-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        padding-bottom: 0.35em;
        padding-top: 0.35em;
      }
      .action-button {
        background-color: #00122b;
        &:hover {
          background-color: rgba(0, 18, 43, 0.85);
        }
      }
      @media screen and (max-width: 640px) {
        flex-direction: column;
        align-items: end;
        row-gap: 15px;
        & > div.d-flex {
          width: calc(100%);
          gap: 15px;
          justify-content: space-between;
          .start-of-update-select-container {
            width: calc(100% - 119px);
            .start-of-update-select {
              width: 100%;
              h5 {
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 27px);
              }
            }
            .start-of-update-select-list {
              width: 100%;
            }
          }
        }
      }
    }
    .election-section-title {
      .dropdown-item-element {
        &::after {
          top: 13px !important;
        }
      }
    }
    .election-sidebar-mobile {
      position: absolute;
      top: calc(100% + 24px);
      left: 0;
      width: 240px;
      min-width: 220px;
      background: #ffffff;
      box-shadow:
        0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      border-radius: 8px;
      z-index: 1001;
      .election-link,
      .election-link-active {
        padding: 0.45rem !important;
      }
    }
  }
  .candidates-register-header {
    background-color: rgba(#0094cc, 0.04);
    border-bottom: 1px solid #d4d4d4;
    p {
      margin: 0;
    }
    button {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 992px) {
    background-color: #f5bd43;
    h1 {
      font-size: 1.75rem;
      line-height: normal;
    }
  }
}

.start-of-update-select {
  width: 260px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: 1px solid #d4d4d4;
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
  h5 {
    margin: 0;
  }

  .arrow-triangle {
    width: 10px;
    border: 6px solid transparent;
    border-top: 7px solid #00122b;
    position: absolute;
    right: 15px;
    top: 16px;
  }
  .arrow-triangle-opened {
    @extend .arrow-triangle;
    transform: rotate(180deg);
    top: 8px;
  }
}
.start-of-update-select-list {
  background-color: #fff;
  position: absolute;
  top: 40px;
  left: 0;
  width: 260px;
  z-index: 1000;
  .radio-button {
    position: relative;
  }
  .tick-radio-button {
    position: relative;
    label {
      padding: 0;
      width: 100%;
      &:before,
      &:after {
        display: none;
      }
    }
    &:hover {
      background-color: rgba(#0094cc, 0.04);
    }
  }
  .icon-tick {
    position: absolute;
    right: 10px;
    top: 11px;
  }
}

@keyframes outerCircle {
  0% {
    r: 13.5px;
  }
  100% {
    r: 18.5px;
    stroke: transparent;
  }
}

.election-link-collapsed {
  padding: 7px 8px !important;
  flex-direction: column;
  .election-link-icon,
  svg {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 5px;
  }
  .election-sidebar-name {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 11px;
  }
}

.sidebar-collapse-button {
  padding: 7px 8px;
  &.collapsed {
    text-align: center;
    border-top: 1px solid $main-grey;
    padding: 16px 8px 7px 8px;
    .election-sidebar-name {
      font-size: 11px;
      margin-top: 5px;
    }
  }
  &.no-collapsed {
    padding: 7px 14px;
    border: 1px solid $main-grey;
    background-color: rgba($main-grey, 0.15);
    .icon-collapse {
      margin-right: 20px;
    }
  }
}

.manifesto-filter {
  width: 50%;
  .checkbox-item-disabled {
    pointer-events: none;
  }
}
.manifesto-tabs {
  align-self: start;
}

.try-another-search-button {
  padding: 0.35em;
  border: 1px solid #0094cc;
  span {
    width: 162px;
    display: block;
  }
}

.manifesto-table {
  table {
    overflow-x: auto;
    color: $main-blue;
    margin: 1em 0;
    border: 1px solid $main-grey;
    min-width: 100%;
  }
  thead {
    tr {
      background-color: $main-blue;
      color: $main-white;
    }
  }
  tbody {
    tr:nth-child(odd) {
      background-color: $main-white;
    }
  }
  th,
  td {
    padding: 1em;
    vertical-align: baseline;
  }

  th {
    border-bottom: 1px solid $main-grey;
  }

  td {
    width: 220px;
  }
}

@media screen and (min-width: 992px) {
  .manifesto-filter {
    width: 25%;
  }
  .manifesto-tabs {
    align-self: end;
  }
  .manifesto-popup-list {
    li {
      padding-right: 1.5em !important;
    }
  }
}
