.statistics-number {
  font-size: 3rem;
  line-height: 1;
}

.statistics-popup-logo {
  width: 8rem;
  margin-left: 3.3rem;
}

.statistics-info {
  flex-grow: 1;
}

.statistics-info-container {
  padding: 1rem 2.5rem;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  flex-wrap: wrap;
}

.statistics-info-title {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  span {
    white-space: nowrap;
  }
}

.statistics-button-action {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.05em;
  .icon-long-arrow-right {
    font-size: 0.75em;
  }
}

.statistics-list-active-item {
  border-radius: 5px;
}

.policy-makers-views-less {
  height: 48px;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 60px;
    height: 7px;
    background: $main-grey;
  }
}
.statistics-card-container {
  flex-grow: 1;
  flex-basis: 200px;
  position: relative;
}
.engagement-statistics-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.not-include-feature-image {
  border: 7px solid #e3dcd9;
  border-radius: 20px;
}

@media screen and (max-width: 380px) {
  .statistics-info-title {
    span {
      white-space: normal;
    }
  }
}

.statistics-popup-list {
  .icon-small-check,
  .icon-close-circle {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }
}

@media screen and (min-width: 992px) {
  .statistics-popup-list {
    li {
      padding-right: 4rem;
    }
    .icon-small-check {
      vertical-align: middle;
      margin-top: -5px;
    }
  }

  .statistics-list-active-item {
    border-radius: 5px 0px 0px 5px;
  }

  .statistics-button-action {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (min-height: 700px) {
  .statistics-info-container {
    padding: 3.5rem 2.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .statistics-info {
    // min-width: 200px;
    margin-right: 0;
  }

  .statistics-button-action {
    font-size: 1.15rem;
  }
}
