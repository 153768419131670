.roundend-action-button {
  border-radius: 20px;
  padding: 9px 36px;
}

.action-button {
  border: 1px solid transparent;
  &:disabled {
    @extend .action-button-disabled;
  }
}

.action-button-disabled {
  background-color: rgba($main-blue, 0.04);
  color: rgba($main-blue, 0.5);
  border: 1px solid rgba($main-blue, 0.1);
  &:hover {
    color: rgba($main-blue, 0.5);
    background-color: rgba($main-blue, 0.04);
  }
}

.report-button-disabled {
  color: rgba($main-blue, 0.45);
  &:hover {
    color: rgba($main-blue, 0.45);
    background-color: rgba($main-blue, 0.07);
  }
}

.general-button-spacing {
  padding: 6px 24px;
}

.ppc-filter-btn {
  color: #0094cc;
  border: 1px solid #0094cc;
  padding: 0.3em 1.3em;
  white-space: nowrap;
  border-radius: 20px;
  &:hover {
    background-color: rgba(#0094cc, 0.04);
  }
}

.outline-button {
  border: 1px solid $main-grey;
  border-radius: $button-border-radius;
  color: rgba($main-blue, 0.7);
  transition: 0.2s ease-in;
  &:hover {
    color: rgba($main-blue, 0.7);
    background-color: $hovered-blue !important;
  }
  &:disabled {
    background-color: rgba($main-grey, 0.2) !important;
    color: rgba($main-blue, 0.4);
  }
}

.rounded-button {
  border-radius: 20px !important;
}

.outline-badge {
  border: 1px solid $main-grey;
  color: rgba($main-blue, 0.7);
  background: $main-white;
  &:hover {
    color: rgba($main-blue, 0.7);
    text-decoration: none;
  }
}

.highlighted-badge {
  background-color: $main-yellow;
  color: $main-white !important;
}

.badge-spacing {
  padding: 2px 15px;
}

.outline-active-button {
  border: 1px solid $light-blue;
  border-radius: $button-border-radius;
  color: $light-blue;
  transition: 0.2s ease-in;
  &:hover {
    color: $light-blue;
    background-color: rgba($light-blue, 0.02) !important;
    text-decoration: none;
  }
  &:disabled {
    border: 1px solid $main-grey;
    color: rgba($main-blue, 0.5);
    background-color: rgba($light-blue, 0.02);
    &:hover {
      background-color: rgba($light-blue, 0.02);
    }
  }
}

.circle-button {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
}

.circle-button-medium {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
}

.add-item-button {
  @extend .circle-button;
  background: url('#{$cdn-url}add_button_rounded.svg') center/contain;
}

.add-item-button_selected {
  @extend .circle-button;
  background: url('#{$cdn-url}icon_yellow_list.svg') center/contain;
}

.indented-button {
  text-indent: -1000px;
  overflow: hidden;
}

.view-link-button {
  padding-right: 45px;
  padding-left: 22px;
  background: url('#{$cdn-url}external-link-icon-blue.svg') calc(100% - 23px) 4px/14px 14px no-repeat;
  color: $light-blue;
  &:hover {
    color: $light-blue;
  }
}

.menu-button-item {
  color: rgba($main-blue, 0.6);
  transition: 0.2s ease-in;
  &:hover {
    color: $main-blue;
  }
}

.menu-button-item-active {
  color: $main-blue;
}

.button-danger-text {
  color: $main-red;
  transition: 0.2s ease-in;
  &:hover {
    opacity: 0.8;
  }
}

.delete-button {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -8px;
  right: -8px;
  background: $main-blue url('#{$cdn-url}close-white.svg') center / 8px 8px no-repeat;
  display: none;
  &:hover {
    display: block;
    background-color: rgba($main-blue, 0.8);
  }
}

.add-more-items-button {
  position: relative;
  border: 1px solid $main-grey;
  width: 25px;
  height: 25px;
  text-align: center;
  display: block;
  border-radius: 50%;
  span {
    display: inline-block;
    margin-top: -8px;
    vertical-align: middle;
    line-height: 0.5;
  }
}

.general-edit-button {
  border: 1px solid $main-grey;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background: url('#{$cdn-url}edit-pencil.svg') 6px 6px / 10px auto no-repeat;
}

.filter-action-button {
  border-radius: 20px;
  padding: 5px 16px 5px 16px;
  font-size: 15px;
}

.secondary-action-button {
  background-color: $main-yellow;
  &:hover {
    background-color: rgba($main-yellow, 0.75);
  }
}

.third-button {
  background-color: $main-blue;
  &:hover {
    background-color: rgba($main-blue, 0.85);
  }
  &:disabled {
    background-color: rgba($main-grey, 0.6);
    color: $main-grey;
  }
}

.fourth-button {
  background-color: rgba($main-grey, 0.2);
  &:hover {
    background-color: rgba($main-grey, 0.5);
  }
}

.simple-delete-button {
  color: $main-red;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    color: rgba($main-blue, 0.5);
  }
}
button:disabled {
  .toggle-button {
    background: rgba($main-blue, 0.2);
  }
}
.toggle-button {
  background: #0094cc;
  text-indent: -1000px;
  overflow: hidden;
  width: 27px;
  height: 15px;
  border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  //margin-left: 5px;
  position: relative;
  &::after {
    content: '';
    border-radius: 50%;
    position: absolute;
    width: 11px;
    height: 11px;
    top: 2px;
    left: 14px;
    background: #ffffff;
    transition: 0.2s ease-in;
  }
}

.toggle-button-inactive {
  .toggle-button {
    background: rgba($main-blue, 0.2);
    &::after {
      left: 2px;
    }
  }
}

.toggle-button-skeleton {
  .toggle-button {
    background-color: #f3eeee !important;
  }
}

.toggle-button-disabled {
  .toggle-button {
    background: rgba($main-blue, 0.2);
  }
}

.back-button {
  padding-left: 16px;
  position: relative;
  &::before {
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid $light-blue;
    content: '';
    top: 6.4px;
    left: 0px;
  }
}

.collapse-expand-button {
  position: relative;
  padding: 8px 16px 8px 36px;
  border-radius: 5px;
  &::after,
  &:before {
    position: absolute;
    content: '';
    border: solid rgba($main-blue, 0.75);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    left: 15px;
  }
  &::after {
    top: 9px;
    transform: rotate(45deg);
  }
  &::before {
    top: 24px;
    transform: rotate(-135deg);
  }
}

.team-clients-admin-header {
  .collapse-expand-button {
    border-radius: 20px;
    padding: 5px 16px 6px 36px;
    &::before {
      top: 21px;
      left: 18px;
    }
    &::after {
      top: 7px;
      left: 18px;
    }

    &.collapse-button {
      &::before {
        top: 18px;
        left: 18px;
      }
      &::after {
        top: 10px;
        left: 18px;
      }
    }
  }
}

.collapse-button {
  &::after {
    top: 12px;
    transform: rotate(-135deg);
  }
  &::before {
    top: 20px;
    transform: rotate(45deg);
  }
}

.open-external-tab-active {
  background: url('#{$cdn-url}20211201-external-link-blue.svg') 100% 2px / 16px auto no-repeat;
  padding-right: 23px;
}

.back-content-button {
  display: none;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 1.1em;
  position: absolute;
  .arrow-left-blue {
    margin-right: 3px;
  }
  &:hover {
    text-decoration: none;
    color: $light-blue;
  }
}

.research-portal-button {
  background: #0094cc url(https://cdn.policymogul.com/images/20220205-right-arrow-white.svg) calc(100% - 15px) 11px/18px
    auto no-repeat;
  padding-right: 42px !important;
}

.action-delete-button {
  background-color: #e46666;
  border-color: #e46666;
  &:hover {
    background: rgba(#e46666, 0.8);
  }
}

.general-link-vertical-padding {
  padding-top: 6px !important;
  padding-bottom: 8px !important;
}

.supported-button {
  background: url('#{$cdn-url}tick-symbol.svg') 20px 13px / 13px auto no-repeat !important;
  padding-left: 42px !important;
}

.general-disabled-button {
  &:disabled {
    background-color: rgba($main-grey, 0.2);
    color: rgba($main-blue, 0.5);
    &:hover {
      background-color: rgba($main-grey, 0.2);
    }
  }
}

.list-action-button {
  transition: 0.2s ease-in;
  &:hover {
    background-color: rgba(244, 239, 237, 0.6);
  }
}

.refresh-button {
  width: 38px;
  height: 38px;
}

.hovered-button {
  &:disabled {
    @extend .action-button-disabled;
  }
}

.main-danger-button {
  background: #9b171f;
  transition: 0.2s ease-in;
  &:hover {
    background: rgba(#9b171f, 0.8);
  }
  &:disabled {
    color: $main-white !important;
    background: rgba(#9b171f, 0.6);
  }
}

.button-blocked {
  background-color: rgba($main-blue, 0.4) !important;
}

.home-action-button {
  transition: 0.2s ease-in;
  &:hover {
    background-color: $light-blue;
  }
}

.transition-default {
  transition: all 0s ease 0s !important;
}

@media screen and (min-width: 992px) {
  .roundend-action-button {
    padding: 9px 48px;
  }

  .action-state-hover {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba($light-blue, 0.04);
    }
  }

  .common-button-hover {
    &:hover {
      background-color: #f1f9fc;
    }
  }

  .back-content-button {
    .arrow-left-blue {
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: rgba($light-blue, 0.04);
    }
  }

  .back-content-button {
    top: 35px;
    left: 26px;
  }

  .hovered-button {
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: $hovered-blue;
    }
  }
}

@media screen and (min-width: 1280px) {
  .back-content-button {
    display: block;
  }
}
