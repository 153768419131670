.react-custom-select-contacts__multi-value {
  border-radius: 5px !important;
  position: relative !important;
  &:hover {
    background-color: rgba(0, 18, 43, 0.03) !important;
  }
  &:hover .react-custom-select-contacts__multi-value__remove {
    color: rgba(0, 18, 43, 0.6);
    transition: all 250ms !important;
    z-index: 2 !important;
  }
}

.react-custom-select-contacts__multi-value:first-of-type {
  margin-left: -8px;
}
.react-custom-select-contacts__control:has(.react-custom-select-contacts__input:focus) {
  .react-custom-select-contacts__multi-value:nth-last-child(2) {
    &:after {
      display: block !important;
      right: 13px;
    }
    &:hover:after {
      translate: 1px !important;
      scale: 0.5;
      opacity: 0;
      transition:
        opacity 250ms,
        transform 100ms,
        scale 250ms !important;
    }
  }
}
.react-custom-select-contacts__multi-value__label {
  color: #00122b !important;
  font-size: 16px !important;
}

.react-custom-select-contacts__multi-value:nth-last-child(2) {
  &:after {
    display: none;
  }
}
.react-custom-select-contacts__multi-value__remove {
  color: transparent;
  border-radius: 0px 2px 2px 0px !important;
  transition: all 250ms !important;

  & > svg {
    height: 16px;
    width: 16px;
  }
  &:hover {
    color: #fff !important;
    background-color: #c4c4c4 !important;
  }
}
.email-component-custom-select-container {
  display: flex;
  align-items: center;
}
.email-component-custom-select {
  position: relative;
  color: #00122b;
  .email-component-close-select {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .email-component-custom-select-title {
    cursor: pointer;
    line-height: 36px;
    white-space: nowrap;
    transition: all 200ms;
    display: flex;
    align-items: center;
    .custom-select-title-text {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:hover {
      opacity: 0.9 !important;
    }
  }

  .open-dropdown {
    position: absolute;
    top: 38px;
    z-index: 9999;
    border-radius: 5px;
    .open-dropdown-option:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .open-dropdown-option {
      border-bottom: 1px solid rgba(212, 212, 212, 0.5);
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:hover {
        background-color: #fafafa !important;
      }
    }
    .filter-item-dropdown {
      padding-left: 25px;
    }
  }
}
.custom-select-side-bar {
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  margin-top: 8px;
  .email-component-custom-select-title {
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    .custom-select-title-text {
      margin-left: 14px;
    }
  }
}
.email-component-select-container,
.send-preview-modal {
  &[data-value='disabled'] {
    .react-custom-select-contacts__value-container {
      cursor: default !important;
    }
    .react-custom-select-contacts__input-container {
      display: none;
    }
    .react-custom-select-contacts__multi-value:first-of-type {
      margin-left: 1px;
    }
  }
  .open-dropdown-arrow {
    right: 1px;
    top: -1px;
  }
  width: 100%;
  margin-left: 9px;
  .email-component-select-option {
    overflow: hidden;
    padding-right: 20px;
    &:hover {
      background-color: rgba(0, 148, 204, 0.04) !important;
    }
    .checkbox-item {
      margin: 8px 0 3px 0 !important;
    }
  }
  .email-component-select-option-selected {
    position: relative;
    &:after {
      background-image: url('https://cdn.policymogul.com/images/stakeholders-tick-blue.svg');
      content: '';
      background-position: 0 0;
      background-size: 14px;
      background-repeat: no-repeat;
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 19px;
      right: 13px;
    }
  }
}
.select-recipient-sidebar,
.send-preview-modal,
.email-component-select-container {
  .email-component-select-option {
    &:hover {
      background-color: rgba(0, 148, 204, 0.04) !important;
    }
  }
  .contacts-select-all-button {
    cursor: pointer;
    color: 00122b;
    font-size: 15px;
    padding: 5px 12px;
    color: #0094cc !important;
    margin-bottom: -38px;
    margin-left: 85px;
    z-index: 10000;
    white-space: nowrap;
    transition: all 200ms;
    text-align: right;
    &:hover {
      text-decoration: underline;
    }
  }
}
.contacts-select-local-option {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  color: #00122b;
  font-size: 16px;
  padding: 6px 12px !important;
  &:active {
    background-color: transparent;
  }
}
.contacts-select-option-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 10px;
  & > span {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .highlight-text {
    font-weight: 700;
    background-color: transparent !important;
  }
}

.contacts-select-multivalue-item {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.filter-select-container {
  @extend .email-component-select-container;
  margin-left: 0;
  position: relative;
  .react-custom-select-contacts__menu {
    left: 0 !important;
    width: 100% !important;
  }
  .main-search-icon {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    min-width: 16px;
    max-width: 16px;
    margin-top: 0;
  }
  .checkbox-item {
    .highlight-text {
      font-weight: 700;
      background-color: transparent !important;
    }
    & > label {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100% !important;
      line-height: 1.2 !important;
    }
  }
  .open-dropdown {
    left: 0;
    .filter-item-dropdown {
      padding-left: 5px;
    }
  }
  .react-custom-select-contacts__multi-value {
    overflow: hidden;
    .react-custom-select-contacts__multi-value__label {
      text-overflow: ellipsis;
      max-width: 162px;
    }
  }
  .contacts-select-multivalue-item {
    padding-left: 0;
  }
  .email-component-select-option-selected {
    position: relative;
    &:after {
      display: none;
    }
  }

  .react-custom-select-contacts__menu {
    left: 0px !important;
    width: 100% !important;
    top: 30px !important;
  }
}
.crm-contacts-scrollbar {
  .ScrollbarsCustom-Content {
    height: calc(100% + 20px) !important;
  }
}

.select-recipient-sidebar {
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  margin-top: 8px;
  flex-grow: 1;
  width: 100%;
  position: relative;

  .icon-close {
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
    color: rgba(#5b5b5b, 0.8);
    z-index: 10;
  }
  .ValueContainerScrollbar {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden auto;
    width: 100%;
  }
  .RefferenceHeightComponent {
    position: absolute;
    top: 0;
  }
  .selected-contacts-qntty-emty-state {
    color: rgba(0, 18, 43, 0.5);
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
  }

  .react-custom-select-contacts__multi-value__remove {
    min-width: 30px !important;
    justify-content: center;
  }
  .react-custom-select-contacts__multi-value:nth-last-child(3) {
    margin-top: 6px !important;
  }
  .selected-contacts-qntty {
    position: absolute;
    top: -32px;
    right: 0;
    color: #212529;
  }
  .selected-contacts-qntty-sent {
    color: #0094cc;
    cursor: pointer;
  }
  .icon-search {
    left: 14px;
    top: 13px;
    position: absolute;
  }
  .sidebar-recipients-input-container {
    width: 282px;
    .email-component-select-option {
      &.action-bar-option {
        margin-bottom: 46px;
        padding: 0 !important;
      }
    }
    .react-custom-select-contacts__group-heading {
      margin-top: 5px;
    }
  }
  .react-custom-select-contacts__multi-value__label {
    max-width: 202px;
    padding-left: 7px;
  }
  .select-recipient-sidebar-separator {
    position: absolute;
    top: 40px;
    left: 0;
    border-bottom: 1px solid rgba(212, 212, 212, 0.6);
    width: 100%;
    z-index: 1;
  }
  .react-custom-select-contacts__control {
    margin-left: 0;
    border: none;
    min-height: 40px;
    flex-grow: 1;
    overflow-y: auto;
  }
  .react-custom-select-contacts__value-container {
    flex-direction: column-reverse;
    max-height: unset;
    align-items: baseline;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    overflow: hidden;
  }
  .contacts-select-multivalue-item {
    flex-grow: 1;
    padding-left: 13px;
    cursor: pointer;
  }
  .react-custom-select-contacts__multi-value {
    width: 100%;
    margin: 2px 0;
  }
  .react-custom-select-contacts__input {
    margin-left: 36px !important;
    margin-bottom: 10px !important;
    margin-top: 3px !important;
    z-index: 2;
  }
  .react-custom-select-contacts__input-container {
    position: relative;
    margin-bottom: 0;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: rgba(212, 212, 212, 0.6);
      bottom: 2px;
      left: -2px;
      right: -8px;
    }
  }
  .react-custom-select-contacts__placeholder {
    grid-area: unset !important;
    color: rgba(0, 18, 43, 0.6);
  }

  .react-custom-select-contacts__input-container {
    width: 100%;
    top: 0;
  }
}

.send-preview-modal,
.select-recipient-sidebar,
.email-component-select-container {
  .react-custom-select-contacts__menu {
    width: calc(100% - 1px);
    position: absolute;
    top: 34px;
    height: calc(100% - 42px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0 6px 10px -5px hsla(0, 0%, 0%, 0.1);
    border-top: 1px solid rgba(212, 212, 212, 0.6);
    border-bottom: 1px solid rgba(212, 212, 212, 0.6);
    & > div {
      max-height: none;
      height: 100%;
    }
    @media screen and (min-width: 1600px) {
      width: calc(100% - 2px);
    }
  }
  .contacts-select-option-wrapper {
    & > span {
      width: 100%;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.send-preview-modal,
.email-component-select-container {
  .react-custom-select-contacts__menu {
    height: auto;
    overflow-y: auto;
    left: -8px;
    border: 1px solid #e0e0e0;
    &:has(div:nth-child(4)) {
      height: 210px;
    }
  }
}

.email-component-to-section {
  .react-custom-select-contacts__menu-list,
  .ValueContainerScrollbar {
    scrollbar-color: rgba(2, 35, 68, 0.2) !important;
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
      -webkit-appearance: none !important;
      width: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px !important;
      background: rgba(#99a4ae, 0.7) !important;
      &:hover {
        background-color: #99a4ae !important;
      }
    }
  }
  .react-custom-select-contacts__menu-list {
    padding-bottom: 0;
  }
}

.select-option-wrapper-group {
  padding-left: 0px !important;
  width: calc(100% - 30px);
  .option-group-count {
    margin-left: -5px;
  }
}

.email-component-section,
.send-preview-modal {
  .select-option-wrapper {
    display: flex;
    column-gap: 10px;
    padding-left: 35px;
    position: relative;
  }
  .react-custom-select-contacts__group-heading {
    font-size: 14px;
    color: rgba(0, 18, 43, 0.7);
    font-weight: 700;
  }
  .custom-checkbox-input {
    left: -14px;
    top: -3px;
    text-align: center;
    width: 48px !important;
    padding: 0 !important;
    height: 30.5px;
    position: absolute;
    display: flex;
    align-items: center;
    & > input {
      visibility: hidden;
      position: absolute;
    }
    & > input:checked + label {
      background: url('#{$cdn-url}stakeholders-tick.svg');
      background-position: 3px 3px;
      background-size: 10.5px;
      background-repeat: no-repeat;
      background-color: #ffffff;
    }
    & > label {
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      background: #ffffff;
      border: 1px solid #000;
      border-radius: 3px;
      position: relative;
      margin: 0 auto;
      cursor: pointer;
      &:hover::before {
        content: '';
        width: 34px;
        height: 34px;
        background: rgba(0, 28, 53, 0.06);
        position: absolute;
        top: -10px;
        left: -10px;
        border-radius: 50%;
      }
    }
  }
}

.tags-select-creatable,
.remove-tags-select {
  position: relative;
  .icon-search {
    left: 24px;
    top: 12px;
    position: absolute;
  }
  .react-custom-select-tags__option {
    padding-left: 24px;
  }
  .react-custom-select-tags__placeholder {
    color: rgba(0, 18, 43, 0.4);
  }
  .react-custom-select-tags__menu-list {
    max-height: 309px;
  }
  .icon-tag-value-label {
    opacity: 0.7 !important;
    margin-left: 9px;
    margin-right: 2px;
    transform: scaleX(-1);
    font-size: 15px;
    padding-top: 7px;
  }
  .icon-tag-create-option {
    margin-right: 2px;
    display: inline-block;
    transform: scaleX(-1) translateY(1px);
    font-size: 15px;
  }
  .react-select-create-tag-message {
    position: relative;
  }
  .react-custom-select-tags__multi-value__remove {
    padding: 0 6px;
    & > svg {
      transform: translateY(1px);
    }
  }
  .highlight-text {
    font-weight: 700;
    background-color: transparent !important;
    color: inherit;
  }
  .react-custom-select-tags__multi-value {
    border: 1px solid rgba(212, 212, 212);
    border-radius: 5px;
    .tags-select-multivalue-item {
      display: flex;
    }
  }
}

.remove-tags-select {
  .react-custom-select-tags__menu-list {
    max-height: 130px;
  }
}
.tags-select-creatable-custom-contact {
  .icon-search {
    left: 8px;
    top: 11px;
  }
}

.send-preview-modal {
  .react-custom-select-contacts__value-container {
    .w-100 {
      width: min-content !important;
    }
    overflow: visible;
  }
  .icon-search {
    display: none;
  }
  .contacts-select-multivalue-item {
    position: absolute;
    left: 4px;
    .react-custom-select-contacts__single-value {
      margin-left: 4px;
    }
  }
  .react-custom-select-contacts__placeholder {
    color: rgba($main-blue, 0.5) !important;
    opacity: 1;
  }
}

.contacts-select-actions-bar {
  background-color: #00122b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  & button {
    border-radius: 20px !important;
    background-color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
      background-color: #f5fbfd;
    }
    &.add-recipients-button {
      background-color: #0094cc;
      color: #fff;
      &:hover:not(&:disabled) {
        background-color: #33a9d6;
      }
      &.add-recipients-button-disabled {
        background-color: #f5f6f7 !important;
        border: 1px solid #dcdfe2 !important;
        color: rgba(0, 18, 43, 0.5) !important;
        pointer-events: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .email-component-select-container {
    .react-custom-select-contacts__menu {
      top: 100%;
    }
    .react-custom-select-contacts__multi-value__remove {
      color: unset;
      opacity: 0.8;
    }
    .contacts-select-multivalue-item {
      padding-left: 2px;
    }
    .react-custom-select-contacts__multi-value:first-of-type {
      margin-left: 0;
    }
    .react-custom-select-contacts__option {
      padding: 2px 12px;
    }
  }
}
