.settings-content-component {
  p {
    margin-bottom: 0;
  }
}

.badge-email-alert {
  //min-width: 142px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.no-email-alerts-badge {
  color: $main-red;
  &:hover {
    color: $main-red;
  }
}

.no-email-alerts-blocked {
  color: rgba($main-blue, 0.4);
  padding-left: 12px;
  &:hover {
    color: rgba($main-blue, 0.4);
  }
}

.topics-item-name_alert--text {
  font-size: 1.1em;
  color: $main-black;
  .main-light-text {
    font-size: 0.985em;
  }
  .dropdown-item-element {
    &:after {
      top: 10px;
    }
  }
}

.topic-item-name_alert-individual {
  background-position: 0px 6px;
}

/* .topics-item-digest-combining{
  background-image: url('#{$cdn-url}keywordlist-digest-topics.svg');
} */

.calendar-alert-name {
  background-image: url('#{$cdn-url}20211208-icon-upcoming-events-alerts.svg');
  background-position: 0px 2px;
}

.email-alert-button {
  a {
    margin-bottom: 16px;
    display: inline-block;
  }
  .add-keyword-list-button {
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    position: relative;
    &::before {
      content: '+';
      position: absolute;
      color: $light-blue;
      left: 15px;
      top: 0px;
      font-size: 21px;
      line-height: 1.075em;
    }
  }
}

.alerts-complete-information {
  width: calc(100% - 25px);
  strong {
    font-size: 0.975em;
    font-weight: 700;
  }
}

.alerts-list-container {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.digest-tooltip {
  max-width: 280px !important;
}

.email-alerts-page {
  .toggle-button {
    width: 32px;
    height: 17px;
    border-radius: 25px;
    margin-top: -2px;
    &::after {
      width: 13px;
      height: 13px;
    }
  }
  .toggle-button-active {
    .toggle-button {
      &::after {
        left: 16px;
      }
    }
  }
}

.email-subject-line {
  padding-top: 16px;
  border-top: 1px solid rgba($main-grey, 0.5);
  margin-top: 35px;
  padding-bottom: 24px;
  background-color: $background-grey;
}

.transition-element {
  transition: 0.2s ease-in;
}

.email-alert-information {
  padding: 42px 0px 5px 0px;
}

.email-alert-information--item {
  padding: 20px 20px 20px 28px;
}

.edit-email-alert-button {
  border-radius: 20px;
  padding: 2px 15px;
  background-color: $main-white !important;
  .icon-edit-pencil {
    margin-left: 8px;
    font-size: 11px;
  }
}

.send-password-email-button {
  border-radius: 20px;
  padding: 2px 15px;
  background-color: $main-white !important;
  border: 1px solid #9b171f;
  color: #9b171f;
  &:hover {
    color: #9b171f;
  }
}

.edit-email-alert-button[disabled] {
  background-color: rgba(0, 18, 43, 0.04) !important;
  color: rgba(0, 18, 43, 0.5) !important;
  border: 1px solid rgba(0, 18, 43, 0.1) !important;
  &:hover {
    background-color: rgba(0, 18, 43, 0.04) !important;
  }
}

.email-alerts-button-padding {
  padding: 2px 13px 2px 15px;
  min-width: 118px;
}

.email-alerts-paused-button {
  border-radius: 20px;
  border: 1px solid $light-blue;
  font-size: 14px;
  background: $main-white;
}

.switch-button {
  color: $light-blue;
  padding: 2px 10px;
  display: inline-block;
}

.switch-button-on {
  border-radius: 10px 0px 0px 10px;
}

.switch-button-off {
  border-radius: 0px 10px 10px 0px;
}

.paused-button-active {
  .switch-button-on {
    background: $light-blue;
    color: $main-white;
  }
}

.paused-button-inactive {
  .switch-button-off {
    background: $light-blue;
    color: white;
  }
}

.email-alerts-paused-button-disabled {
  border-color: #a0a2a9;
  .switch-button {
    color: #a0a2a9;
  }
  .switch-button-off {
    background-color: #a0a2a9;
    color: white;
  }
}

.email-alerts-badges {
  padding-left: 28px;
}

.adjust-flex-items {
  margin-top: -10px;
  a,
  p {
    margin-top: 10px;
  }
}

.email-alerts-user-info {
  .general-edit-button {
    border: none;
    display: none;
    background-image: url('#{$cdn-url}edit-pencil.svg');
    background-position: 6px 7px;
    background-size: 11px auto;
  }
}

.email-alert-information-box {
  background-color: $background-grey;
  border: 1px solid $main-grey;
  border-radius: 5px;
}

.email-alerts-main-box-container {
  padding-top: 2.5em;
  padding-bottom: 2.2em;
  -webkit-appearance: none !important;
  outline: none !important;
}

.send-diary-emails {
  padding: 0.9em 1em 0.5em 1em;
}
.send-diary-emails.alerts-form {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  .checkbox-item {
    gap: 10px;
    &::before {
      transform: scale(0.9);
      top: 4px;
    }
    label {
      padding-top: 1px;
      font-size: inherit;
      line-height: 1.5 !important;
      &::before {
        transform: scale(0.9);
        top: 4px;
      }
      &::after {
        transform: scale(0.9);
        top: 4px;
      }
    }
  }
}

.upcoming-event-main-container {
  margin-bottom: 1.85em;
}

.email-based-title {
  padding-left: 24px;
  position: relative;
  .icon-calendar {
    font-size: 16px;
  }
  .icon-topics-master,
  .icon-calendar,
  .icon-warning-circle-thick {
    position: absolute;
    left: 0;
    top: 3px;
  }
}

.retweets-alert-icon {
  padding-left: 20px;
}

.frequency-alert-icon {
  .icon-clock {
    margin-right: 9px;
    font-size: 13px;
  }
}

.topiclist-alert-icon {
  .icon-topics-thin {
    margin-right: 9px;
    font-size: 15px;
  }
}

.resources-alert-icon {
  .icon-description {
    margin-right: 9px;
    font-size: 10px;
    transform: translateY(-2px);
    display: inline-block;
  }
}

.retweets-alert-icon {
  background: url('#{$cdn-url}20220512-icon-social-media.svg') 0px 6px / 14px auto no-repeat;
}

.alert-list-text-item {
  opacity: 0.75;
  font-size: 16.4px;
  a {
    color: $main-blue;
  }
}

.alert-list-main-container {
  &:not(:last-child) {
    border-bottom: 1px solid rgba($main-grey, 0.5);
  }
}

@media screen and (min-width: 526px) {
  .email-alert-information--item {
    padding: 16px 28px;
    &:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid rgba($main-grey, 0.5);
    }
  }
}

@media screen and (min-width: 992px) {
  .edit-email-alert-button {
    &:hover {
      background-color: rgba($light-blue, 0.06) !important;
    }
  }

  .email-alert-information--item {
    padding: 16px 42px;
  }

  /* .topics-item-digest-combining{
    background-position: 7px 2px;
    min-height: 27px;
  } */

  .calendar-alert-name {
    background-position: 0px 4px;
  }
  .email-alert-button {
    .add-keyword-list-button {
      transition: 0.2s ease-in;
      &::before {
        transition: 0.2s ease-in;
      }
      &:hover {
        color: rgba($light-blue, 0.8) !important;
        &::before {
          color: rgba($light-blue, 0.8);
          transform: rotate(90deg);
        }
      }
    }
  }
  .email-alerts-user-info {
    padding-right: 19px;
    min-height: 25px;
    &:hover {
      .general-edit-button {
        display: inline-block;
      }
    }
  }
  .alerts-complete-information {
    padding-right: 0.5em;
  }

  .email-based-title {
    margin-top: 7px;
    margin-left: -24px;
  }

  .send-diary-emails {
    width: calc(100% + 16px);
    margin-left: -16px;
  }
}
