$image-size: 80px;
.influence-list-title {
  width: calc(100% + 14px);
  margin-left: -7px;
  padding: 0 7px 0 12px;
  z-index: 1;
}

.influence-initial-message {
  .message-content {
    padding: 28px 21px;
  }
  .extra-space {
    padding-top: 69px;
  }

  .roundend-action-button {
    font-size: map-get($paragraph-sizes, p2) + px;
    display: inline-block;
  }

  .feature-description {
    border-bottom: 1px solid rgba($main-grey, 0.5);
    margin-bottom: 0;
    color: rgba($main-blue, 0.5);
    padding: 14px 21px;
  }
}

.influence-list-image {
  width: $image-size;
}

.influence-content-image {
  img {
    width: 50px;
  }
}

.influence-white-image-container {
  width: $image-size;
  height: $image-size;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-blue;
  img {
    width: 70px;
  }
  &.influence-content-image {
    width: 50px;
    height: 50px;
  }
}

.influence-item_title {
  h3 {
    font-size: 0.95em;
  }
}

.influence-item_title_container {
  margin-bottom: 14px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba($main-grey, 0.6);
}

.influence-general-item-title {
  font-size: 0.95em;
  color: #022344;
  font-weight: 700;
}

.influence-list-titlebriefing-material {
  display: block !important;
  h3 {
    margin-bottom: 0.8em !important;
  }
}

.beta-feature {
  border: 1px solid rgba($main-grey, 0.5);
  border-radius: 4px;
  padding: 4px 9px;
  font-weight: 700;
  white-space: nowrap;
}

.influence-type-container {
  min-width: 150px;
}

.influence-type {
  padding-left: 32px;
  display: inline-block;
}

.influence-type-policyask {
  background: url('#{$cdn-url}20210825-icon-policy-asks-grey.svg') 10px 6px / 14px auto no-repeat;
  @extend .influence-type;
}

.influence-type-briefingmaterial {
  background: url('#{$cdn-url}20210825-icon-briefing-material-grey.svg') 10px 6px / 14px auto no-repeat;
  @extend .influence-type;
}

.influence-content-page {
  .simple-scrollbar {
    border: none;
  }
  .content-header-actions {
    .button-thumbs-down,
    .button-create-report,
    .item-actions-save-content {
      align-self: center;
      width: 40px;
      height: 40px;
      border: 1px solid $main-grey;
      background-color: $main-white;
      background-position: center 9px;
      margin-right: -2px;
      border-radius: 5px;
    }
    .item-actions-save-content.item-actions-remove-content {
      background-position: 12px 11px;
    }

    .button-thumbs-down,
    .button-create-report {
      margin-right: 0.75em !important;
    }
  }
}

.section-subtext-influence {
  white-space: normal;
}

.add-content-message {
  max-width: 500px;
}

.influence-list-item {
  .unseen-dot {
    top: 26px;
  }
  .unseen-dot-hover {
    top: 19px;
  }
}

.influence-type-text {
  min-width: 98px;
}

.main-link-item {
  margin-bottom: 11px;
  text-decoration: none;
  color: $main-blue;
  display: block;
  &:hover {
    text-decoration: none;
    color: $main-blue;
  }
}

.main-link-item-padding {
  padding: 18px;
}

@media screen and (min-width: 992px) {
  .main-add-content-page {
    .add-content-box {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
  }

  .add-content-message-container {
    margin-top: -60px;
  }

  .influence-item_title {
    padding-left: 30px;
    width: calc(100% - #{$image-size});
    color: #022344;
  }

  .influence-content-page {
    width: 100%;
    margin: 0 auto;
    padding-left: 4em !important;
    padding-right: 4em !important;
  }
  .influence-initial-message {
    .message-content {
      padding: 28px 62px;
    }
  }
  .influence-list-titlebriefing-material {
    h3 {
      margin-bottom: 0em !important;
    }
    display: flex !important;
  }

  .main-link-item-padding {
    padding: 25px 25px 25px 33px;
  }

  .influence-list-item {
    .item-share-feedback-container {
      width: 100%;
      margin-left: 0px;
    }
  }
}
