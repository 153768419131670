.chart-data-container {
  font-size: 0.95em;
  color: $main-blue;
  position: relative;
  table {
    position: relative;
    border-bottom: 1px solid #dbe3eb;
    margin-bottom: 0;
  }
  td,
  th {
    font-weight: normal;
    background-color: $main-white;
    top: 0;
    z-index: 2;
    padding: 0.75em 1.25em;
    &:not(:last-child) {
      border-right: 1px solid #dbe3eb;
    }
  }

  thead {
    th {
      top: 0;
      background-color: #f5f5f7;
      border-bottom: 1px solid #dbe3eb;
    }
  }
}

.chart-data-labels {
  border: 1px solid #dbe3eb;
  border-right: none;
  position: absolute;
  width: 280px;
  top: 0;
  left: 0;
  td {
    min-width: 280px;
  }
}

.chart-data-labels {
  td {
    min-width: 170px;
  }
}

.chart-data-labels-container {
  min-width: 150px;
  border-right: 1px solid $main-grey;
}

.chart-data-info-container {
  border-right: 1px solid #dbe3eb;
  scroll-behavior: smooth;
}

.chart-data-info {
  th,
  td {
    text-align: center;
  }
  td {
    border-left: none;
    cursor: pointer;
  }

  .label-total {
    background-color: #f5f5f7;
  }
  .selected-label-info {
    background-color: rgba($light-blue, 0.04);
  }
}

.selected-label-total {
  background-color: $main-blue !important;
  color: $main-white !important;
  a {
    color: $main-white !important;
  }
}

.chart-data-info-sentiment-changes {
  td {
    min-width: 130px;
  }
}

.cell-item-positive {
  color: #98c364;
}
.cell-item-negative {
  color: #e34242;
}
.cell-item-neutral {
  color: rgba($main-blue, 0.4);
}
.cell-item-mixed {
  color: #c3934c;
}

.number-contributions-item {
  padding-left: 20px;
  position: relative;
  &::before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.number-contributions-positive {
  &::before {
    background-color: #98c364;
  }
}
.number-contributions-negative {
  &::before {
    background-color: #e34242;
  }
}
.number-contributions-mixed {
  &::before {
    background: url('#{$cdn-url}20230629-mixed-contributions-circle.svg') center/contain no-repeat;
  }
}

.number-contributions-neutral {
  &::before {
    background-color: #d4d4d4;
  }
}

.stakeholder-number-contributions {
  padding-left: 0px;
  margin-right: 6px;
  &::before {
    display: inline-block;
    position: relative;
    top: -1px;
  }
}

.sort-table-item {
  padding-right: 2.3em !important;
  background: url('#{$cdn-url}20220911-sort-arrow-grey.svg') calc(100% - 16px) 17px/6px auto no-repeat;
}

.selected-label-total.sort-table-item {
  background-image: url('#{$cdn-url}202209111-sort-arrow-white.svg');
}

.stakeholders-table-empty {
  color: transparent;
}

.top-stakeholders-table {
  border-top: none;
  border-left: 1px solid $main-grey;
  border-right: 1px solid $main-grey;
  thead {
    th {
      text-align: center;
      vertical-align: middle;
      box-shadow:
        inset 0 1px 0 #dbe3eb,
        inset 0 -1px 0 #dbe3eb;
      border-bottom: none !important;
      border-top: none !important;
      background-image: url(https://cdn.policymogul.com/images/20220911-sort-arrow-grey.svg);
      cursor: pointer;
      background-size: 6px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 7px);
      background-position-y: center;
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none !important;
        }
      }
    }
  }
  td {
    &:not(:first-child) {
      cursor: auto;
    }
  }
}

.table-stakeholder-name {
  color: $main-blue;
}

.sentiment-dot {
  width: 10px;
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  border-radius: 50%;
  margin-top: 11px;
}
.sentiment-title-dot {
  margin-top: -3px;
  vertical-align: middle;
}
.twitter-sentiment-dot {
  margin-top: -6px;
}

.sentiment-bar-dot {
  width: 11px;
  height: 11px;
  min-width: 11px;
  max-width: 11px;
}

.sentiment-dot-positive {
  background-color: #98c364;
} //a3e456
.sentiment-dot-negative {
  background-color: #e34242;
}
.sentiment-dot-neutral {
  background-color: rgba($main-blue, 0.2);
  border: 1px solid rgba($main-blue, 0.3);
}
.sentiment-dot-mixed {
  background: url('#{$cdn-url}20230629-mixed-contributions-circle.svg') center/contain no-repeat;
}

@media screen and (min-width: 992px) {
  .chart-data-labels-container {
    min-width: 283px;
  }

  .chart-data-labels {
    td {
      min-width: 280px;
    }
  }

  .chart-data-info-sentiment-changes {
    td {
      min-width: 130px;
    }
  }
  .chart-data-info {
    td {
      &:hover {
        .cell-element {
          text-decoration: underline;
        }
      }
    }
  }

  .table-stakeholder-name {
    &:hover {
      text-decoration: underline;
    }
  }
}

.analytics-content-items-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  .ScrollbarsCustom-Content {
    position: relative;
  }
}

.analytics-content-items-container {
  border-radius: 0px;
}

.analytics-panel-buttons {
  .action-button {
    transition: all 0s ease 0s;
    &:hover {
      background-color: $light-blue;
    }
  }
  .analytics-button {
    transition: all 0s ease 0s;
    &:hover {
      background-color: $light-blue;
      color: $main-white;
    }
  }
}

.analytics-close-button {
  position: fixed;
}

.analytics-panel-title {
  position: sticky;
  top: 0;
  z-index: 1001;
}

.sentiment-box-filter-container {
  top: 43px;
}

@media screen and (min-width: 992px) {
  .analytics-content-items-panel {
    position: relative;
    width: 32%;
    padding-left: 1.5em;
    z-index: 1;
    .analytics-close-button {
      transition: 0.2s ease-in;
      &:hover {
        background-color: #4c515e;
      }
    }
  }

  .analytics-close-button {
    position: absolute;
  }
  .analytics-content-items-container {
    border-radius: 5px 5px 0px 0px;
  }
  .analytics-panel-title {
    position: relative;
    z-index: 1;
  }
}

@media screen and (min-width: 1310px) {
  .top-stakeholders-table {
    thead {
      th {
        position: sticky;
        top: 0px;
      }
    }
  }
}
