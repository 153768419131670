.email-domains-container-list {
  margin-top: 48px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 100%;
  .email-domains-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 19px 68px 18px 61px;
  }
  .email-domains-header-separator {
    background-color: #d4d4d4;
    height: 1px;
  }
  .email-domains-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #00122b;
    margin-bottom: 0;
  }
  .email-domains-body {
    padding: 21.5px 30px 21.5px 61px;
    width: 100%;
  }
  .email-addresses-body {
    @extend .email-domains-body;
  }
  .add-domain-btn {
    width: 148px;
    height: 35px;
    background: #0094cc;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    border: none;
    transition: all 200ms;
    &:hover {
      opacity: 0.85;
    }
  }
  .add-domain-btn.disabled {
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }
  .add-sender-btn {
    @extend .add-domain-btn;
    width: 238px;
  }
  .email-domains-container-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #00122b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    & > div {
      display: flex;
      align-items: center;
    }
    .domain-name {
      margin-left: 12px;
    }
    .domain-status {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #00122b;
    }
    cursor: pointer;
    position: relative;
    @media screen and (min-width: 768px) {
      &:hover {
        &:before {
          content: '';
          display: block;
          position: absolute;
          height: calc(100% + 25px);
          left: -62px;
          top: -12px;
          width: calc(100% + 92px);
          background-color: rgba(0, 148, 204, 0.04);
        }
      }
    }
  }

  .domain-record-container {
    margin-bottom: 25px;
  }
}
.domain-status {
  background: rgba(140, 49, 73, 0.9);
  border-radius: 5px;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  padding: 4.6px 14px 6.6px 14px;
  margin-left: 32px;
  height: 26px;
  display: block;
  width: fit-content;
}
.domain-status[data-value='Authenticated'],
.domain-status[data-value='Verified'] {
  background: rgba(32, 128, 30, 0.74);
}
.domain-status[data-value='Pending'] {
  background: #f2a805;
}
.domain-status-cell {
  @extend .domain-status;
  margin-left: 0;
}
.edit-cignature-button {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: rgba(0, 18, 43, 0.8);
  position: relative;
  border-radius: 8px;
  margin-top: -8px;
  display: inline-block;
  .icon-edit-bold {
    margin-right: 8px;
  }
  .icon-add {
    margin-right: 8px;
    margin-left: 1px;
  }
  &:has(.icon-add) {
    color: #0094cc;
  }
  &:hover {
    background-color: rgba(#0094cc, 0.04);
  }
}

.domain-status-column {
  margin-left: -15px;
  margin-right: 15px;
}
.domains-expand-btn-container {
  width: 36px;
  height: 36px;
  transition: all 200ms;
  border-radius: 50%;
  margin-left: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(#00122b, 0.08);
  }
  &[data-value='disabled'] {
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }
  .domains-expand-btn {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #00122b;
    line-height: 10px;
  }
}

.domain-record-container {
  padding: 9px 23px 26px 26px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.domains-table {
  border-collapse: collapse;
  top: -5px;
  background-color: #fff;
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  table-layout: fixed;
  display: grid;

  & thead,
  & tbody,
  & tr {
    display: contents;
  }
  & tr {
    & td {
      overflow: hidden;
      background-color: #fff;
    }
  }
  & thead {
    & th {
      border-bottom: 1px solid rgba(212, 212, 212, 0.5);
      padding-bottom: 9px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 400 !important;
      color: #00122b;
    }
  }
  & tbody {
    & td {
      padding-top: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 18, 43, 0.7);
      padding-right: 15px;
    }
    & .table-cellemailAddress-container {
      min-width: min-content;
    }
  }
}
.domains-table-domain {
  grid-template-columns: auto 1fr 1fr auto;
}
.domains-table-unauthenticated {
  grid-template-columns: auto auto 1fr 1fr auto;
}
.domains-table-addresses {
  grid-template-columns: 1fr 1fr 1fr auto auto;
}
.domains-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: 60px 0 37px;
  & p {
    margin: 0;
  }
}
.domains-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #00122b;
}
.domains-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: rgba(0, 18, 43, 0.45);
}
.domains-unauthenticated-container {
  background: #f4efed;
  border: 1px solid #d4d4d4;
  padding: 24px 103px 24px 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  & p {
    margin: 0;
  }
  .domains-expand-btn-unauthenticated-container {
    @extend .domains-expand-btn-container;
    position: absolute;
    right: 50px;
    top: 12px;
    width: 50px !important;
    height: 50px !important;
    .domains-expand-btn {
      margin-top: 3px !important;
      border-left: 12px solid transparent !important;
      border-right: 12px solid transparent !important;
      border-top: 14px solid #00122b !important;
      line-height: 10px !important;
    }
  }
  .domains-unauthenticated-header {
    display: flex;
    flex-direction: column;
    .unauthenticated-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #00122b;
    }
    .unauthenticated-subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: rgba(0, 18, 43, 0.7);
    }
  }
  .unauthenticated-section {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: $hover-blue-color;
    }
  }
  .share-instructions {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #0094cc;
    cursor: pointer;
    padding: 7px 60px;
    position: relative;
    transition: all 200ms;
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 12px;
      background-image: url('https://cdn.policymogul.com/images/20211125-email-alerts-icon-blue.svg');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 28px;
      top: 16.5px;
    }
    &:hover {
      background-color: rgba(0, 148, 204, 0.04);
    }
  }
  .unauthenticated-section-instruction {
    padding: 13px 29px;
  }
  .section-instruction-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #0094cc;
    display: flex;
    align-items: center;
    &[data-value='true'] {
      padding: 7px 26px;
    }
    .email-name {
      color: rgba(0, 18, 43, 0.7);
    }
    .back-to-emails-button {
      height: 22px;
      padding-left: 0;
      display: block;
      width: 18px;
      margin-right: 14px;
    }
  }
  .section-instruction-body {
    margin-top: 28px;
    .instruction-body-title {
      font-weight: 700;
      font-size: 17px;
      line-height: 26px;
      color: #00122b;
      margin-bottom: 10px;
    }
    .instruction-body-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: rgba(0, 18, 43, 0.7);
    }
    .instruction-body-description-list {
      @extend .instruction-body-description;
      margin-top: 20px;
    }
    .blue-text-span {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #0094cc;
      cursor: default !important;
    }
    .instruction-body-records {
      margin-top: 28px;
    }
  }
}

.add-domain-modal-container {
  max-width: 605px !important;
  max-height: 450px !important;
  & ul {
    margin: 0 !important;
  }
}
.add-domain-modal-body {
  display: flex;
  flex-direction: column;
  padding: 10px 80px 30px 50px;

  & li {
    padding: 0 !important;
  }
  .add-domain-modal-list-item {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #00122b;
    margin-top: 20px;
    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: rgba(0, 18, 43, 0.5);
      margin: 0 !important;
    }
  }
  .add-domain-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 24px;
    & .add-domain-modal-action-add {
      white-space: nowrap !important;
    }
    & .add-domain-modal-action-cancel {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #0094cc;
      border: none;
      background: none;
    }
  }
  .send-copy-container {
    padding-left: 34px;
    .send-copy-label {
      margin-bottom: 0;
      cursor: pointer;
    }
    .stakeholder-checkbox-input {
      left: 0;
      top: 2px;
    }
  }
}

.signature-text-editor {
  height: 400px;
  position: relative;
  .signature-buttons-section {
    position: absolute;
    right: 19px;
    bottom: 6px;
    z-index: 10;
  }
}

.email-addresses-default-select-container {
  display: flex;
  padding-left: 36px;
  margin-bottom: 30px;
  position: relative;
  align-items: center;
  & .default-select-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #00122b;
    margin-bottom: 0;
    .default-select-title-icon {
      background-image: url('https://cdn.policymogul.com/images/send-icon-dark.svg');
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 0 -2px;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 9px;
      transform: rotate(-5deg);
    }
  }
  & .email-component-custom-select {
    margin-left: 20px;
  }
}

.delete-domain-btn {
  background: url('#{$cdn-url}delete-icon-active.svg') center/contain no-repeat;
  opacity: 0;
  margin-left: 15px;
  color: #ffffff !important;
  transition: all 200ms;
  border: none;
  cursor: pointer !important;
  height: 20px;
  width: 14px;
}

.domain-name-container {
  &:hover {
    .delete-domain-btn {
      opacity: 1;
    }
  }
}
.table-cellemailAddress-container {
  @extend .domain-name-container;
  display: flex;
}
@media screen and (max-width: 480px) {
  .domains-expand-btn-container {
    margin-left: 10px;
  }
}

.email-component-custom-select {
  .domains-expand-btn-container {
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .domain-status-column {
    margin-left: 0;
    margin-right: 0;
  }
  .edit-cignature-button {
    margin-bottom: -8px;
  }
  .email-domains-body {
    padding: 20px !important;
    .delete-domain-btn {
      opacity: 1;
    }
  }
  .unauthenticated-section-instruction {
    padding: 32px 16px !important;
  }
  .domains-table {
    grid-template-columns: auto;
    thead {
      display: none;
    }
    tr {
      border: 1px solid #d4d4d4;
      display: grid;
      margin-top: 15px;
      border-radius: 5px;
      td {
        text-align: center;
        padding: 10px 15px;
        &:not(td:last-of-type) {
          border-bottom: 1px solid #d4d4d4;
        }
        .domain-status-cell {
          margin: 0 auto;
        }
        background-color: transparent;
      }
      td:first-of-type {
        font-weight: 700;
      }
    }
    .table-cellemailAddress-container {
      justify-content: center;
      div:first-of-type {
        margin-left: 29px;
      }
    }
  }

  .email-domains-header {
    flex-direction: column;
    gap: 15px;
    padding: 20px !important;
    .email-domains-title {
      text-align: center;
    }
  }
}
