.home-container {
  background: #fff;
  img {
    image-rendering: -webkit-optimize-contrast;
  }
  h2 {
    font-size: 1.45rem;
    font-weight: 700;
  }
}

.home-diagonal-line {
  &::before {
    content: '';
    position: absolute;
    background: #fff;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    bottom: -1px;
    height: 100px;
    width: 100%;
    left: 0;
    z-index: 1000;
  }
}

.home-main-information {
  background: $dark-blue;
  color: #fff;
  position: relative;
  padding-top: 1.5em;
  overflow: hidden;
  p,
  li {
    font-size: 1.1em;
  }
}

.home-main-container {
  position: relative;
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.home-searchbar-container,
.mobile-loggedout-searchbar {
  .simple-searchbar {
    width: 100%;
    max-width: 100%;
    display: flex;
  }
}

.mobile-loggedout-searchbar {
  width: 100%;
  padding-top: 10px;
}

.main-information-container {
  h1 {
    font-size: 3em;
    line-height: 1.175em;
    font-weight: 700;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Work Sans';
    font-size: 1.2em;
    line-height: 1.43em;
    margin-bottom: 1.6em;
    max-width: 560px;
    font-weight: 300;
    margin-right: auto;
    margin-left: auto;
  }
}

.home-trybanner {
  background: $hover-blue;
  color: #fff;
  button {
    background: $dark-mustard;
    color: #000;
    font-size: 1.125rem;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    font-weight: 700;
  }
}

.blockquote-image {
  width: 55px;
}

.home-blockquote-width {
  max-width: 1100px;
}

.home-questions-icon {
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  height: 35px;
}

.home-footer {
  background: $dark-blue;
  color: #fff;
  font-size: 0.9em;
  line-height: 1.75em;
  a {
    color: #fff;
  }
}
.footer-copyright {
  border-top: 1px solid $dark-mustard;
}

.footer-socials {
  a {
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    text-indent: -1000px;
    overflow: hidden;
  }
}

.footer__socials--fb {
  background-image: url('#{$cdn-url}facebook.svg');
  &:hover {
    background-image: url('#{$cdn-url}facebook-y.svg');
  }
}

.footer__socials--tw {
  background-image: url('#{$cdn-url}twitter.svg');
  &:hover {
    background-image: url('#{$cdn-url}twitter-y.svg');
  }
}
.footer__socials--li {
  background-image: url('#{$cdn-url}linkedin.svg');
  &:hover {
    background-image: url('#{$cdn-url}linkedin-y.svg');
  }
}

.home-video-image {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 98%;
    height: 100%;
    background-color: $main-yellow;
    top: 0;
    left: 0;
    border-radius: 20px;
    top: -2%;
    left: 2%;
  }
  img {
    position: relative;
    z-index: 1;
    width: 98%;
  }
}

.home-swiper-navigation-button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: calc(50% - 22px);
  background-color: $main-yellow;
  color: $main-white;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .icon-left-triangle-filled,
  .icon-right-triangle-filled {
    font-size: 8px;
  }
}

.home-swiper-navigation-button-prev {
  left: 0px;
}

.home-swiper-navigation-button-next {
  right: 0px;
}

.left-panel,
.right-panel {
  img {
    width: 117px;
    height: 117px;
    opacity: 0;
    transform: translateX(-75px);
  }
}

.left-panel {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 2em);
  margin-left: -1em;
  img {
    &:nth-child(even) {
      margin-top: 60px;
    }
  }
}

.right-panel,
.left-panel {
  display: none;
}

.right-panel {
  width: 310px;
  img {
    transform: translateX(75px);
  }
}

.panel-active {
  img {
    opacity: 1;
    transform: translateX(0px);
  }
}

.left-panel-1 {
  left: 140px;
  top: 0px;
  z-index: 6;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1),
    transform 800ms;
}
.left-panel-2 {
  left: 0px;
  top: 117px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 120ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 120ms;
}

.left-panel-3 {
  left: 140px;
  top: 192px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 240ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 240ms;
}
.left-panel-4 {
  left: 0px;
  top: 317px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 360ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 360ms;
}
.left-panel-5 {
  left: 140px;
  top: 401px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 480ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 480ms;
}

.right-panel-1 {
  left: 2px;
  top: 0px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 60ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 60ms;
}

.right-panel-2 {
  left: 149px;
  top: 117px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 180ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 180ms;
}
.right-panel-3 {
  left: 0px;
  top: 192px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 300ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 300ms;
}
.right-panel-4 {
  left: 147px;
  top: 316px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 420ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 420ms;
}
.right-panel-5 {
  left: 0px;
  top: 400px;
  transition:
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 540ms,
    transform 800ms cubic-bezier(0.7, 0, 0.3, 1) 540ms;
}

/*===============================================
ANIMATION OF ELEMENTS
================================================*/

.home-spinner {
  position: relative;
  top: 0.2em;
  transition: flex 0.5s;
  transition:
    flex 0.5s,
    -ms-flex 0.5s;
}

.spinner-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  height: 1.1em;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.home-spinner .spinner-container .spinner-item {
  display: block;
  position: relative;
  transform: translateY(100%);
  transition-duration: 0.5s;
  transition-property: transform;
  white-space: nowrap;
  top: -0.2em;
}
.home-spinner[data-spin-to='0'] .spinner-container .spinner-item {
  transform: translateY(0%);
}
.home-spinner .spinner-item:nth-child(2) {
  color: $light-blue;
}
.home-spinner[data-spin-to='1'] .spinner-container .spinner-item {
  transform: translateY(-100%);
}
.home-spinner .spinner-item:nth-child(3) {
  color: $main-yellow;
}
.home-spinner[data-spin-to='2'] .spinner-container .spinner-item {
  transform: translateY(-200%);
}
.home-spinner .spinner-item:nth-child(4) {
  color: #26bef5;
}
.home-spinner[data-spin-to='3'] .spinner-container .spinner-item {
  transform: translateY(-300%);
}
.home-spinner .spinner-item:nth-child(5) {
  color: #f5bd43; //#f3c9b9
}
.home-spinner[data-spin-to='4'] .spinner-container .spinner-item {
  transform: translateY(-400%);
}
.home-spinner .spinner-item:nth-child(6) {
  color: #fff;
}
.home-spinner[data-spin-to='5'] .spinner-container .spinner-item {
  transform: translateY(-500%);
}
.home-spinner[data-spin-to='6'] .spinner-container .spinner-item {
  transform: translateY(-600%);
}

@keyframes mainBackground {
  0% {
    background-color: #001129;
  }
  14.2% {
    background-color: #121d30;
  }
  28.5% {
    background-color: #121d30;
  }
  42.8% {
    background-color: #f4bc40;
  }
  57.1% {
    background-color: #f4bc40;
  }
  71.4% {
    background-color: #111528;
  }
  85.7% {
    background-color: #111527;
  }
  100% {
    background-color: #001129;
  }
}

@keyframes secondBackground {
  0% {
    background-color: #001129;
  }
  14.2% {
    background-color: #101426;
  }
  28.5% {
    background-color: #f0a702;
  }
  42.8% {
    background-color: #f0a702;
  }
  57.1% {
    background-color: #121d30;
  }
  71.4% {
    background-color: #121c30;
  }
  85.7% {
    background-color: #f1a605;
  }
  100% {
    background-color: #001129;
  }
}

@keyframes topNavLinks {
  0% {
    color: #fff;
  }
  14.2% {
    color: #fff;
  }
  28.5% {
    color: #fff;
  }
  42.8% {
    color: #001129;
  }
  57.1% {
    color: #001129;
  }
  71.4% {
    color: #fff;
  }
  85.7% {
    color: #fff;
  }
  100% {
    color: #fff;
  }
}

.home-animation-square {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.home-animation-square-element {
  width: 100%;
  height: 100%;
  position: relative;
  background: #001129;
  border-radius: 10px;
  &::before {
    content: '';
    background-color: #001129;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 65% 0, 32% 99%, 0% 100%);
  }
}

.home-animation-colors {
  .home-animation-square-element {
    animation: secondBackground 2s steps(1) 1s forwards;
    &::before {
      animation: mainBackground 2s steps(1) 1s forwards;
    }
  }
  .nav-pages a {
    animation: topNavLinks 2s steps(1) 1s forwards;
  }
}

.logo-header-scrolled {
  width: 125px;
  height: 25px;
  position: relative;
  overflow: hidden;
}

.logo-part {
  position: absolute;
  transition: 0.3s ease-in-out;
  transform: translateX(0px);
}

.logo-part-1 {
  width: 13px;
  height: 18px;
  left: 0px;
  top: 1px;
}

.logo-part-2 {
  left: 10px;
  top: 0px;
  width: 55px;
  height: 25px;
}

.logo-part-3 {
  left: 61px;
  top: 1px;
  width: 16px;
  height: 18px;
}

.logo-part-christmas {
  height: 41px;
  .logo-part-1,
  .logo-part-4 {
    top: 14px;
  }
  .logo-part-2 {
    top: 13px;
  }

  .logo-part-3 {
    width: 29px;
    height: 31px;
  }
}

.logo-part-4 {
  left: 76px;
  top: 0px;
  width: 49px;
  height: 25px;
}

.logo-header-favicon {
  &.resized {
    width: 28px;
  }
  &.logo-part-christmas {
    width: 41px;
  }
  .logo-part-2,
  .logo-part-4 {
    opacity: 0;
    transform: translateX(-60px);
  }
  .logo-part-3 {
    transform: translateX(-49px);
  }
}

.logo-header-scrolled-container {
  padding-top: 5px;
}

.home-searchbar-container {
  border: 3px solid $main-blue;
}

.white-top-header-bar {
  padding-top: 8px;
  padding-bottom: 8px;
}

.body-home-page {
  .action-button,
  .hovered-button {
    &:hover {
      background-color: $main-yellow !important;
      color: $main-white;
    }
    &:disabled {
      color: rgba($main-blue, 0.5) !important;
      background-color: rgba($main-blue, 0.04) !important;
    }
  }
}

.learn-more-button {
  &:hover {
    background-color: $light-blue;
  }
}

.home-answer-padding {
  padding-left: 50px;
  p {
    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }
}

.home-policy-mogul-action-section {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: $main-blue;
  }
  .action-title {
    line-height: 1.2;
  }
}

.how-it-comes-together {
  .action-button,
  .hovered-button {
    &:hover {
      background-color: $main-blue !important;
      color: $main-white !important;
    }
  }
}

.home-hero-buttons {
  transition:
    opacity 1s ease-in-out,
    transform 0.4s ease-in-out;
  gap: 20px;
  overflow: hidden;
  .home-hero-button {
    padding-top: 0.85em !important;
    padding-bottom: 0.85em !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
  }
}

.home-logos-transition {
  transition: opacity 0.4s ease-in;
}

.home-video-container {
  background: $salmon;
}

.home-video-player {
  &:hover {
    .home-video-player-thumbnail {
      &::before {
        opacity: 1;
      }
    }
    .home-video-player-button {
      transform: scale(0.95);
    }
  }
}

.home-video-player-thumbnail {
  &:before {
    content: '';
    width: 98%;
    height: 100%;
    background-color: rgba($main-blue, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    z-index: 2;
    opacity: 0;
    transition: 0.2s ease-in;
  }
  img {
    position: relative;
    z-index: 1;
  }
}

.wordpress-thumbnail {
  &::before {
    width: 100%;
  }
}

.home-video-player-button {
  position: absolute;
  background-color: $main-white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in;
  img {
    width: 48px;
    height: 48px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 600px) {
  .main-information-container {
    h1 {
      font-size: 4.5em;
    }
  }
}

@media screen and (min-width: 768px) {
  .home-container {
    h2 {
      font-size: 1.65rem;
    }
  }

  .blockquote-container {
    padding-left: 60px;
    &::before {
      width: 65px;
      height: 50px;
      top: 0;
      left: -25px;
    }
    &::after {
      width: 65px;
      height: 50px;
      right: 20px;
    }
    blockquote {
      font-size: 1.85em;
    }
  }
}

@media screen and (min-width: 992px) {
  .home-hero-buttons {
    opacity: 0;
    transform: translateY(100%);
    &.finished {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  .home-swiper-navigation-button {
    width: 44px;
    height: 44px;
    transition: 0.2s ease-in;

    .icon-left-triangle-filled,
    .icon-right-triangle-filled {
      font-size: 1em;
    }
    &:hover {
      background-color: $light-blue;
    }
  }

  .home-swiper-navigation-button-prev {
    left: -22px;
  }

  .home-swiper-navigation-button-next {
    right: -22px;
  }

  .home-video-container {
    width: 45%;
    margin-left: 0;
    background-color: transparent;
    .speaker-button {
      color: $main-white;
    }
  }

  .blockquote-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .home-main-information {
    padding-top: 2.5em;
  }

  .main-information-container {
    h1 {
      font-size: 5.25em;
      margin-bottom: 32px;
    }
  }

  .left-panel,
  .right-panel {
    width: 257px;
    height: 518px;
    position: relative;
    img {
      position: absolute;
    }
  }
  .left-panel {
    img {
      &:nth-child(even) {
        margin-top: 0px;
      }
    }
  }

  .right-panel,
  .left-panel {
    display: block;
  }

  .home-hero-button {
    width: 50%;
  }
  .home-question-component {
    transition: 0.2s ease-in;
    width: calc(100% + 3em);
    margin-left: -1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    .link-text {
      color: $main-white;
    }
    &:hover {
      background-color: rgba($main-white, 1);
      color: $main-blue;
      .home-questions-icon {
        background-color: $main-blue;
      }
      .icon-search {
        color: $main-white;
      }
      .link-text {
        color: $light-blue;
      }
    }
  }
  .product-question-component {
    &:hover {
      background-color: rgba($light-blue, 0.04);
    }
  }
}

@media screen and (min-width: 1080px) {
  .left-panel,
  .right-panel {
    width: 301px;
  }
  .left-panel-1 {
    left: 184px;
  }

  .left-panel-3 {
    left: 184px;
  }

  .left-panel-5 {
    left: 184px;
  }

  .right-panel-2 {
    left: 193px;
  }

  .right-panel-4 {
    left: 191px;
  }
}

@media screen and (min-width: 1280px) {
  .body-home-page {
    .main-content-wrapper {
      max-width: 1300px;
    }
  }
}

@media screen and (min-width: 1500px) {
  .body-home-page {
    .main-content-wrapper {
      max-width: 1440px;
    }
  }

  .main-information-container {
    h1 {
      font-size: 6em;
    }
  }
}
