.crm-table-item {
  min-height: 60px;
  &:hover td {
    background-color: #fafafa;
  }
  & td {
    border-right: 1px solid rgba(212, 212, 212, 0.5);
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    padding: 17px 23px 14px 20px;
    color: #00122b;
    white-space: nowrap;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  & .crm-table-item-checkbox {
    text-align: center;
    width: 48px !important;
    padding: 0 !important;
    height: 30.5px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    & > input {
      visibility: hidden;
      position: absolute;
    }
    & > input:checked + label {
      background: url('#{$cdn-url}stakeholders-tick.svg');
      background-position: 3px 3px;
      background-size: 10.5px;
      background-repeat: no-repeat;
    }
    & > label {
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      background: #ffffff;
      border: 1px solid #000;
      border-radius: 3px;
      position: relative;
      margin: 0 auto;
      cursor: pointer;
      &:hover::before {
        content: '';
        width: 34px;
        height: 34px;
        background: rgba(0, 28, 53, 0.06);
        position: absolute;
        top: -10px;
        left: -10px;
        border-radius: 50%;
      }
    }
  }
  & .crm-table-item-checkbox-selected-all {
    @extend .crm-table-item-checkbox;
    &:hover label {
      cursor: default;
      &::before {
        display: none;
      }
    }
    & > input:checked + label {
      opacity: 0.6;
      background-color: rgba(0, 28, 53, 0.13) !important;
    }
  }
  & .crm-table-item-name {
    position: relative;

    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      & > a {
        text-decoration: none;
        overflow: hidden;
      }
    }
    & .crm-table-img {
      flex-basis: 30px;
      min-width: 30px;
      height: 30px;
    }
  }
  & .crm-table-item-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #0094cc;
    cursor: pointer !important;
    &:hover {
      color: #0094cc;
      text-decoration: underline;
    }
  }
  & .crm-table-item-tags {
    position: relative;
    display: block;
    .add-tag-cell-container {
      margin-top: -17px;
      position: absolute;
      left: -41px;
      transition: all 150ms;
      transition-delay: 100ms;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      height: 100%;
    }
    .icon-add {
      color: #0094cc;
      font-size: 26px;
      cursor: pointer;
      z-index: 2;
      &:hover {
        opacity: 0.85;
      }
    }
    &:hover {
      .add-tag-cell-container {
        left: -5px;
      }
      .allow-add-tag.tag-cell-container {
        & .tagsContainer {
          margin-left: 15px;
          padding-right: 7px;
        }
      }
    }
    & > div {
      height: 100%;
    }
    .tag-cell-container {
      display: flex;

      & .tagsContainer {
        margin-left: -12px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: calc(100% + 31px);
        margin-top: -17px;
        padding-top: 17px;
        transition: all 100ms;
        transition-delay: 100ms;
      }
    }
    & .tag {
      &:first-child {
        margin-left: 7px;
      }
      position: relative;
      transition: all 200ms;
      padding: 7px 2px;
      border-radius: 5px;
      cursor: default;
      box-sizing: border-box;
      &:hover {
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 4px);
          left: -2px;
          height: 110%;
          top: 0px;
          border-radius: 5px;
          background-color: rgba(0, 18, 43, 0.05);
        }
      }
      &:hover {
        .tag-remove {
          visibility: visible;
        }
      }
      .tag-remove {
        background: url('#{$cdn-url}remove-button-dark.svg');
        background-position: 0 0;
        background-size: 13px;
        background-repeat: no-repeat;
        cursor: pointer;
        position: absolute;
        visibility: hidden;
        display: block;
        height: 13px;
        width: 13px;
        left: -7px;
        top: -2px;
      }
    }
  }
  .cellText {
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .cellTextTooltip {
    cursor: pointer !important;
    @extend .cellText;
  }
}
.crm-table-item-newly-added {
  td {
    animation-name: newlyAdded;
    animation-duration: 3s;
  }
}

@keyframes newlyAdded {
  from {
    background-color: rgba(#0094cc, 0.1);
  }
  to {
    background-color: #ffffff;
  }
}

.crm-table-item-state {
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  display: block;
  width: 69px;
  min-width: 69px;
  height: 26px;
  text-align: center;
}

.email-state-sent {
  height: 38px;
  padding-top: 6px;
  border-radius: 20px;
  font-size: 15px;
  line-height: 18px;
  padding-top: 10px;
  border: 1px solid #d4d4d4 !important;
}

.email-state-draft {
  height: 38px;
  padding-top: 6px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 18px;
  padding-top: 9px;
}

.crm-table-item-draft {
  background: rgba(0, 18, 43, 0.1);
  border: 1px solid rgba(0, 18, 43, 0.15);
  color: rgba(0, 18, 43, 0.8);
  &:hover {
    background: rgba(0, 18, 43, 0.08);
  }
}
.crm-table-item-partiallysent {
  width: 95px !important;
  background: #f2a805;
  border: 1px solid rgba(0, 18, 43, 0.15);
  color: rgba(0, 18, 43, 0.8);
  &:hover {
    background: rgba(#f2a805, 0.8);
  }
}

.green-pill-item {
  background: rgba(33, 159, 122, 0.2);
  border: 1px solid rgba(33, 159, 122, 0.3);
  color: #022f21;
}

.crm-table-item-sent {
  @extend .green-pill-item;
  &:hover {
    background: rgba(33, 159, 122, 0.17);
  }
}

.sent-state-color {
  background: rgba(52, 199, 157, 0.06);
  color: #212529;
}

.sent-state-badge {
  @extend .sent-state-color;
  padding-top: 9px;
}

.crm-table-item-discarded {
  background: rgba(183, 2, 2, 0.1);
  border: 1px solid rgba(183, 2, 2, 0.3);
  color: rgb(183, 2, 2);
  &:hover {
    background: rgba(183, 2, 2, 0.08);
  }
}

.crm-table-item-text-decor-none {
  &:hover {
    text-decoration: none;
  }
}

.crm-email-state-link-disabled {
  pointer-events: none !important;
}

.crm-table-item-no-status {
  border: 1px solid rgba(0, 18, 43, 0.15);
  color: #022f21;
}

.crm-table-item-emails {
  &:hover td {
    &:first-child {
      user-select: none;
      color: #0094cc;
      cursor: pointer;
    }
  }
}

.crm-table-item-champion-status {
  padding: 17px 20px 14px 20px !important;
}

$status: (
  'no-status': $main-white,
  'promoter': #d9ead3,
  'detractor': #fbdfdf,
  'neutral': #ebebeb,
);

.list-action-button-no-status {
  color: rgba($main-blue, 0.5);
}

@each $status, $color in $status {
  .status-#{$status} {
    background-color: rgba($color, 0.5);
  }
  @if $status != 'no-status' {
    .list-action-button-#{$status} {
      &:hover {
        background-color: rgba($color, 0.35);
      }
    }
  }
}

.table-item-alignment-adjustment {
  td {
    display: flex;
    align-items: center;
  }
}
