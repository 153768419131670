.product-question-component {
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  &.open {
    .dropdown-item-element {
      &::after {
        border-top: none;
        border-bottom: 6px solid currentColor;
      }
    }
  }
}

.collapse-content {
  padding-top: 15px;
}

.product-page-hero {
  padding: 50px 0px 70px 0px;
  position: relative;
  .main-content-wrapper {
    position: relative;
    z-index: 1;
  }
}

.product-page-title {
  font-size: 2em;
}

.product-page-hero-text {
  max-width: 540px;
  /* .product-hero-subtext {
    max-width: 536px;
  } */
}

.product-hero-buttons {
  gap: 10px;
  a {
    width: 50%;
  }
}

.pages-general-hero {
  background: #00122b !important;
  position: relative;
  padding: 12.5vh 0;
  z-index: 10;
  &:before {
    content: '';
    position: absolute;
    background: #fff;
    -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    bottom: -1px;
    height: 100px;
    width: 100%;
    left: 0;
    z-index: 1000;
  }
  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 2.5em;
    line-height: 1.175em;
  }
  p {
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Work Sans';
    font-size: 1.19em !important;
    line-height: 1.43em;
    font-weight: 300;
  }
  .competitor-trial-main-button {
    font-size: 1.15em;
    &:hover {
      background-color: rgb(4, 171, 232);
    }
  }
}

@media screen and (min-width: 992px) {
  .product-page-title {
    font-size: 2.8em;
  }
  .product-page-hero {
    padding: 100px 0px 120px 0px;
    min-height: 642px;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $main-yellow;
      top: 0;
      right: 0;
      clip-path: polygon(80% 0, 100% 0, 100% 100%, 65% 100%);
    }
  }
  .product-video-container {
    width: 50%;
    margin-top: 1em;
  }
}

.product-swiper-pagination {
  position: relative !important;
  padding-right: 10px;

  .swiper-pagination-bullet {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 7px;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    background-color: $main-blue !important;
  }
}

.product-carousel-next {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-indent: -10000px;
  overflow: hidden;
  background-color: $main-blue;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 11px;
    width: 0px;
    height: 0px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 9px solid white;
  }
}

/* .gslide-description {
  .gdesc-inner {
    position: absolute;
    z-index: 1000;
    bottom: 0px;
    left: -1px;
    width: calc(100% + 1px);
    padding: 1em;
    background: rgba($main-blue, 0.6);
    h4 {
      margin: 0;
      color: $main-white;
    }
  }
} */

.glightbox-clean {
  .gslide-description {
    background: rgba($main-blue, 0.6);
    position: absolute;
    width: calc(100% + 2px);
    bottom: 0;
    margin: auto;
    left: -1px;
    right: 0;
    h4 {
      margin: 0;
      color: $main-white;
      font-family: 'Lato';
    }
  }
}
