.bg-grey {
  background-color: $background-grey;
}

.bg-grey-50 {
  background-color: rgba($background-grey, 0.5);
}

.bg-main-grey-20 {
  background-color: rgba($main-grey, 0.2);
}

.bg-main-white {
  background-color: $main-white;
}

.bg-main-white-50 {
  background-color: rgba($main-white, 0.5);
}

.bg-salmon {
  background-color: $salmon;
}

.bg-main-blue {
  background-color: $main-blue;
}

.bg-main-blue-80 {
  background-color: rgba($main-blue, 0.8);
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-light-blue-hovered {
  background-color: $hovered-blue;
}

.bg-reports-blue {
  background-color: $reports-blue;
}

.bg-blue-hovered {
  background-color: rgba($light-blue, 0.04);
}

.bg-salmon {
  background-color: $salmon;
}

.bg-yellow {
  background-color: $main-yellow;
}

.bg-main-white {
  background-color: $main-white;
}

.bg-light-red-40 {
  background-color: rgba(#fbdedf, 0.4);
}

.bg-light-red {
  background-color: #fbdedf;
}

.bg-green {
  background-color: #08aa7b;
}

.bg-main-danger {
  background: rgba(140, 49, 73, 0.9);
}

.text-main-danger {
  color: rgba(140, 49, 73, 0.9);
}

.text-red {
  color: $main-red;
}

.text-white {
  color: $main-white;
}

.text-main-blue {
  color: $main-blue;
}

.text-main-blue-40 {
  color: rgba($main-blue, 0.5);
}

.link-main-blue {
  &:hover {
    color: $main-blue;
  }
}

.text-light-blue {
  color: $light-blue;
}

.text-grey {
  color: $main-grey;
}

.text-red-70 {
  color: rgba($main-red, 0.7) !important;
}

.text-red-dark {
  color: #8f072b;
}

.text-red-warning {
  color: #b40a37;
}

.text-green-create {
  color: #007226;
}

.text-yellow {
  color: $main-yellow;
}

@for $i from 1 through 10 {
  $val: calc($i / 10);
  .opacity-#{$i} {
    opacity: $val;
  }
}

.text-grey-description {
  color: $description-grey;
}

.text-transparent {
  color: transparent !important;
}

.general-transition {
  transition: all 0.2s ease-in;
}
