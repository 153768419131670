.main-input {
  border: 1px solid $main-grey;
  width: 100%;
  color: $main-blue;
  outline: none !important;
  transition: 0.2s ease-in;
  &:focus,
  &:focus-visible {
    box-shadow: none;
    border: 1px solid $main-blue !important;
    color: $main-blue;
    outline: none;
  }
  &::placeholder {
    color: rgba($main-blue, 0.4);
  }
}

.general-dropzone {
  height: 113px;
  cursor: pointer;
  color: rgba($main-blue, 0.5);
  border-color: rgba($main-blue, 0.5);
  &:hover {
    background: rgba($light-blue, 0.04);
  }
}

.active-dropzone {
  border: 1px solid rgba($main-grey, 0.9);
  color: rgba($main-blue, 0.7);
  background: rgba($light-blue, 0.04);
}

.attachment-name {
  padding: 8px 20px 8px 18px;
}

.tabs-component {
  border-top: 1px solid rgba($main-grey, 0.5);
  border-bottom: 1px solid rgba($main-grey, 0.5);
  background-color: rgba($light-blue, 0.03);
  button {
    padding: 12px 0;
    &:not(:last-child) {
      border-right: 1px solid rgba($main-grey, 0.5);
    }
  }
}

.active-tab {
  color: $light-blue;
  font-weight: 700;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $light-blue;
  }
}

.input-search {
  background: url('#{$cdn-url}icon_header_search_grey.svg') 17px 11px /15px 15px no-repeat;
  padding-left: 42px;
  &::placeholder {
    color: rgba($main-blue, 0.45);
  }
}

.input-empty {
  border: none;
  outline: none;
  &:focus {
    box-shadow: none;
  }
  &::placeholder {
    color: #bababa !important;
  }
}

.input-line {
  @extend .input-empty;
  border-bottom: 1px solid $main-grey;
}
