.emails-onboarding {
  padding-left: 53px;
  padding-right: 65px;
  padding-bottom: 82px;
  overflow-x: hidden;
  .domains-unauthenticated-container {
    padding-bottom: 40px;
    transition: background 300ms;
  }
  .no-domains-action-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .no-domains-action {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: rgba(0, 18, 43, 0.7);
      cursor: pointer;
      padding: 7px 60px 7px 16px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 22px;
        background-image: url('#{$cdn-url}back-to-emails-arrow-gray.svg');
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.7;
        position: absolute;
        right: 28px;
        top: calc(50% - 12px);
        transform: rotate(180deg);
        transition: all 200ms;
      }
      &[data-value='expanded'] {
        &:before {
          transform: rotate(270deg) translateY(3px);
        }
      }
    }
    .no-domains-action-agency {
      color: $light-blue;
      &:before {
        background-image: url('#{$cdn-url}back-to-emails-arrow.svg');
      }
    }
    .no-domains-action-active {
      @extend .no-domains-action;
      color: #0094cc;
      max-width: calc(100% - 28px);
      &:before {
        display: none;
      }
    }
    .default-add-domain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 28px;
    }
    .back-to-emails-button {
      height: 22px;
      padding-left: 0;
      display: block;
      min-width: 18px;
      margin-right: 14px;
      transform: rotate(180deg);
      background-size: contain;
      margin-right: 0;
      margin-top: 5px;
      border: none;
      background-color: transparent;
    }
    .no-domains-add-domain-form {
      padding: 0 60px;
      .add-domain-modal-body {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .domains-unauthenticated-container {
    padding: 20px 14px !important;
  }
  .emails-onboarding {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;

    .no-domains-action:not(.no-domains-action-active) {
      padding: 10px 50px 10px 10px !important;
    }
    .no-domains-action-active {
      max-width: calc(100% - 17px);
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 10px !important;
    }
    .no-domains-action-container {
      .no-domains-add-domain-form {
        padding: 0 10px;
        .add-domain-modal-actions {
          margin-top: 24px;
        }
      }
    }
  }
}
