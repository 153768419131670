.table-pagination-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  .table-pagination-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #d4d4d4;
    color: #00122b;
    cursor: pointer;
    &:hover {
      background-color: rgba(212, 212, 212, 0.2);
    }
  }
  .table-pagination-item-active {
    background-color: rgba(212, 212, 212, 0.5) !important;
  }
}
.table-pagination-header-container {
  @extend .crm-email-header-container;
  margin-top: 14px !important;
}
