.general-dropdown-filter-options {
  min-width: 310px;
  max-width: 310px;
  max-height: 486px;
  .common-outside-filter {
    .checkbox-item {
      label {
        margin-top: -1px;
      }
    }
  }
}

.general-dropdown-selected-button {
  max-width: 500px;
  &:hover {
    border-color: #223954 !important;
    background-color: #223954;
  }
}
