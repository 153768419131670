/*====================================================
PAGE CONFERENCE
=====================================================*/
.conference-first-banner {
  p {
    color: rgb(0, 18, 43);
  }
}

.conference-first-banner {
  em {
    color: rgb(0, 18, 43);
    font-weight: bold;
  }
}

.conference-first-banner {
  li {
    &::before {
      background: url('https://cdn.policymogul.com/images/20220928-blue-tickbox.svg');
    }
  }
}

.conference-list {
  list-style: none;
  counter-reset: item;
  max-width: 800px;
  margin: 0 auto;
  li {
    counter-increment: item;
    margin-bottom: 5px;
    margin-bottom: 24px;
    &:before {
      margin-right: 10px;
      content: counter(item);
      background: #fff;
      border-radius: 50%;
      color: rgb(0, 18, 43);
      width: 28px;
      height: 28px;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      padding-top: 1px;
    }
  }
}

.form-description-label {
  margin-bottom: 0px;
}

.conference-form {
  .col-lg {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.conference-form {
  .form-group {
    margin-bottom: 0;
  }
  label {
    font-weight: 700;
  }
  input,
  textarea {
    background-color: rgba(212, 212, 212, 0.05);
  }
  .form-check-label {
    font-weight: 400;
    font-size: 0.925em;
    position: relative;
    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #00122b;
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      margin-top: -3px;
      transition: 0.2s ease-in;
    }
    &:hover {
      &:before {
        box-shadow: 1px 1px 10px 0px rgba(0, 18, 43, 0.2);
      }
    }
  }
}

.conference-form input::placeholder,
.form-description-text {
  color: rgba(0, 18, 43, 0.5);
  font-size: 0.925em;
}

.conference-form input:focus,
.conference-form select:focus,
.conference-form textarea:focus {
  box-shadow: none;
  border: 1px solid rgb(0, 18, 43);
  color: rgb(0, 18, 43);
}

.form-check-container input:checked + label:before {
  background-color: #00122b;
}

.form-check-container input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-check-input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.chosen-container-multi {
  .chosen-choices {
    background-color: rgba(212, 212, 212, 0.05);
    padding: 0.375rem 0.75rem !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    background-image: none !important;
    li.search-field input[type='text'] {
      font-family: 'Lato' !important;
      font-size: 16px !important;
      color: rgba(0, 18, 43, 0.5);
    }
  }
}

.chosen-container-active {
  .chosen-choices {
    box-shadow: none !important;
    border: 1px solid rgb(0, 18, 43) !important;
  }
}

.submit-form-button {
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: rgba(0, 18, 43, 0.2);
    color: rgba(0, 18, 43, 0.5);
  }
}

.conference-form-header {
  font-size: 24px;
  max-width: 620px;
  line-height: 1.5;
  margin: 0 auto;
}

.form-error-parent input {
  border-color: #c56969 !important;
}

.form-error-message,
.parsley-type {
  font-size: 15px;
  margin: 7px 0px 0px 8px;
  color: #c56969;
}

.parsley-errors-list {
  list-style: none;
  padding: 0;
}

.conference-form-sucess-message {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c8dcb0;
  min-height: 400px;
  transition: 0.2s ease-in;
  display: none;
  opacity: 0;
}

.share-conference-page-banner {
  flex-wrap: nowrap !important;
  text-align: left;
  background: #00122b;
}

.conference-share-button {
  white-space: nowrap;
  color: #00122b;
  min-width: 140px;
  &:hover {
    background-color: #f7fcfe !important;
    color: #00122b;
  }
  img {
    width: 33px;
  }
}

.conference-quote {
  position: absolute;
  width: 20px;
}

.conference-quote-open {
  left: -24px;
  top: 3px;
}

.conference-quote-close {
  margin-left: 14px;
  bottom: 16px;
}

.get-started-button,
.get-started-button:focus {
  border: none;
  outline: none;
  transition: 0.2s ease-in;
}

.get-started-button:hover {
  background-color: #f2a805;
}

.conference-form-textarea {
  padding-top: 0.75em;
}

.chosen-container {
  .chosen-drop {
    font-size: 15px;
  }
  .chosen-results {
    li {
      padding: 8px 12px !important;
    }
    li.highlighted {
      background-color: rgb(0, 148, 204) !important;
      background-image: none !important;
    }
  }
}

.submitting-text {
  color: rgb(0, 18, 43, 0.6);
  font-size: 15px;
  a {
    color: rgb(0, 18, 43, 0.6);
    &:hover {
      color: rgb(0, 148, 204);
    }
  }
}
