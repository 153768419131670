.upcoming-events {
  h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.25rem;
  }
  .events-subtitle {
    position: relative;
    font-weight: 500;
    font-size: 1.18rem;
  }
  .arrow-down {
    transform: rotate(0deg);
    padding: 4px;
  }
}

.upcoming-event {
  position: relative;
  img {
    max-width: 18px;
  }
  h4 {
    font-size: 0.92rem;
    font-weight: 600;
  }
  .category-name {
    font-size: 0.9rem;
    color: #848484;
  }
  .arrow-down {
    position: absolute;
    top: 35%;
    right: 5%;
    transform: rotate(0deg);
    padding: 4px;
    max-width: 25px;
  }
}

.upcoming-event-expandable {
  transition: 0.2s ease-in;
}

.upcoming-event-body {
  transition: 0.2s ease-in;
  overflow-y: hidden;
  margin: 0 !important;
}

.announcements-container {
  transition: 0.2s ease-in;
  overflow: hidden;
  margin: 0 !important;
}

.event-hour {
  font-weight: 700;
}
.event-title {
  padding-left: 1em;
  color: #00122b !important;
  width: calc(100% - 20px);
}

.upcoming-select-range {
  position: relative;
  z-index: 10;
  .show-picker {
    font-weight: 700;
    background-image: url('#{$cdn-url}icon-calendar.svg');
    background-size: 15px auto;
    background-position: 10px center;
    padding-left: 36px !important;
    background-repeat: no-repeat;
  }
  .active-button {
    background-color: rgba(132, 132, 132, 0.2);
  }
}

.no-border {
  &:focus {
    outline: none;
  }
}

.no-results-calendar {
  background: #f3efec;
  height: 100%;
  @extend .box;
  p {
    font-size: 1.2rem;
  }
  .message {
    color: #807d7d;
    max-width: 450px;
  }
}

.clear-results {
  color: #0094cc;
  cursor: pointer;
  font-weight: 700;
}

.question-mark {
  background-color: rgba($soft-grey, 0.32);
  font-size: 0.92rem;
  font-weight: 700;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 3px;
  text-align: center;
  cursor: pointer;
}

.announcements-tooltip {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  top: 100%;
  width: 330px;
  z-index: 100;
  left: 5%;
  font-size: 0.85rem;
  line-height: 1.4;
  padding: 0.5rem 0.8rem;
  color: #022344;
}

.upcoming-events-results {
  margin-top: 18px;
}

/*=====================================
          REACT DAY PICKER
======================================*/

.DayPicker {
  font-family: $font-family-sans-serif;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: none;
  font-size: 0.85rem !important;
  &:focus {
    border: none;
    outline: none;
  }
}

.DayPicker-Caption {
  text-align: center !important;
  div {
    font-weight: bolder !important;
    color: #022344 !important;
  }
}

.DayPicker-Weekday {
  color: #022344 !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  padding: 0.5em 0.35rem !important;
}

.DayPicker-wrapper {
  &:focus {
    border: none;
    outline: none;
  }
}

.DayPicker-Month {
  margin: 0 0.2rem !important;
  margin-top: 0.6rem !important;
  border-collapse: unset !important;
}

.DayPicker-Day {
  border-radius: 0 !important;
  padding: 0.25em 0.5em !important;
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    background-color: rgba($soft-grey, 0.1) !important;
  }
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today {
  border-radius: 0 !important;
}

.DayPicker-Day--selected {
  background-color: rgba(242, 168, 5, 0.3) !important;
  color: #022344 !important;
  border: none !important;
  &:hover {
    background-color: rgba(242, 168, 5, 0.3) !important;
  }
}

.DayPicker-Day--start,
.DayPicker-Day--end {
  background: #f2a805 !important;
  border-radius: 8px !important;
  position: relative;
  &:hover {
    background: #f2a805 !important;
  }
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected.DayPicker-Day--today,
.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--today {
  border-radius: 8px !important;
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today {
  border: none !important;
}

.DayPicker-NavButton--prev {
  background-image: url('#{$cdn-url}calendar-leftarrow.svg') !important;
  left: 0.5rem !important;
}

.DayPicker-NavButton--next {
  background-image: url('#{$cdn-url}calendar-rightarrow.svg') !important;
  right: 0.5rem !important;
}

.DayPicker-NavButton {
  top: 0.65rem !important;
  &:focus {
    border: none;
    outline: none;
  }
}

.DayPicker-Day--start {
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: rgba(242, 168, 5, 0.3);
    top: 0;
    right: 0;
  }
}

.DayPicker-Day--end {
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: rgba(242, 168, 5, 0.3);
    top: 0;
    left: 0;
  }
}

.DayPicker-Day--today {
  color: #022344 !important;
  border: 0.5px solid #001c35 !important;
  border-radius: 8px !important;
  border-collapse: unset !important;
}

/*=====================================
          REACT DATEPICKER
======================================*/
.react-datepicker {
  font-family: $font-family-sans-serif;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 100;
  button {
    @extend .no-border;
  }
}

.react-datepicker__month {
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__day--outside-month {
  color: $soft-grey !important;
}

.react-datepicker__day {
  margin-bottom: 0;
  margin-top: 0;
  color: #022344;
  @extend .no-border;
  //background: #F2A805;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  border: none !important;
  &:before {
    background-color: transparent !important;
  }
  &:after {
    width: 0.4rem !important;
  }
}
.react-datepicker__day--range-end {
  border: none !important;
  &:after {
    background-color: transparent !important;
  }
  &:before {
    width: 0.4rem !important;
  }
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__day-name {
  font-weight: bold;
  line-height: 1;
  color: #022344;
}

.react-datepicker__current-month {
  margin-bottom: 16px;
  color: #022344;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: rgba(242, 168, 5, 0.3);
  position: relative;
  border-radius: 0;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0.166rem;
    background-color: rgba(242, 168, 5, 0.3);
    top: 0;
    left: -0.166rem;
  }
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0.166rem;
    background-color: rgba(242, 168, 5, 0.3);
    top: 0;
    right: -0.166rem;
  }
  &:hover {
    background-color: rgba(242, 168, 5, 0.3);
    border-radius: 0 !important;
  }
}

.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--today {
  background-color: rgba(242, 168, 5, 0.3);
  border-radius: 0;
  border: none;
  color: #022344;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-start {
  background: #f2a805 !important;
  border-radius: 8px !important;
  &:hover {
    border-radius: 8px !important;
  }
}

.react-datepicker {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: none;
}

.react-datepicker__day--today {
  border: 0.5px solid #001c35;
  background: none;
  color: $medium-blue;
  border-radius: 0.3rem;
  &:hover {
    background-color: #f2a805;
  }
}

.react-datepicker__today-button {
  background: none;
  border-top: none;
  padding: 8px 5px;
  span {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 3px 10px;
    border-radius: 8px;
  }
}

.react-datepicker__navigation {
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  top: 5.4%;
  width: 12px;
  height: 12px;
}

.react-datepicker__navigation--previous {
  background-image: url('#{$cdn-url}calendar-leftarrow.svg');
}
.react-datepicker__navigation--next {
  background-image: url('#{$cdn-url}calendar-rightarrow.svg');
}

@media screen and (min-width: 768px) {
  .upcoming-events-results {
    margin-top: -38px;
  }
}

@media screen and (min-width: 992px) {
  .question-mark {
    &:hover {
      + .search-tooltip {
        display: block;
      }
    }
  }
}
