.parliamentary-content-in {
  @extend .legislation-content-in;
  .content-item {
    padding-left: 0 !important;
  }
  .content-container {
    box-shadow: none !important;
    border: none !important;
  }
  .timestamp {
    margin-top: 1.6rem;
  }
  time {
    position: relative;
    padding-left: 25px;
  }
  .glyphicon-time,
  .clockface {
    background-image: url('#{$cdn-url}clock-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-right: 20px;
    left: 0;
    top: 1px;
  }
  svg.fa-clock {
    width: 16px;
    margin-top: -2px;
  }
  .nohighlight {
    &:hover {
      color: #0094cc;
    }
  }
  .share-button {
    display: none;
  }
  .share-bar {
    display: none;
  }
  .debate-item-timestampdebateitem {
    margin-bottom: 20px;
    time {
      padding-left: 0px !important;
    }
  }
  h2 {
    font-size: 1rem;
  }
  .attributed-to-details {
    font-size: 1rem;
    font-weight: 700;
    div {
      display: inline-block;
    }
  }
  .attributed-to {
    margin-bottom: 0.5em;
  }
  table {
    margin: 20px 0;
  }
  .memberBar {
    a {
      display: flex;
      align-items: center;
      margin: 0px 0px 12px;
      .time {
        display: block;
        color: rgba($main-blue, 0.85);
        font-size: 0.8em;
      }
      img {
        display: none;
      }
    }
  }
  .adjustment-anchor-welsh .verbatim {
    font-size: 1.05em;
    font-weight: 700;
    margin-top: 10px;
  }

  .timepiece {
    position: relative;
    margin: 16px 0;
    font-weight: 700;
    padding-left: 28px;
    .clockface {
      top: 3px;
    }
  }
}

.parliamentary-hansard-content {
  .card {
    font-family: National, Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    cursor: pointer;
    display: block;
    text-decoration: none;
    background-color: #cbc9cd;
    padding: 0;
    position: relative;
    border: none;
    border-radius: 0;
    box-shadow:
      0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    .card-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 15px;
      border-left: 6px solid #625a75;
      position: relative;
      background-color: #fff;
      height: 100%;
      min-height: 80px;
      &:hover {
        background-color: #f8f7f7;
      }
      .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        .primary-info {
          flex-grow: 1;
          font-weight: 700;
          color: #373151;
          font-size: 1.25rem;
          line-height: 1.35;
          padding-right: 30px;
          position: relative;
        }
        .additional-text {
          margin-top: -4px;
          padding: 8px 15px;
          margin: 0 -15px -9px;
          color: #625a75;
          font-weight: 500;
          em {
            font-style: normal !important;
          }
        }
        .additional-text-after {
          font-weight: 700;
          border-top: 1px dotted #cbc9cd;
          margin-top: 10px;
          padding: 6px 15px 5px;
          color: #625a75;
        }
        .infographic {
          background-color: rgba(248, 246, 252, 0.5);
          margin: 10px -15px -10px;
          padding-top: 0;
          padding-left: 0;
          flex: 0 1 auto;
          display: flex;
          flex-direction: column;
          border-top: 1px dotted #cbc9cd;
          .items {
            display: flex;
            justify-content: space-between;
            .item {
              margin-right: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1 1 0%;
              padding: 10px 15px;
              &:nth-child(2) {
                border-left: 1px dotted #cbc9cd;
              }
              .item-content {
                .label {
                  font-size: 1rem;
                  line-height: 1.4;
                  color: #717171;
                }
                .value {
                  font-size: 1.66rem;
                  line-height: 1.2;
                  font-weight: 700;
                  color: #625a75;
                }
              }
              .item-icon {
                font-size: 1.66rem;
                line-height: 1.2;
                margin: 0 10px;
                color: #cbc9cd;
                svg {
                  width: 1em;
                  display: inline-block;
                  font-size: inherit;
                  height: 1em;
                  overflow: visible;
                  vertical-align: -0.125em;
                }
              }
            }
          }
        }
      }
      .info {
        margin: 10px -15px -10px;
        padding: 0 15px;
        border-top: 1px dotted #cbc9cd;
        .info-inner {
          font-size: 1rem;
          line-height: 1.4;
          padding-top: 7px;
          text-align: left;
          overflow: hidden;
          .indicators-left {
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 6px;
            .indicator {
              color: #717171;
              font-size: 1rem;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
  .card-vote {
    color: #373151;
  }
  .column-number-indicator {
    display: none;
  }
}

.welsh-official-report-content {
  .itemContent {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
  .memberInfo,
  .simple-link-button.hover-stakeholder-link {
    margin-bottom: 8px;
  }
}

.parliamentary-senedd-committee-transcript-content {
  .itemContent {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
  .memberInfo,
  .simple-link-button.hover-stakeholder-link {
    margin-bottom: 8px;
    .time {
      display: none;
    }
  }

  #meeting table {
    margin-bottom: 50px;
    border-collapse: collapse;
  }
  table {
    width: 100%;
    font-size: 14px;
    background-color: #fff;
  }
  #meeting .attendees tr:nth-child(odd) {
    border: 1px solid #ccc;
    border-bottom: 0;
  }
  #meeting .attendees .nameCol {
    width: 30%;
    border-bottom: 1px solid #ccc;
  }
  #meeting table td {
    vertical-align: middle;
  }
  table tbody td {
    padding: 0 10px;
  }
  table td,
  table th {
    line-height: 30px;
  }
  table {
    width: 100%;
    font-size: 14px;
    background-color: #fff;
  }
  #meeting .attendees .nameCol {
    width: 30%;
    border: 1px solid #ccc;
  }
  #meeting .attendees tr:nth-child(even) {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  #meeting .itemContent.proceduralText .contributionText {
    font-style: italic;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .meetingShareContainer {
    display: none;
  }

  .memberBar .memberDetail {
    font-weight: 600;
  }
}

.parliamentary-crec-content,
.legislation-us-content,
.news-us-content {
  pre {
    font-family: $font-family-sans-serif;
    font-size: inherit;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

.question-answer-by {
  color: #848484;
}

.no-answer-box {
  background: #f9f9f9;
  border-radius: 3px;
}

.parliamentary-current-house {
  span {
    color: #022344;
    font-size: 0.85em;
  }
}

.debate-item {
  padding: 0 1.85em;
}

.explanatory-container {
  .other-content,
  .content-item .content-item {
    padding-right: 1.25em;
    padding-left: 1.25em !important;
  }
}

.debate-item-contributiondebateitem {
  position: relative;
  padding: 0.65em 1.25em;
  p {
    &:last-child {
      margin-bottom: 0.35em;
    }
  }
}

.debate-item-contributiondebateitem-padding {
  padding: 0.65em 1.25em;
}

.debate-item-columnnumber {
  display: none;
}

.parliamentary-title,
.share-parliamentary-bottom {
  padding: 0em 1.5em;
}

.parliamentary-share-buttons {
  position: absolute;
  top: 5px;
  right: 30px;
  display: none;
}
.debate-item-contributiondebateitem-highlighted {
  padding-top: 32px;
  background-color: rgba(#0094cc, 0.04);
  .parliamentary-share-buttons {
    display: block;
  }
}
.scottish-welsh-debate-item-contributiondebateitem-highlighted {
  @extend .debate-item-contributiondebateitem-highlighted;
  padding-top: 0;
  padding-bottom: 1rem;
  margin-bottom: 0 !important;
  margin-left: -1.26rem;
  margin-right: -1.26rem;
  padding-right: 1.26rem;
  padding-left: 1.26rem;
}
.content-title-line {
  border-bottom: 1px solid rgba($main-grey, 0.6);
}

.piece-content-container {
  margin: 0em;
  justify-content: space-between;
  .ScrollbarsCustom-Wrapper {
    right: 0 !important;
  }
}

.adjustment-anchor-welsh {
  margin-top: -50px;
  padding-top: 50px;
}

.total-contributions-buttons {
  padding: 0 9px 0px 0px;
  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: 7px 8px;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    .icon-arrow-back,
    .icon-arrow-next {
      font-size: 14px;
      color: #0094cc;
    }
    &:disabled {
      .icon-arrow-back,
      .icon-arrow-next {
        color: rgba(#00122b, 0.3) !important;
      }
    }
    .icon-arrow-back {
      margin-left: -1px;
    }
    .icon-arrow-next {
      margin-right: -1px;
    }
  }
}

.widget-navigation-button {
  margin-right: 12px;
  &:hover {
    text-decoration: none !important;
  }
}

.widget-total-contributions-progress-bar {
  height: 2px;
  background-color: $light-blue;
}

.total-contributions-widget {
  width: calc(100% + 9px);
}

@media screen and (min-width: 768px) {
  .scottish-welsh-debate-item-contributiondebateitem-highlighted {
    margin-left: -1.86rem;
    margin-right: -1.86rem;
    padding-right: 1.86rem;
    padding-left: 1.86rem;
  }
  .parliamentary-current-house {
    padding-left: 0.65em !important;
  }

  .piece-content-container {
    border: none;
    .main-title {
      border-top-left-radius: 5px;
    }

    &::before {
      border: none;
    }
  }

  .explanatory-container {
    .other-content,
    .content-item .content-item {
      padding-right: 1.85em !important;
      padding-left: 1.85em !important;
    }
  }

  .debate-item-contributiondebateitem {
    padding: 0.65em 1.85em;
  }
  .debate-item-contributiondebateitem-padding {
    padding: 0.65em 1.85em;
  }

  .debate-item-contributiondebateitem {
    &:hover {
      .parliamentary-share-buttons {
        display: block;
      }
    }
  }
  .parliamentary-content-in {
    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .debate-item-contributiondebateitem-highlighted {
    padding-top: 0.65em;
  }

  .piece-content-container {
    margin: 0;
  }
  .total-contributions-buttons {
    button {
      transition: 0.2s ease-in;
      &:hover {
        background-color: rgba($light-blue, 0.08);
      }
    }
  }
}
