.influence-box-container {
  padding: 0;
  margin: 10px 0;
}

.influence-visibility-box {
  padding: 40px 68px;
  position: relative;
  .icon-padlock {
    position: absolute;
    top: 17px;
    right: 48px;
    color: rgba($main-blue, 0.6);
  }
}

.preview-organisation-item {
  img {
    width: 18px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    margin-top: -4px;
    border-radius: 3px;
  }
}

.supporter-title {
  font-size: 14.8px !important;
}

.region-autocomplete-main-value {
  padding: 6px 43px 6px 0.9rem;
}

.region-autocomplete {
  position: relative;
  border: 1px solid rgba(132, 132, 132, 0.5);
  border-radius: 5px !important;
  background: #fff;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid $main-blue;
    position: absolute;
    top: 44%;
    right: 10px;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    background: #fff;
    color: $main-blue;
  }
  ::placeholder {
    color: rgba(132, 132, 132, 0.8) !important;
  }
  .react-autosuggest__container {
    z-index: 0;
  }
  .react-autosuggest__container {
    position: absolute;
    top: 36px;
    left: -1px;
    width: calc(100% + 2px);
    background: #fff;
    border: 1px solid rgba(132, 132, 132, 0.9);
    padding: 0.3em 43px 0.3rem 0.9rem;
    border-radius: 0 0 5px 5px;
  }
  .react-autosuggest__suggestions-container--open {
    border: 1px solid rgba(132, 132, 132, 0.9);
    border-radius: 0 0 5px 5px;
    background: #ffffff;
    box-shadow: none;
    border-top: none;
    max-height: 150px;
    top: 39px;
  }
  .search-item {
    .icon {
      margin-top: 4px;
      margin-left: 4px;
      background-image: url('#{$cdn-url}20210817-location-icon.svg');
    }
  }
}

.expiry-date-dropdown {
  .general-dropdown {
    max-height: 240px;
  }
}

.region-autocomplete-open {
  border-radius: 5px 5px 0px 0px !important;
  border: 1px solid rgba(132, 132, 132, 0.9);
  border-bottom: none;
}

.visibility-options-keywords {
  min-height: 165px;
  border: 1px solid $main-grey;
  .adimn-keywords-suggestions {
    width: calc(100% + 16px);
    margin-left: -8px;
    border-top: none !important;
  }
}

.visibility-options-keywords-focus {
  border: 1px solid $main-blue !important;
}

.stakeholder-only-option {
  display: flex;
  padding: 8px 20px 8px 16px;
  border-radius: 5px;
  margin-bottom: 12px;
  [class^='icon-'] {
    margin-top: 3px;
    margin-right: 12px;
  }
}

.stakeholders-only-popup {
  .logo {
    width: 130px;
  }
  .box-select {
    cursor: auto;
  }
  .influence-visibility-box {
    padding: 40px 35px;
  }
}

.stakeholders-only-title {
  max-width: 450px;
  font-size: 1.4em !important;
  margin: 0 auto;
  line-height: 1.5;
}

.influence-box-container-auto {
  .box-select {
    cursor: auto;
  }
}

.influence-box-container-disabled {
  opacity: 0.6;
  background: rgba($main-grey, 0.08);
  .box-selected {
    border: 1px solid $main-grey;
    &:before {
      border: 1px solid $main-grey;
    }
    &::after {
      background: $main-grey;
    }
  }
}

@media screen and (min-width: 992px) {
  .preview-organisation-item {
    img {
      height: 18px;
      width: auto;
    }
  }
  .influence-box-container {
    margin: 22px 0 40px 0;
    &:nth-child(1) {
      padding-right: 12px;
    }
    &:nth-child(2) {
      padding-left: 12px;
    }
  }
  .stakeholder-only-option {
    border-radius: 5px 0px 0px 5px;
  }
  .influence-visibility-box {
    transition: 0.2s ease-in;
    &:hover {
      background: rgba($main-grey, 0.08);
    }
  }
  .stakeholders-only-popup {
    .influence-visibility-box {
      &:hover {
        background: $main-white;
      }
    }
  }
}
