.referral-link-modal {
  .referral-link-header {
    .referral-link-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      span {
        color: $light-blue;
      }
    }
    .referral-link-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      transform: translate(1px, -4px);
    }
  }
  .link-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 18, 43, 0.4);
    color: rgba(0, 28, 53, 0.9);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    background-color: rgba($light-blue, 0.04);
    span {
      max-width: calc(100% - 90px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .copy-referral-link {
    background-color: $light-blue;
    color: #ffffff;
    padding: 5px 13px;
    border: none;
    border-radius: 3px;
    height: 29px;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    transition: all 200ms;
    &:hover {
      background-color: rgba($light-blue, 0.85);
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 11px;
  }
  .how-it-works-section {
    background: #f9f9f9;
    border-top: 0.5px solid #ececec;
    h4 {
      margin-bottom: 22px;
    }
    .how-it-works-list {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
    }
    .how-it-works-list-item {
      display: flex;
      align-items: center;
      column-gap: 21px;
      font-size: 16px;
      line-height: 20px;
    }
    .how-it-works-email-input {
      border-radius: 3px;
      border: 1px solid #d4d4d4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 53px;
      background-color: #ffffff;
      height: 34px;
      margin-top: -19px;
      position: relative;
      input {
        background: transparent;
        border: none;
        flex-grow: 1;
        color: rgba(0, 28, 53, 0.9);
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        &:active {
          border: none;
          outline: none;
        }
        &:focus {
          border: none;
          outline: none;
        }
        &:disabled {
          color: rgba(0, 28, 53, 0.7) !important;
        }
      }
      button {
        border: none;
        background-color: transparent;
        color: $light-blue;
      }
    }
  }
  .circle-marker {
    display: block;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    background-color: #00122b;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
  }
  .footer {
    border-top: 0.5px solid #ececec;
    .terms-and-conditions {
      color: inherit;
      opacity: 0.8;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      padding-top: 11px;
      padding-bottom: 14px;
    }
  }
  .error-message {
    position: absolute;
    display: flex;
    top: 35px;
    left: 0;
  }
  .referral-link-edit-btn {
    &:disabled {
      color: rgba(0, 28, 53, 0.7) !important;
    }
  }
  .referral-parliament-description {
    color: rgba(0, 28, 53, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 23px;
  }
  .referral-link {
    color: #0094cc;
    &:hover {
      text-decoration: underline;
    }
  }
  .input-violet {
    background-color: rgba(98, 99, 163, 0.06) !important;
    & button {
      background-color: rgba(98, 99, 163, 1) !important;
      &:hover {
        background-color: rgba(98, 99, 163, 0.85) !important;
      }
    }
  }
  .referral-link-invite-organisations-section {
    border-top: 0.5px solid #ececec;
  }
}
