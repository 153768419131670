body,
html,
#root {
  height: 100%;
  font-family: 'Lato', sans-serif;
}

.login-container {
  position: relative;
  height: 100%;
  background: #fff;
  .general-button {
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 100;
    font-weight: bold;
  }
}

.login-information {
  display: none;
  background-color: #001c35;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 470px;
  padding: 2.2rem 0rem 0 0rem;
  color: #fff;
  background-image: url('#{$cdn-url}Approved_Blunkett_david.png');
  background-repeat: no-repeat;
  background-size: auto 45%;
  background-position: left bottom;
  z-index: 1;
}

.main-quote {
  font-size: 1.3rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.8rem;
}
.company-name {
  text-indent: -10000px;
  background-image: url('#{$cdn-url}logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 155px;
  height: 31px;
  margin-bottom: 2rem;
  left: 2.2rem;
  position: relative;
}

.welcome-message {
  font-weight: 700;
  font-size: 1.475rem;
  margin-bottom: 2rem;
}

.login-data {
  color: #00122b;
  position: relative;
  width: 100%;
}

.login-main-content {
  max-width: 500px;
}

.policy-quote {
  position: absolute;
  bottom: 55vh;
  width: 420px;
  padding-left: 2.2rem;
  p {
    font-weight: 400;
    color: #f2a805;
  }
}

.awaits-message {
  padding-top: 20px;
  font-size: 1.05rem;
  button {
    font-size: 1.05rem;
    text-decoration: underline;
    color: rgba(0, 18, 43, 0.6);
    font-size: 0.995em;
    &:hover {
      color: rgba(0, 18, 43, 0.6);
      text-decoration: underline;
    }
  }
}

.accept-invite {
  .welcome-message {
    width: auto;
    color: #001c35;
    font-size: 1.2em;
  }
}

@media screen and (max-height: 630px) {
  .policy-quote {
    bottom: 50%;
  }
  .login-information {
    background-size: auto 50%;
    background-position: center bottom;
  }
}

@media screen and (min-width: 576px) {
  .welcome-message {
    width: 500px;
  }
}

@media screen and (min-width: 992px) {
  .login-information {
    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100px;
      top: 0;
      right: 0;
      background: #fff;
      clip-path: polygon(62% 0, 100% 0%, 100% 100%, 0 100%, 0 75%);
    }
  }
}

@media screen and (min-width: 1024px) {
  .login-information {
    display: block;
  }
  .login-data {
    width: calc(100% - 412px);
  }
}
@media screen and (min-width: 1280px) {
  .login-information {
    width: 512px;
  }
}
