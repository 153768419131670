$party-colours: (
  'labour': #c42c3d,
  'labour-independent': #c42c3d,
  'independent-labour': #c42c3d,
  'labour-co-op': #c42c3d,
  'conservative': #5d9cde,
  'conservative-independent': #5d9cde,
  'democratic-unionis': #000080,
  'democratic-unionist-party': #000080,
  'non-affiliated': #4d4d4d,
  'lord-speaker': #4d4d4d,
  'speaker': #4d4d4d,
  'liberal-democrat': #eec543,
  'green-party': #81af3f,
  'scottish-national-party': #faf590,
  'social-democratic-labour-party': #3f8428,
  'sinn-f-in': #99cc00,
  'plaid-cymru': #3f8428,
  'ulster-unionist-party': #99ccff,
);

@each $party-name, $colour in $party-colours {
  .member-#{$party-name}-picture {
    border-color: $colour;
  }
  .profile-image-member-#{$party-name}-picture {
    border-color: $colour;
  }
}
