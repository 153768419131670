.unread-actions {
  height: 24px;
  padding-right: 24px;
  .item-actions-container {
    top: -3px;
    right: -10px;
  }
  .item-actions-box {
    width: 320px;
    padding: 15px 10px !important;
  }
}

.individual-item {
  padding-left: 0 !important;
  span[class^='icon'] {
    margin-right: 6px;
    display: inline-block;
  }
  .icon-team {
    font-size: 12px;
  }
  .icon-parliamentary-record {
    font-size: 14px;
    transform: translateY(1px);
  }
  .icon-key-update {
    font-size: 11px;
  }
  .icon-briefing-material {
    font-size: 14px;
    transform: translateY(1px);
  }
  .icon-consultation {
    font-size: 13.5px;
  }
  .icon-calendar {
    font-size: 15px;
    transform: translateY(1px);
  }
  .icon-library-material {
    font-size: 12px;
  }
  .icon-legislation {
    font-size: 14px;
    transform: translateY(1px);
  }
  .icon-document-bold {
    font-size: 14px;
    margin-right: 7px !important;
    transform: translateY(1px);
  }
}

.text-left {
  .popup-individual-item-container {
    display: inline-block;
  }
}
.popup-individual-item-container {
  margin-top: 6px;
  span[class^='icon'] {
    margin-left: 10px;
    margin-right: 8px;
  }

  .icon-legislation {
    transform: translateY(2px);
  }
  .icon-parliamentary-record {
    transform: translateY(2px);
  }
  .icon-consultation {
    transform: translateY(1px);
  }
  .icon-policy-ask {
    margin-right: 7px !important;
  }
}

.saved-item-stakeholder {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 10px;
}

.total-results-container {
  .item-actions-container {
    top: -3px;
    right: -12px;
  }
}
