.create-client-container-logo {
  width: 134px;
  height: 124px;
  .reports-logo {
    max-width: 134px;
    max-height: none;
  }
}

.client-actions {
  width: 205px;
  right: 0;
  .icon-edit-pencil-thick {
    margin-right: 12px;
  }
}

.client-topic-box {
  cursor: auto;
  p {
    color: rgba(0, 18, 43, 0.5);
  }
}

.client-element-disabled {
  opacity: 0.5;
}

.client-navigation-more-actions {
  width: 150px;
  right: 0;
  .container-icon {
    display: inline-block;
    padding-right: 10px;
  }
}

.setting-clients-picker-container {
  .client-picker {
    margin-left: 0px;
  }
}

.client-list-keywords {
  .advanced-rules-button {
    padding-left: 1em !important;
  }
}

.client-duplicate-container {
  .dropdown-keywords-activeitem {
    padding-left: 1.5em;
  }
}

.duplicate-client-checkbox-item {
  margin: 0 !important;
  p {
    line-height: 1.1em;
  }
}

.link-action-hover {
  &:hover {
    color: $light-blue !important;
  }
}

.client-component-actions {
  .hide-topic-btn {
    border-color: rgba(0, 18, 43, 0.5);
    padding: 4px 14px 4px 14px;
  }

  .hide-topic-btn-disabled {
    color: rgba($main-blue, 0.5);
    border: 1px solid #d4d4d4;
    label {
      cursor: auto !important;
    }
    .hide-topic-btn-icon {
      color: rgba($main-blue, 0.5);
    }
  }
}

@media screen and (min-width: 992px) {
  .client-component-list-container {
    padding-left: 94px;
  }
}
