.popupcheckout-modal {
  h2 {
    font-weight: 400 !important;
  }
  h3 {
    font-size: 1.2em !important;
    margin-bottom: 1.2em;
  }
  li {
    margin-bottom: 1em;
    padding-left: 1.5em;
  }
}

.lose-list {
  li {
    background: url('#{$cdn-url}close-icon-yellow.svg') left 5px / 14px auto no-repeat;
  }
}

.keep-features {
  li {
    background: url('#{$cdn-url}tick-icon-yellow.svg') left 7px / 14px auto no-repeat;
  }
}

.confirmation-downgrade-button {
  border: 1px solid #f2a805;
  color: #f2a805;
  &:hover {
    color: #f2a805;
  }
}

.confirmation-downgrade-button,
.confirmation-checkout-button {
  display: block;
  width: 100%;
}

.confirmation-checkout-button {
  background-color: #f2a805;
  color: #fff;
  min-width: 222px;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.period-dropdown {
  width: 100%;
  height: auto;
}

.period-select {
  width: 100%;
}

.period-item {
  &:not(:last-child) {
    margin-bottom: 0.35em !important;
  }
  &:hover {
    background: transparent;
    font-weight: 700;
  }
}

.plan-confirmation-label {
  font-weight: 700;
}

.dropdown-arrow {
  position: absolute;
  right: 9px;
  top: 9px;
  transform: rotate(180deg);
  width: 17px;
  padding: 2px;
  z-index: 100;
}

.categories-buttons-container {
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  display: inline-block;
}

.categories-button {
  border-radius: 2px;
  padding: 0.2em 0.75em;
  touch-action: none;
  &:hover {
    background: rgba(244, 239, 237, 0.6) !important;
  }
  &:disabled {
    color: rgba(0, 18, 43, 0.4);
    background-color: rgba(244, 239, 237, 0.6);
  }
}

.category-buttons-main-text {
  padding: 0.2em 0.75em;
  display: inline-block;
  border-left: 1px solid rgba(212, 212, 212, 0.6);
  border-right: 1px solid rgba(212, 212, 212, 0.6);
  min-width: 66px;
  text-align: center;
}

.confirmation-pricing-table {
  td {
    padding: 0.85em 0;
    border: none;
    font-weight: 700;
    &:nth-child(1) {
      width: 65%;
    }
    &:nth-child(2) {
      width: 35%;
      text-align: right;
      padding-right: 1em;
    }
  }
}

.items-information {
  td {
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
    font-weight: 400;
    &:nth-child(2) {
      padding-right: 1em !important;
    }
  }
}

.vat-information {
  td {
    border-top: 1px solid rgba(212, 212, 212, 0.3);
    border-bottom: 1px solid rgba(212, 212, 212, 0.3);
    color: rgba(0, 18, 43, 0.4);
    font-weight: 400;
  }
}

.pricing-confirmation-general-container {
  overflow: hidden;
  position: relative;
  min-height: 410px;
  p {
    line-height: 1.6;
  }
  .statistics-popup-list {
    .icon-info {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      color: rgba($main-white, 0.85);
    }
  }
}

.downgrade-popup-tooltip {
  max-width: 350px;
}

.report-popup-tooltip {
  max-width: 300px;
}

.pricing-note {
  font-size: 0.85em;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.billing-information {
  animation: opacity 0.4s ease-in backwards;
}

.chosen-popup-close {
  top: 8px;
  right: 8px;
  display: block !important;
}

.billing-select-disabled {
  .category-buttons-main-text {
    color: rgba(0, 18, 43, 0.4);
    background-color: rgba(244, 239, 237, 0.6);
  }
}

.billing-item-select {
  min-width: 180px;
  .icon-info {
    vertical-align: middle;
    margin-left: 0.5em;
    margin-top: -4px;
  }
}

.downgrade-pricing-button {
  width: 100%;
  display: block;
}

@media screen and (min-width: 992px) {
  .chosen-popup-close {
    top: 15px;
    right: 15px;
  }

  .lose-features {
    border-right: 1px solid rgba(212, 212, 212, 0.3);
  }

  .confirmation-checkout-button,
  .confirmation-downgrade-button {
    width: auto;
    display: inline-block;
  }

  .confirmation-pricing-table {
    td {
      &:nth-child(2) {
        padding-right: 2em;
      }
    }
  }

  .items-information {
    td {
      &:nth-child(2) {
        padding-right: 2.1em !important;
      }
    }
  }

  .downgrade-popup-features-container {
    width: calc(100% - 350px);
    .action-button,
    .outline-active-button {
      font-size: 14px;
    }
  }

  .downgrade-pricing-button {
    width: auto;
    display: inline-block;
  }
}

@media screen and (min-width: 1032px) {
  .downgrade-popup-features-container {
    .action-button,
    .outline-active-button {
      font-size: 16px;
    }
  }
}
