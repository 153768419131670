.react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  position: relative;
  color: #00122b;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 10px;
  padding: 2px 25px 2px 10px;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  box-sizing: border-box;
  &:not(:nth-last-child(2)) {
    margin-right: 30px;
    &::after {
      content: 'or';
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      word-spacing: normal;
      color: rgb(133, 150, 165);
      position: absolute;
      top: 7px;
      right: -22px;
    }
  }
}

.react-tagsinput-remove {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 5px 5px 0px;
  width: 21px;
  transition: 0.2s ease-in;
  &::before {
    content: ' ';
    width: 8px;
    height: 8px;
    position: absolute;
    right: 7px;
    top: 38%;
    background: url('#{$cdn-url}delete-tag-icon.svg') center / contain no-repeat;
  }
  &:hover {
    background: #c4c4c4;
    &::before {
      background: url('#{$cdn-url}delete-tag-icon-white.svg') center / contain no-repeat;
    }
  }
}
button.react-tagsinput-remove {
  border: none;
  background-color: transparent;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #00122b;
  font-weight: 400;
  margin-bottom: 8px;
  outline: none;
  padding: 0px 5px 0px 0;
  width: 200px;
  &::placeholder {
    color: rgba(0, 18, 43, 0.3);
  }
}

.tags-modal-items {
  padding-top: 0.65em;
  padding-bottom: 0.5em;
}

.tags-modal-component {
  min-height: unset !important;
  .react-tagsinput-tag {
    word-break: break-all;
    margin-bottom: 7px !important;
  }
}
