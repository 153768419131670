.ReactModal__Overlay {
  opacity: 0;
  z-index: 100;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  &:focus-visible {
    outline: transparent;
  }
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-modal-overlay-base {
  position: fixed;
  background-color: rgba(2, 35, 68, 0.45);
  transition: opacity 200ms ease-in-out;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-modal-overlay {
  @extend .react-modal-overlay-base;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-modal-content {
  width: 100%;
  background-color: $main-white;
  border-radius: 8px;
  position: relative;
  max-height: 90%;
}

.react-modal-content {
  .modal-close-button {
    position: absolute;
    top: 8px;
    right: 15px;
    width: 35px;
    height: 35px;
  }
}

.modal-close-position-adjustment {
  .modal-close-button {
    top: 15px;
    right: 18px;
  }
}
