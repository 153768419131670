.sidebar-influence {
  font-size: 15px;
  [class^='icon-container'] {
    width: 20px;
    margin-right: 10px;
    font-size: 15px;
    padding-top: 0;
  }
  .icon-email {
    font-size: 13px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }
  .icon-graph,
  .icon-statistics {
    font-size: 14px;
  }
  .icon-briefing-material,
  .icon-bars {
    font-size: 16px;
  }
  .icon-container-briefing-material,
  .icon-container-bars {
    padding-left: 3px;
  }
  .icon-container-graph {
    padding-left: 1px;
  }
  .icon-bot {
    font-size: 19px;
  }
  .icon-directory {
    font-size: 17px;
  }
}
