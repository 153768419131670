.current-page-parent a {
  color: $main-yellow !important;
}
.menu-item-has-children {
  position: relative;
  > a {
    @extend .dropdown-item-element;
    @extend .dropdown-item-element-10;
  }
  .sub-menu {
    display: none;
    position: absolute;
    left: 0;
    li {
      margin: 0 !important;
    }
    a {
      display: block;
      padding: 5px 16px;
      margin: 0 !important;
      width: 100%;
      color: $main-blue !important;
      @extend .list-action-button;
    }
  }
}

.sub-menu {
  top: 40px;
}

.submenu-item {
  color: $main-blue !important;
  padding: 5px 12px;
}

.static-page-link {
  &.open,
  &.active {
    color: $main-yellow !important;
  }
  &:hover {
    color: $main-yellow !important;
  }
}

.navepage-product-mobile {
  .dropdown-item-element {
    &::after {
      top: 14px;
    }
  }
}
