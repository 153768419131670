.salutation-modal-text {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 18, 43, 0.8);
}
.salutation-modal-content-container {
  background: rgba(0, 148, 204, 0.04);
  padding: 40px;
  .salutation-modal-content-title {
    font-size: 1.03em;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .salutation-modal-content-img {
    border-right: 1px solid #d4d4d4;
    border-radius: 5px;
    background-image: url('#{$cdn-url}salutation-example.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: cover;
    min-height: 198px;
    width: 75%;
  }
}

@media screen and (max-width: 620px) {
  .salutation-modal-content-container {
    .salutation-modal-content-img {
      width: 80%;
    }
  }
}
@media screen and (max-width: 520px) {
  .salutation-modal-content-container {
    padding: 16px;
    .salutation-modal-content-img {
      width: 100%;
      min-height: 214px;
    }
  }
}
