.stake-strength {
  min-width: 181px;
  color: #00122b;
  font-size: 0.9em;
  font-weight: 700;
}

.strength-indicator {
  border-radius: 2px;
  width: 30px;
  height: 10px;
  display: inline-block;
  margin-left: 3px;
  &:first-child {
    margin-left: 10px;
  }
}

.strength-indicator1 {
  background: linear-gradient(90deg, #f2a805 5%, rgba(242, 168, 5, 0) 341.67%);
}
.strength-indicator2 {
  background: linear-gradient(90deg, #f2a805 -108.33%, rgba(242, 168, 5, 0) 243.33%);
}
.strength-indicator3 {
  background: linear-gradient(90deg, #f2a805 -226.67%, rgba(242, 168, 5, 0) 113.33%);
}

.stake-list-information {
  li {
    border: none;
    color: #00122b;
    font-size: 0.92em;
    line-height: 1.7em;
    margin: 0.5em 0;
    &:hover {
      box-shadow: none;
    }
  }
  .icon-parliamentary-record,
  .icon-star,
  .icon-profile {
    margin-right: 9px;
  }
  .icon-group {
    margin-right: 8px;
    margin-top: -2px;
    margin-left: -2px;
  }
  .icon-star,
  .icon-profile {
    margin-top: -3px;
  }
  .icon-group,
  .icon-star,
  .icon-profile {
    display: inline-block;
    vertical-align: middle;
  }
  .sentiment-stakeholder-list {
    .sentiment-dot {
      margin-right: 9px;
      width: 12px;
      min-width: 12px;
      height: 12px;
      margin-top: -3px;
      vertical-align: middle;
    }
  }
}

.stake-list-information-mapping {
  li {
    margin: 7px 0;
  }
}

.stake-information-bio-list {
  background: none !important;
  padding-left: 0em !important;
  line-height: 1.4em !important;
}

.stake-information-office {
  background: url('#{$cdn-url}stake-icon-office.svg') 1px 6px / 11px auto no-repeat !important;
}

.stake-information-parliamentary {
  background: url('#{$cdn-url}20210107-icon-parliamentary-recording.svg') 1px 6px / 13px auto no-repeat !important;
}

.stake-information-parliamentary-mapping {
  background-position-y: 2px !important;
}

.stake-information-keyupdate {
  background: url('#{$cdn-url}20210107-icon-key-updates.svg') 0px 8px / 14px auto no-repeat !important;
}

.stake-information-tweet {
  background: url('#{$cdn-url}20210107-icon-social-media.svg') 1px 6px / 13px auto no-repeat !important;
}

.stake-information-tweet-mapping {
  background-position-y: 2px !important;
}

.parliamentarian-positions-container {
}

.category-subtitle {
  color: #00122b;
  font-weight: 700;
  font-size: 0.92em;
  margin-bottom: 1.1em !important;
  &:not(:first-child) {
    margin-top: 1.5em !important;
  }
}

.member-data {
  font-size: 0.9125em;
  color: rgba(0, 18, 43, 0.7);
}

.member-data-date {
  color: rgba(0, 18, 43, 0.6);
}

.external-url {
  color: rgba(0, 18, 43, 0.7);
  &:hover {
    color: rgba(0, 18, 43, 0.7);
  }
}

.mail-url {
  background: none;
  &:has(~ .member-email-button:hover) {
    text-decoration: underline;
  }
}

.feature-subtitle {
  margin: 0 !important;
}

.member-info-subtitle {
  margin-bottom: 0.35em !important;
}

.committe-subtitle {
  background: url('#{$cdn-url}stake-icon-group-grey.svg') 0px 7px / auto 7px no-repeat;
  padding-left: 1.5em;
  color: rgb(0, 18, 43);
  opacity: 0.7;
}

.position-subtitle {
  background: url('#{$cdn-url}star-icon.svg') 0px 4.85px/ 10px auto no-repeat !important;
  padding-left: 1.35em;
}

.member-email-button {
  background: url('#{$cdn-url}copy-icon-grey.svg') 5px 4px/11px auto no-repeat;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-top: -4px;
  vertical-align: middle;
  text-indent: -1000px;
  overflow: hidden;
  display: inline-block;
  margin-left: 3px;
}
.external-url-button {
  background: url('#{$cdn-url}external-link-icon-grey.svg') 5px 4px/12px auto no-repeat;
}

.stakeholder-profile-image-container {
  width: 110px;
  text-align: right;
}

.stakeholder-profile-image {
  width: 65px;
  height: 65px;
  min-width: 65px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 0;
}

.related-stakeholder-item {
  width: calc(100% - 22px);
  padding-left: 10px;
}

.no-stakeholder-item {
  p {
    width: 100%;
  }
  button {
    padding-left: 10px;
    font-size: 0.9em;
    color: #0094cc;
  }
  &:hover {
    color: rgba(0, 18, 43, 0.8);
  }
}

.member-picture {
  width: 117px;
  height: 117px;
  border: 3px solid #c0c0c0;
  margin: 0 auto;
}

.stakeholder-profile-related {
  width: 22px !important;
  height: 22px !important;
  border: 1px solid #c0c0c0;
  min-width: 22px;
}

.edm-link {
  .member-picture {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
}

.latest-tweets-list {
  .tweet-general-button-container {
    display: block !important;
  }
  .tweet-container {
    padding: 1em !important;
  }
  .twitter-content {
    font-size: 0.95em;
    margin-bottom: 0.85em !important;
  }
  .twitter-action-buttons,
  .twitter-date {
    width: 100% !important;
  }
  .item-actions-container {
    right: 3px;
  }
  .twitter-logo {
    display: none;
    margin-right: 8px;
  }
  .content-item {
    padding: 0 !important;
  }
  .parliamentary-item-padding {
    padding: 1em !important;
  }
  .sentiment-dot {
    width: 8px;
    min-width: 8px;
    height: 8px;
  }
}

.see-more-link {
  color: rgba(0, 18, 43, 0.5);
  text-decoration: underline;
  font-size: 0.9em;
  &:hover {
    color: rgba(0, 18, 43, 0.5);
  }
}

.see-more-category {
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: none;
  }
  &::before {
    content: '';
    position: absolute;
    border-top: 1px solid rgba(0, 18, 43, 0.2);
    top: 52%;
    left: 0;
    width: 100%;
  }
  span {
    background-color: #fff;
    z-index: 1;
    position: relative;
    transition: 0.2s ease-in;
    &:hover {
      color: rgba(0, 18, 43, 0.8) !important;
    }
  }
}

.stake-main-title {
  color: #212529;
  cursor: auto;
  &:hover {
    color: #212529;
    text-decoration: none;
  }
}

.stake-main-title-content-related {
  cursor: pointer;
  position: relative;
  padding-left: 1.2em;
  &:hover {
    color: #0094cc;
    &::before {
      border-color: #0094cc;
    }
  }
  &::before {
    content: '';
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    transform: rotate(135deg);
    position: absolute;
    top: 8px;
    left: 0px;
  }
}

.stake-holder-no-mogul {
  h3 {
    font-weight: 700;
    max-width: 261px;
  }
  p {
    max-width: 300px;
  }
}

.stakeholder-item-position {
  color: #00122b;
  padding-left: 22px;
}

.stakeholder-position-mapping {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  background-position-y: center !important;
}

.stakeholder-position-commons {
  color: #006548;
}

.stakeholder-position-lords {
  color: #b40a37;
}

.stakeholder-position-cabinet-commons,
.stakeholder-position-shadow-cabinet-commons {
  color: #006548;
  background: url('#{$cdn-url}stake-position-cabinet-commons.svg') 0px 6px / 15px auto no-repeat;
}

.stakeholder-position-cabinet-lords,
.stakeholder-position-shadow-cabinet-lords {
  background: url('#{$cdn-url}stake-position-cabinet-lords.svg') 0px 6px / 15px auto no-repeat;
}

.stakeholder-position-government-commons,
.stakeholder-position-shadow-government-commons {
  background: url('#{$cdn-url}stake-position-government-commons.svg') 0px 2px / 12px auto no-repeat;
}

.stakeholder-position-government-lords,
.stakeholder-position-shadow-government-lords {
  background: url('#{$cdn-url}stake-position-government-lords.svg') 0px 2px / 12px auto no-repeat;
}

.stakeholder-position-parliamentary-private-secretary-commons {
  background: url('#{$cdn-url}stake-position-parliamentary-private-commons.svg') 0px 5px / 15px auto no-repeat;
}

.stakeholder-position-parliamentary-private-secretary-lords {
  background: url('#{$cdn-url}stake-position-parliamentary-private-lords.svg') 0px 5px / 15px auto no-repeat;
}

.stakeholder-position-special-advisor-commons {
  background: url('#{$cdn-url}stake-position-special-commons.svg') 0px 4px / 11px auto no-repeat;
}

.stakeholder-position-special-advisor-lords {
  background: url('#{$cdn-url}stake-position-special-lords.svg') 0px 4px / 11px auto no-repeat;
}

.explanations-stakeholders {
  padding-left: calc(65px + 1.85em) !important;
}

.parliamentarian-positions-container {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  .member-data {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.see-bio-button {
  font-size: 1em;
  position: relative;
  width: 100% !important;
  padding: 1.1em 0;
  background-color: #f9f9f9;
  border: 1px solid rgba(#d4d4d4, 1);
  border-bottom: none;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #00122b;
    position: absolute;
    top: 45%;
    left: 1.5em;
  }
}

.hide-bio-button {
  &::after {
    border-top: none;
    border-bottom: 6px solid #00122b;
  }
}

.export-message-popup {
  color: $main-blue;
}

.export-message-title {
  font-size: 1.15em;
  span {
    background: url('#{$cdn-url}export-results-button-blue.svg') 0 5px / 14.3px auto no-repeat;
    padding-left: 25px;
  }
}

.filters-register-title,
.no-enable-stakeholders-title {
  span {
    background-size: 15px auto;
    background-position: 0 4px;
  }
}

.filters-register-title {
  span {
    background-image: url('#{$cdn-url}20211231-filters-icon.svg');
  }
}

.no-enable-stakeholders-title {
  span {
    background-image: url('#{$cdn-url}icon_header_search.svg');
  }
}

/*========================
  MAIN BAR
==========================*/

.stakeholder-profile-mainname {
  width: calc(100% - 60px);
  padding-left: 1em;
  .main-title {
    margin-bottom: 0.2em !important;
  }
  p {
    line-height: 1.3;
  }
}

.profile-content-actions {
  .item-actions-save {
    background-color: #fff;
    width: 39px;
    height: 39px;
    background-position: 13px 10px;
    margin-right: 8px;
  }
  .item-actions-remove-content {
    background-position: 13px 11px !important;
  }
}

.stakeholder-activity-container {
  background: #fff;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
}

.stakeholder-share-buttons {
  button {
    span {
      margin-top: 0.5em;
      display: inline-block;
    }
  }
  .react-share__ShareButton {
    padding: 1rem !important;
    margin-left: 2.5em;
  }
  .share-twitter-icon {
    width: 19px;
  }
}

.share-popoup {
  width: 100%;
  .react-share__ShareButton {
    margin-left: 0;
  }
  & > div {
    display: flex;
    width: 100%;
    align-items: flex-end;

    & > button {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > span {
        text-align: center;
      }
    }
  }
}

.icon-share-image {
  display: block !important;
  margin: 0 auto;
  width: 30px;
  height: 30px;
}

.icon-share-image-twitter {
  background: url('#{$cdn-url}twitter-x.svg') center 51% / 51px auto no-repeat;
}
.icon-share-image-copy {
  background: url('#{$cdn-url}share-icon-v1.svg') center 51% / 18px auto no-repeat;
}

.stakeholder-share-buttons {
  button {
    border-radius: 3px;
    transition: 0.2s ease-in;
    &:hover {
      background: rgba(0, 148, 204, 0.04) !important;
    }
  }
}
.stakeholder-page {
  padding-top: 0;
  width: calc(100% + 2em);
  margin-left: -1em;
  .hover-container-scroll {
    z-index: 100;
    position: relative;
  }
}

.stakeholder-section-title {
  border-bottom: 0.5px solid rgba(#00122b, 0.6);
  padding: 0.5em 0 0.85em 0;
  margin-bottom: 1.2em;
}

.stakeholder-section-title-profile {
  padding: 2.1em 15px 1.5em 15px;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.profile-section-container {
  padding: 0 1.5em 0 1.95em;
  border: 1px solid rgba(#d4d4d4, 1);
  border-bottom: none;
  background-color: #fff;
}

.sort-mode-radio-stakeholders {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    padding-bottom: 1em !important;
  }
  &:not(:first-child) {
    padding-top: 0.6em !important;
  }
  label {
    display: block !important;
    height: auto !important;
    padding: 0 0 0 34px !important;
    p {
      margin-bottom: 0;
    }
    &::before {
      top: 2px !important;
    }
    &::after {
      top: 7px !important;
    }
  }
}

.label-description {
  color: rgba(0, 18, 43, 0.55);
  line-height: 1.35;
  margin-top: 5px;
  margin-bottom: 0px;
}

.sort-mode-container {
  width: 301px !important;
}

.waiting-screen {
  max-width: 500px;
  font-size: 1.05em;
  margin-top: -70px;
  p {
    margin-bottom: 0;
    &:nth-child(1) {
      font-size: 1.075em;
      margin-bottom: 0.9em;
    }
    &:nth-child(2) {
      color: rgba(0, 18, 43, 0.5);
    }
  }
}

@keyframes animationWaitingBar {
  0% {
    transform: translateX(-184%);
  }
  100% {
    transform: translateX(184%);
  }
}

.waiting-bar {
  max-width: 550px;
  background: rgba(212, 212, 212, 0.35);
  border-radius: 2px;
  height: 10px;
  position: relative;
  overflow: hidden;
}

.waiting-bar-indicator {
  background: linear-gradient(269.97deg, #d4d4d4 7.08%, #d7d7d7 98.88%);
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  animation: animationWaitingBar 2s ease-in infinite backwards;
}

.stakeholders-search-banner {
  border-radius: 8px;
  padding: 14px 14px 14px 47px;
  margin: 14px 0px;
  background: $light-blue url('#{$cdn-url}20211026-info-icon-white-1.svg') 18px 18px / 17px 17px no-repeat;
  color: $main-white;
  font-size: 1.025em;
  a {
    color: $main-white;
    text-decoration: underline;
  }
}

.influence-create-topic {
  .add-keyword-list-button {
    background: $light-blue;
    text-align: center;
    border-radius: 5px;
    color: $main-white !important;
    width: auto;
    padding: 0.5em 2.5em;
  }
  .empty-message-no-results {
    max-width: 450px;
  }
}

.stakeholder-list-item-container {
  &:hover > a > li {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px !important;
  }
  &:hover .stake-list-information-mapping {
    width: fit-content;
  }
  &:hover .bracket-relevant-activity {
    display: inherit !important;
  }
}

.stakeholder-list-item-container:has(.stakeholder-checkbox-input:hover):not(div.isContact) {
  &:hover > a > li {
    box-shadow: none !important;
  }
  .bracket-relevant-activity {
    opacity: 0;
  }
}
.bracket-relevant-activity {
  flex-grow: 0;
  div {
    margin-left: 22px;
    position: relative;
    font-size: 0.95em;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #0094cc;
    width: 100%;
    svg {
      margin-left: -50%;
    }
    span {
      display: block;
      margin-left: -50px;
      white-space: nowrap;
    }
  }
  .bracket-relevant-activity-img {
    max-width: 60px;
  }
  height: 100%;
  display: none;
  position: absolute;
}

.stakeholder-selectbar {
  transition: 0.2s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 300;
  min-height: 42px;
  &:hover {
    box-shadow: none;
  }
  .stakeholder-selectbar-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #00122b;
  }
  p {
    margin-bottom: 0 !important;
  }
}
.stakeholder-selectbar-left-side {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.stakeholder-subnav-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: white;
  width: 100%;
  p {
    margin-bottom: 0 !important;
  }
  .stakeholder-subnav-selected-text {
    column-gap: 5px;
    display: flex;
  }
  .stakeholder-subnav-selected-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
  }
}
.stakeholder-subnav-deselect-btn {
  min-height: 38px;
  height: 38px;
  color: rgba(0, 18, 43, 0.75);
  padding: 0 21px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 15px;
  background-color: #fff;
  transition: all 200ms;
  &:hover {
    background: #f5fbfd;
  }
}
.blue-link-underline {
  position: relative;
  color: #0094cc;
  cursor: pointer;
  &:hover:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 5px;
    height: 1.5px;
    background-color: #0094cc;
  }
}
.white-link-underline {
  position: relative;
  color: white;
  cursor: pointer;
  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 5px;
    height: 1.5px;
    background-color: white;
  }
}
.stakeholder-selectbar-actions {
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 18, 43, 0.8);
}

.stakeholder-checkbox-already-added {
  & > input + label {
    background-image: url('#{$cdn-url}stakeholders-tick-blue.svg') !important;
    background-position: 3.5px 3.5px;
    background-size: 14px !important;
    background-repeat: no-repeat;
  }
  & > label {
    border: none !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }
  &:hover label {
    &::before {
      display: none;
    }
  }
}

.stakeholder-checkbox-selected-all:not(.stakeholder-checkbox-already-added) {
  @extend .stakeholder-checkbox-input;
  &:hover label {
    cursor: default;
    &::before {
      display: none;
    }
  }
  & > input:checked + label {
    opacity: 0.6;
    background-color: rgba(0, 28, 53, 0.13) !important;
  }
}

.stakeholder-checkbox-input {
  position: absolute;
  left: 15px;
  top: calc(15px + 0.5rem);
  display: flex;
  align-items: center;
  z-index: 1000;
  & > input {
    visibility: hidden;
    position: absolute;
  }
  & > input:checked + label {
    background: url('#{$cdn-url}stakeholders-tick.svg');
    background-position: 3.5px 3.5px;
    background-size: 11px;
    background-repeat: no-repeat;
  }
  &:hover label {
    &::before {
      content: '';
      width: 34px;
      height: 34px;
      background-color: rgba(0, 28, 53, 0.06);
      position: absolute;
      top: -8.5px;
      left: -8.5px;
      border-radius: 50%;
    }
  }
  & > label {
    box-sizing: border-box;
    width: 19px;
    height: 19px;
    background: #ffffff;
    border: 1px solid rgba(0, 18, 43, 0.3);
    border-radius: 3px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
}
.stakeholder-master-checkbox-input-wrapper {
  display: flex;
  height: 39px;
  min-width: 47px;
  width: 47px;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: white;
}
.stakeholder-master-checkbox-input {
  @extend .stakeholder-checkbox-input;
  position: relative;
  top: 0;
  left: 0;
}

.stakeholder-mapping-list-item {
  &:hover {
    box-shadow: none !important;
  }
}

.tag-button-disabled {
  background-color: rgba($main-grey, 0.4);
  color: rgba($main-blue, 0.5);
  .react-tagsinput-remove {
    cursor: auto;
  }
}

@media screen and (max-width: 480px) {
  .stakeholder-subnav-selected-text {
    font-size: 15px;
  }
}

@media screen and (min-width: 576px) {
  .export-message-popup {
    min-width: 331px;
    max-width: 331px;
  }
}

@media screen and (min-width: 768px) {
  .stake-list-information {
    li {
      font-size: 0.95em;
    }
  }
  .stakeholder-list-item {
    padding-left: 0.5em !important;
  }
  .stakeholder-mapping-list-item {
    & > div {
      padding: 26px 28px 16px 28px !important;
    }
  }
  .stakeholder-page {
    width: calc(100% + 3em);
    margin-left: -1.5em;
  }
  .stakeholder-profile-image {
    width: 95px;
    height: 95px;
  }
  .candidate-profile-image {
    width: 85px !important;
    height: 85px !important;
  }
  .explanations-stakeholders {
    padding-left: calc(95px + 2.5em) !important;
    margin-top: -10px;
  }
  .stakeholder-title {
    width: calc(100% - 110px);
  }
  .stake-holder-no-mogul {
    .action-button {
      min-width: 300px;
    }
  }
}

@media screen and (min-width: 992px) {
  .stakeholder-max-width-item {
    max-width: 57%;
  }

  .profile-section-container {
    border: none;
    background: transparent;
  }

  .stakeholder-section-title-profile {
    padding: 1em 15px 1.5em 15px;
  }
  .stakeholder-mainbar-information {
    border: 1px solid #d4d4d4;
    border-radius: 5px 5px 0px 0px;
  }

  .stakeholder-page {
    padding-top: 2.0625em;
    width: 100%;
    margin-left: 0;
    .hover-content {
      height: 100%;
      border-right: 1px solid #d4d4d4;
    }
    .hover-container-scroll {
      width: calc(100% + 6px);
    }
  }

  .stakeholder-content-page {
    .hover-content {
      height: 100%;
      border-right: 1px solid #d4d4d4;
    }
  }

  .member-email-button {
    &:hover {
      background-color: rgba(0, 148, 204, 0.1);
    }
  }

  .profile-content-actions {
    .item-actions-save {
      &:hover {
        background-color: $hovered-blue !important;
      }
    }
  }
}

@media screen and (min-width: 1176px) {
  .image-stakeholder-nomogul {
    border-left: 1px solid rgba(212, 212, 212, 0.6);
  }
}
