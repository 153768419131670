.video-page-template .module__content-panel--features {
  background: none;
  padding: 0;
}

.video-page-template .features__wrapper {
  display: none;
}

.video-page-template .epyt-video-wrapper {
  box-shadow: 0 1px 7px rgb(0, 0, 0, 0.8);
  border-radius: 10px;
}

.video-page-template .fluid-width-video-wrapper iframe,
.video-page-template .fluid-width-video-wrapper object,
.video-page-template .fluid-width-video-wrapper embed {
  border-radius: 10px;
}
