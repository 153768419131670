.sort-button {
  @extend .general-button;
  color: rgba(0, 18, 43, 0.75);
  padding: 0.3em 1em 0.3em 2.5em;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 20px;
  background: #ffffff url('#{$cdn-url}sort-filter-icon.svg') 15px 9px / 14px auto no-repeat;
  white-space: nowrap;
  font-size: 15px;
  min-height: 34px;
  &:disabled {
    background-color: rgba($background-grey, 0.83);
    color: rgba($main-blue, 0.45);
    background-image: url('#{$cdn-url}sort-filter-icon-grey.svg');
    &:hover {
      background-color: rgba($background-grey, 0.83);
    }
  }
}

.clear-all-stakeholders {
  background: #0094cc;
  padding: 0.3em 1.3em;
  color: white !important;
  border: none;
  &:hover {
    background-color: rgba(#0094cc, 0.8) !important;
  }
}

.open-content-type-button {
  background: transparent;
  border: 1px solid #d4d4d4;
  padding: 0.3em 1.3em;
  color: #00122b !important;
  display: flex;
  &:hover {
    background-color: rgba(#0094cc, 0.04) !important;
  }
  .external-link-lobbying-material {
    margin-left: 9px;
    background-position-y: 5px;
  }
}

.resources-button {
  min-width: 135px;
}

.filter-button {
  background: #ffffff;
  padding: 0.3em 1em 0.3em 1em;
  position: relative;
  font-size: 15px;
  &:disabled {
    background: rgba($background-grey, 0.83) !important;
    color: rgba($main-blue, 0.45);
    &:hover {
      background: rgba($background-grey, 0.83);
    }
  }
}

.search-filter-button {
  background: #fff;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  padding: 0 !important;
  &::after {
    border: none !important;
  }
}

.sort-large-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 168px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.15;
  margin-top: -5px;
}

.button-export-results {
  background: #ffffff url('#{$cdn-url}export-results-icon.svg') 0.75em 9px / 15px auto no-repeat;
  padding-left: 0em !important;
  width: 38px;
  .filter-name {
    display: none;
  }
  &:disabled {
    background-image: url('#{$cdn-url}20220217-export-results-icon-grey.svg');
  }
}

.filter-main-container {
  &:not(:first-child) {
    .filter-button,
    .sort-button {
      margin-left: 0.65em;
    }
  }
  &:not(:last-child) {
    .filters-container,
    .calendar-picker {
      right: 10px;
    }
  }
}

.team-filters-container {
  input {
    min-width: 100px !important;
  }
  @media screen and (min-width: 480px) {
    .simple-search-bar input {
      min-width: unset !important;
    }
  }
}

.consultations-filters-container {
  margin-top: -0.5em;
}

.filters-container {
  position: absolute;
  padding: 1.2rem 1.75rem 0.5rem 1.5rem;
  top: 46px;
  z-index: 10000;
  width: 200px;
  right: 0px;
  box-shadow:
    0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  button {
    display: block;
  }
  .title {
    font-weight: 700;
    margin-bottom: 0.45rem;
    font-size: 1.042em;
  }
}

.filter-options-container {
  padding-top: 0.01em;
}
.filter-options-radio-container {
  padding: 0.5em 0 1.1em 0;
}

.sort-button.active {
  background-color: rgba(0, 148, 204, 0.04);
}

.sort-button-disabled {
  background-color: rgba(249, 249, 249, 0.83);
  color: rgba(0, 18, 43, 0.45);
}

.sort-stakeholder-disabled {
  background-image: url('#{$cdn-url}sort-filter-icon-grey.svg');
}

.sort-stakeholder-search {
  background-image: url('#{$cdn-url}sort-filter-icon-grey-search.svg');
  color: rgba(0, 18, 43, 0.6);
}

.export-button-disabled {
  background-image: url('#{$cdn-url}export-results-icon-grey.svg');
}

.filter-button.active.active-complete {
  &::after {
    border-top: none;
    border-bottom: 6px solid #00122b;
  }
}
.filter-button.active-single {
  color: #0094cc;
  padding-right: 2.2em;
  &:disabled {
    color: rgba(0, 18, 43, 0.45);
  }
  &::after {
    border: none;
  }
}

.house-container {
  width: 220px;
}

.type-container {
  width: 295px;
}

.type-legislation-container {
  width: 248px;
}

.type-consultation-container {
  width: 263px;
}

.search-filter-box {
  width: 330px;
  padding-right: 1.5em;
}

.party-container {
  width: 290px;
  padding: 1.2rem 15px 1.4em 1.1em !important;
  padding-bottom: 1.4em !important;
  .scroll-container {
    height: 350px;
  }
}

.role-container {
  width: 254px;
}
.search-filters-text {
  border: 1px solid rgba(132, 132, 132, 0.5);
  border-radius: 5px;
  padding: 0.25em 0.5em 0.25em 1em;
  padding-left: 0.35em;
  input {
    border: none;
    padding: 0;
    min-width: 235px;
    height: 20px;
    &:focus {
      box-shadow: none;
    }
  }
}

.large-container {
  .checkbox-item {
    label {
      padding-left: 2em !important;
      display: inline-block;
      vertical-align: middle;
      margin-top: -4px;
      line-height: 1.4;
    }
    input[type='checkbox'] {
      display: none;
    }
  }
}

.website-popup-container-lists {
  &.large-container {
    .checkbox-item {
      &:last-child {
        margin-bottom: 8px;
      }
    }
  }
}

.close-filter-active {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s ease-in;
  color: inherit;
  & .icon-close {
    transform: translateY(6px);
  }
  &:hover {
    background-color: rgba(0, 148, 204, 0.08);
  }
}

.filters-indented-list {
  padding-left: 1.8em;
}

.close-button-popup {
  position: absolute;
  top: 10px;
  right: 10px;
}

.search-filter-tooltip {
  background: #00122b !important;
  padding: 0.4em 1em !important;
}

.search-filters-icon {
  text-indent: -1000px;
  width: 15px;
  height: 15px;
  background: url('#{$cdn-url}icon_header_search_grey.svg') center / contain no-repeat;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}

.search-filters-icon-inside {
  width: 18px;
  height: 18px;
  margin-top: 0px;
}

/*==================================
  MOBILE
===================================*/
.filter-mobile-button {
  background: #fff;
  padding-right: 1em;
  white-space: nowrap;
  &::after {
    border: none;
  }
}

.filters-mobile-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}

.filters-mobile-content {
  .filters-container {
    position: relative;
    box-shadow: none;
    width: 100%;
    top: 0;
    padding-top: 1em;
  }
  .tweets-main-filters {
    border: none;
    padding: 0 !important;
    padding-right: 0.6em !important;
  }
  .tweet-filters-container {
    padding-right: 0 !important;
  }
}

.filters-done-button {
  position: fixed;
  bottom: 1.5rem;
  left: 1.3em;
  z-index: 1000;
}

.calendar-picker-container {
  left: 24px !important;
  position: fixed !important;
  top: 70px;
  width: 260px;
}

.filters-mobile-buttons {
  overflow-x: auto;
}

/*==================================
  OLD STYLES
===================================*/
.filter-radio-button {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #f4efed;
  border: 1px solid rgba(0, 18, 43, 0.75);
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}

.filter-radio-button-active {
  &::before {
    background: rgba(0, 18, 43, 0.75);
  }
}

.filter-square-button {
  background: #f4efed;
  border-radius: 2px;
  border: none;
  position: relative;
}

.filter-square-button-active {
  &::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    width: 90%;
    color: #00122b;
    height: auto;
    background: transparent;
    font-size: 0.65rem;
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
  }
}

.consultation-sort-button {
  min-width: 236px;
  margin-top: 0.75em;
  .type-consultation-container {
    right: -30px;
  }
}

.hand-picked-filters {
  padding-right: 1em !important;
  &::after {
    border: none !important;
  }
  &:disabled {
    color: rgba($main-blue, 0.45) !important;
    .hand-picked-toggle {
      background-color: rgba($main-blue, 0.25);
    }
  }
}

.hand-picked-toggle {
  background: #0094cc;
  text-indent: -1000px;
  overflow: hidden;
  width: 27px;
  height: 15px;
  border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  position: relative;
  &::after {
    content: '';
    border-radius: 50%;
    position: absolute;
    width: 11px;
    height: 11px;
    top: 2px;
    left: 14px;
    background: #ffffff;
    transition: 0.2s ease-in;
  }
}

.hand-picked-active {
  color: #0094cc !important;
}

.hand-picked-inactive {
  .hand-picked-toggle {
    background: rgba(0, 18, 43, 0.2);
    &::after {
      left: 2px;
    }
  }
}

.unselectable-option {
  color: rgba(0, 18, 43, 0.7);
}

.filter-item-primary-legislation {
  margin-bottom: 0.65em !important;
}
.filter-item-written-questions-answers {
  margin-bottom: 0.5em !important;
}

@media screen and (min-width: 386px) {
  .consultation-sort-button {
    margin-top: 0em;
    .type-consultation-container {
      right: 0px;
    }
  }
}
.button-export-contacts {
  display: none;
}
@media screen and (min-width: 576px) {
  .sort-button {
    span {
      display: inline-block;
    }
  }

  .parlimentery-sort-button {
    vertical-align: middle;
    margin-top: -5px;
    span {
      display: none;
    }
  }

  .button-export-results {
    background-position: 0.75em 9px;
    padding-left: 2.1em !important;
    width: auto;
    .filter-name {
      display: inline-block;
    }
  }
  .button-export-contacts {
    display: unset;
  }
}
@media screen and (min-width: 768px) {
  .filter-button {
    padding: 0.3em 2.2em 0.3em 1em;
    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #00122b;
      position: absolute;
      top: 44%;
      right: 15px;
    }
    &:disabled {
      &::after {
        border-top: 6px solid rgba($main-blue, 0.45);
      }
    }
  }

  .sort-button-disabled {
    &::after {
      border-top: 6px solid rgba(0, 18, 43, 0.45);
    }
  }

  .parlimentery-sort-button {
    span {
      display: inline-block;
    }
  }
  .sort-large-name {
    max-width: 70px;
  }

  .sort-button {
    .filter-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 1.15;
      max-width: 94px;
      vertical-align: middle;
      margin-top: -5px;
    }
  }

  .sort-button {
    &:hover {
      background-color: rgba(0, 148, 204, 0.04);
    }
  }
  .filter-mobile-stakeholders {
    margin-right: 0.65em;
  }
}

@media screen and (min-width: 992px) {
  .parliamentary-sort-button {
    background: url('#{$cdn-url}filters-icon.svg') 0.65em 14px/15px auto no-repeat;
    padding: 0.35em 1em 0.35em 2em;
    min-height: 38px;
    margin-right: 1.5rem !important;
  }
  .content-filters {
    padding-right: 1.5em;
  }

  .stakeholders-filter-button {
    //margin-right: 2em !important;
  }

  .filters-mobile-content {
    .tweets-main-filters {
      height: 68vh !important;
    }
  }
}

@media screen and (min-width: 1280px) {
  .sort-button {
    .filter-name {
      max-width: none;
    }
  }
  .sort-large-name {
    max-width: 168px;
  }
  .sort-button {
    img {
      margin-right: 0.85rem;
    }
    span {
      display: inline-block;
    }
  }
}

.onlysort-filters-button {
  border: 1px solid #d4d4d4;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
}
