.company-name-create-a-topic {
  left: 0;
  width: 124px;
  height: 25px;
  margin-bottom: 1.3em;
}

.create-topic-subtext {
  color: rgba($main-white, 0.8);
}

.create-topic-tips-box {
  max-width: 350px;
}

.signup-topic-list {
  padding-left: 20px;
  margin-left: 0;
}

.weak-keywords,
.strong-keywords {
  padding-left: 0px;
  list-style: none;
  li {
    padding-left: 24px;
    background-position: 0px 5px;
    background-size: 14px auto;
    background-repeat: no-repeat;
  }
}

.weak-keywords {
  li {
    background-image: url('#{$cdn-url}20221005-red-close-icon.svg');
    background-position: 0px 6px;
  }
}

.strong-keywords {
  li {
    background-image: url('#{$cdn-url}20221005-green-tick.svg');
  }
}

.include-items {
  li {
    background-image: url('#{$cdn-url}20221005-blue-tick.svg');
  }
}

.agency-keywords {
  li {
    font-style: italic;
  }
}

.agency-keywords.strong-keywords {
  li {
    &:last-child {
      font-style: normal;
    }
  }
}

.sign-up-create-container {
  max-width: 1000px;
}

.arrow-pointer {
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid $salmon;
  right: -10px;
  transition: 0.2s ease-in-out;
}

.signup-topic-input {
  height: 42px;
  padding-bottom: 8px;
  &:focus {
    border: none !important;
  }
}

@media screen and (min-width: 992px) {
  .create-topic-content-box {
    max-width: calc(100% - 350px);
  }
}
