$code-icon: '#{$cdn-url}20210713-code-icon.svg';
.web-feed-images {
  background: rgba(#f4efed, 0.15);
  border-radius: 8px 8px 0px 0px;
}

.webfeeds-image {
  background: #fff;
  border: 1px solid rgba(212, 212, 212, 0.5);
  border-radius: 5px;
  img {
    border-radius: 5px 5px 0px 0px;
  }
}

.webfeeds-container {
  padding-top: 12px;
  .action-button {
    margin-bottom: 15px;
  }
  p {
    font-size: 1.05em;
    max-width: 300px;
    color: rgba(0, 18, 43, 0.6);
  }
}

.iframe-code {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  color: rgba(0, 18, 43, 0.5);
  padding-bottom: 10px;
}

.simple-link-button {
  color: $light-blue;
  &:hover {
    color: $light-blue !important;
    text-decoration: underline;
  }
  &:disabled {
    color: $main-grey !important;
    &:hover {
      color: $main-grey !important;
    }
  }
}
button.simple-link-button {
  &:disabled {
    &:hover {
      text-decoration: none;
    }
  }
}

.simple-link-button.text-red {
  color: $main-red;
  &:hover {
    color: $main-red !important;
  }
}

.action-delete-text {
  color: #e46666 !important;
  &:hover {
    color: #e46666 !important;
  }
}

.copy-action-button {
  background: #0094cc url('#{$cdn-url}copy-link-white.svg') 11px 7px /15px auto no-repeat !important;
  padding-left: 36px !important;
  &:hover {
    background-color: rgba($hover-blue, 0.8) !important;
  }
}

.keywordlist-select {
  padding-left: 35px !important;
  background: url('#{$cdn-url}keywordlist-icon.svg') 10px 9px / 15px auto no-repeat;
}

.webfeeds-create-topic {
  color: #0094cc !important;
  p {
    padding-left: 35px !important;
    background: url('#{$cdn-url}keywordlist-icon-blue.svg') 10px 8px / 15px auto no-repeat;
    &::after {
      border: none;
    }
  }
  &:hover {
    text-decoration: none !important;
  }
}

.overlap-options {
  top: 0px;
  border-radius: 3px;
  label {
    font-weight: 400 !important;
    font-size: 1em !important;
  }
}

.indented-checkbox-item {
  &::before {
    top: 2px !important;
  }
  label {
    display: block !important;
    height: auto !important;
    padding: 0 0 0 34px !important;
    &::before,
    &::after {
      top: 2px !important;
    }
  }
}

.feed-tooltip {
  max-width: 350px;
}

.billing-tooltip {
  max-width: 280px;
}

.rounded-button {
  border-radius: 5px;
}

.outline-button {
  background: #fff;
  color: rba(#00122b, 0.7);
  border: 1px solid #d4d4d4;
  &:not(&:disabled):hover {
    color: #00122b;
    background-color: rgba(0, 148, 204, 0.04) !important;
  }
}

.code-outline-button {
  display: none;
  padding-left: 40px !important;
  background: url($code-icon) 14px 15px/16px auto no-repeat;
  &:hover {
    background: #fff url($code-icon) 14px 15px/16px auto no-repeat;
  }
}

.uponclick-radio-button {
  padding-top: 0.3em;
  margin-bottom: 0.7em;
  label {
    height: auto;
  }
}

.website-feeds-modal {
  .hour-select {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.keyword-lists-website-feeds {
  .add-keyword-list-button {
    margin-bottom: 0;
    border-radius: 3px;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
  }
  .login-navbar {
    margin-top: -8px;
    top: 0px;
    position: relative;
    box-shadow: none;
    z-index: 0;
    width: calc(100% + 32px);
    margin-left: -17px;
    .settings-button {
      padding: 0.5em 1em;
    }
  }
}

.website-feed-topics-variant {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 16px;
  margin-bottom: 0 !important ;
  &:not(&:has(input:disabled)):hover {
    background-color: rgba(#0094cc, 0.04);
  }
  &:has(input:disabled) {
    p,
    .icon-topics-thin {
      opacity: 0.5;
    }
  }
  & > label {
    width: 96%;
    cursor: pointer;
    margin-bottom: 0;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 40px) !important;
    }
  }
  .topic-selected-mark {
    top: 25%;
  }
  & input:disabled + span + label {
    cursor: default !important;
  }
  & .general-button.topic-description-field {
    color: #0094cc;
    font-size: 16px;
  }
}
.website-feed-topics-variant.dropdown-keywordlist {
  &:last-child {
    margin-bottom: 5px !important;
  }
}
.dropdown-firstItem {
  border-bottom: 1px solid #dee2e6;
  cursor: default !important;
  padding-top: 0 !important;
  & p {
    cursor: default !important;
  }
  &:hover {
    background-color: transparent !important;
  }
}
.website-list-scroll-component-topics {
  .ScrollbarsCustom-Content {
    padding: 0 !important;
  }
  .ScrollbarsCustom-TrackY {
    right: 1px !important;
  }
}

.webfeed-popup-close {
  display: block !important;

  &:hover {
    background-color: transparent;
  }
}

.general-dropdown {
  overflow: hidden;
}

.website-popup-title {
  border-top: 1px solid #dee2e6;
  padding-top: 17px;
  .report-design-button {
    min-height: 38px;
  }
}

.website-popup-settings-title {
  text-transform: capitalize;
}

.analytics-popup-basis {
  flex-basis: auto !important;
}

.website-feeds-popup-settings {
  border-top: none !important;
  .hour-select {
    background-color: $main-white;
    color: rgba($main-blue, 0.75);
  }
  .main-input {
    border: 1px solid rgba($main-grey, 0.6);
  }
  .topic-email-alerts-content {
    label {
      font-size: 1em !important;
    }
  }
  .resources-dropdown {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
  }
  .keywords-website-settings {
    .frequency-dropdown {
      max-height: 292px;
    }
  }
  .hour-select-opendropdown {
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.12) !important;
  }
  .general-dropdown,
  .resources-dropdown {
    box-shadow:
      0 1px 3px rgba(60, 63, 66, 0.12),
      0 4px 10px rgba(60, 63, 66, 0.05);
  }

  .website-list-scroll-component {
    .ScrollbarsCustom-Content {
      padding: 0.5em 1em 0em 1em !important;
    }
  }
  .website-feed-topics {
    .ScrollbarsCustom-Content {
      overflow-x: hidden !important;
      &.website-feed-topics-windows {
        padding-bottom: 15px !important;
      }
    }
  }
  .master-topic-option {
    width: calc(100% + 2em);
    margin-left: -1em;
    border-bottom: 1px solid rgba($main-grey, 0.5);
    padding-left: 1em !important;
    padding-bottom: 1em !important;
  }
  .client-picker {
    min-width: 100% !important;
    margin: 0em;
    background-color: white;
    border-radius: 5px;
    z-index: 0 !important;
    &:hover {
      box-shadow: none;
    }
    .search-dropdown {
      border: none;
      &:hover {
        background-color: white;
      }
    }
  }
  .dropdown-keywords-lists-open {
    border-radius: 5px 5px 0px 0px;
  }
}

.website-feed-embed-main-container {
  .embed-content {
    zoom: 0.65;
    border-radius: 5px;
  }
  .title-widget-lists {
    .ScrollbarsCustom-Content {
      width: calc(100% + 26px);
      overflow-x: hidden !important;
      padding-bottom: 17px;
    }
  }
  .embed-content-single {
    zoom: 1;
  }
  .element-with-scroll {
    margin-right: -28px !important;
  }
  .embed-link-bottom {
    background: #f4efed;
  }
  .content-new-title {
    width: 100% !important;
    padding-right: 0 !important;
  }
}

.title-widget-lists {
  .dropdown-keywordlist {
    padding: 0.6em 1em;
    font-size: 0.91em !important;
  }
}

@media screen and (min-width: 992px) {
  .website-popup-settings-title {
    text-transform: lowercase;
  }

  .analytics-popup-basis {
    flex-basis: 100% !important;
  }

  .webfeed-popup-close {
    &:hover {
      background-color: rgba(0, 18, 43, 0.1);
    }
  }
  .web-feed-images {
    border-radius: 0px 8px 0px 0px;
    .action-button {
      margin-top: 30px;
    }
  }

  .code-outline-button {
    display: inline-block;
  }
  .webfeeds-container {
    padding-left: 37px;
    .action-button {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  .webfeed-popup-close {
    right: 20px;
    top: 15px;
  }
}
