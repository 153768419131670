.resize-handle-influence-column-container {
  display: flex !important;
  justify-content: center;
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 14px;
  right: -7px;
  top: -5px;
  z-index: 1;
  padding: 0 !important;
  .resize-handle-influence-column {
    border-right: 2px solid transparent;
    height: 100%;
    width: 1px;
  }

  &:hover {
    & .resize-handle-influence-column {
      border-color: #ccc;
    }
  }

  .resize-handle-influence-column.active-column-influence {
    border-color: #517ea5;
  }
}
