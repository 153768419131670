.top-nav-create-email-button {
  background-color: $light-blue;
  color: #fff;
  padding: 0.45em 1.5em;
  border: none;
  background-image: none;
  line-height: 24.5px;
  transition: background-color 200ms !important;
  &:hover {
    text-decoration: none;
    background-color: rgba($light-blue, 0.8);
    color: #fff;
  }
}
.top-nav-create-email-button-disabled {
  cursor: default;
  background-color: #f9f9f9 !important;
  color: rgba(0, 18, 43, 0.5) !important;
  border: 1px solid rgb(244, 239, 237) !important;
}

.top-nav-create-contact-button {
  @extend .top-nav-create-email-button;
  position: relative;
}

.top-nav-create-contact-button.action-delete-button {
  background-color: #e46666;
  &:hover {
    background: rgba(#e46666, 0.8);
  }
}

.top-nav-create-contact-dropdown {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  .create-contact-dropdown-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #ffffff;
    line-height: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }
}
.top-nav-create-contact-dropdown-expanded {
  .create-contact-dropdown-arrow {
    transform: rotate(180deg);
  }
}
.top-nav-create-contact-dropdown + .open-dropdown {
  width: 215px;
  position: absolute;
  top: 40px;
  z-index: 9999;
  border-radius: 5px;
  .open-dropdown-option:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .open-dropdown-option {
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    cursor: pointer;
    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:hover {
      background-color: #fafafa !important;
    }
  }
  .filter-item-dropdown {
    padding-left: 25px;
  }
}
.search-filter-container-sub-nav {
  gap: 10px;
}

.back-to-emails-button {
  font-size: 20px;
  line-height: 30px;
  color: $light-blue;
  padding-left: 38px;
  cursor: pointer;
  background-image: url('#{$cdn-url}back-to-emails-arrow.svg');
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: 0 4.5px;
  transition: opacity 200ms;
  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: $light-blue;
  }
}

.email-component-sub-nav-title {
  font-size: 17px;
  line-height: 30px;
  color: #00122b;
}

.email-component-sub-nav-title-container {
  display: flex;
  gap: 28px;
  align-items: center;
  max-width: calc(100% - 358px);
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.email-component-sub-nav {
  min-height: 26px;
  max-width: calc(100% - 135px);
}

.subnav-email-button-container {
  display: flex;
  gap: 12px;
}
.subnav-email-button-container-performance {
  padding-right: 48px;
}
.subnav-email-button-container-email {
  padding-right: 280px;
}

.subnav-email-button {
  background-color: #ffffff;
  border-radius: 5px;
  width: 106px;
  height: 40px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 0;
  transition:
    opacity 200ms,
    background-color 200ms;
  &:hover {
    background-color: rgba(0, 148, 204, 0.04);
  }
  &:disabled {
    opacity: 1 !important;
    background-color: #f9f9f9;
    color: rgba(0, 18, 43, 0.5);
    border: 1px solid rgba(0, 18, 43, 0.1);
  }
}

.email-component-action-button-discard {
  border: 1px solid #9b171f;
  color: #9b171f;
}
.email-component-action-button-preview,
.email-component-action-button-save-draft {
  border: 1px solid #dee2e6;
  color: rgba(0, 18, 43, 0.8);
}

.email-component-action-button-send {
  border: none;
  color: #fff;
  background-color: $light-blue;
  &:hover {
    background-color: rgba($light-blue, 0.9);
  }
  &:disabled {
    opacity: 1 !important;
    background-color: #f9f9f9;
    color: rgba(0, 18, 43, 0.5);
    border: 1px solid rgb(244, 239, 237);
  }
}
.send-email-trial {
  border: 1px solid rgba(0, 18, 43, 0.15) !important;
}

.influence-subnav-wrapper {
  display: flex;
  position: absolute;
  width: calc(100% - 344px);
  max-width: 1094px;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: calc(100% - 326px);
  }
  @media screen and (max-width: 992px) {
    width: 100% !important;
    padding-right: 3rem !important;
  }
  @media screen and (max-width: 767px) {
    padding-right: 2rem !important;
  }
}

.add-to-contact-mobile-button {
  background: url(https://cdn.policymogul.com/images/plus-rounded-filled-thin-blue.svg) no-repeat;
  background-position: center -2px;
  background-size: 41px;
  min-width: 38px;
  min-height: 38px;
  border: none;
  padding: 0;
  margin-left: 16px;
  text-indent: -100000px;
  overflow: hidden;
}
.crm-contacts-list-bar {
  z-index: 100;
  color: #fff;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 30px;
  display: flex;
  align-items: center;
  column-gap: 23px;
  row-gap: 10px;
  position: relative;
  .contacts-list-bar-helper-text-wrapper {
    @media screen and (min-width: 992px) {
      position: absolute;
      left: -265px;
    }
  }
  .contacts-list-bar-helper-text {
    margin: 0;
    display: flex;
    & > span {
      white-space: nowrap;
    }

    .selected-qntty {
      transform: translateY(100%);
      animation-name: selected-animation;
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.6, 0.9, 0.58, 0.77);
      position: relative;
      color: transparent;
    }
    .selected-qntty-decreased {
      @extend .selected-qntty;
      transform: translateY(-100%);
    }
    .contacts-list-bar-helper-text-link {
      color: #e40741;
      cursor: pointer;
      margin-left: 10px;
      transition: all 200ms;
      position: relative;
      &:hover:after {
        content: '';
        display: block;
        width: calc(100%);
        position: absolute;
        bottom: 5px;
        height: 1.5px;
        background-color: #e40741;
      }
    }
  }
}
@keyframes selected-animation {
  100% {
    transform: translateY(0);
    color: #fff;
  }
}
.contacts-list-bar-action-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  column-gap: 10px;
}
.action-container-expanded {
  column-gap: 4px !important;
}

.contacts-list-bar-action-container-btn {
  min-height: 38px;
  height: 38px;
  color: #0094cc;
  padding: 0 16px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 15px;
  background-color: #fff;
  transition: all 200ms;
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  position: relative;
  outline: none;
  &:hover {
    background: #f5fbfd;
  }

  &:disabled {
    color: rgba(0, 18, 43, 0.4);
  }
  &.unverified-email {
    color: rgba(0, 18, 43, 0.4);
  }
}

.remove-contact-from-group-button {
  color: #8f072b;
}

.contacts-list-bar-action-container {
  .icon-download {
    font-size: 15px;
    display: inline-block;
    transform: translateY(1.5px);
  }

  .icon-email {
    font-size: 13px;
    display: inline-block;
    transform: translateY(1px);
  }

  .icon-tag {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    transform: translateY(1px);
  }

  .icon-close {
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    transform: translateY(1.5px);
    padding-left: 2px;
    padding-right: 2px;
  }

  .icon-tag-disabled,
  .icon-email-disabled,
  .icon-download-disabled,
  .icon-close-disabled,
  .icon-arrow-down-disabled {
    color: rgba(#00122b, 0.7);
    opacity: 0.5;
  }
  .icon-info {
    font-size: 14px;
    display: inline-block;
    transform: translateY(1px);
  }
  .icon-arrow-down {
    font-size: 7px;
    display: inline-block;
    transform: translateY(2px);
    transition: all 200ms;
    &-expanded {
      transform: rotate(180deg);
    }
  }
  .login-navbar {
    width: 246px;
    top: 46px;
    button {
      &:has(.icon-close) {
        color: #9b171f;
      }

      padding-left: 20px;
      &:disabled {
        color: rgba(0, 18, 43, 0.4);
      }
      .icon-close-disabled {
        color: rgba(#00122b, 0.7);
        opacity: 0.5;
      }
    }
    .icon-close {
      display: inline-block;
      transform: translateY(-1px);
    }
    .icon-tag {
      display: inline-block;
      transform: translateY(2px);
    }
  }
}

.action-container-btn-expanded {
  width: auto !important;
  padding: 0 16px;
}

@media screen and (max-width: 992px) {
  .subnav-email-button-container-performance,
  .subnav-email-button-container-email {
    padding-right: 0px;
  }
}
@media screen and (max-width: 820px) {
  .contacts-list-bar-action-container {
    column-gap: 10px;
    .contacts-list-bar-action-container-btn {
      line-height: 25px;
      column-gap: 7px;
    }
  }
}

@media screen and (max-width: 677px) {
  .contacts-list-bar-action-container-btn {
    padding: 0;
    width: 38px;
    justify-content: center;
    .contacts-list-bar-add-tag {
      background-position: -1px 2px;
    }
    .more-option-button {
      padding-right: 10px;
      &::after {
        top: -2px;
      }
    }
  }
  .contacts-list-bar-action-container {
    column-gap: 12px;
  }
}

@media screen and (max-width: 768px) {
  .subnav-email-button-container {
    position: absolute;
    top: 64px;
    left: 1px;
    z-index: 2;
  }
  .subnav-email-button-container.on-trial {
    top: 51px;
  }
  .side-left-link {
    display: none;
  }
  .email-component-sub-nav-title-container {
    justify-content: space-between;
    width: inherit;
  }
  .email-component-sub-nav {
    max-width: unset;
  }
  .email-component-sub-nav-title-container {
    max-width: unset;
  }
}

@media screen and (max-width: 480px) {
  .crm-contacts-list-bar {
    font-size: 15px;
    column-gap: 15px;
  }
  .subnav-email-button {
    width: 90px;
  }
  .subnav-email-button-container {
    gap: 7px;
  }
}

@media screen and (max-width: 992) {
  .top-nav-create-contact-button {
    min-width: 173px;
  }
}
