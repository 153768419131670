.saved-item-element {
  border: 1px solid #ccd6dd;
  background-color: #fff;
  border-radius: 15px;
  transition: 0.2s ease-in;
  padding: 16px 24px 24px 30px;
  .dot {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    background-color: rgba(#00122b, 0.8);
  }
  .popup-individual-item {
    border: none !important;
  }
}

.save-items-elements-list {
  .content-item-link {
    margin-bottom: 16px;
    &:hover {
      text-decoration: none;
    }
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  .twitter-actions-container {
    margin-right: -11px;
  }
}
