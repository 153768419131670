.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal-title {
  p {
    color: rgba(0, 28, 53, 0.7);
  }
}
