.create-profile-container {
  position: relative;
  height: 100%;
  margin: 0 auto;
  background: #f4efed;
  .company-name {
    left: 1rem;
    top: 1rem;
    position: fixed;
    width: 134px;
    height: 26px;
  }
  label {
    font-weight: 700;
    span {
      color: #d21212;
    }
  }
  .react-tagsinput-input {
    &:focus {
      border: none;
    }
  }
  .keywords-category-words,
  .react-tagsinput {
    background: #fcfcfc;
  }
  .react-tagsinput {
    padding-top: 0px;
  }
}
.create-profile-general-content {
  z-index: 1;
  position: relative;
}

.create-profile-content {
  min-height: 531px;
  position: relative;
  z-index: 2;
  h2 {
    font-size: 1.325em;
    font-weight: 700;
  }
  p {
    color: rgba(0, 28, 53, 0.4);
  }
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  z-index: 100;
  input,
  select {
    &:focus {
      border: 1px solid #000;
      box-shadow: none;
    }
  }
  select {
    color: #000;
    &:invalid {
      color: rgba(#000, 0.4);
    }
    option {
      color: #000;
    }
  }
}

.create-profile-button {
  background: #0094cc url('#{$cdn-url}202103241-sign-up-left-arrow.svg') calc(100% - 12px) center / 16px auto no-repeat;
  padding-right: 45px !important;
  &:hover {
    background: rgba(0, 148, 204, 0.8) url('#{$cdn-url}202103241-sign-up-left-arrow.svg') calc(100% - 12px) center /
      16px auto no-repeat;
  }
}

.create-profile-steps {
  color: #001c35;
  background-color: #f4efed;
  z-index: 1;
  position: relative;
}

.create-profile-step {
  height: 5px;
  width: 100%;
  background: rgb(213, 209, 208);
  border-radius: 1px;
  position: relative;
  &::before {
    content: '';
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #828282;
    transition: 0.2s ease-in;
  }
}

.create-profile-step-2 {
  &::before {
    width: 100%;
    border-radius: 5px;
  }
}

.create-first-keywords-instruction {
  color: #001c35 !important;
  font-weight: 700;
}

.profile-error-message {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  background: #fff url('#{$cdn-url}warning-icon.svg') 10px 11px / 20px auto no-repeat;
  margin-top: 0.5em;
  border-radius: 5px;
  padding-left: 40px !important;
  color: #000 !important;
}

.create-profile-awaits {
  h2 {
    font-size: 2em;
    margin-bottom: 1.5em;
  }
  p {
    color: rgba(0, 18, 43, 0.9);
  }
}

.resend-button {
  margin-top: 2.5em;
  color: rgba(0, 18, 43, 0.6);
  text-decoration: underline;
}

.create-name-topic-input {
  background: rgba(249, 249, 249, 0.5);
}

.create-subtext {
  font-size: 0.925em;
  opacity: 0.6;
}

.keywords-category-words-onboarding {
  min-height: 176px !important;
  border: 1px solid #ced4da;
  border-top: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  .react-tagsinput {
    padding-top: 3px;
  }
  &.keywords-container-focused {
    border-top: none !important;
    border: 1px solid #ced4da;
  }
  &.keywords-category-words-onboarding-suggestions {
    min-height: 130px !important;
  }
}

.keywords-container-focused {
  border: 1px solid #000;
}

@media screen and (min-width: 500px) {
  .create-profile-content {
    min-width: 500px;
    max-width: 500px;
  }
}
@media screen and (min-width: 576px) {
  .create-profile-content {
    min-height: 572px;
  }
}

@media screen and (min-width: 992px) {
  .create-profile-container {
    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      background: #001c35;
      clip-path: polygon(0 90%, 100% 65%, 100% 100%, 0% 100%);
    }
    .company-name {
      left: 2.2rem;
      top: 3.2rem;
      width: 155px;
      height: 31px;
    }
  }
}
