.search-modal {
  .modal__overlay {
    align-items: start !important;
    padding-top: 10vh;
  }
}

.modal-calendar {
  .calendar-picker {
    padding: 24px;
    min-width: auto;
    top: 0;
  }
}

.transparent-close-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
}

.modal-slide-text {
  font-size: 1.03em;
}
