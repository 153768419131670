.save-item-side-nav {
  color: $main-blue;
  background: url('#{$cdn-url}20211231-saved-item-icon-blue.svg') 38px 13px/14px auto no-repeat;
  .dot {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    background-color: rgba($main-blue, 0.6);
  }
}

.mobile-list-sidenav-folders {
  .save-item-side-nav {
    padding-left: 38px !important;
    background-position: 13px 15px;
  }
}

.save-item-side-nav.active {
  background-image: url('#{$cdn-url}20211231-saved-item-icon-light-blue.svg');
}

.add-folder-button {
  background: url('#{$cdn-url}20211229-plus-icon-light-blue.svg') 38px 13px/ 13px auto no-repeat;
  padding: 8px 0px 8px 65px;
  display: block;
  width: 100%;
  text-align: left;
  border-radius: 0px 5px 5px 0px;
}

.saved-item-folder-button {
  padding-left: 38px;
  background-position: 14px 13px;
}

@media screen and (min-width: 992px) {
  .settings-navigation {
    .save-item-side-nav {
      padding-left: 65px !important;
    }
  }
  .add-folder-button {
    transition: 0.2s ease-in;
    &:hover {
      color: $light-blue;
      background-color: rgba(244, 239, 237, 0.6);
    }
  }
}
