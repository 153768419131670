.main-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.d-inline-block-centered {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

@for $i from 1 through 20 {
  .centered-#{$i} {
    margin-top: -#{$i}px;
  }
}

@for $i from 1 through 20 {
  .centered-#{$i} {
    margin-top: -#{$i}px;
  }
}

.basis-full {
  flex-basis: 100%;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}
.z-200 {
  z-index: 200;
}

.z-max {
  z-index: 1001;
}

.z-extra-max {
  z-index: 10000;
}

@media screen and (min-width: 992px) {
  .main-sticky-lg {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
