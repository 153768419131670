.analytics-sidenav {
  font-size: 15px;
  .icon-container-team {
    padding-top: 2px;
  }
  .icon-container-location,
  .icon-container-graph {
    padding-left: 2px;
    margin-right: -2px;
  }
}

.most-vocal-widget {
  a {
    &:hover {
      h4 {
        color: $light-blue;
      }
    }
  }
}

@keyframes animation {
  from {
    background-color: #ecf7fb;
  }
  to {
    background-color: #ffffff;
  }
}

.most-vocal-main-container {
  padding-top: 18px;
  .hour-select {
    background-color: $main-white;
  }
}

.most-vocal-main-container.active {
  animation-name: animation;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

.analytics-share-icon-container {
  min-width: 24px;
  text-align: center;
}

.analytics-logged-out-share-button {
  width: 38px;
  height: 38px;
  padding-top: 6px !important;
}
.stakeholders-analytics-popup-top-container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.stakeholder-analytics-image {
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  object-fit: cover;
  border-radius: 50%;
}

.report-analytics-stakeholder-popup {
  .modal-close-button {
    background-color: $main-white;
    top: 15px;
  }
}

@media screen and (min-width: 992px) {
  .analytics-logged-out-share-button {
    padding-top: 8px !important;
    width: auto;
  }

  .report-analytics-stakeholder-popup {
    .react-modal-content {
      margin-top: -50px;
    }
  }
}
