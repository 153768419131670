.email-component-container,
.influence-description,
.signature-text-editor {
  .tox .tox-edit-area {
    &::before {
      border: none;
    }
  }
  .tox-statusbar {
    display: none !important;
  }
  .tox-tinymce {
    border: none;
  }
  .tox-edit-area {
    &::before {
      border: none !important;
    }
  }
  .tox-toolbar__group {
    border-right: 1px solid rgba(212, 212, 212, 0.5) !important;
    padding: 0 !important;
    .tox-tbtn {
      &:first-child {
        padding-right: 15px;
        padding-left: 27px;
      }
      &:last-child {
        padding-left: 15px;
        padding-right: 27px;
      }
      margin: 0 !important;
      height: 100% !important;
      &:hover {
        background-color: rgba(0, 148, 204, 0.04) !important;
      }
      &--disabled {
        cursor: default !important;

        span {
          opacity: 0.5;
        }
        &:hover {
          background-color: #f9f9f9 !important;
        }
      }
    }
  }
  .tox-tbtn__select-chevron,
  .tox-tbtn__select-label {
    display: none !important;
    font-size: 15px;
    margin-left: 8px !important;
  }

  .tox-tbtn--select {
    padding-left: 15px !important;
    padding-right: 15px !important;
    &:has(.salutation-toolbar-button),
    &:has(#signature_btn),
    &:has(.insert-image-toolbar-button) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: auto !important;
    }
    .signature-toolbar-button {
      height: 24px;
      padding: 10px 15px 8px 6px;
      box-sizing: content-box !important;
    }
    #signature_btn {
      position: absolute;
      top: -1px;
      display: block;
      height: 49px;
      width: 45px;
      transform: translateY(2px);
    }
    .salutation-toolbar-button {
      transform: translateY(1px);
      padding: 15px 10px 15px 15px;
    }
    .insert-image-toolbar-button {
      height: 26px;
      transform: translateY(1px);
      padding: 11px 10px 7px 10px;
      box-sizing: content-box !important;
    }
  }
  .tox-tbtn--enabled {
    background: #ebf7fb !important;
  }
  .tox-tinymce {
    border-radius: 5px;
  }
  .tox-tbtn--enabled svg,
  .tox .tox-tbtn--enabled:hover svg {
    fill: #0094cc !important;
  }
}

.tox-tooltip {
  display: none !important;
}

.insert-trackable-link-btn {
  width: 187px !important;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  .tox-tbtn__select-label {
    display: inline-block !important;
    font-family: 'Lato', sans-serif;
    color: #00122b;
    cursor: pointer;
  }
  @media screen and (max-width: 430px) {
    .tox-tbtn__select-label {
      display: none !important;
    }
    width: 53px !important;
  }
}
.insert-related-content-btn {
  width: 162px !important;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  .tox-tbtn__select-label {
    display: inline-block !important;
    font-family: 'Lato', sans-serif;
    color: #00122b;
    cursor: pointer;
    margin-right: 15px;
  }
  @media screen and (max-width: 430px) {
    .tox-tbtn__select-label {
      display: none !important;
    }
    width: 53px !important;
  }
}

.email-component-container,
.signature-text-editor {
  .tox-tinymce--disabled {
    height: calc(100% - 78px) !important;
  }
  .tox-editor-header {
    padding: 0 !important;
    border: none !important;
  }
  .tox-toolbar__primary,
  .tox-toolbar--scrolling {
    border-top: 1px solid rgba(212, 212, 212, 0.5) !important;
    height: 50px;
    background: none !important;
  }
  .tox-toolbar__group:nth-last-of-type(2) {
    .tox-tbtn {
      @extend .insert-trackable-link-btn;
    }
  }
  .tox-toolbar__group:last-of-type {
    .tox-tbtn {
      @extend .insert-related-content-btn;
    }
  }
  .tox-tbtn--select:last-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 53px !important;
  }
}

.signature-text-editor {
  .tox-toolbar__group:last-of-type,
  .tox-toolbar__group:nth-last-of-type(1),
  .tox-toolbar__group:nth-last-of-type(2),
  .tox-toolbar__group:nth-last-of-type(3) {
    visibility: hidden;
  }
  #upload-image {
    display: none;
  }
}

.influence-description {
  .tox-tbtn__icon-wrap {
    transform: scale(0.85) !important;
  }
  .tox-editor-header {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
  .tox-toolbar__primary,
  .tox-toolbar--scrolling {
    border-bottom: 1px solid rgba(212, 212, 212, 0.5) !important;
    height: 43px;
    background: none !important;
  }
  .tox-editor-container {
    min-height: 200px;
    border: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: 5px;
    margin-bottom: 1.65em;
  }
  .tox-toolbar__group {
    .tox-tbtn {
      border-radius: 0;
      &:last-child {
        padding-left: 19px;
        padding-right: 19px;
      }
    }
  }
}

.tox:has(.tox-dialog) {
  z-index: 10002 !important;
}
.tox-dialog-wrap__backdrop {
  transition: all 150ms !important;
  background-color: rgba(2, 35, 68, 0.45) !important;
}
.tox-dialog {
  border-radius: 8px !important;
  .tox-form__group {
    .tox-label {
      font-size: 1.03em;
      font-weight: 700;
      margin-bottom: 0rem;
      color: #00122b;
      font-family: 'Lato', sans-serif;
    }
    .tox-textfield {
      border: 1px solid rgba(132, 132, 132, 0.3);
      border-radius: 5px;
      padding: 0.35em 0.75em;
      outline: none;
      height: auto !important;
      width: 100%;
      transition: 0.2s ease-in;
      background: #f9f9f9;
      margin-top: 8px;
      font-family: 'Lato', sans-serif;
      &:focus {
        border: 1px solid #00122b;
        box-shadow: none;
        background: #f9f9f9;
      }
    }
  }
  .tox-dialog__body-content {
    padding: 24px 40px !important;
    min-height: 200px;
  }
  .tox-form__group:last-child {
    display: none;
  }
  .tox-form {
    gap: 24px;
  }
  .tox-dialog__header {
    border-bottom: 0.5px solid #ececec !important;
    padding-left: 40px !important;
    padding-top: 24px !important;
    padding-bottom: 16px !important;
    .tox-icon {
      path {
        stroke: #fff;
      }
    }
    .tox-button--icon {
      border-radius: 50%;
      color: rgba(0, 18, 43, 0.5) !important;
      transform: translateY(-13px);
    }
    .tox-button--icon:hover {
      background-color: rgba(0, 18, 43, 0.1) !important;
      path {
        stroke: #e5e7e9;
      }
    }

    .tox-dialog__title {
      font-size: 1.075em !important;
      font-weight: 700;
      color: #00122b;
      font-family: 'Lato', sans-serif;
    }
  }
  .tox-dialog__footer {
    background-color: #f4efed !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    padding-right: 1.5em !important;
    border-top: 1px solid rgba(212, 212, 212, 0.5) !important;
    .tox-button:not(.tox-button--secondary) {
      background-color: #0094cc;
      border: none;
      color: #fff;
      border-radius: 5px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      transition: all 200ms;
      padding: 8px 16px;
      font-size: 1.075em !important;
      &:hover {
        background-color: rgba(0, 148, 204, 0.8) !important;
      }
    }
    .tox-button--secondary {
      color: #0094cc !important;
      font-family: 'Lato', sans-serif !important;
      font-weight: 400;
      font-size: 1.075em !important;
      border: none;
      &:hover {
        background-color: transparent !important;
        color: rgba(0, 148, 204, 0.8) !important;
        border: none;
      }
    }
  }
  .tox-button {
    &:focus {
      &:before {
        box-shadow: none !important;
      }
    }
  }
}
