.current-folder-name {
  width: 100%;
  line-height: 1.1;
  display: -webkit-box;
}

.saved-more-button {
  border: 1px solid $main-grey;
  width: 34px;
  height: 34px;
  background: $main-white url('#{$cdn-url}20211229-horizontal-dots-blue.svg') center / 14px auto no-repeat;
}

.resources-container {
  width: 240px;
}

.folder-actions {
  padding: 8px 0px;
}

.folder-action-button {
  padding: 5px 10px 5px 38px;
  display: block;
  width: 100%;
  text-align: left;
  background-size: 13px auto;
  background-repeat: no-repeat;
  background-position: 15px 11px;
}

.folder-action-rename {
  background-image: url('#{$cdn-url}20211229-edit-icon-dark-blue.svg');
}

.folder-action-delete {
  background-image: url('#{$cdn-url}20211229-delete-icon-dark-blue.svg');
  background-size: 12px auto;
}

.folder-action-change {
  background-image: url('#{$cdn-url}20211229-shared-icon-blue.svg');
  background-size: 14px auto;
  background-position: 14px 12px;
}

.folder-property {
  width: 34px;
  height: 34px;
  border: 1px solid $main-grey;
  margin-left: 8px;
  background: $main-white url('#{$cdn-url}20211229-private-folder-icon.svg') 9px center / 13px auto no-repeat;
}

.folder-shared {
  background-image: url('#{$cdn-url}20211229-shared-folder-icon.svg');
}

@media screen and (min-width: 992px) {
  .folder-property {
    width: 30px;
    height: 30px;
    background-position: 8px center;
  }
  .folder-action-button {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(244, 239, 237, 0.6);
    }
  }
}
