.influence-empty-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 57px);
  background-color: #fff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
}

.no-contacts-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  row-gap: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
  .identify-relevant-stakeholders-btn {
    @extend .top-nav-create-contact-button;
    padding-right: 1.5em !important;
    letter-spacing: 0.2px;
    &:before {
      display: none !important;
    }
  }

  .add-your-own-contact-outline {
    @extend .sort-button;
    padding: 0.45em 1.5em;
    background: #ffffff;
    color: #0094cc !important;
    border: 1px solid #0094cc !important;
    &:hover {
      opacity: 0.8;
    }
  }
}

.setup-guide-emails-btn {
  @extend .sort-button;
  padding: 0.45em 1.5em;
  background: #ffffff;
  color: #0094cc !important;
  border: 1px solid #0094cc !important;
  &:hover {
    opacity: 0.8;
  }
}
