.settings-title {
  font-size: 1.55em;
}

.keyword-category {
  position: relative;
}
.keyword-category-container {
  transition: 0.2s ease-in;
}

.keyword-category-name-container {
  border-bottom: 1px solid rgba(0, 28, 53, 0.3);
  .edit-item {
    margin-top: -3px;
    vertical-align: middle;
  }
}

.keyword-category-name {
  font-size: 1.125em;
  border: none;
  padding-bottom: 0.3em;
  width: auto;
  &:focus {
    border: none;
    outline: none;
  }
  &:disabled {
    background: none;
    color: #001c35;
  }
  &::placeholder {
    color: rgba(0, 18, 43, 0.4);
    font-weight: 400;
  }
}

.light-link {
  color: $hover-blue;
  line-height: 1.2;
  &:hover {
    color: $hover-blue;
    text-decoration: none;
  }
}

.edit-item {
  background: url('#{$cdn-url}edit-pencil.svg') center / contain no-repeat;
  width: 14px;
  height: 18px;
  text-indent: -10000px;
  margin: 0;
  cursor: pointer;
  display: inline-block;
}

.span-to-width {
  position: absolute;
  left: 2px;
  top: 1px;
  visibility: hidden;
}

.keywords-category-words {
  border-radius: 5px;
  min-height: 100px;
  position: relative;
  &:focus {
    border: 1px solid rgba(212, 212, 212, 0.6);
    outline: none;
  }
  .edit-item {
    vertical-align: middle;
    margin-top: -2px;
  }
}

.enter-text {
  text-align: right;
  font-style: italic;
  color: rgba(0, 18, 43, 0.4);
  font-size: 0.95em;
  position: absolute;
  bottom: 0.9em;
  right: 0.9em;
}

.light-text {
  color: rgba(0, 18, 43, 0.3);
}

.settings-text {
  color: rgba(0, 18, 43, 0.3);
  position: relative;
}

.settings-subtitle {
  font-weight: 700;
  font-size: 1.05em;
}

.settings-titlethird {
  color: #848484;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75em;
}

.toggle-component {
  width: 38px;
  height: 15px;
  background: rgba(196, 196, 196, 0.8);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: -2.1px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: #f9f9f9;
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    transition: 0.2s ease-in;
  }
  &:focus {
    &::after {
      box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
}

.toggle-component-twitter {
  &::before {
    content: '';
    width: 44px;
    height: 44px;
    background: rgba(0, 18, 43, 0.1);
    position: absolute;
    left: -13px;
    top: -15px;
    border-radius: 50%;
    transition: 0.2s ease-in;
  }
}

.toggle-active {
  background: rgba(0, 148, 204, 0.4);
  &::after {
    background: #0094cc;
    left: 18px;
  }
  &::before {
    background: rgba(0, 148, 204, 0.1);
    left: 5px;
  }
}
.hour-drowpdown {
  position: absolute;
  top: 0;
  border-radius: 5px;
  border: 0.5px solid #d4d4d4;
  background-color: #fff;
  height: 136px;
  z-index: 100;
  li {
    cursor: pointer;
  }
  .scroll-container {
    height: 100%;
  }
}

.hour-container-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
}

.delete-button-category {
  border: none !important;
}

.list-dot {
  background: #f4efed;
  border-radius: 2px;
  border: none;
}

.day-dropdown {
  width: 100%;
}

.add-new-category {
  font-size: 1.025em;
  text-align: left;
  span {
    color: #00122b !important;
    &::before {
      content: '+';
      margin-right: 8px;
    }
  }
}

.add-new-category-block {
  cursor: pointer;
  img {
    width: 12px;
    vertical-align: middle;
    margin-left: 0.65em;
    margin-top: -4px;
    cursor: pointer;
  }
  .plan-message {
    background: none;
    padding-left: 0;
    font-style: italic;
    margin-left: 0.65em;
    display: none;
    color: rgba(0, 18, 43, 0.5) !important;
  }
  &:hover {
    .plan-message {
      display: inline-block;
    }
  }
}

.delete-button {
  border: 1px solid #848484;
  color: #848488;
  border-radius: 5px;
}

.block-link {
  color: rgba(0, 18, 43, 0.5);
  padding-right: 20px;
  display: inline-block;
  cursor: pointer;
  background: url('#{$cdn-url}padlock.svg') right center / 12px auto no-repeat;
  &:hover {
    color: rgba(0, 18, 43, 0.5);
    text-decoration: none;
    + .upgrade-message {
      display: block !important;
    }
  }
}

.block-link-header {
  color: #fff;
  background: url('#{$cdn-url}padlock-white.svg') right center / 12px auto no-repeat;
  text-decoration: underline;
  position: relative;
  &:hover {
    color: #fff;
    text-decoration: underline;
    .upgrade-message {
      display: block !important;
    }
  }
  .upgrade-message {
    left: 0;
    min-width: 320px;
  }
}

.disabled-tag {
  color: rgba(0, 18, 43, 0.5);
}

.upgrade-message {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #fff;
  color: #00122b;
  border-radius: 5px;
  padding: 0.2em 1em;
  display: none;
}

.upgrade-message-immediate {
  top: -40px;
  left: 90%;
}

.resources-arrow {
  position: absolute;
  top: 12px;
  right: 13px;
  z-index: 10;
  cursor: pointer;
  width: 12px;
}

.email-alerts-name {
  font-weight: 400;
}

.close-settings {
  height: 35px;
  width: 35px;
  background-color: rgba(0, 148, 204, 0.08);
  border-radius: 50%;
  background: url('#{$cdn-url}close-settings.svg') center/15px 15px no-repeat;
}

.settings-logo {
  width: 140px;
  height: 27px;
  background: url('#{$cdn-url}logo.svg') 0px / contain no-repeat;
  position: relative;
  z-index: 2;
  opacity: 1;
}

.settings-logo-christmas {
  background: url('#{$cdn-url}logo-christmas3.png') 0px / contain no-repeat;
  height: 41px;
}

.settings-logo,
.close-name-settings {
  transition: opacity 0.2s ease-in;
  /* &::before {
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #001c35;
    content: '';
    top: 5px;
    left: -15px;
  } */
}

.close-name-settings {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #001c35;
  opacity: 0;
  &:hover {
    text-decoration: none;
    color: #001c35;
  }
}

.close-influence {
  top: 0px;
}

.keyword-category-arrow {
  position: absolute;
  top: 32px;
  right: 14px;
  padding: 4px;
  z-index: 10;
}

.add-keywords-message {
  right: 32%;
  top: 40%;
}

.delete-keywordcategory-modal {
  .action-button {
    background-color: #f2a805;
    font-weight: 400;
    margin-top: -4px;
  }
}
.frequency-title {
  display: none;
}

.upgrade-more-message {
  background: $hover-blue;
  margin-bottom: -13px;
  width: calc(100% + 2em);
  margin-left: -1em;
  .settings-text {
    color: #fff;
  }
  .light-link {
    color: #fff;
    text-decoration: underline;
  }
}

.hand-picked-banner {
  color: #fff;
}

.over-limit-message {
  background: #001c35;
  color: #fff;
  .settings-text {
    background: url('#{$cdn-url}warning-icon-red.svg') left 4px/20px auto no-repeat;
    padding-left: 30px !important;
    span {
      color: #f03800;
    }
    a {
      color: #f2a805 !important;
    }
  }
}

.keyword-category-box {
  background: #fff;
  border-radius: 8px;
}

.tabs-container {
  background: #f9f9f9;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.tab-category {
  font-size: 1.025em;
  &:first-child {
    border-top-left-radius: 5px;
  }
  cursor: pointer;
  &:hover {
    background-color: rgba(#d4d4d4, 0.8);
  }
  &:not(:last-child) {
    border-right: 1px solid rgba(212, 212, 212, 0.6);
  }
}

.tab-category-active {
  font-size: 1.075em;
  font-weight: 700;
  background: #ffffff;
  color: #0094cc;
  border-right: 1px solid rgba(212, 212, 212, 0.6);
  &:hover {
    color: #0094cc;
    background-color: #fff;
  }
}

.save-keyword-lists-container {
  position: fixed;
  background: #eeeeee;
  bottom: 0;
  transition: 0.2s ease-in;
}
.more-keyword-lists {
  position: absolute;
  width: calc(100% + 2px);
  border-left: 1px solid rgba(212, 212, 212, 0.6);
  border-right: 1px solid rgba(212, 212, 212, 0.6);
  left: -1px;
  z-index: 100;
  top: calc(100% + 1px);
  font-size: 0.95em;
}
.more-keyword-name {
  background: #f9f9f9;
  border-bottom: 1px solid rgba(212, 212, 212, 0.6);
  font-weight: 400 !important;
  color: #00122b;
  &:hover {
    background-color: rgba(#d4d4d4, 0.8);
  }
}

.more-lists-text {
  display: inline-block;
  padding-right: 20px;
  background: url('#{$cdn-url}accordion_bottom_arrow.svg') right 10px / 12px auto no-repeat;
}

.dropdown-next-icon {
  position: absolute;
  right: -32px;
  top: 7px;
}

.warning-resources {
  text-indent: -1000px;
  width: 20px;
  overflow: hidden;
  height: 20px;
  background: url('#{$cdn-url}warning-icon-yellow.svg') left center/contain no-repeat;
}

.warning-resource {
  width: 15px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -2px;
  cursor: pointer;
  &:hover {
    + .upgrade-message {
      display: block;
    }
  }
}

.warning-resource-message {
  min-width: 512px;
  z-index: 10;
}

.disabled-name {
  color: rgba(0, 18, 43, 0.3) !important;
}

.disabled-hour-select {
  cursor: auto;
  background-color: rgba(249, 249, 249, 0.83) !important;
}

.advancedpopup-subtitle {
  max-width: 220px;
  margin: 0 auto;
  font-size: 1.1em;
  font-weight: 700;
}

.advancedpopup-text {
  max-width: 300px;
  margin: 0 auto;
}

.advance-button {
  font-weight: 700 !important;
}

.advanced-link {
  color: rgba(0, 18, 43, 0.75);
  text-decoration: underline;
}

.disabled-button {
  border: 1px solid rgba(0, 18, 43, 0.4);
  color: rgba(0, 18, 43, 0.4);
  background-color: #fff;
  cursor: auto !important;
  &:hover {
    color: rgba(0, 18, 43, 0.4);
    background-color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .upgrade-more-message {
    width: calc(100% + 3em);
    margin-left: -1.5em;
  }
}

@media screen and (min-width: 992px) {
  .upgrade-more-message {
    border-radius: 3px;
    width: 100%;
    margin-left: 0em;
  }

  .keyword-category-container {
    border-left: 1px solid rgba(212, 212, 212, 0.6);
    border-right: 1px solid rgba(212, 212, 212, 0.6);
  }

  /*  .settings-logo {
    &:hover {
      opacity: 0;
      + .close-name-settings {
        opacity: 1;
      }
    }
  } */

  .close-settings {
    margin-right: 5px;
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }
  }
  .frequency-title {
    display: flex;
  }
}
