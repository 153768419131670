.reports-create-popup-content {
  .influence-visibility-box {
    padding: 40px 58px;
  }
}

.reports-default-popup {
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, minmax(90px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-flow: row dense;
}

.reports-create-prospective-keywords-content {
  max-height: 68vh;
  overflow-y: auto;
}

.prospective-keywords-container {
  .keywords-category-words {
    padding-right: 0.8em !important;
    padding-left: 0.8em !important;
  }
  .add-rule-title,
  .keywords-exclusions-title {
    padding-left: 1em !important;
  }
  .keywords-specific-category-title {
    padding-left: 0.9em !important;
  }
  .main-input {
    padding-left: 1em;
  }
  .topics-search-items,
  .keywords-category-words {
    background: #fff !important;
  }

  .keywords-category-words .react-tagsinput {
    background: #fff;
  }
}

.report-prospecting-title {
  color: rgba($main-white, 0.85);
}

.prospecting-icon {
  margin-top: -8px;
}

.prospecting-content-padding {
  padding: 0 2em;
}

.prospecting-keywords {
  max-height: 50vh;
}

.report-create-overlay {
  .react-modal-content {
    margin-top: -80px;
  }
}

@media screen and (min-width: 992px) {
  .prospecting-content-padding {
    padding: 0 3.5em;
  }
  .reports-default-popup {
    display: grid;
    grid-gap: 15px;
    grid-template-rows: repeat(2, minmax(147px, 1fr));
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row dense;
  }
}
