.settings-box {
  background: #ffffff;
  .main-title {
    font-size: 21px;
  }
}

.account-list-title {
  font-size: 1.03em;
  font-weight: 700;
  margin-bottom: 0rem;
}

.organisation-title {
  margin-top: 1.25em;
}

.account-list {
  color: #00122b;
  .scroll-container {
    height: 100%;
  }
  li {
    padding: 1.25rem 0 0.75rem 0;
  }
  h3 {
    @extend .account-list-title;
  }
  .add-note-modal-textarea {
    min-height: 138px !important;
  }
  input,
  textarea {
    border: 1px solid rgba(#848484, 0.3);
    border-radius: 5px;
    padding: 0.35em 0.75em;
    outline: none;
    height: auto;
    width: 100%;
    transition: 0.2s ease-in;
    background: #f9f9f9;
    &:disabled {
      background: none;
      color: #00122b;
    }
    &:focus {
      border: 1px solid #00122b;
      box-shadow: none;
    }
  }
  textarea {
    border: none !important;
    min-height: 93px !important;
    padding: 0.45em 0.75em;
  }
  select {
    border: 1px solid rgba(#848484, 0.3);
    border-radius: 5px;
    padding: 0.35em 0.75em;
    color: #00122b;
    height: 38px;
    &:focus {
      border: 1px solid #00122b;
      box-shadow: none;
    }
  }
  .custom-select {
    background: #f9f9f9 url('#{$cdn-url}accordion_bottom_arrow.svg') no-repeat right 0.75rem center/10px 10px;
  }
}
.custom-select {
  background: url('#{$cdn-url}accordion_bottom_arrow.svg') no-repeat right 0.75rem center/10px 10px;
}

.error-message {
  position: relative;
  img {
    width: 25px;
  }
  .message {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    min-width: 100%;
    z-index: 100;
    margin-top: 0.5em;
    background: #fff;
  }
}

.red-error-message {
  color: $main-red;
  .icon-info {
    display: inline-block;
    transform: translateY(1.5px);
  }
}

.account-email-field {
  background: #f9f9f9;
  border: 1px solid rgba(#848484, 0.3);
  border-radius: 5px;
  .edit-item {
    vertical-align: middle;
    margin-top: -2px;
  }
  input {
    background: #f9f9f9;
    border: none;
    &:focus {
      border: none;
    }

    &::placeholder {
      color: rgba($main-blue, 0.5) !important;
      opacity: 1;
    }
  }
}

.account-save {
  background-color: rgba($soft-grey, 0.12);
  height: 15%;
  border-radius: 0px 0px 8px 8px;
}

.account-save-button {
  background: #848484;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 1.075rem;
  min-width: 149px;
  &:hover {
    color: #fff;
  }
}

.change-button {
  border: 0.5px solid #d4d4d4;
  border-radius: 4px;
  color: #848484;
}

.alert-danger {
  background: #df6767;
  border-radius: 4px;
  color: white;
  img {
    width: 25px;
  }
  p {
    width: calc(100% - 50px);
    font-weight: 400;
    line-height: 1rem;
  }
}

.account-logo-container {
  max-width: 180px;
}

@keyframes buttonsave {
  0% {
    transform: translateY(73px);
  }
  100% {
    transform: translateY(0px);
  }
}

.account-save-container {
  transition: 0.2s ease-in;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid hsla(0, 0%, 83.1%, 0.5);
  background-color: #f9f9f9;
  z-index: 1000;
  animation: buttonsave 0.2s ease-in;
}

.account-spacing-button {
  padding-bottom: 73px !important;
}

.remove-logo-button {
  margin-top: -4px;
  display: inline-block;
  vertical-align: middle;
}

.organisation-website-input {
  &:disabled {
    background-color: rgba($main-blue, 0.05) !important;
    color: rgba($main-blue, 0.5) !important;
    border: 1px solid rgba($main-blue, 0.1) !important;
  }
}

.influence-box {
  border: 1px solid rgba(212, 212, 212, 0.6);
  box-shadow: none !important;
}
.settings-box-all-screen {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-error-message {
  position: relative;
  display: flex;

  img {
    width: 25px;
  }
  .message {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 100;
    margin-top: 0.5em;
    background: #fff;
  }
}

@media screen and (min-width: 992px) {
  .account-left-items {
    border-right: 1px solid rgba(#d4d4d4, 0.5);
  }

  .settings-box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .error-message {
    position: relative;
    .message {
      position: absolute;
      top: -5px;
      left: 45px;
      margin-top: 0em;
      width: 315px;
    }
  }
}
