.saved-items-icon,
.calendar-top-nav-icon,
.prospective-top-nav-icon {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  border-radius: 50%;
  background-color: $main-white;
  color: $main-blue;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
  &:hover {
    color: $main-blue;
    text-decoration: none;
  }
}

.saved-items-icon {
  font-size: 18px;
}

.calendar-top-nav-icon {
  font-size: 17px;
  .icon-calendar {
    margin-top: -2px;
  }
}

.saved-items-icon.active,
.calendar-top-nav-icon.active {
  background-color: $main-yellow;
  color: $main-white;
  &:hover {
    background-color: $main-yellow !important;
  }
}

.item-actions-container {
  position: absolute;
  top: 12px;
  right: 7px;
  z-index: 100;
}

.item-actions-button {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  background: url('#{$cdn-url}20211222-item-actions-dots.svg') 13px 7px / auto 15px no-repeat;
}
.item-actions-button.active {
  background-color: rgba($main-grey, 0.5);
}

.item-actions-button-horizontal {
  transform: rotate(90deg);
}

.item-actions-box {
  position: absolute;
  top: 38px;
  right: 7px;
  width: auto;
  z-index: 1;
  .share-container {
    display: block;
    > div {
      padding: 0 !important;
    }
  }
  button {
    padding: 6px 15px 6px 45px !important;
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    color: $main-blue !important;
    font-weight: 400 !important;
    white-space: nowrap;
    &:disabled {
      opacity: 0.5;
    }
  }
  .twitter-button {
    padding-left: 14px !important;
    display: flex;
    align-items: center;
    .icon-twitter-x-circle {
      margin-right: 10px;
      margin-left: -2px;
      font-size: 21px;
    }
  }
  .agency-image {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  .share-icon-button {
    padding-left: 14px !important;
    display: flex;
    align-items: center;
    .icon-share-link {
      margin-right: 12px;
      font-size: 18px;
    }
  }
  .contacts-activity-button {
    padding-left: 14px !important;
    display: flex;
    align-items: center;
    .icon-minus-rounded,
    .icon-plus-rounded {
      margin-right: 7px;
      margin-left: -3px;
      font-size: 25px;
    }
  }
  .linkedin-button {
    padding-left: 14px !important;
    .icon-linkedin {
      margin-right: 8px;
    }
  }
  .facebook-button {
    padding-left: 10px !important;
    .icon-facebook {
      margin-right: 8px;
    }
  }
}
.remove-all-recipients-action {
  .item-actions-box {
    button {
      padding: 6px 15px !important;
    }
  }
}
.light-blue-color {
  color: #0094cc !important;
  & > * {
    color: #0094cc !important;
  }
}
.light-red-color {
  color: #e46666 !important;
  & > * {
    color: #e46666 !important;
  }
}

.item-actions-box-feedback {
  padding: 8px;
  width: 180px;
  button {
    padding: 6px 0px 6px 16px !important;
    color: $light-blue !important;
  }
  .action-button {
    margin-top: 5px;
    color: $main-white !important;
    &:hover {
      background-color: rgba($hover-blue, 0.8) !important;
    }
  }
}

.item-actions-save {
  background: url('#{$cdn-url}20211231-saved-item-icon-blue.svg') 15px 11px / 12px auto no-repeat;
}

.items-actions-save-disabled {
  opacity: 0.4;
}
.circle-btn-with-hover {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.item-actions-save-content {
  width: 30px;
  height: 30px;
  background-position: center 5px;
  background-size: 14px auto;
  border-radius: 50%;
  background-image: url('#{$cdn-url}20220315-saved-item-icon-grey.svg');
}

.item-actions-save-content.item-actions-remove-content,
.item-actions-list-item.item-actions-remove-content {
  background-image: url('#{$cdn-url}20220304-saved-item-icon-blue-filled.svg');
}

.item-actions-list-item.item-actions-remove-content {
  background-position: 15px 10px;
}

.item-actions-save-content.item-actions-remove-content {
  background-position: 8px 7px;
}

.item-actions-share,
.item-actions-twitter {
  border-radius: 0;
}

.item-actions-share {
  background-image: url('#{$cdn-url}20211222-share-icon-blue.svg');
  background-position: 14px 10px;
  height: auto;
}

.item-actions-twitter {
  background-image: url('#{$cdn-url}twitter-x.svg');
  background-position: 3px 5px;
  height: 36px;
  background-size: 36px;
}

.close-name-saved-items {
  top: 4px;
}

.item-actions-remove-content {
  background: url('#{$cdn-url}20211229-cancel-blue.svg') 15px 12px / 13px auto no-repeat;
}

.saved-items-main-list {
  .unseen-dot,
  .unseen-dot-hover {
    display: none !important;
  }
}

.saved-items-header {
  .btn-set-keywords {
    border: 1px solid rgba($description-grey, 0.25);
  }
  .nav-user-profile {
    &:hover {
      border: 1px solid rgba($description-grey, 0.25);
    }
  }
}

.item-actions-container-current-content {
  top: 0;
  left: 0;
  position: relative;
  .item-actions-button {
    //transform: rotate(90deg);
    margin-top: 2px;
  }
}

.popup-individual-item-container {
  padding-right: 50px !important;
  .topic-title {
    font-size: 1.025em !important;
  }
}

.prospective-top-nav-icon {
  img {
    margin-top: -2px;
    max-width: 20px;
  }
}

@media screen and (min-width: 992px) {
  .item-actions-button,
  .item-actions-box button {
    transition: 0.2 ease-in;
    &:hover {
      background-color: rgba($main-grey, 0.5) !important;
    }
  }

  .saved-items-icon {
    transition: 0.2 ease-in;
    &:hover {
      background-color: $hovered-blue;
    }
  }

  .saved-items-icon,
  .calendar-top-nav-icon,
  .prospective-top-nav-icon {
    display: flex;
  }
}

.pointer-event-none {
  pointer-events: none;
}
