.more-filters-container {
  height: 34px;
}

.more-filters-stakeholders {
  margin-top: -3px;
}

.more-filters-button {
  padding: 0.45em;
  height: 34px;
  transition: all 0s ease 0s;
  background: none;
  min-width: 34px;
}
.more-filters-button-active {
  background-color: $light-blue;
  color: $main-white;
  border-color: $light-blue;
  &:hover {
    background-color: $light-blue;
  }
}
.stakeholders-influence-filters-button {
  margin-left: 0 !important;
}

.contacts-influence-filters-button {
  margin-left: 0 !important;
  margin-top: -1px;
}

.contacts-influence-filters-button.more-filters-button-active {
  padding-top: 5px !important;
  background-position: 14px 10px !important;
}

.calendar-popup-fixed-height {
  height: 408px;
}
.date-filter-popup-close {
  top: 25px !important;
}
.calendar-popup {
  .DayPicker-NavButton {
    display: none;
  }
  .DayPicker-Months {
    justify-content: space-between;
  }
  .DayPicker.Selectable {
    width: 100%;
  }
  .DayPicker-Weekday {
    abbr {
      cursor: text !important;
    }
  }
  .DayPicker-Caption div {
    font-size: 1.4em !important;
  }
  .DayPicker-Day--outside {
    cursor: pointer !important;
  }
}
.calendar-popup.single-item-date-filter-popup {
  .DayPicker-Months {
    justify-content: center;
  }
}
.calendar-popup-arrow-back,
.calendar-popup-arrow-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #f2a805;
  color: #fff;
  z-index: 1002;
  border: 1px solid #f2a805;
  transition: background-color 200ms;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  &:hover {
    background-color: #f0b638;
  }
  &:disabled {
    background-color: #f5f6f7;
    border: 1px solid #e5e7e9;
    color: rgba(0, 18, 43, 0.5);
  }
}
.calendar-popup-arrow-back {
  margin-left: -20px;
  & > span {
    margin-left: -2px;
  }
}
.calendar-popup-arrow-next {
  margin-left: -20px;
  & > span {
    margin-right: -2px;
  }
}
.calendar-popup-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 45px;
}

.date-inputs-container {
  display: flex;
  border: 1px solid #dee2e6;
  width: fit-content;
  border-radius: 0.25rem;
  transition: all 200ms;
  .date-input-wrapper {
    padding: 6px 16px;
    margin: -1px;
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid transparent;
    transition: all 200ms;
    z-index: 1;
    position: relative;
    input {
      background-color: transparent;
      border: none;
      width: 100%;
      width: calc(100% - 28px);
      text-overflow: ellipsis;
      &:hover {
        border: none;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
    .next-prev-day-arrows-container {
      display: flex;
      position: absolute;
      right: 0;
      height: 100%;
      & button {
        padding: 0 7px;
        display: flex;
        align-items: center;
        font-size: 14px;
        & > span {
          -webkit-text-stroke: 0.5px white;
        }
        &:hover {
          background-color: rgba(#0094cc, 0.04);
          color: rgba(#0094cc, 0.6);
        }
      }
    }

    &:has(input:hover),
    &:has(input:focus) {
      border: 1px solid #dee2e6;
    }
    border-radius: 0.25rem;
  }
  .date-input-separator {
    width: 1px;
    background-color: #dee2e6;
    margin: 8px 0px;
    z-index: 0;
  }
}
.reset-calendar-button {
  padding: 0.45em 1.5em;
  color: #0094cc;
  border: 1px solid #0094cc;
  background-color: #fff;
  border-radius: 5px;
  &:hover {
    background-color: #f7fcfe;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .stakeholders-influence-filters-button {
    width: 38px !important;
    padding: 0.3em !important;
    .icon-filters {
      margin-right: 0 !important;
    }
  }
  .stakeholders-influence-filters {
    display: none !important;
  }
}

.filters-popup-container {
  overflow: auto;
  h3 {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 1em !important;
  }
  .no-contacts-title {
    margin-bottom: 0.5em !important;
  }

  .checkbox-item {
    label {
      line-height: 1.15;
    }
  }
}

.filters-section {
  &:not(:last-child) {
    .filters-section-container {
      border-bottom: 1px solid rgba($main-grey, 0.5);
      margin-bottom: 28px;
    }
  }
}

.filters-section {
  .filters-section-container {
    padding-bottom: 12px;
  }
}

.filter-section-sortMode-topic-mode.master-topic {
  display: none;
}

.filter-toggle {
  background: rgba($main-blue, 0.2);
  border-radius: 32px;
  height: 32px;
  width: 48px;
  position: relative;
  .toggle-item {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 28px;
    width: 28px;
    background-color: $main-white;
    border-radius: 50%;
    transform: translateX(0px);
    transition: transform 0.2s ease-in;
  }
}

.toggle-title {
  font-size: 1.1em;
  margin-bottom: 0;
  font-weight: 600;
}

.filter-toogle-active {
  background: $light-blue;
  .toggle-item {
    transform: translateX(16px);
    background: $main-white url('#{$cdn-url}20211127-blue-tick.svg') center / 14px 14px no-repeat;
  }
}

.radio-button-disabled {
  label {
    &::after {
      background: rgba($main-blue, 0.6) !important;
    }
  }
}

.radio-button-disabled,
.checkbox-item-disabled {
  label {
    color: rgba($main-blue, 0.6);
    &::before {
      border-color: rgba($main-blue, 0.6) !important;
    }
  }
}

.search-filter-disabled {
  background-color: $background-grey;
  input {
    &:disabled {
      background-color: $background-grey;
    }
  }
}

.transition-item {
  transition: 0.2s ease-in;
}

.showitem-button {
  position: relative;
  padding-right: 18px;
  -webkit-appearance: none !important;
  outline: none !important;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid $light-blue;
    position: absolute;
    top: 10px;
    right: 0px;
  }
}

.closeitem-button {
  &::after {
    border-top: none;
    border-bottom: 6px solid $light-blue;
  }
}

.close-filters-popup {
  display: block !important;
}

.checkbox-item {
  .pricing-feature-more {
    margin-top: -9px;
  }
}

.upcoming-events-filters-popup,
.influence-filters-popup {
  .filters-section-container {
    margin-bottom: 0 !important;
    border: none !important;
  }
}

.calendar-picker-filter {
  display: flex;
  align-items: center;
  column-gap: 30px;
  align-items: flex-start;
  min-height: 217px;
  justify-content: space-between;
  .daypicker-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: -35px;
  }
  .DayPicker {
    top: unset;
    position: relative;
    border: 1px solid rgba(212, 212, 212, 0.5);
    box-shadow: none;
  }
}

.indented-filters {
  padding-left: 33px;
}

.parliamentary-hierarchy-filter {
  label {
    margin-top: -1px !important;
  }
  .label-name {
    opacity: 0.85;
  }
}

.parliamentary-hierarchy-filter,
.custom-filter-house {
  font-size: 0.9em;
  label {
    padding-left: 26px !important;
    &::before {
      border: 1px solid rgba($main-grey, 0.9);
    }
  }
}

.custom-filter-house {
  margin-top: -8px;
  margin-bottom: 14px;
  max-width: 220px;
}

.common-outside-filter-inbox {
  .custom-filter-house {
    label {
      margin-top: 0px !important;
    }
  }
}

.custom-filter-commons {
  color: #006e46;
}

.checkbox-item input[type='checkbox']:checked + .custom-filter-lords:before {
  background: #b50938;
}
.checkbox-item input[type='checkbox']:checked + .custom-filter-commons:before {
  background: #006e46;
}

.custom-filter-commons-container {
  border-right: 1px solid rgba($main-grey, 0.5);
}

.custom-filter-lords {
  color: #b50938;
}

.have-your-voice-actions {
  .more-filters-container {
    display: block !important;
  }
}

.candidates-filter-btn {
  display: block !important;
  button {
    border-radius: 5px;
  }
}

.influence-contacts-filters-popup {
  .calendar-picker-filter {
    min-height: 0;
  }
}

@media screen and (min-width: 768px) {
  .more-filters-button {
    background-position: 14px center;
  }

  .more-filters-button {
    padding: 0.3em 1em;
    span {
      padding-right: 0px;
    }
    .icon-filters {
      display: inline-block;
      transform: translateY(1px);
      margin-right: 8px;
    }
  }
}

.two-filters-popup-container {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  margin-bottom: 28px;
  padding-bottom: 12px;
  column-gap: 50px;
  row-gap: 28px;
  & .transition-item {
    & .filter-item-checkbox:first-child {
      & .checkbox-item {
        margin-top: 0;
      }
    }
  }
  .filters-section {
    width: calc(50% - 25px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.contacts-oversubscribed-confirm-modal {
  .popup-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #00122b;
    margin-top: 24px;
  }
  .separator {
    height: 1px;
    background-color: rgba(212, 212, 212, 0.5);
    width: 100%;
  }
  .popup-body {
    p {
      margin-top: -15px;
    }
  }
}

.tailor-summaries-modal {
  @extend .contacts-oversubscribed-confirm-modal;
  .popup-header {
    line-height: 1.5;
    & > span,
    .hour-select {
      font-size: 18px;
    }
  }
}
.tailor-summaries-modal.many-clients {
  .popup-header {
    & > span > span,
    .hour-select {
      color: #0094cc !important;
      &::after {
        border-top: 6px solid #0094cc;
      }
      &:hover {
        opacity: 1;
      }
    }
    & > span > span,
    .hour-select-opendropdown::after {
      border-top: none;
      border-bottom: 6px solid #0094cc;
    }
  }
}

.summary-content {
  user-select: auto;
  .last-edited-by-subtext {
    user-select: auto;
    margin-top: -20px;
    margin-bottom: 16px !important;
  }
}

.last-edited-by-subtext {
  color: rgba(0, 18, 43, 0.5);
  font-size: 16px;
  line-height: 24px; /* 150% */
}

.modal-with-header-title {
  .popup-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #00122b;
    margin-top: 24px;
  }
  .separator {
    height: 1px;
    background-color: rgba(212, 212, 212, 0.5);
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .more-filters-button {
    padding-bottom: 0.3em !important;
  }
}
