.admin-general-content {
  p {
    margin-bottom: 0;
  }
}

.subscription-status {
  text-transform: uppercase;
  padding: 5px 15px;
  font-size: 0.775em;
  letter-spacing: 0.5px;
  color: $main-white;
  background-color: $main-grey;
  border-radius: 20px;
}
.status-subscriptionended {
  color: rgba($main-blue, 0.6);
}
.status-subscribed {
  background-color: $light-blue;
}

.status-intrial {
  background-color: $main-blue;
}

.status-trialended {
  background-color: $main-red;
}

.status-onfreeplan {
  background-color: $main-yellow;
}

.simple-search-bar {
  border: 1px solid $main-grey;
  border-radius: 20px;
  padding-left: 5px;
  input {
    border-radius: 20px;
    height: 34px;
    min-width: 250px;
    border: none;
    &:focus {
      border: none;
    }
  }
}

.create-user-success {
  p {
    margin-bottom: 0;
  }
  img {
    max-width: 50px;
    margin-bottom: 8px;
  }
}

.rounded-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.object-fit-contain {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}
.widget-padding > * {
  padding: 20px;
}
.widget-padding-container {
  padding: 20px;
}
.widget-padding-container-button {
  width: 160px;
}
.container-text-size > * {
  font-size: 11pt;
}
.line-color-grid {
  color: lightgray;
  background-color: lightgray;
  height: 5;
}

.end-trial-date-picker {
  .DayPicker {
    top: 0;
    right: unset;
  }
  .form-error-message .message {
    z-index: 0;
  }
}
