.social-media-item {
  .content-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.twitter-tweet-rendered {
  margin-bottom: 40px !important;
  iframe {
    width: 100% !important;
  }
}

.loading-tweets {
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  background: #f9f9f9;
  left: 0;
  width: 100%;
}

.tweets-main-filters {
  border-radius: 15px 15px 0 0;
  border: 1px solid #ccd6dd;
  background: #fff;
  h3 {
    font-size: 0.75em;
    text-transform: uppercase;
    color: #848484;
  }
  h4 {
    color: rgba(0, 18, 43, 0.75);
    font-size: 1.05em;
    font-weight: 700;
  }
}

.tweets-main-filters-padding {
  padding: 1em 1em 1em 1.25em;
  .tweet-filters-section {
    padding-left: 0.5em;
  }
}

.tweet-filter-range-text {
  font-size: 0.875em;
  color: rgba(0, 18, 43, 0.6);
}

.filter-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #d4d4d4;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.tweet-range-filters {
  .filter {
    background: #d4d4d4;
    width: calc(50% - 4px);
    height: 2px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: 0.2s ease-in;
    &::after {
      content: '';
      @extend .filter-dot;
      position: absolute;
      right: 0;
      top: -4px;
      transition: 0.2s ease-in;
    }
    &:nth-of-type(1) {
      @extend .filter-dot;
      &::after {
        width: 0px;
        height: 0px;
      }
    }
  }
}
.range-filter-active {
  background: $hover-blue !important;
  &::after {
    background: $hover-blue !important;
  }
}

.tweets-upgrade-message {
  position: absolute;
  width: calc(100% - 2.5rem);
  border-bottom: 1px solid #ccd6dd;
  border-left: 1px solid #ccd6dd;
  border-right: 1px solid #ccd6dd;
  border-radius: 0px 0px 5px 5px;
  bottom: 0;
  background: linear-gradient(0.03deg, #ffffff 83.32%, rgba(255, 255, 255, 0) 106.62%);
  .main-message {
    color: #0094cc;
    font-size: 1.25em;
    font-weight: 700;
  }
  .secondary-message {
    max-width: 460px;
  }
}

.tweet-container {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Ubuntu,
    'Helvetica Neue',
    sans-serif !important;
  border: 1px solid rgb(204, 214, 221);
  background-color: #fff;
  border-radius: 15px;
  transition: 0.2s ease-in;
  cursor: pointer;
  position: relative;
  padding-left: 2em !important;
  .unseen-dot {
    top: 28px;
    left: 13px;
  }
  .unseen-dot-hover {
    top: 22px;
    left: 7px;
  }
}

.twitter-logo {
  width: 40px;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -5px;
}

.twitter-actions-container {
  .item-actions-save-content {
    margin-top: -6px;
  }
}

.twitter-profile-info {
  margin-bottom: 1em;
  p {
    margin-bottom: 0;
  }
  .profile-name {
    a {
      color: #000;
      &:hover {
        color: #000;
      }
    }
    font-weight: 700;
    font-size: 15px;
    line-height: 1em;
  }
}

.twitter-profile-image {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}

.twitter-content {
  font-size: 19px;
  word-break: break-word;
}

.profile-username {
  color: rgb(101, 119, 134);
  font-size: 14px;
}

.twitter-action-buttons {
  margin-left: -8px;
}

.twitter-action-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding-top: 7px;
  transition: 0.2s ease-in;
  background: url('#{$cdn-url}twitter-comment-icon.svg') center / 16px 20px no-repeat;
  font-size: 0;
  &:hover {
    background-color: #fae3ed;
  }
}

.twitter-reply-icon {
  &:hover {
    background-image: url('#{$cdn-url}twitter-comment-icon-red.svg');
  }
}

.twitter-retweet-icon {
  background-image: url('#{$cdn-url}twitter-retweet-icon.svg');
  background-size: 21px 20px;
  &:hover {
    background-image: url('#{$cdn-url}twitter-retweet-icon-red.svg');
  }
}

.twitter-like-icon {
  background-image: url('#{$cdn-url}twitter-like-icon.svg');
  background-size: 17px 20px;
  &:hover {
    background-image: url('#{$cdn-url}twitter-like-icon-red.svg');
  }
}

.twitter-save-item {
  background-image: url('#{$cdn-url}202112311-saved-item-icon-grey-tweets.svg');
  background-size: auto 16px;
}

.twitter-date {
  color: #657786;
  font-size: 0.95em;
  &:hover {
    text-decoration: underline !important;
    color: #657786;
  }
}

.mps-arrow {
  width: 10px;
  vertical-align: middle;
  display: inline-block;
  margin-left: 6px;
  transition: 0.2s ease-in;
}

.mps-list {
  transition: 0.2s ease-in;
  overflow: hidden;
}

.retweets-section {
  border-top: 1px solid rgba(212, 212, 212, 0.4);
  border-bottom: 1px solid rgba(212, 212, 212, 0.4);
}

.custom-range-text {
  color: rgba(0, 18, 43, 0.5);
  text-decoration: underline;
  font-size: 0.925em;
}

.clear-button-range {
  color: rgba(0, 18, 43, 0.4);
  font-size: 0.85em;
}

@keyframes opacidad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tweets-calendar-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
  animation: opacidad 0.2s ease-in;
}

.close-picker {
  background: rgba(2, 35, 68, 0.15);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.close-tweet-mobile {
  z-index: 999;
}

.tweets-calendar {
  position: relative;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.calendar-popup,
.tweets-calendar {
  .DayPicker {
    position: relative !important;
    box-shadow: none;
    top: 0;
  }
  .DayPicker-NavButton--prev {
    background-image: url('#{$cdn-url}arrow-left.svg') !important;
    width: 1.3em;
    background-size: contain;
    height: 1.5em;
    left: 0.4em !important;
    top: 0.7em !important;
  }
  .DayPicker-NavButton--next {
    background-image: url('#{$cdn-url}arrow-right.svg') !important;
    width: 1.3em;
    background-size: contain;
    height: 1.5em;
    right: 0.4em !important;
    top: 0.7em !important;
  }
  .DayPicker-Day {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    border: 1px solid transparent;
    padding: 0 !important;
    &:hover {
      background: none !important;
      border: 1px solid #0094cc;
    }
  }
  .DayPicker-Caption {
    div {
      font-weight: 400 !important;
      font-size: 1.2em !important;
    }
  }
  .DayPicker-Weekday {
    color: rgba(0, 18, 43, 0.6) !important;
    font-size: 0.9em !important;
  }
  .DayPicker-Day--selected {
    background-color: #cceaf5 !important;
    border-radius: 0 !important;
    border: none !important;
    &:hover {
      background-color: #cceaf5 !important;
    }
  }

  .DayPicker-Day--start {
    border-radius: 10px 0px 0 10px !important;
  }
  .DayPicker-Day--end {
    border-radius: 0px 10px 10px 0px !important;
  }
  .DayPicker-Day--start,
  .DayPicker-Day--end {
    background: #7bc6e5 !important;
    &:hover {
      background: #7bc6e5 !important;
    }
  }
  .DayPicker-Day--start {
    &:after {
      background: none !important;
    }
  }

  .DayPicker-Day--end {
    &:before {
      background: none !important;
    }
  }
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--today {
    border-radius: 0 !important;
    border: none !important;
  }
}

.tweets-calendar-inputs {
  border-bottom: 1px solid rgba(132, 132, 132, 0.4);
}

.calendar-inputs-container {
  border: 1px solid rgba(132, 132, 132, 0.5);
  border-radius: 3px;
  p {
    margin-bottom: 0;
    background: url('#{$cdn-url}icon-calendar-grey.svg') 3px 2px / 15px auto no-repeat;
    flex-grow: 1;
    font-size: 0.85em;
    color: rgba(0, 18, 43, 0.6);
    padding-left: 26px;
    &:nth-child(1) {
      border-right: 1px solid rgba(132, 132, 132, 0.5);
    }
    &:nth-child(2) {
      background: url('#{$cdn-url}icon-calendar-grey.svg') 8px 2px / 15px auto no-repeat;
      padding-left: 30px;
    }
  }
}

.close-tweets-calendar {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff url('#{$cdn-url}close-icon.svg') center / 12px 12px no-repeat;
}

.tweet-filters-mobile {
  display: block;
}
/* 
.tweet-main-filters{
  position: fixed;
  width: 85%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: .2s ease-in;
} */

.tweet-item-link {
  cursor: auto;
  color: #000;
  &:hover {
    color: #000;
  }
}

.twitter-non-access-list {
  li {
    background: url('#{$cdn-url}check-grey.svg') 0 2px / 22px auto no-repeat;
    padding-left: 38px;
    &:not(:first-child) {
      margin-top: 0.85em;
    }
    &:not(:last-child) {
      margin-bottom: 0.85em;
    }
  }
}

.skeleton-inbox-padding {
  padding-right: 19px;
}

@media screen and (min-width: 992px) {
  .tweet-filters-mobile {
    display: none;
  }
  .tweet-main-filters {
    position: relative;
    width: auto;
    height: auto;
    z-index: auto;
    transition: all 0s ease 0s;
  }
}
@media screen and (min-width: 1116px) {
  .other-political-commentators {
    max-width: 170px;
  }
}

@media screen and (min-width: 1318px) {
  .other-political-commentators {
    max-width: none;
  }
}
