.main-content-scrollbar {
  width: calc(100% + 1rem) !important;
  .ScrollbarsCustom-TrackY {
    width: 8px !important;
    height: 100% !important;
    background-color: transparent !important;
  }
  .ScrollbarsCustom-ThumbY {
    background-color: rgba(#99a4ae, 0.7) !important;
    right: 0px !important;
  }
  .dragging {
    background-color: #99a4ae !important;
  }
  .ScrollbarsCustom-Content {
    padding-right: 0.65em !important;
    padding-left: 0em !important;
    height: 100%;
  }
  .ScrollbarsCustom-Wrapper {
    inset: 0 10px 0 0px !important;
  }
  .ScrollbarsCustom-TrackY {
    top: 0px !important;
  }
}

.individual-stakeholder-scrollbar {
  .ScrollbarsCustom-Content {
    padding-right: 0.1em !important;
  }
}

.emails-list-scrollbar-table {
  .ScrollbarsCustom-TrackY {
    display: block !important;
  }
  .ScrollbarsCustom-ThumbY {
    display: block !important;
  }
}

.content-page-scrollbar,
.content-related-scrollbar {
  position: relative;
  .ScrollbarsCustom-TrackY {
    top: 3px !important;
  }
}

.ScrollbarsCustom-TrackY {
  z-index: 200;
}

.main-tweets-option {
  .checkbox-item {
    margin-top: 0.7em;
  }
}
.stakeholder-scrollbar {
  width: 100% !important;
  .ScrollbarsCustom-Content {
    padding-right: 0px !important;
  }
}

.website-list-scroll-component {
  .ScrollbarsCustom-TrackY {
    height: calc(100% - 10px) !important;
  }
}

.website-list-scroll-component {
  .ScrollbarsCustom-TrackY {
    right: 10px !important;
  }
}

.website-list-scroll-component {
  width: 100% !important;
  margin-left: 0 !important;
  .ScrollbarsCustom-TrackY {
    right: 10px !important;
  }
  .ScrollbarsCustom-Content {
    padding: 0.5em 1em !important;
  }
}

.contact-list-scrollbar-table-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.email-list-scrollbar-table-wrapper {
  @extend .contact-list-scrollbar-table-wrapper;
}
.contact-list-scrollbar-table {
  overflow: scroll;
  position: relative;
  height: calc(100% - 7px);
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-right: none;
  border-radius: 5px 0 0 0;
  width: calc(100% + 17px);
  scrollbar-color: rgba(2, 35, 68, 0.2);
  scrollbar-width: thin;
  background-color: white;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(#99a4ae, 0.4);
    &:hover {
      background-color: rgba(#99a4ae, 0.7);
    }
  }
}
.email-list-scrollbar-table {
  @extend .contact-list-scrollbar-table;
  @media screen and (min-width: 992px) {
  }
}
.contact-list-scrollbar-table-no-data {
  @extend .contact-list-scrollbar-table;
  overflow: hidden;
  border-right: 1px solid rgba(212, 212, 212, 0.6) !important;
  width: 100% !important;
  border-radius: 5px 5px 0 0 !important;
}

.contact-list-scrollbar-table-no-data-emails {
  height: unset;
}
.email-performance-scrollbar {
  .ScrollbarsCustom-ThumbY {
    visibility: unset;
  }
}

@media screen and (min-width: 992px) {
  .email-performance-scrollbar {
    .ScrollbarsCustom-ThumbY {
      visibility: hidden;
    }
  }
  .main-content-scrollbar {
    width: calc(100% + 2.9rem) !important;
    margin-left: -0.4em !important;
    &:hover {
      .ScrollbarsCustom-TrackY {
        opacity: 1;
      }
    }
    .ScrollbarsCustom-ThumbY {
      &:hover {
        background-color: #99a4ae !important;
      }
    }
    .ScrollbarsCustom-TrackY {
      opacity: 0;
      right: 16px !important;
    }
    .ScrollbarsCustom-Content {
      padding-right: calc(2.5rem - 1px) !important;
      padding-left: 0.4em !important;
    }
  }
  .reference-main-content {
    .main-content-scrollbar {
      .ScrollbarsCustom-TrackY {
        right: 24px !important;
      }
    }
  }

  .tweets-scrollbar {
    .ScrollbarsCustom-Content {
      padding-right: calc(2.5rem - 3px) !important;
    }
    .ScrollbarsCustom-TrackY {
      right: 24px !important;
    }
  }

  .main-content-section {
    width: 100% !important;
    margin-left: 0 !important;
    .ScrollbarsCustom-Content {
      padding: 0em !important;
    }
    .ScrollbarsCustom-TrackY {
      right: 5px !important;
    }
    .ScrollbarsCustom-Wrapper {
      inset: 0px 0px 0px 0px !important;
    }
  }

  .content-related-scrollbar {
    .ScrollbarsCustom-Wrapper {
      inset: 0px 10px 0px 0px !important;
    }

    .ScrollbarsCustom-Content {
      padding-right: calc(2.5rem - 1px) !important;
    }

    .ScrollbarsCustom-TrackY {
      opacity: 0 !important;
    }
    &:hover {
      .ScrollbarsCustom-TrackY {
        opacity: 1 !important;
      }
    }
  }
  .tweets-scrollbar {
    width: calc(100% + 1.6rem) !important;
  }
  .stakeholder-scrollbar {
    width: calc(100% + 2.95rem) !important;
    .ScrollbarsCustom-Content {
      height: 100%;
    }
    .ScrollbarsCustom-Content {
      padding-right: calc(2.5rem - 7px) !important;
    }
  }
  .stakeholder-no-scroll {
    width: calc(100% + 3rem) !important;
  }
  .website-list-scroll-component {
    .ScrollbarsCustom-TrackY {
      right: 10px !important;
    }
    .ScrollbarsCustom-Content {
      padding: 0.35em 1em 0em 1em !important;
    }
  }
}
