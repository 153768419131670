.filter-group {
  border-radius: 20px;
  padding: 3px 3px 1px 3px;
  background-color: $main-white;
  font-size: 15px;
  border: 1px solid $main-grey;
}

.filter-item-button {
  border-radius: 20px;
  padding: 4px 14px;
  max-height: 35px;
  color: $main-blue;
}

.section-filter-button {
  color: $light-blue;
  padding: 4px 0px 6px 0px;
  .dropdown-item-element {
    &::after {
      top: 43%;
    }
  }
}

.data-option-filter-button {
  padding: 4px 16px 6px 16px;
}

.filter-item-button.active {
  background-color: $main-blue;
  color: $main-white;
}

.filter-item-button.action {
  background-color: $light-blue;
  color: $main-white;
}

.filter-overlay-button {
  background-color: $main-white;
  padding: 8px 18px;
}

.trends-box-information {
  background: rgba($light-blue, 0.04);
  border: 1px solid $main-grey;
  border-radius: 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.trends-topic-picker {
  position: relative;
  .dropdown-keywords-lists {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dropdown-keywords-activeitem {
    padding: 0;
    padding-left: 14px;
    position: static;
  }
}

.small-trends-topic-picker {
  font-size: 0.925em;

  .dropdown-keywords-lists {
    height: 34px;
    min-height: 34px;
    max-height: 34px;
  }
  .search-dropdown {
    height: 100%;
    &::after {
      top: 44%;
    }
  }
  .open-dropdown {
    top: 33px;
  }
  .dropdown-keywordlist {
    background-position: 15px 13px;
  }
}

.trends-chart-controls-container {
  border: 1px solid $main-grey;
  border-radius: 3px;
}

.trends-chart-controls {
  width: 25px;
  height: 25px;
  font-size: 1em;
  text-align: center;
  position: relative;
  background: #fff;
  font-weight: bold;
}

.trends-minus {
  border-top: 1px solid $main-grey;
  background: url('#{$cdn-url}20220627-minus-icon.svg') center / 11px auto no-repeat;
}

.trends-plus {
  background: url('#{$cdn-url}20220627-plus-icon.svg') center / 11px auto no-repeat;
}

.legend-container {
  font-size: 13px;
}

.legend-item {
  border: 1px solid $main-blue;
  border-radius: 3px;
  background-color: rgba($main-blue, 0.2);
  display: inline-block;
  margin-top: -3px;
  vertical-align: middle;
  margin-right: 10px;
  color: $main-blue;
  height: 17px;
  width: 17px;
  position: relative;
  &::before {
    content: '';
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 0px 8px rgba(0, 28, 53, 0.06);
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    opacity: 0;
  }
}

.legend-item-tweets {
  border: 1px solid $light-blue;
  background-color: rgba($light-blue, 0.2);
}

.legend-selected {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 0px;
    transform: translate(0.235em, 0.31346em) rotate(-45deg);
    width: 0.68em;
    height: 0.34em;
    border: 0.125em solid $main-blue;
    border-top-style: none;
    border-right-style: none;
  }
}

.trends-ink-box {
  h3 {
    color: $main-blue;
  }
  &:hover {
    text-decoration: none;
    h3 {
      color: $main-blue;
    }
  }
}

.trends-subnav-bar {
  min-height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.crm-contacts-subnav-bar {
  height: 63px !important;
}
.subnav-contacts-selected {
  background-color: #0094cc;
  transition: all 250ms;
  .influence-subnav-tab-title {
    color: #fff;
  }
}
.dropdown-fixed-position {
  z-index: 100000;
}

.dropdown-keywords-lists-fixed-position {
  min-width: 280px;
}

.go-to-dashboard-link {
  span {
    display: inline-block;
    margin-top: 6px;
  }
}

.legend-container-select-box {
  margin-right: '20px';
  margin-bottom: '10px';
  font-size: '0.925em';
  &:hover {
    p {
      color: $main-blue !important;
    }
  }
}

.trends-date-options {
  top: 42px !important;
}

.preloader-min-height {
  min-height: 280px;
}

.tooltip-trends-information-box {
  .committe-subtitle {
    background-position: 0px 6px !important;
  }
  .position-subtitle {
    background-position: 0px 4.85px !important;
  }
  .stake-information-parliamentary,
  .stake-information-tweet {
    background-position: 0px 4px !important;
  }
}

.trends-hover-state-feature {
  height: 24px !important;
  width: 24px !important;
  background-image: url('#{$cdn-url}202209012-information-icon.svg') !important;
  margin-left: 0px !important;
}

.trends-title.dropdown-item-element {
  &::after {
    top: 10px;
  }
}

.analytics-screenshot-image {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
}

.icon-container-changes {
  margin-left: -2px;
  margin-right: 2px;
}

.print-chart-button {
  transition: all 200ms;
  position: relative;
  cursor: pointer;
}

.chart-general-button {
  transition: 0.2s ease-in;
  background-color: $main-white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $main-grey !important;
  color: rgba($main-blue, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in;
  &:hover {
    background-color: $hovered-blue;
  }
}

.chart-action-buttons {
  margin-top: -2px;
  .general-button {
    @extend .chart-general-button;
  }
}

.graph-actions-buttons {
  span {
    position: relative;
    z-index: 2;
  }
  .general-button.animated {
    //https://codepen.io/digital_playground/pen/RwaVXOL
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      background: linear-gradient(120deg, transparent, rgba(0, 148, 204, 0.2), transparent);
      animation: shine 2s ease-in-out infinite;
    }
  }
}

.graph-actions-buttons-empty-message {
  .general-button {
    color: rgba($main-blue, 0.45) !important;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{calc(1 / (2 + 0.5) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}

.chart-icon-information {
  @extend .chart-general-button;
  width: 34px;
  height: 34px;
  margin-right: 0px;
}

.hiddenScaledChart {
  z-index: -1000;
  opacity: 0;
  position: fixed;
  top: -520px;
  left: -1394px;
  height: 520px;
  width: 1394px;
}

.country-picker {
  .hour-select {
    color: rgba($main-blue, 0.8);
    border-radius: 20px;
    border-color: $main-grey;
    padding-left: 24px !important;
    min-height: 36px;
    background-color: $main-white;
    &::after {
      right: 18px;
    }
    &:hover {
      background-color: $hovered-blue;
    }
  }
}

.country-options-dropdown {
  border: none !important;
  box-shadow:
    0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  border-radius: 8px;
  .checkbox-item {
    margin: 0.5em 0;
    &:last-of-type {
      margin-bottom: 0em !important;
    }
  }
}

.chart-buttons-scrollbar {
  height: 45px !important;
  &:hover {
    .ScrollbarsCustom-TrackX {
      opacity: 1;
    }
  }
  .ScrollbarsCustom-ThumbX {
    &:hover {
      background-color: #99a4ae !important;
    }
  }
  .ScrollbarsCustom-TrackX {
    opacity: 0;
    height: 5px !important;
  }
}

.title-spacing-adjustment {
  padding-bottom: 12px;
}

@media screen and (min-width: 992px) and (hover: hover) {
  .trends-ink-box {
    transition: 0.2s ease-in;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    }
  }

  .go-to-dashboard-link {
    transition: 0.2s ease-in;
    &:hover {
      text-decoration: underline;
      color: $light-blue;
      opacity: 0.7;
    }
  }
  .legend-item {
    &::before {
      transition: 0.2s ease-in;
    }
  }
  .legend-container-select-box {
    &:hover {
      .legend-item {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .filter-item-button,
  .filter-overlay-button {
    transition: 0.2s ease-in;
    &:hover {
      background: $hovered-blue;
    }
  }

  .filter-item-button.active {
    &:hover {
      background: $main-blue;
    }
  }

  .filter-item-button.action {
    &:hover {
      background: rgba($light-blue, 0.8);
    }
  }

  .section-filter-button {
    padding: 4px 16px 6px 18px;
    &:hover {
      background: rgba($light-blue, 0.08);
    }
  }

  .chart-action-buttons {
    .general-button {
      width: auto;
      height: auto;
      padding: 5px 16px;
      border-radius: 20px;
      margin-left: 16px;
    }
    .button-hide-text {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      padding: 0;
      .button-text {
        display: none !important;
      }
    }
  }
  .title-spacing-adjustment {
    padding-bottom: 9px;
  }
}
