.research-button-link {
  padding: 1.175em 1em 1.175em 1em;
  display: block;
  color: rgba($main-blue, 0.7);
  &:not(:first-child) {
    margin-left: 35px;
  }
}

.research-button-link.active {
  position: relative;
  color: rgba($main-blue, 1);
  .active-mark {
    display: inline-block;
    position: absolute;
    margin: auto;
    bottom: -2px;
    left: 0px;
    right: 0px;
    width: 40px;
    height: 23px;
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      background: #f9f9f9;
      transform: rotate(45deg);
      top: 15px;
      left: 8px;
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
    }
    &::after {
      content: '';
      width: 30px;
      height: 15px;
      background: #f9f9f9;
      position: absolute;
      top: 23px;
      left: 3px;
    }
  }
}

.sub-nav-searchbar {
  border: 1px solid $main-grey;
  border-radius: 20px;
  flex-grow: 1;
  .simple-searchbar-open {
    display: flex;
    height: 36px;
  }
}

.sub-nav-searchbar-influence {
  min-width: 380px;
  @media screen and (max-width: 650px) {
    min-width: 0;
  }
}

@media screen and (min-width: 992px) {
  .research-button-link {
    &:hover {
      transition: 0.2s ease-in;
      color: $main-blue;
      background-color: rgba($main-grey, 0.2);
      text-decoration: none;
    }
  }
  .research-button-link.active {
    &:hover {
      &:after {
        border-bottom: 10px solid transparent;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .sub-nav-searchbar {
    max-width: 400px;
    .simple-searchbar-open {
      max-width: none;
      margin-right: 0 !important;
    }
  }
}

@media screen and (min-width: 1366px) {
  .sub-nav-searchbar {
    max-width: 500px;
  }
}
