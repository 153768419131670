$gap-values: 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
.margin-small {
  margin-bottom: 0.75em;
}

@each $value in $gap-values {
  .gap-#{$value} {
    gap: #{$value}px;
  }
}
@each $value in $gap-values {
  .row-gap-#{$value} {
    row-gap: #{$value}px;
  }
}
@for $i from 1 through 100 {
  .specific-y-padding-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
  .specific-x-padding-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
  .specific-top-padding-#{$i} {
    padding-top: #{$i}px;
  }
  .specific-right-padding-#{$i} {
    padding-right: #{$i}px;
  }
  .specific-bottom-padding-#{$i} {
    padding-bottom: #{$i}px;
  }
  .specific-left-padding-#{$i} {
    padding-left: #{$i}px;
  }

  .specific-y-margin-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .specific-x-margin-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
  .specific-top-margin-#{$i} {
    margin-top: #{$i}px;
  }
  .specific-right-margin-#{$i} {
    margin-right: #{$i}px;
  }
  .specific-bottom-margin-#{$i} {
    margin-bottom: #{$i}px;
  }
  .specific-left-margin-#{$i} {
    margin-left: #{$i}px;
  }
}
