body.were-hiring {
  background-color: $main-white;
  .wp-content {
    padding: 2.5rem 0 7.5vh 0;
  }
}

#were-hiring {
  padding-top: 2.5em !important;
  .product-search-box {
    margin-top: 2em;
  }
  .page-searchbar {
    margin-top: 3em;
  }
  .product-search-input-box {
    display: flex;
    padding: 5px 12px 5px 42px;
    background: url('https://cdn.policymogul.com/images/icon_header_search_grey.svg') 15px 10px / 17px 17px no-repeat;
  }
  .container {
    max-width: none !important;
    padding: 0 !important;
  }
}

.hiring-content {
  padding-bottom: 50px;
  strong {
    font-weight: 700 !important;
  }
  h1 {
    font-size: 2.25em;
    margin-bottom: 0.83em !important;
  }
  p {
    color: rgba(0, 18, 43, 0.8);
    line-height: 1.65;
    strong {
      color: rgba(0, 18, 43, 1);
    }
  }
  h3 {
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 1em;
    font-size: 1.25rem;
  }
}

.product-search-box {
  border: 1px solid #d4d4d4;
  padding: 20px 30px 42px 20px;
  border-radius: 10px;
  background: #fff;
  input {
    width: 100%;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 1em 0;
  }
}

.product-search-input-box {
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  padding: 8px 20px 8px 42px;
  margin-top: 32px;
  background: url('https://cdn.policymogul.com/images/icon_header_search_grey.svg') 15px 12px / 17px 17px no-repeat;
}

.hiring-subtitle {
  color: rgba(0, 18, 43, 0.5) !important;
  max-width: 750px;
  font-size: 16px !important;
  margin: 0 auto !important;
  padding-bottom: 20px;
}

.page-searchbar {
  padding: 40px 0px;
  background: #f4efed;
}

.page-searchbar-container,
.hiring-content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.page-searchbar-container > div {
  flex-grow: 1;
}

.page-searchbar-content {
  h3 {
    font-size: 1.5em !important;
    font-weight: 700;
    margin: 0 !important;
  }
  blockquote {
    border-left: 3px solid #0094cc;
    margin-left: 10px;
    padding-left: 15px;
    p {
      color: #0094cc;
      font-size: 1.1rem;
    }
  }
}

@media screen and (min-width: 992px) {
  #were-hiring {
    .product-search-box {
      padding: 20px 50px 50px 50px;
      margin-top: 0;
    }
  }

  .page-searchbar-content {
    blockquote {
      max-width: 300px;
    }
  }

  .page-searchbar-container,
  .hiring-content {
    padding: 0;
  }

  .page-searchbar-container {
    display: flex;
  }
}
